import axios from "axios";
import {
  SETTINGS_ROUTE,
  getEndPointFor,
  getHeaders,
} from "../../utils/api_endpoints";

export const getMyProfileDetails = async ({ queryKey }) => {
  const headers = await getHeaders();
  const response = await axios
    .get(`${getEndPointFor(SETTINGS_ROUTE.base)}/currentUserProfile`, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const editMyProfileDetails = async (obj) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SETTINGS_ROUTE.base)}/currentUserProfile`, obj, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};
