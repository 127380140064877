import { useTranslation } from "react-i18next";
import { LiveInventory } from "../../pages/LiveInventory";
import Products from "../../pages/Products";
import "./style.scss";
// import { useTranslation } from "react-i18next";

// const { t } = useTranslation();
export const Widgets = () => {
  const { t } = useTranslation();

  return [
    {
      centered: true,
      tabItems: [
        {
          label: t("subNav.liveInventory"),
          key: "Tab1",
          children: <LiveInventory />,
        },
        {
          label: t("subNav.products"),
          key: "Tab2",
          children: <Products />,
        },
      ],
      type: "tab",
    },
  ];
};
