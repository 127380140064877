import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Layout,
  Pagination,
  Row,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import ConfirmationComponent from "../../components/ConfirmationComponent";
import SuccessComponent from "../../components/SuccessComponent";
import ProfileEdit from "../../components/UserProfile/ProfileEdit";
import ProfileView from "../../components/UserProfile/ProfileView";
import UserAdd from "../../components/UserProfile/UserAdd";
import "../../pages/UserProfile/UserProfile.css";
import {
  useAddUser,
  useDeleteUser,
  useEditProfile,
  useGetProfile,
} from "../../queries/react-query/userProfile";
const { Header } = Layout;

export default function SearchUsers({ searchResult, ...props }) {
  const { t } = useTranslation();
  const [editClicked, setEditClicked] = useState(false);
  const [editData, setEditData] = useState([]);
  const [addClicked, setAddClicked] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [popUp, setPopUp] = useState(0);
  const [sort, setSort] = useState("");
  const [deleteData, setDeleteData] = useState(null);

  const pageChange = (page, pageSize) => {
    // console.log(val)
    localStorage.setItem("pageStartUser", (page - 1) * 10);
    localStorage.setItem("pageSizeUser", 10);
    searchResult.refetch();
  };
  return (
    <Layout>
      <Content style={{ marginTop: "2vh", width: "98%", paddingLeft: "1vw" }}>
        <Layout
          style={{
            padding: "0",
          }}
        >
          <Row className="userTableHeader">
            <Col
              className="userTableHeader"
              span={11}
              style={{ paddingLeft: "2vw" }}
            >
              {t("usersHeaders.fullname")}
            </Col>
            {/* <Col span={6}>Organization</Col> */}
            <Col className="userTableHeader" span={11}>
              {t("usersHeaders.jobTitle")}
            </Col>
            <Col className="userTableHeader" span={2}>
              {t("usersHeaders.department")}
            </Col>
            {/* <Col span = {6}> </Col> */}
          </Row>
          {searchResult.isLoading ? (
            <Skeleton active />
          ) : (
            <Content
              className="userBody"
              style={{
                width: "100%",
                height: "53vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {searchResult.data === undefined ? (
                <Empty
                  style={{
                    width: "100%",
                    marginTop: "2vh",
                    fontFamily: "Roboto",
                  }}
                  description={<span>No results found</span>}
                />
              ) : searchResult.data.userList.length === 0 ? (
                <Empty
                  style={{
                    width: "100%",
                    marginTop: "2vh",
                    fontFamily: "Roboto",
                  }}
                  description={<span>No results found</span>}
                />
              ) : (
                searchResult.data.userList.map((data, i) => {
                  return (
                    <ProfileView
                      setConfirmDelete={setConfirmDelete}
                      key={i}
                      data={data}
                      setEditClicked={setEditClicked}
                      setEditData={setEditData}
                      setDeleteData={setDeleteData}
                      searchView={true}
                    />
                  );
                })
              )}
            </Content>
          )}
        </Layout>
      </Content>
    </Layout>
  );
}
