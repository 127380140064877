import { CameraOutlined } from "@ant-design/icons";
import { Button } from "@mui/material";
import { Upload, message } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
const { Dragger } = Upload;

export default function InventoryImageFormStepOne({
  step,
  setStep,
  addImageTo,
  fileList,
  setFileList,
  imageUrls,
  setImageUrls,
  uploadStatus,
  setUploadStatus,
}) {
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      const newUrlList = imageUrls.slice();
      newUrlList.splice(index, 1);
      const newUploadList = uploadStatus.slice();
      newUploadList.splice(index, 1);
      setUploadStatus(newUploadList);
      setImageUrls(newUrlList);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (file.type.split("/")[0] === "image" || file.type.includes("video")) {
        setFileList([...fileList, file]);
        setUploadStatus([...uploadStatus, 0]);
        const reader = new FileReader();
        reader.onload = () => {
          setImageUrls([
            ...imageUrls,
            {
              preSignedUrl: reader.result,
              fileType: file.type,
              fileId: Math.floor(Math.random() * 100000),
            },
          ]);
        };
        reader.readAsDataURL(file);
      } else {
        message.error(`${file.name} is not a image`);
      }
      return false;
    },
    fileList,
  };
  return (
    <>
      <Content style={{ textAlign: "center", width: "100%" }}>
        <p
          className="imageUploadHeading"
          style={{
            fontFamily: "Roboto",
          }}
        >
          Image(s) for{" "}
          {addImageTo.slab !== "-1" ? `Slab ${addImageTo.slab} -` : ""}{" "}
          {addImageTo.bundle !== "-1" ? `Bundle ${addImageTo.bundle}` : ""}{" "}
          {addImageTo.block ? `- Block ${addImageTo.block}` : ""}
        </p>
      </Content>
      <Dragger
        {...props}
        accept="image/*,video/*"
        style={{ paddingTop: "5vh", paddingBottom: "5vh" }}
      >
        <p className="ant-upload-drag-icon">
          <CameraOutlined />
        </p>
        <p
          className="ant-upload-text UploadDescription"
          style={{
            fontFamily: "Roboto",
          }}
        >
          Click or drag file to this area to upload
        </p>
        {/* <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
            banned files.
            </p> */}
      </Dragger>
      <Content style={{ textAlign: "right", width: "100%" }}>
        <Button
          variant="contained"
          type="Next"
          // className={classes.buttonSeparate}
          className="nextButton"
          sx={{
            marginTop: "1vh",
            padding: "1vh 3vw",
            borderRadius: "100px",
            background: "var(--primary-blue-500, #0C25F3)",
          }}
          onClick={() => {
            setStep(1);
          }}
          disabled={fileList.length === 0 ? true : false}
        >
          Next
        </Button>
      </Content>
    </>
  );
}
