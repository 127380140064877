const isMobile = () => {
  return window.innerWidth <= 767;
};

const set2placeToDecimal = (value) => parseFloat(value).toFixed(2);

export const globalConstants = {
  isMobile,
  set2placeToDecimal,
};
