import { useMutation, useQuery } from "react-query";
import {
  cancelOffer,
  createOffer,
  getListOfOffers,
  getOffer,
  updateSlabDetailsOffers,
} from "../api/offers";

export const useCreateOffers = (queryKey, onSuccess, createOfferError) => {
  return useMutation({
    mutationKey: queryKey,
    mutationFn: createOffer,
    onError: (error) => {
      if (error.response.status === 500) {
        // notify(
        //   t("toast.reachAdmin", { item: t("toast.inventory") }),
        //   TOAST_TYPE.FAIL
        // );
      } else if (
        error.response.status / 100 >= 4 &&
        error.response.status / 100 < 5
      ) {
        createOfferError(error.response.data);
      }
    },
    onSuccess: onSuccess,
  });
};

export const useGetOffers = (queryKey) => {
  return useQuery({ queryKey: queryKey, queryFn: getListOfOffers });
};

export const useCancelOffer = (queryKey, onSuccess) => {
  return useMutation({
    mutationKey: queryKey,
    mutationFn: cancelOffer,
    onSuccess: onSuccess,
  });
};

export const useGetOffer = (queryKey) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: getOffer,
  });
};

export const useUpdateSlabDetailsOffers = (mutationKey, onSuccess) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: updateSlabDetailsOffers,
    onSuccess: onSuccess,
  });
};
