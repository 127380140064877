import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { TOAST_TYPE, notify } from "../../utils/constants";
import {
  createSales,
  deleteCart,
  fetchCart,
  getBundleList,
  getSlabsByBundleId,
  getSummary,
} from "../api/cart";

export const useGetCartItems = (queryKey) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: fetchCart,
  });
};

export const useGetBundleList = (queryKey, onSuccess, enabled) => {
  // TODO: Add query filter based on slab or block
  const { t } = useTranslation();
  return useQuery({
    queryKey: queryKey,
    queryFn: getBundleList,
    onSuccess: onSuccess,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(
          t("toast.reachAdmin", { item: t("toast.inventory") }),
          TOAST_TYPE.FAIL
        );
      } else {
        return (
          <Snackbar open={true} autoHideDuration={6000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Error fetching bundles
            </Alert>
          </Snackbar>
        );
      }
    },
    enabled: enabled,
  });
};

export const useGetSlabsByBundleIdCart = (queryKey, onSuccess, enabled) => {
  // TODO: Add query filter based on slab or block
  const { t } = useTranslation();
  return useQuery({
    queryKey: queryKey,
    queryFn: getSlabsByBundleId,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(
          t("toast.reachAdmin", { item: t("toast.inventory") }),
          TOAST_TYPE.FAIL
        );
      } else {
        return (
          <Snackbar open={true} autoHideDuration={6000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Error fetching slab details
            </Alert>
          </Snackbar>
        );
      }
    },
    enabled: enabled,
  });
};

export const useGetSummaryForOffer = (queryKey) => {
  // TODO: Add query filter based on slab or block
  const { t } = useTranslation();
  return useQuery({
    queryKey: queryKey,
    queryFn: getSummary,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(
          t("toast.reachAdmin", { item: t("toast.inventory") }),
          TOAST_TYPE.FAIL
        );
      } else {
        return (
          <Snackbar open={true} autoHideDuration={6000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Error fetching Offer summary
            </Alert>
          </Snackbar>
        );
      }
    },
  });
};

export const useCreateSales = (queryKey, onSuccess, createSalesError) => {
  const { t } = useTranslation();
  return useMutation({
    mutationKey: queryKey,
    mutationFn: createSales,
    onError: (error) => {
      if (error.response.status === 500) {
        // notify(
        //   t("toast.reachAdmin", { item: t("toast.inventory") }),
        //   TOAST_TYPE.FAIL
        // );
      } else if (
        error.response.status / 100 >= 4 &&
        error.response.status / 100 < 5
      ) {
        createSalesError(error.response.data);
      }
    },
    onSuccess: onSuccess,
  });
};

export const useDeleteCart = (queryKey, onSuccess) => {
  const { t } = useTranslation();
  return useMutation({
    mutationKey: queryKey,
    mutationFn: deleteCart,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(
          t("toast.reachAdmin", { item: t("toast.inventory") }),
          TOAST_TYPE.FAIL
        );
      } else {
        return (
          <Snackbar open={true} autoHideDuration={6000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Error in deleting item from cart
            </Alert>
          </Snackbar>
        );
      }
    },
    onSuccess: onSuccess,
  });
};
