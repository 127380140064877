import { useTranslation } from "react-i18next";
import MainLayout from "../../components/Search/MainLayout";

export const Widgets = () => {
  const { t } = useTranslation();
  return [
    {
      centered: true,
      tabItems: [
        {
          key: "Search",
          children: <MainLayout />,
        },
      ],
      type: "tab",
    },
  ];
};
