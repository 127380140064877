import {
  DeleteOutline,
  Done,
  DoneOutline,
  EditOutlined,
} from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Card, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function AdditionalContact(props) {
  const { t } = useTranslation();
  const [template, setTemplate] = useState(
    props.value === null
      ? {
          id: props.key1,
          contactName: "",
          phoneNumber: "",
          email: "",
        }
      : props.value
  );
  const [flag, setFlag] = useState(props.value === null ? false : true);
  const [checkFlag, setCheckFlag] = useState(
    props.value === null ? false : true
  );

  const editClickFn = () => {
    setCheckFlag(false);
    setFlag(false);
  };
  const saveDetails = () => {
    props.data.additionalContactList = props.data.additionalContactList.filter(
      (obj) => obj.id !== props.key1
    );
    props.data.additionalContactList.push(template);
    setCheckFlag(true);
    setFlag(true);
  };
  return (
    <Card
      size="small"
      title="Additional Contact"
      extra={
        <Content style={{ width: "100%" }}>
          <DeleteOutline
            sx={{ fontSize: "x-large", color: "red" }}
            onClick={props.onDelete}
          />
          {flag && checkFlag ? (
            <EditOutlined
              sx={{ fontSize: "x-large", color: "#0C25F3", marginLeft: "1vw" }}
              onClick={editClickFn}
            />
          ) : (
            <Done
              sx={{ fontSize: "x-large", color: "#0C25F3", marginLeft: "1vw" }}
              onClick={saveDetails}
            />
          )}
        </Content>
      }
      style={{
        width: "33vw",
        marginLeft: "33.5vw",
        marginBottom: "1vh",
        padding: "1vh 1vw",
        borderRadius: "8px",
        borderBottom:
          "0.75px solid var(--neutral-background-text-white-300, #E6E6E6)",
        background: "var(--neutral-background-text-white-100, #F9F9F9)",
      }}
    >
      <Content style={{ width: "100%", paddingBottom: "2vh" }}>
        <p className="inputHeader" style={{ marginLeft: "0" }}>
          {t("customers.contactName")}
        </p>
        <Content style={{ width: "100%" }} className="ProfileRow">
          <Content style={{ width: "100%", textAlign: "center" }}>
            <TextField
              sx={{ width: "30vw" }}
              id="outlined-basic"
              label={t("customers.contactName")}
              variant="outlined"
              defaultValue={
                template.contactName === "--" ? "" : template.contactName
              }
              onChange={(e) => {
                e.preventDefault();
                setTemplate((prevState) => ({
                  ...prevState,
                  contactName: e.target.value,
                }));
              }}
            />
          </Content>
        </Content>
      </Content>
      <Content style={{ width: "100%", paddingBottom: "2vh" }}>
        <p className="inputHeader" style={{ marginLeft: "0" }}>
          {t("customers.phoneNo")}
        </p>
        <Content style={{ width: "100%" }} className="ProfileRow">
          <Content style={{ width: "100%", textAlign: "center" }}>
            <Space>
              <FormControl sx={{ width: "5vw" }}>
                <InputLabel id="demo-simple-select-label">Code</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Code"
                  disabled={flag && checkFlag}
                  value={
                    template.phoneNumber.split(" ") === "--"
                      ? "Select"
                      : template.phoneNumber.split(" ")[0]
                  }
                  onChange={(e) => {
                    if (template.phoneNumber === undefined) {
                      setTemplate((prevState) => ({
                        ...prevState,
                        phoneNumber: e.target.value,
                      }));
                    } else {
                      const firstWord = template.phoneNumber.split(" ");
                      if (firstWord.length === 1)
                        setTemplate((prevState) => ({
                          ...prevState,
                          phoneNumber: e.target.value,
                        }));
                      else
                        setTemplate((prevState) => ({
                          ...prevState,
                          phoneNumber: `${e.target.value} ${firstWord[1]}`,
                        }));
                    }
                  }}
                >
                  {props.phoneNoCode.map((phoneNumber, i) => {
                    return (
                      <MenuItem key={i} value={phoneNumber.value}>
                        {phoneNumber.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                id="outlined-basic"
                label={t("customers.phoneNo")}
                variant="outlined"
                sx={{ width: "25vw" }}
                defaultValue={
                  template.phoneNumber.split(" ") === "--"
                    ? ""
                    : template.phoneNumber.split(" ").length === 1
                    ? ""
                    : template.phoneNumber.split(" ")[1]
                }
                onChange={(e) => {
                  e.preventDefault();
                  const firstWord = template.phoneNumber.split(" ");

                  setTemplate((prevState) => ({
                    ...prevState,
                    phoneNumber: `${firstWord[0]} ${e.target.value}`,
                  }));
                }}
              />
            </Space>
          </Content>
        </Content>
      </Content>
      <Content style={{ width: "100%", paddingBottom: "2vh" }}>
        <p className="inputHeader" style={{ marginLeft: "0" }}>
          {t("customers.email")}
        </p>
        <Content style={{ width: "100%" }} className="ProfileRow">
          <Content style={{ width: "100%", textAlign: "center" }}>
            <TextField
              sx={{ width: "30vw" }}
              id="outlined-basic"
              label={t("customers.email")}
              variant="outlined"
              defaultValue={template.email === "--" ? "" : template.email}
              onChange={(e) => {
                e.preventDefault();
                setTemplate((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }));
              }}
              disabled={flag && checkFlag}
            />
          </Content>
        </Content>
      </Content>
    </Card>
  );
}
