import { Col, Collapse, Descriptions, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { useTranslation } from "react-i18next";
import ProfileRow from "./ProfileRow";
const { Panel } = Collapse;

export default function ProfileView(props) {
  const { t } = useTranslation();
  return (
    <Collapse
      className="collapseHeaderUser"
      style={{ marginBottom: "24px", width: "100%" }}
    >
      <Panel
        header={
          <ProfileRow
            data={props.data}
            setConfirmDelete={props.setConfirmDelete}
            setEditClicked={props.setEditClicked}
            setEditData={props.setEditData}
            setDeleteData={props.setDeleteData}
            searchView={props.searchView}
            deleteMutate={props.deleteMutate}
            confirmDelete={props.confirmDelete}
            index={props.index}
            loadingIndex={props.loadingIndex}
            setLoadingIndex={props.setLoadingIndex}
          />
        }
        showArrow={false}
        key="1"
      >
        <Content style={{ width: "100%" }}>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">
                {t("usersHeaders.employeeNumber")}
              </p>
              <p className="profileValues">
                {props.data.employeeNumber ? props.data.employeeNumber : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{t("usersHeaders.joiningDate")}</p>
              <p className="profileValues">
                {props.data.joinDate ? props.data.joinDate : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{t("usersHeaders.department")}</p>
              <p className="profileValues">
                {props.data.department ? props.data.department : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{t("usersHeaders.jobTitle")}</p>
              <p className="profileValues">
                {props.data.jobTitle ? props.data.jobTitle : "--"}
              </p>
            </Content>
          </Content>
        </Content>
        <Content
          style={{ paddingTop: "1vh", paddingBottom: "2vh", width: "100%" }}
        >
          <Content
            style={{
              borderBottom:
                "0.5px solid var(--neutral-background-text-white-900, #797979)",
              width: "100%",
            }}
          ></Content>
        </Content>
        <Content style={{ width: "100%" }}>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{t("usersHeaders.userID")}</p>
              <p className="profileValues">
                {props.data.email ? props.data.email : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{t("usersHeaders.accessLevel")}</p>
              <p className="profileValues">
                {props.data.accessType
                  ? props.data.accessType === "admin"
                    ? "Admin"
                    : "Non-Admin"
                  : "Non-Admin"}
              </p>
            </Content>
          </Content>
        </Content>
        <Content
          style={{ paddingTop: "1vh", paddingBottom: "1vh", width: "100%" }}
        >
          <Content
            style={{
              borderBottom:
                "0.5px solid var(--neutral-background-text-white-900, #797979)",
              width: "100%",
            }}
          ></Content>
        </Content>
        <Content style={{ width: "100%" }}>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{t("usersHeaders.phoneNo")}</p>
              <p className="profileValues">
                {props.data.phoneNumber ? props.data.phoneNumber : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">
                {t("usersHeaders.secondaryAddress")}
              </p>
              {/* {console.log((props.data.address1 === undefined || props.data.address1 ==="") && (props.data.address2 === undefined || props.data.address2 === "") && (props.data.state === undefined || props.data.state === "") && (props.data.country === undefined || props.data.country === "") && (props.data.zipCode === undefined || props.data.zipCode === "") )} */}
              {(props.data.address1 === undefined ||
                props.data.address1 === "") &&
              (props.data.address2 === undefined ||
                props.data.address2 === "") &&
              (props.data.state === undefined || props.data.state === "") &&
              (props.data.country === undefined || props.data.country === "") &&
              (props.data.zipCode === undefined ||
                props.data.zipCode === "") ? (
                <>
                  <p className="profileValues" style={{ margin: "0" }}>
                    --
                  </p>
                </>
              ) : (
                <>
                  {props.data.address1 ? (
                    <p className="profileValues" style={{ margin: "0" }}>
                      {props.data.address1 ? props.data.address1 : "--"}
                    </p>
                  ) : (
                    <></>
                  )}
                  {props.data.address2 ? (
                    <p className="profileValues" style={{ margin: "0" }}>
                      {props.data.address2 ? props.data.address2 : "--"}
                    </p>
                  ) : (
                    <></>
                  )}
                  {props.data.state !== undefined ||
                  props.data.country !== undefined ||
                  props.data.zipCode !== undefined ? (
                    <p className="profileValues" style={{ margin: "0" }}>
                      {props.data.state ? props.data.state + ", " : ""}
                      {props.data.country ? props.data.country + ", " : ""}
                      {props.data.zipCode ? props.data.zipCode : ""}
                    </p>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Content>
          </Content>
        </Content>
        {/* <Row>
          <Col span={8}>
            <Descriptions
              className="Header"
              title="Contact details"
              layout="vertical"
              style={{ paddingLeft: "2.5vw" }}
              labelStyle={{
                fontFamily: "'Open Sans', sans-serif",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "100%",
                letterSpacing: "0.005em",
                color: "black",
              }}
            >
              <Descriptions.Item className="Body" label="Phone no." span={3}>
                {props.data.phoneNumber ? props.data.phoneNumber : "--"}
              </Descriptions.Item>
              <Descriptions.Item className="Body" label="Email" span={3}>
                {props.data.email ? props.data.email : "--"}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={16}>
            <Descriptions
              className="Header"
              title="Geographical details"
              layout="vertical"
              labelStyle={{
                fontFamily: "'Open Sans', sans-serif",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "100%",
                letterSpacing: "0.005em",
                color: "black",
              }}
            >
              <Descriptions.Item className="Body" label="Time zone" span={3}>
                {props.data.timeZone ? props.data.timeZone : "--"}
              </Descriptions.Item>
              <Descriptions.Item className="Body" label="Language" span={3}>
                {props.data.language ? props.data.language : "--"}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Row style={{ margin: "1vh 0 0 0" }}>
          <Col span={8}>
            <Descriptions
              className="Header"
              title="Job details"
              style={{ paddingLeft: "2.5vw" }}
              layout="vertical"
              labelStyle={{
                fontFamily: "'Open Sans', sans-serif",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "100%",
                letterSpacing: "0.005em",
                color: "black",
              }}
            >
              <Descriptions.Item
                className="Body"
                label="Employee number"
                span={3}
              >
                {props.data.employeeNumber ? props.data.employeeNumber : "--"}
              </Descriptions.Item>
              <Descriptions.Item className="Body" label="Joining date" span={3}>
                {props.data.joinDate ? props.data.joinDate : "--"}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={16}>
            <Descriptions
              className="Header"
              title="Address Details"
              layout="vertical"
              labelStyle={{
                fontFamily: "'Open Sans', sans-serif",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "100%",
                letterSpacing: "0.005em",
                color: "black",
              }}
            >
              <Descriptions.Item className="Body" label="Address line 1">
                {props.data.address1 ? props.data.address1 : "--"}
              </Descriptions.Item>
              <Descriptions.Item
                className="Body"
                label="Address line 2"
                span={3}
              >
                {props.data.address2 ? props.data.address2 : "--"}
              </Descriptions.Item>
              <Descriptions.Item className="Body" label="Zip code">
                {props.data.zipCode ? props.data.zipCode : "--"}
              </Descriptions.Item>
              <Descriptions.Item className="Body" label="State">
                {props.data.state ? props.data.state : "--"}
              </Descriptions.Item>
              <Descriptions.Item className="Body" label="Country">
                {props.data.country ? props.data.country : "--"}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row> */}
      </Panel>
    </Collapse>
  );
}
