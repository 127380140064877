import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { ProductConstants } from "../../utils/product";

const { colours, category } = ProductConstants;

countries.registerLocale(enLocale);

const countryObj = countries.getNames("en", { select: "official" });

const countryArr = Object.entries(countryObj).map(([key, value]) => {
  return {
    label: value,
    value: value,
  };
});

export const fields = [
  {
    column: 24,
    name: "name",
    label: "productContainer.name",
    type: "input",
    required: true,
    subfields: [
      {
        column: 8,
        name: "category",
        label: "inventoryForm.category",
        type: "select",
        options: category,
      },
      {
        column: 8,
        name: "colour",
        label: "inventoryForm.colour",
        type: "select",
        options: colours,
      },
      {
        column: 8,
        name: "location",
        label: "inventoryForm.location",
        type: "select",
        options: countryArr,
      },
    ],
  },
];
