const quotes = [
  {
    quote: "Only a life lived for others is a life lived worthwhile.",
    author: "Albert Einstein",
  },
  {
    quote: "There is more to life than increasing its speed.",
    author: "Mahatma Gandhi",
  },
  {
    quote: "Because I have loved life, I shall have no sorrow to die.",
    author: "Amelia Burr",
  },
  {
    quote:
      "We must be willing to let go of the life we planned so as to have the life that is waiting for us.",
    author: "Joseph Campbell",
  },
  {
    quote: "Life is a process. We are a process. The universe is a process.",
    author: "Anne Wilson Schaef",
  },
  {
    quote:
      "I can't change the direction of the wind, but I can adjust my sails to always reach my destination.",
    author: "Jimmy Dean",
  },
  {
    quote: "The good life is inspired by love and guided by knowledge.",
    author: "Bertrand Russell",
  },
  {
    quote: "Breathe. It's just a bad day, not a bad life.",
    author: "Johnny Depp",
  },
  {
    quote: "Dream as if you'll live forever, live as if you'll die today.",
    author: "James Dean",
  },
  {
    quote: "It does not matter how slowly you go as long as you do not stop.",
    author: "Confucius",
  },
  {
    quote: "I don't need it to be easy, I need it to be worth it.",
    author: "Lil Wayne",
  },
  {
    quote:
      "Life becomes easier when you learn to accept the apology you never got.",
    author: "R. Brault",
  },
  { quote: "Broken crayons still color.", author: "Ralph Waldo Emerson" },
  { quote: "Leave no stone unturned.", author: "Euripides" },
  {
    quote:
      "It is more fitting for a man to laugh at life than to lament over it.",
    author: "Seneca",
  },
  {
    quote:
      "Never bend your head. Always hold it high. Look the world straight in the face.",
    author: "Helen Keller",
  },
  {
    quote: "Time you enjoy wasting is not wasted time.",
    author: "Marthe Troly-Curtin",
  },
  { quote: "Nothing will work unless you do.", author: "Maya Angelou" },
  {
    quote:
      "The ones who are crazy enough to think they can change the world, are the ones that do.",
    author: "Steve Jobs",
  },
  { quote: "Die with memories, not dreams.", author: "Unknown" },
  {
    quote:
      "The road to success and the road to failure are almost exactly the same.",
    author: "Colin R. Davis",
  },
  {
    quote: "Victory is sweetest when you've known defeat.",
    author: "Malcolm S. Forbes",
  },
  { quote: "Whatever you do, do it well.", author: "Walt Disney" },
  {
    quote:
      "Success is not final, failure is not fatal: it is the courage to continue that counts.",
    author: "Winston Churchill",
  },
  {
    quote: "Tough times never last but tough people do.",
    author: "Robert H. Schuller",
  },
  {
    quote: "Whatever you do, do with all your might.",
    author: "Marcus Tullius Cicero",
  },
  { quote: "Try Again. Fail again. Fail better.", author: "Samuel Beckett" },
  {
    quote: "Action is the foundational key to all success.",
    author: "Pablo Picasso",
  },
  { quote: "Impossible is for the unwilling.", author: "John Keats" },
  {
    quote: "The successful warrior is the average man, with laser-like focus.",
    author: "Bruce Lee",
  },
  {
    quote: "Don't let what you cannot do interfere with what you can do.",
    author: "John R. Wooden",
  },
  {
    quote:
      "Success is going from failure to failure without losing enthusiasm.",
    author: "Winston Churchill",
  },
  {
    quote: "Growth begins when we start to accept our own weakness.",
    author: "Jean Vanier",
  },
  {
    quote:
      "Never stop fighting until you arrive at your destined place – that is, the unique you. Have an aim in life, continuously acquire knowledge, work hard, and have the perseverance to realize the great life.",
    author: "A.P.J. Abdul Kalam",
  },
  { quote: "Perseverance, the secret of all triumphs.", author: "Victor Hugo" },
  {
    quote:
      "Don't be discouraged. It's often the last key in the bunch that opens the lock.",
    author: "Anonymous",
  },
  {
    quote:
      "Every great dream begins with a dreamer. Always remember, you have within you the strength, the patience, and the passion to reach of the stars to change the world.",
    author: "Harriet Tubman",
  },
  {
    quote:
      "Each life is made up of mistakes and learning, waiting and growing, practicing patience and being persistent.",
    author: "Billy Graham",
  },
  {
    quote:
      "Patience and perseverance have a magical effect before which difficulties disappear and obstacles vanish.",
    author: "John Quincy Adams",
  },
  {
    quote:
      "Just because you fail once doesn't mean you're gonna fail at everything.",
    author: "Marilyn Monroe",
  },
  {
    quote: "anything in life worth having comes from patience and hard work.",
    author: "Greg Behrendt",
  },
  {
    quote:
      "I will persist until I succeed. Always will I take another step. If that is of no avail I will take another, and yet another. In truth, one step at a time is not too difficult. I know that small attempts, repeated, will complete any undertaking.",
    author: "Og Mandino",
  },
  {
    quote:
      "If patience is worth anything, it must endure to the end of time. And a living faith will last in the midst of the blackest storm.",
    author: "Mahatma Gandhi",
  },
  {
    quote:
      "Have patience with all things, but chiefly have patience with yourself. Do not lose courage in considering your own imperfections but instantly set about remedying them – every day begin the task anew.",
    author: "Saint Francis de Sales",
  },
  {
    quote:
      "I learned patience, perseverance, and dedication. Now I really know myself, and I know my voice. It's a voice of pain and victory.",
    author: "Anthony Hamilton",
  },
  {
    quote: "You usually have to wait for that which is worth waiting for.",
    author: "Craig Bruce",
  },
  {
    quote:
      "There is something good in all seeming failures. You are not to see that now. Time will reveal it. Be patient.",
    author: "Sivananda Saraswati",
  },
  {
    quote:
      "Indeed, this life is a test. It is a test of many things – of our convictions and priorities, our faith and our faithfulness, our patience and our resilience, and in the end, our ultimate desires.",
    author: "Sheri L. Dew",
  },
  {
    quote: "Have patience. All things are difficult before they become easy.",
    author: "Saadi",
  },
  {
    quote:
      "Almost everything is outside of your control. You may take all the right actions and fail. You may take no action and win. All you can do is put yourself in the position to win. Be consistent. Patience will eventually get luck on your side.",
    author: "Crypto Seneca",
  },
  {
    quote:
      "Purity, patience, and perseverance are the three essentials to success and above all, love.",
    author: "Swami Vivekananda",
  },
  {
    quote: "Great difficulties may be surmounted by patience and perseverance.",
    author: "Abigail Adams",
  },
  {
    quote:
      "Making your mark on the world is hard. If it were easy, everybody would do it. But it's not. It takes patience, it takes commitment, and it comes with plenty of failures along the way. The real test is not whether you avoid this failure because you won't. It's whether you let it harden or shame you into inaction, or whether you learn from it; whether you choose to persevere.",
    author: "Barack Obama",
  },
  {
    quote:
      "Life is not easy for any of us. But what of that? We must have perseverance and above all confidence in ourselves.",
    author: "Marie Curie",
  },
  {
    quote:
      "And you very soon find out, if you have not found it out before, that patience and tenacity of purpose are worth more than twice their weight of cleverness.",
    author: "Thomas Huxley",
  },
  {
    quote:
      "When you have a great and difficult task, something perhaps almost impossible, if you only work a little at a time, every day a little, suddenly the work will finish itself.",
    author: "Isak Dinesen",
  },
  {
    quote:
      "Through perseverance many people win success out of what seemed destined to be certain failure.",
    author: "Benjamin Disraeli",
  },
  {
    quote:
      "Good things come to those who wait. But while waiting, you have to make sure that you are also doing something to make the dream happen. Patience and perseverance are the keys to a successful life.",
    author: "Unknown",
  },
  {
    quote:
      "No matter how long it is going to take, know that a determined heart always wins the battle in the end. Keep pushing and turn the odds in your favor—you are meant to do great things in life.",
    author: "Unknown",
  },
  {
    quote:
      "Grit is that ‘extra something' that separates the most successful people from the rest. It's the passion, perseverance, and stamina that we must channel in order to stick with our dreams until they become a reality.",
    author: "Travis Bradberry",
  },
  {
    quote:
      "Failure after long perseverance is much grander than never to have a striving good enough to be called a failure.",
    author: "George Eliot",
  },
  {
    quote:
      "Of all that is good, sublimity is supreme. Succeeding is the coming together of all that is beautiful. Furtherance is the agreement of all that is just. Perseverance is the foundation of all actions.",
    author: "Lao Tzu",
  },
  {
    quote:
      "If your determination is fixed, I do not counsel you to despair. Few things are impossible to diligence and skill. Great works are performed not by strength, but perseverance.",
    author: "Samuel Johnson",
  },
  {
    quote:
      "Permanence, perseverance, and persistence in spite of all obstacles, discouragements, and impossibilities: it is this that in all things distinguishes the strong soul from the weak.",
    author: "Thomas Carlyle",
  },
  {
    quote:
      "Most of the important things in the world have been accomplished by people who have kept on trying when there seemed to be no hope at all.",
    author: "Dale Carnegie",
  },
  {
    quote:
      "Success is the child of drudgery and perseverance. It cannot be coaxed or bribed; pay the price and it is yours.",
    author: "Orison Swett Marden",
  },
  {
    quote:
      "You may encounter many defeats, but you must not be defeated. In fact, it may be necessary to encounter the defeats, so you can know who you are, what you can rise from, how you can still come out of it.",
    author: "Maya Angelou",
  },
  {
    quote:
      "Courage doesn't always roar, sometimes it's the quiet voice at the end of the day whispering I will try again tomorrow.",
    author: "Mary Anne Radmacher",
  },
  {
    quote:
      "A river cuts through rock, not because of its power, but because of its persistence.",
    author: "Jim Watkins",
  },
  {
    quote:
      "There are two ways of attaining an important end, force and perseverance; the silent power of the latter grows irresistible with time.",
    author: "Sophie Swetchine",
  },
  {
    quote:
      "The difference between perseverance and obstinacy is that one comes from a strong will, and the other from a strong won't.",
    author: "Henry Ward Beecher",
  },
  {
    quote:
      "Courage is not having the strength to go on; it is going on when you don't have the strength.",
    author: "Teddy Roosevelt",
  },
  {
    quote: "It always seems impossible until it's done.",
    author: "Nelson Mandela",
  },
  {
    quote:
      "Failure is often that early morning hour of darkness which precedes the dawning of the day of success.",
    author: "Leigh Mitchell Hodges",
  },
  {
    quote: "You may have to fight a battle more than once to win it.",
    author: "Margaret Thatcher",
  },
  {
    quote:
      "Perseverance is the hard work you do after you get tired of doing the hard work you already did.",
    author: "Newt Gingrich",
  },
  {
    quote:
      "Success is not final; failure is not fatal: It is the courage to continue that counts.",
    author: "Winston Churchill",
  },
  {
    quote:
      "When you undervalue what you do, the world will undervalue who you are.",
    author: "Oprah Winfrey",
  },
  { quote: "Dream big and dare to fail.", author: "Norman Vaughan" },
  { quote: "Courage is grace under pressure.", author: "Ernest Hemingway" },
  {
    quote:
      "It is still best to be honest and truthful; to make the most of what we have; to be happy with simple pleasures; and have courage when things go wrong.",
    author: "Laura Ingalls Wilder",
  },
  {
    quote: "Nothing is impossible, the word itself says, 'I'm possible!'",
    author: "Audrey Hepburn",
  },
  {
    quote: "It does not matter how slowly you go as long as you do not stop.",
    author: "Confucius",
  },
  {
    quote: "Don't find fault, find a remedy: anyone can complain.",
    author: "Henry Ford",
  },
  {
    quote: "A man may die, nations may rise and fall, but an idea lives on",
    author: "John F. Kennedy",
  },
  {
    quote:
      "I have learned over the years that when one's mind is made up, this diminishes fear.",
    author: "Rosa Parks",
  },
  {
    quote: "I didn't fail the test. I just found 100 ways to do it wrong.",
    author: "Benjamin Franklin",
  },
  { quote: "With great power comes great responsibility.", author: "Stan Lee" },
  {
    quote: "I would rather die of passion than of boredom.",
    author: "Vincent van Gogh",
  },
  {
    quote:
      "If you look at what you have in life, you'll always have more. If you look at what you don't have in life, you'll never have enough.",
    author: "Oprah Winfrey",
  },
  {
    quote: "Dreaming, after all, is a form of planning.",
    author: "Gloria Steinem",
  },
  {
    quote: "Whatever the mind of man can conceive and believe, it can achieve.",
    author: "Napoleon Hill",
  },
  {
    quote:
      "First, have a definite, clear practical ideal; a goal, an objective. Second, have the necessary means to achieve your ends; wisdom, money, materials, and methods. Third, adjust all your means to that end.",
    author: "Aristotle",
  },
  {
    quote:
      "Twenty years from now you will be more disappointed by the things that you didn't do than by the ones you did do. So, throw off the bowlines, sail away from safe harbor, catch the trade winds in your sails. Explore, Dream, Discover.",
    author: "Mark Twain",
  },
];

export const QuotesConstants = {
  quotes,
};
