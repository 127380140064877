import { PictureFilled, RightOutlined } from "@ant-design/icons";
import { Col, Collapse, Row } from "antd";
import _ from "lodash";
import { useState } from "react";
import { globalConstants } from "../../utils/global";
import { inventoryConstants } from "../../utils/inventory";
import "../BundleCard/style.css";
import SlabTableSales from "./SlabTableSales";

const { Panel } = Collapse;

const BundleCardSales = ({
  bundles,
  productId,
  expand,
  onDrawerExpand,
  selectedItems,
  onItemsSelected,
  setImages,
  images,
  viewing,
  setViewing,
  setSelectedProductId,
  setSelectedImagesSourceData,
  ...props
}) => {
  const [selectedParentRowKeys, setSelectedParentRowKeys] = useState([]);
  const { set2placeToDecimal } = globalConstants;
  const { currencies } = inventoryConstants;

  return (
    <Collapse
      className="BundleCollapseSales InnerCollapse"
      style={{ marginBottom: "2vh" }}
      expandIcon={() => <></>}
    >
      <Panel
        header={
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "16.66% 16.66% 16.66% 16.66% 16.66% 16.66%",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <RightOutlined
                style={{
                  position: "absolute",
                  left: "20px",
                  marginTop: "4px",
                  color: "#2c3fff",
                }}
              />
              {bundles.bundleNumber ? bundles.bundleNumber.slice(0, 9) : "--"}
            </div>

            <div style={{ textAlign: "center" }}>{bundles.totalSlabs}</div>

            <div style={{ textAlign: "center" }}>
              {set2placeToDecimal(bundles.totalPrice)}{" "}
              {currencies[bundles.totalPriceCurrencyCode]}
            </div>

            <div style={{ textAlign: "center" }}>{bundles.quality}</div>

            <div style={{ textAlign: "center" }}>{bundles.finish}</div>

            <div style={{ textAlign: "center" }}>
              {bundles.images !== undefined && bundles.images.length !== 0 ? (
                <PictureFilled
                  style={{
                    color: "#2c3fff",
                    cursor: "pointer",
                    fontSize:
                      viewing.bundle === bundles.bundleNumber &&
                      viewing.slab === "-1"
                        ? "x-large"
                        : "large",
                  }}
                  onClick={() => {
                    setImages(bundles.images);
                    setSelectedProductId(productId);
                    setSelectedImagesSourceData(bundles);
                    setViewing({ bundle: bundles.bundleNumber, slab: "-1" });
                    props.setActiveStep(0);
                  }}
                />
              ) : (
                <PictureFilled
                  style={{
                    color: "grey",
                    fontSize: "large",
                    cursor: "not-allowed",
                  }}
                />
              )}
            </div>
          </div>
        }
      >
        <SlabTableSales
          productId={productId}
          bundleId={bundles.bundleNumber}
          salesData={bundles.slabList}
          onItemsSelected={onItemsSelected}
          selectedParentRowKeys={selectedParentRowKeys}
          setImagePopUp={props.setImagePopUp}
          setImagePopUpValue={props.setImagePopUpValue}
          setSelectedSlab={props.setSelectedSlab}
          selectedSlab={props.selectedSlab}
          setImages={setImages}
          images={images}
          viewing={viewing}
          setViewing={setViewing}
          setActiveStep={props.setActiveStep}
          entityId={props.entityId}
          value={props.value}
          searchView={props.searchView ? true : false}
          setIndex={props.setIndex}
          offerId={props.offerId} //In case If it is coming from the Offer page
          setSelectedProductId={setSelectedProductId}
          setSelectedImagesSourceData={setSelectedImagesSourceData}
        />
      </Panel>
    </Collapse>
  );
};

export default BundleCardSales;
