import axios from "axios";
import {
  SETTINGS_ROUTE,
  getEndPointFor,
  getHeaders,
} from "../../utils/api_endpoints";

export const getCompanyDetails = async ({ queryKey }) => {
  const headers = await getHeaders();
  const response = await axios
    .get(`${getEndPointFor(SETTINGS_ROUTE.base)}/companyProfile`, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const editCompanyDetails = async (obj) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SETTINGS_ROUTE.base)}/companyProfile`, obj, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};
