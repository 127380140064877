import { Button, Card, Layout } from "antd";
import React from "react";
import imageValue from "../Images/Alpine_Blue.jpeg";

export default function ImagePopUp(props) {
  const closeFn = () => {
    props.setImagePopUp(false);
  };
  return (
    <Layout
      className="popUpClass imagePopUp"
      style={{
        backgroundColor: "rgba(128, 128, 128, 0.5)",
        width: "100%",
        height: "100%",
        zIndex: 2,
        position: "absolute",
        top: "0",
        left: "0",
        borderRadius: "5px",
      }}
    >
      <Card
        hoverable
        style={{
          width: 540,
          padding: 0,
        }}
        cover={<img alt="example" src={imageValue} />}
      >
        <Button
          style={{
            width: "100%",
            height: "100%",
            margin: 0,
            backgroundColor: "#1f7eff",
            color: "white",
            padding: "1vh 0",
          }}
          onClick={closeFn}
        >
          Close
        </Button>
      </Card>
    </Layout>
  );
}
