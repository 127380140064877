import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Skeleton, Upload, message } from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useGetPreSignedUrl } from "../../queries/react-query/sales";
import {
  SALES_ROUTE,
  getEndPointFor,
  getHeaders,
} from "../../utils/api_endpoints";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "application/pdf";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/PDF file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("Image must smaller than 10MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function UploadReport(props) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  // const preSignedURL = useGetPreSignedUrl([
  //   "Report",
  //   {
  //     salesId: props.data.id,
  //   },
  // ]);
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <Content
      style={{
        width: "100%",
        paddingTop: "4vh",
        paddingBottom: "4vh",
        paddingLeft: "2vw",
        textAlign: "center",
      }}
    >
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={async (file) => {
          const headers = await getHeaders();
          axios
            .post(
              `${getEndPointFor(SALES_ROUTE.base)}/uploadNewInspectionFile`,
              { salesId: props.data.id },
              {
                headers: headers,
              }
            )
            .then((response) => {
              axios
                .put(response.data.presignedUrl, file, {
                  headers: { "Content-Type": file.type },
                })
                .then((d) => {
                  setLoading(false);
                  props.setSuccessLoadingAddReport(true);
                  setTimeout(() => {
                    queryClient.refetchQueries(["ISales"]);
                  }, [4000]);
                  setTimeout(() => {
                    props.setSuccessLoadingAddReport(false);
                    props.setAddReportSuccess(true);
                  }, [5500]);
                  setTimeout(() => {
                    props.setAddReportSuccess(false);
                  }, [6500]);
                })
                .catch((err) => {
                  setLoading(false);
                });
            })
            .catch((err) => console.log(err));
        }}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: "100%",
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </Content>
  );
}
