import { Button, Divider, Modal } from "antd";
import { useEffect, useRef } from "react";
import "./style.scss";

const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};

export const ModalForm = ({
  children,
  form,
  open,
  onCancel,
  title,
  updateMode,
  updateFn,
  createFn,
  ...props
}) => {
  useResetFormOnCloseModal({
    form,
    open,
  });
  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        try {
          if (updateMode) updateFn(values);
          else createFn(values);
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal
      cancelButtonProps={{
        style: {
          display: "none",
        },
      }}
      className="modalForm"
      width={1500}
      onCancel={onCancel}
      onOk={handleFormSubmit}
      okText={updateMode ? "Update Product" : "Create Product"}
      open={open}
      title={title}
      destroyOnClose={true}
      footer={[
        <Button
          key="submit"
          style={
            (props.autoCompleteFields.location === "" ||
              props.autoCompleteFields.colour === "" ||
              props.autoCompleteFields.category === "" ||
              props.autoCompleteFields.location === undefined ||
              props.autoCompleteFields.colour === undefined ||
              props.autoCompleteFields.category === undefined ||
              props.autoCompleteFields.productName === undefined ||
              props.autoCompleteFields.productName === undefined) &&
            !updateMode
              ? { backgroundColor: "grey", color: "white" }
              : {}
          }
          type="primary"
          disabled={
            (props.autoCompleteFields.location === "" ||
              props.autoCompleteFields.colour === "" ||
              props.autoCompleteFields.category === "" ||
              props.autoCompleteFields.location === undefined ||
              props.autoCompleteFields.colour === undefined ||
              props.autoCompleteFields.category === undefined ||
              props.autoCompleteFields.productName === undefined ||
              props.autoCompleteFields.productName === undefined) &&
            !updateMode
              ? true
              : false
          }
          onClick={handleFormSubmit}
        >
          {updateMode ? "Update Product" : "Create Product"}
        </Button>,
      ]}
    >
      <Divider style={{ margin: "12px 0" }} />
      {children}
    </Modal>
  );
};
