import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  FloatButton,
  Form,
  Layout,
  Row,
  Skeleton,
  Space,
  Tooltip,
  message,
} from "antd";
import { Content } from "antd/es/layout/layout";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import CollapsiblePanelHeader from "../components/CollapsiblePanelHeader";
import ConfirmationComponent from "../components/ConfirmationComponent";
import Filter from "../components/Filter";
import { ModalForm } from "../components/ModalForm";
import ProductCard from "../components/ProductCard";
import ProductForm from "../components/ProductForm";
import SuccessComponent from "../components/SuccessComponent";
import SuccessLoading from "../components/SuccessLoading";
import {
  useCreateProduct,
  useDeleteProduct,
  useGetProducts,
  usePostSearchProduct,
  useUpdateProduct,
} from "../queries/react-query/products";
import "../styles/products.css";
import { ProductConstants } from "../utils/product";
const fields = ["Product", "Category", "Colour", "Location"];

export default function Products() {
  const { t } = useTranslation();
  const [openModalForm, setopenModalForm] = useState(false);
  const [popUp, setPopUp] = useState(0);
  const [loadingState, setLoadingState] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({ name: "" });
  const queryClient = useQueryClient();
  const showModalForm = () => {
    setopenModalForm(true);
  };
  const hideModalForm = () => {
    setopenModalForm(false);
  };

  // console.log(props.flag)

  const [product, setProduct] = useState({});
  const [search, setSearch] = useState("");
  const [updateMode, setUpdateMode] = useState(false);
  const [filterObject, setFilterObject] = useState({});
  // const [flag,setFlag] = useState(false);
  const [autoCompleteFields, setAutoCompleteFields] = useState({
    category: "",
    colour: "",
    location: "",
    productName: "",
    skus: [],
  });
  const [form] = Form.useForm();

  const products = useGetProducts([
    "products",
    { filterContext: filterObject },
  ]);

  const editProduct = (product) => {
    setProduct(product);
    showModalForm();
  };

  const productSearch = usePostSearchProduct(["productSearch"]);

  const handleSearch = (value = "") => {
    setSearch(value);
    if (search !== "") {
      productSearch.mutateAsync(search);
    }
  };

  const removeEmptySubproducts = (subProducts) => {
    return subProducts.filter((subproduct) => !!subproduct.price);
  };

  const onErrorCreateProduct = (err) => {
    setTimeout(() => {
      setLoadingState(false);
      setErrMsg(err);
      setPopUp(4);
    }, [1000]);
    setTimeout(() => {
      setPopUp(0);
      setErrMsg("");
    }, [4000]);
  };

  const onSuccessCreateProduct = () => {
    // message.success("Product Created");
    setTimeout(() => {
      queryClient.invalidateQueries(["products"]);
      setLoadingState(false);
      setPopUp(1);
    }, [1000]);
    setTimeout(() => {
      setPopUp(0);
    }, [2000]);
  };

  const productCreate = useCreateProduct(
    ["productCreate"],
    onErrorCreateProduct,
    onSuccessCreateProduct
  );

  const addProduct = async (values) => {
    if (!values.name) {
      console.error("Please fill all the required input fields");
      return;
    }
    setLoadingState(true);
    setopenModalForm(false);
    let reqBody = {};
    reqBody.name = values.name;
    reqBody.category = autoCompleteFields.category;
    reqBody.colour = autoCompleteFields.colour;
    reqBody.location = autoCompleteFields.location;
    values.skus = removeEmptySubproducts(values.skus);
    reqBody.skus = values.skus;
    // console.log(reqBody);
    setTimeout(() => {
      productCreate.mutateAsync(reqBody);
    }, [1000]);
  };

  const onErrorUpdateProduct = (err) => {
    setTimeout(() => {
      setLoadingState(false);
      setErrMsg(err);
      setPopUp(4);
    }, [1000]);
    setTimeout(() => {
      setPopUp(0);
      setErrMsg("");
    }, [4000]);
  };

  const onSuccessUpdateProduct = () => {
    // message.success("Product Updated");
    setTimeout(() => {
      queryClient.invalidateQueries(["products"]);
      setLoadingState(false);
      setPopUp(2);
    }, [1000]);
    setTimeout(() => {
      setPopUp(0);
    }, [2000]);
  };

  const productUpdate = useUpdateProduct(
    ["updateProduct"],
    onErrorUpdateProduct,
    onSuccessUpdateProduct
  );

  const updateProduct = async (values) => {
    setLoadingState(true);
    setopenModalForm(false);
    let reqBody = {};
    reqBody.name = values.name;
    reqBody.category = autoCompleteFields.category;
    reqBody.colour = autoCompleteFields.colour;
    reqBody.location = autoCompleteFields.location;
    reqBody.skus = values.skus;
    setTimeout(() => {
      productUpdate.mutateAsync(reqBody);
    }, [1000]);
  };

  const onSuccessDelete = () => {
    setTimeout(() => {
      queryClient.invalidateQueries(["products"]);
      setLoadingState(false);
      setPopUp(3);
    }, [1000]);
    setTimeout(() => {
      setPopUp(0);
    }, [2000]);
  };

  const onErrorDelete = (err) => {
    setTimeout(() => {
      setLoadingState(false);
      setErrMsg(err);
      setPopUp(4);
    }, [1000]);
    setTimeout(() => {
      setPopUp(0);
      setErrMsg("");
    }, [4000]);
  };
  const deleteMutate = useDeleteProduct(
    ["products"],
    onSuccessDelete,
    onErrorDelete
  );

  const deleteClicked = (productId) => {
    setConfirmDelete(false);
    setLoadingState(true);
    setTimeout(() => {
      deleteMutate.mutate(productId);
    }, [1000]);
  };

  const Filters = ProductConstants.Filters;
  return (
    <Layout>
      <Filter
        queryClient={queryClient}
        setFilterObject={setFilterObject}
        Filters={Filters}
        queryKey={"products"}
        filterObject={filterObject}
      />
      <Content
        style={{
          width: "85%",
          paddingRight: "2vw",
        }}
      >
        {products.isLoading ? (
          <Skeleton active />
        ) : (
          <Space direction="vertical" style={{ width: "100%" }} size={24}>
            {/* {console.log(props.flag)} */}
            <CollapsiblePanelHeader
              panelProps={{
                header: (
                  <Row
                    style={{ width: "calc(96% - 45px)" }}
                    className="tableHeader2"
                  >
                    {_.map(fields, (field) => (
                      <Col
                        key={field}
                        span={6}
                        style={
                          t(`productHeaders.${field.toLowerCase()}`) ===
                          "Colour"
                            ? { marginLeft: "-0.25vw" }
                            : {}
                        }
                      >
                        {t(`productHeaders.${field.toLowerCase()}`)}
                      </Col>
                    ))}
                  </Row>
                ),
                key: "header",
                className: "collapable-header",
              }}
              collapseProps={{
                ghost: true,
                size: "large",
                collapsible: "disabled",
              }}
            />
            <Content
              className="ListViewProducts"
              style={{ width: "100%", overflowX: "hidden" }}
            >
              {products.data === undefined ? (
                <Empty
                  style={{
                    width: "100%",
                    marginTop: "2vh",
                    fontFamily: "Roboto",
                  }}
                  description={<span>Error in loading data</span>}
                />
              ) : products.data.products.length === 0 ? (
                <Empty
                  style={{
                    width: "100%",
                    marginTop: "2vh",
                    fontFamily: "Roboto",
                  }}
                  description={<span>No results found</span>}
                />
              ) : (
                products.data?.products?.map((product) => (
                  <ProductCard
                    key={product.name}
                    product={product}
                    onEdit={editProduct}
                    setUpdateMode={setUpdateMode}
                    setDeleteData={setDeleteData}
                    setConfirmDelete={setConfirmDelete}
                  />
                ))
              )}
            </Content>
            <ModalForm
              form={form}
              open={openModalForm}
              onCancel={hideModalForm}
              setLoadingState={setLoadingState}
              title={
                updateMode
                  ? t("productContainer.updateProduct")
                  : t("productContainer.createProduct")
              }
              updateMode={updateMode}
              updateFn={updateProduct}
              createFn={addProduct}
              autoCompleteFields={autoCompleteFields}
            >
              <ProductForm
                form={form}
                product={product}
                updateMode={updateMode}
                setAutoCompleteFields={setAutoCompleteFields}
                autoCompleteFields={autoCompleteFields}
              />
            </ModalForm>
            {/* <FloatButton
            icon={<PlusOutlined />}
            style={{
              position: "fixed",
              right: "1vw",
              bottom: "3vh",
              width: "50px",
              height: "50px",
              fontSize: "18px",
              textAlign: "center",
            }}
            
            onClick={() => {
              setUpdateMode(false);
              showModalForm();
            }}
            tooltip={t("productContainer.createProductBtn")}
            type="primary"
          /> */}
            <Button
              style={{
                position: "fixed",
                right: "1vw",
                bottom: "3vh",
                width: "50px",
                height: "50px",
                fontSize: "18px",
                textAlign: "center",
              }}
              type="primary"
              className="addButton"
              icon={<PlusOutlined />}
              onClick={() => {
                setUpdateMode(false);
                showModalForm();
              }}
            ></Button>
          </Space>
        )}
      </Content>
      {confirmDelete ? (
        <Content
          style={{
            backgroundColor: "rgba(128, 128, 128, 0.5)",
            width: "100%",
            height: "75.75vh",
            zIndex: 2,
            position: "absolute",
            top: "-2vh",
            left: "0",
            borderRadius: "5px",
          }}
        >
          <Card
            style={{
              width: "30%",
              height: "auto",
              backgroundColor: "white",
              margin: "20vh 0 0 34vw",
            }}
            extra={
              <CloseOutlined
                style={{ color: "#0066FF" }}
                onClick={() => {
                  setConfirmDelete(false);
                }}
              />
            }
          >
            <ConfirmationComponent
              setClose={setConfirmDelete}
              detail={`Do you want to delete product - ${deleteData.name}`}
              onConfirm={() => {
                deleteClicked(deleteData.name);
              }}
            />
          </Card>
        </Content>
      ) : (
        <></>
      )}
      {popUp !== 0 || loadingState ? (
        <Content
          style={{
            backgroundColor: "rgba(128, 128, 128, 0.5)",
            width: "100%",
            height: "75.75vh",
            zIndex: 2,
            position: "absolute",
            top: "-2vh",
            left: "0",
            borderRadius: "5px",
          }}
        >
          <Card
            style={{
              width: "30%",
              height: "auto",
              backgroundColor: "white",
              margin: "20vh 0 0 34vw",
            }}
            // extra={
            //   <CloseOutlined
            //     style={{ color: "#0066FF" }}
            //     onClick={() => {
            //       setPopUp(0);
            //     }}
            //   />
            // }
          >
            {loadingState ? (
              <SuccessLoading
                detail={"Please wait while we update your data!!"}
              />
            ) : (
              <></>
            )}
            {popUp !== 0 ? (
              <SuccessComponent
                isError={popUp === 4 ? true : false}
                detail={
                  popUp === 1
                    ? "Successfully added Product!!"
                    : popUp === 2
                    ? "Successfully edited Product!!"
                    : popUp === 3
                    ? "Successfully deleted Product!!"
                    : errMsg
                }
              />
            ) : (
              <></>
            )}
          </Card>
        </Content>
      ) : (
        <></>
      )}
    </Layout>
  );
}
