import { PictureFilled } from "@ant-design/icons";
import { Table } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Slablistviewable({ slabList, setImages }) {
  const { t } = useTranslation();
  const [viewing, setViewing] = useState(0);
  const columns = [
    {
      title: t("slabHeader.slabNo"),
      dataIndex: "slabNumber",
      className: "values",
      key: "1",
    },
    {
      title: t("slabHeader.thickness"),
      dataIndex: "thickness",
      className: "values",
      key: "2",
      render: (text, record) =>
        !_.isUndefined(text) ? `${text} ${record.thicknessUnit}` : "-",
    },
    {
      title: t("slabHeader.netLength"),
      dataIndex: "netWidth",
      className: "values",
      key: "3",
      render: (text, record) =>
        !_.isUndefined(text) ? `${text} ${record.thicknessUnit}` : "-",
    },
    {
      title: t("slabHeader.netHeight"),
      dataIndex: "netHeight",
      className: "values",
      key: "4",
      render: (text, record) =>
        !_.isUndefined(text) ? `${text} ${record.thicknessUnit}` : "-",
    },
    {
      title: t("slabHeader.finish"),
      dataIndex: "finish",
      className: "values",
      key: "5",
    },
    {
      title: t("slabHeader.price"),
      dataIndex: "price",
      className: "values",
      key: "6",
      render: (text, record) => (!_.isUndefined(text) ? `${text} USD` : "-"),
    },
    {
      title: t("slabHeader.images"),
      dataIndex: "photo",
      className: "values",
      key: "7",
      render: (text, record) => {
        if (!record.images) {
          return (
            <PictureFilled
              style={{
                color: "#a2a3a5",
                fontSize: viewing === record.slabNumber ? "x-large" : "large",
              }}
            />
          );
        }
        return (
          <PictureFilled
            style={{
              color: "#0C25F3",
              fontSize: viewing === record.slabNumber ? "x-large" : "large",
            }}
            onClick={() => {
              setViewing(record.slabNumber);
              setImages(
                record.images === undefined || record.images.length === 0
                  ? []
                  : record.images
              );
            }}
          />
        );
      },
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={slabList}
      size="small"
      pagination={false}
      className="marketPlaceTable"
    />
  );
}
