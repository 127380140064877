import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";

countries.registerLocale(enLocale);

const countryObj = countries.getNames("en", { select: "official" });

const countryArr = Object.entries(countryObj).map(([key, value]) => {
  return value;
});

const portCities = [
  "Bahia Blanca",
  "Buenos Aires",
  "Mar del Plata",
  "Puerto Deseado",
  "Puerto Madryn",
  "Rio Grande",
  "Rosario",
  "San Antonio Oeste",
  "San Pedro",
  "Ushuaia",
  "Zarate",
  "Puerto Quijarro",
  "Aratu",
  "Belem",
  "Fortaleza",
  "Imbituba",
  "Ipojuca",
  "Itaguai",
  "Itajai",
  "Manaus",
  "Navegantes",
  "Paranagua",
  "Pecem",
  "Rio de Janeiro",
  "Rio Grande",
  "Salvador",
  "Santos",
  "Sao Francisco do Sul",
  "Vila do Conde",
  "Vitoria",
  "Antofagasta",
  "Arica",
  "Coronel",
  "Easter Island",
  "Huasco",
  "Iquique",
  "Lirquen",
  "Mejillones",
  "Port Williams",
  "Puerto Chacabuco",
  "Puerto Ventanas",
  "Punta Arenas",
  "San Antonio",
  "San Vicente",
  "Talcahuano",
  "Valparaiso",
  "Barranquilla",
  "Buenaventura",
  "Cartagena",
  "San Andres Island",
  "Santa Marta",
  "Turbo",
  "Guayaquil",
  "Manta",
  "Georgetown",
  "Asuncion",
  "Callao",
  "Ilo",
  "Matarani",
  "Paita",
  "Paramaribo",
  "Montevideo",
  "Cumana",
  "El Guamache",
  "Guanta",
  "La Guaira",
  "Maracaibo",
  "Puerto Cabello",
  "Argentia",
  "Halifax",
  "Liverpool",
  "Montreal",
  "Pictou",
  "Port Hawkesbury",
  "Prince Rupert",
  "Saint John",
  "Sidney",
  "Sydney",
  "Vancouver",
  "Victoria",
  "Mobile",
  "Anchorage",
  "Angoon",
  "Cordova",
  "Craig",
  "Excursion Inlet",
  "Haines",
  "Hoonah",
  "Juneau",
  "Kake",
  "Kenai",
  "Ketchikan",
  "Klawock",
  "Petersburg",
  "Port MacKenzie",
  "Seward",
  "Sitka",
  "Skagway",
  "Tenakee Springs",
  "Thorne Bay",
  "Unalaska",
  "Valdez",
  "Whittier",
  "Wrangell",
  "Yakutat",
  "Long Beach",
  "Los Angeles",
  "Oakland",
  "Port Hueneme",
  "San Diego",
  "Wilmington",
  "Cape Canaveral",
  "Fernandina Beach",
  "Jacksonville",
  "Miami",
  "Palm Beach",
  "Port Everglades",
  "Tampa",
  "Savannah",
  "Hilo",
  "Honolulu",
  "Kahului",
  "Kaunakakai",
  "Kawaihae",
  "Nawiliwili",
  "New Orleans",
  "Eastport",
  "Portland",
  "Baltimore",
  "Boston",
  "Aberdeen",
  "Gulfport",
  "Portsmouth",
  "Newark",
  "Pennsauken",
  "New York",
  "Wilmington",
  "Portland",
  "Chester",
  "Philadelphia",
  "Charleston",
  "Brownsville",
  "Freeport",
  "Galveston",
  "Houston",
  "Newport News",
  "Norfolk",
  "Portsmouth",
  "Richmond",
  "Everett",
  "Longview",
  "Seattle",
  "Tacoma",
  "Almirante",
  "Balboa",
  "Colon",
  "Colon",
  "Cristobal",
  "Corinto",
  "El Bluff",
  "El Rama",
  "Altamira",
  "Ensenada",
  "Lazaro Cardenas",
  "Manzanillo",
  "Mazatlan",
  "Tampico",
  "Veracruz",
  "Puerto Castilla",
  "Puerto Cortes",
  "San Lorenzo",
  "Puerto Barrios",
  "Puerto Quetzal",
  "Santo Tomas de Castilla",
  "Acajutla",
  "Caldera",
  "Limon",
  "Moin",
  "Belize City",
  "Aden",
  "Hodeidah",
  "Abu Dhabi",
  "Dubai",
  "Fujairah",
  "Jebel Ali",
  "Khor Fakkan",
  "Sharjah",
  "Akdeniz",
  "Ambarli",
  "Derince",
  "Gemlik",
  "Haydarpasa",
  "Istanbul",
  "Izmir",
  "Mersin",
  "Lattakia",
  "Tartous",
  "Dammam",
  "Jeddah",
  "Jubail",
  "Doha",
  "Muscat",
  "Salalah",
  "Beirut",
  "Tripoli",
  "Doha",
  "Shuaiba",
  "Shuwaikh",
  "Aqaba",
  "Ashdod",
  "Eilat",
  "Haifa",
  "Umm Qasr",
  "Bandar Abbas",
  "Bushehr",
  "Chabahar",
  "Imam Khomeini",
  "Khorramshahr",
  "Batumi",
  "Poti",
  "Dhekelia",
  "Limassol",
  "Mina Salman",
  "Baku",
  "Chittagong",
  "Mongla",
  "Chennai",
  "Cochin (Kochi)",
  "Haldia",
  "Jawaharlal Nehru/ Nhava Sheva",
  "Kolkata (Calcutta)",
  "Mumbai",
  "Mundra",
  "Pipavav",
  "Tuticorin",
  "Visakhapatnam",
  "Male",
  "Yangon",
  "Karachi",
  "Muhammad Bin Qasim",
  "Colombo",
  "Cua Cam",
  "Da Nang",
  "Hai Phong",
  "Ho Chi Minh City",
  "Ho Chi Minh City",
  "Phu My",
  "Bangkok",
  "Laem Chabang",
  "Map Ta Phut",
  "Rayong",
  "Songkhla",
  "Sriracha",
  "Singapore",
  "Bugo",
  "Cagayan de Oro",
  "Cebu",
  "Davao",
  "General Santos",
  "Manila",
  "Subic Bay",
  "Bintulu",
  "Johor",
  "Kota Kinabalu",
  "Kuantan",
  "Kuching",
  "Labuan",
  "Penang",
  "Port Klang",
  "Sandakan",
  "Sibu",
  "Tanjung Pelepas",
  "Tawau",
  "Belawan",
  "Jakarta",
  "Palembang",
  "Panjang",
  "Selat Panjang",
  "Surabaya",
  "Tanjung Emas",
  "Tanjung Sekong",
  "Kaohsiung",
  "Keelung",
  "Taichung",
  "Taipei",
  "Macao",
  "Busan",
  "Gunsan",
  "Gwangyang",
  "Incheon",
  "Masan",
  "Okgye",
  "Pohang",
  "Pyeongtaek",
  "Ulsan",
  "Akita",
  "Chiba",
  "Fukuyama",
  "Hachinohe",
  "Hakata",
  "Hibiki",
  "Hiroshima",
  "Hitachinaka",
  "Hososhima",
  "Imari",
  "Ishigaki",
  "Ishikari",
  "Iwakuni",
  "Kainan",
  "Kawasaki",
  "Kitakyushu",
  "Kobe",
  "Mizushima",
  "Moji",
  "Nagoya",
  "Naha",
  "Naoshima",
  "Niigata",
  "Niihama",
  "Oita",
  "Omaezaki",
  "Onahama",
  "Osaka",
  "Saganoseki",
  "Sendai",
  "Shibushi",
  "Shimizu",
  "Tokachi",
  "Tokyo",
  "Tomakomai",
  "Toyama",
  "Toyohashi",
  "Ube",
  "Yokkaichi",
  "Yokohama",
  "Hong Kong",
  "Kwai Chung",
  "Chiwan",
  "Da Chan Bay",
  "Dalian",
  "Fuqing",
  "Fuzhou",
  "Guangzhou",
  "Haikou",
  "Huangpu",
  "Jinzhou",
  "Jiujiang",
  "Lianyungang",
  "Longkou",
  "Lufeng",
  "Nanjing",
  "Nantong",
  "Ningbo",
  "Qingdao",
  "Shanghai",
  "Shantou",
  "Shekou",
  "Tianjin",
  "Wuhan",
  "Wuhu",
  "Xiamen",
  "Xingang",
  "Yangzhou",
  "Yantai",
  "Yantian",
  "Yingkou",
  "Zhangjiagang",
  "Zhangzhou",
  "Zhanjiang",
  "Freetown",
  "Dakar",
  "Apapa",
  "Onne",
  "Takoradi",
  "Tema",
  "Jamestown",
  "Cape Town",
  "Durban",
  "East London",
  "Port Elizabeth",
  "Richards Bay",
  "Walvis Bay",
  "Beira",
  "Maputo",
  "Nacala",
  "Pemba",
  "Port Louis",
  "Majunga",
  "Toamasina",
  "Banana",
  "Boma",
  "Matadi",
  "Cabinda",
  "Lobito",
  "Luanda",
  "Namibe",
  "Soyo",
  "Agadir",
  "Casablanca",
  "Tanger (Tangier)",
  "Al Khoms",
  "Benghazi",
  "Misurata (Qasr Ahmed)",
  "Tripoli",
  "Ain Sukhna",
  "Alexandria",
  "Damietta",
  "East Port Said",
  "El-Dekheila",
  "El-Sokhna",
  "Port Said",
  "Suez",
  "Algiers",
  "Annaba",
  "Bejaia",
  "Oran",
  "Skikda",
  "Dar es Salaam",
  "Tanga",
  "Zanzibar",
  "Port Victoria",
  "Mombasa",
  "Port Vila",
  "Santo",
  "Apia",
  "Lae",
  "Madang",
  "Port Moresby",
  "Rabaul",
  "Auckland",
  "Bluff",
  "Lyttelton",
  "Napier",
  "Nelson",
  "New Plymouth",
  "Port Otago",
  "Tauranga",
  "Timaru",
  "Wellington",
  "Westport",
  "Noumea",
  "Papeete",
  "Lautoka",
  "Suva",
  "Adelaide",
  "Bell Bay",
  "Brisbane",
  "Fremantle",
  "Geraldton",
  "Gladstone",
  "Hay Point",
  "Melbourne",
  "Newcastle",
  "Port Hedland",
  "Port Kembla",
  "Sydney",
  "Townsville",
  "Pago-Pago",
  "Piti",
  "Basel",
  "Birsfelden",
  "Kleinhuningen",
  "Muttenz-Au",
  "St. Johann",
  "Amsterdam",
  "Moerdijk",
  "Rotterdam",
  "Terneuzen/Vlissingen",
  "Vlaardingen",
  "Hercules",
  "Fontvieille",
  "Bremen",
  "Bremerhaven",
  "Hamburg",
  "Travemunde",
  "Boulogne-sur-Mer",
  "Brest",
  "Dunkerque",
  "Fos-sur-Mer",
  "La Pallice",
  "Le Havre",
  "Le Verdon",
  "Marseille",
  "Nantes - Saint Nazaire",
  "Rouen",
  "Antwerp",
  "Ghent",
  "Zeebrugge",
  "Enns",
  "Krems",
  "Linz",
  "Vienna",
  "A Coruna",
  "Algeciras",
  "Alicante",
  "Almeria",
  "Barcelona",
  "Bilbao",
  "Cadiz",
  "Cartagena",
  "Gijon",
  "Las Palmas",
  "Malaga",
  "Sagunto",
  "Santa Cruz de Tenerife",
  "Santander",
  "Seville",
  "Tarragona",
  "Valencia",
  "Vigo",
  "Koper",
  "Belgrade",
  "Figueira Da Foz",
  "Leixoes",
  "Lisbon",
  "Praia Da Vitoria",
  "Setubal",
  "Sines",
  "Bar",
  "Marsaxlokk",
  "Ancona",
  "Bari",
  "Cagliari",
  "Civitavecchia",
  "Genoa",
  "Gioia Tauro",
  "La Spezia",
  "Livorno",
  "Napoli (Naples)",
  "Palermo",
  "Pozzallo",
  "Ravenna",
  "Salerno",
  "Taranto",
  "Trieste",
  "Vado Ligure",
  "Venezia (Venice)",
  "Patras",
  "Piraeus",
  "Thessaloniki",
  "Marina Bay",
  "Mid-Harbour Marina",
  "Gibraltar",
  "Ploce",
  "Rijeka",
  "Durres",
  "Goole",
  "Aberdeen",
  "Belfast",
  "Bristol",
  "Chatham",
  "Dover",
  "Felixstowe",
  "Grangemouth",
  "Greenock",
  "Harwich",
  "Heysham",
  "Hull",
  "Immingham",
  "Inverness",
  "Killingholme",
  "Liverpool",
  "Montrose",
  "Newport",
  "Plymouth",
  "Rochester",
  "Southampton",
  "Sunderland",
  "Teesport",
  "Tilbury",
  "Tyne",
  "Warrenpoint",
  "Gavle",
  "Goteborg",
  "Helsingborg",
  "Karlshamn",
  "Malmo",
  "Norrkoping",
  "Sodertalje",
  "Stockholm",
  "Uddevalla",
  "Barentsburg",
  "Longyearbyen",
  "Ny-Alesund",
  "Bergen",
  "Gamle Fredrikstad",
  "Kristiansand",
  "Larvik",
  "Moss",
  "Oslo",
  "Stavanger",
  "Klaipeda",
  "Riga",
  "Cork",
  "Dublin",
  "Wicklow",
  "Grundartangi",
  "Reydarfjordur",
  "Reykjavik",
  "Vestmannaeyjar",
  "Hamina",
  "Helsinki",
  "Kokkola",
  "Kotka",
  "Rauma",
  "Thorshavn",
  "Tallinn",
  "Fredericia",
  "Aarhus",
  "Copenhagen",
  "Esbjerg",
  "Ilyichevsk",
  "Odessa",
  "Bratislava",
  "Anadyr",
  "Kaliningrad",
  "Kholmsk",
  "Korsakov",
  "Magadan",
  "Nakhodka",
  "Novorossiysk",
  "Providenija",
  "St. Petersburg",
  "Vladivostok",
  "Vostochny",
  "Constantza",
  "Gdansk",
  "Gdynia",
  "Csepel/ Budapest",
  "Prague",
  "Usti nad Labem",
  "Varna",
  "Mazyr",
  "Boca Chica",
  "Puerto Plata",
  "Punta Caucedo",
  "Rio Haina",
  "Santo Domingo",
  "Roseau",
  "Woodbridge Bay",
  "Havana",
  "Santiago de Cuba",
  "George Town",
  "Freeport",
  "Hamilton",
  "Bridgetown",
  "Freeport",
  "Marsh Harbor",
  "Nassau",
  "Oranjestad",
  "Saint John's",
  "The Valley",
  "Cruz Bay",
  "Port Alucroix",
  "St. Thomas",
  "Port Purcell",
  "Virgin Gorda",
  "Grand Turk",
  "Providenciales",
  "Point Lisas",
  "Pointe-a-Pierre",
  "Port of Spain",
  "Canouan",
  "Campden Park Bay",
  "Kingstown",
  "Castries",
  "Vieux-Fort",
  "Basseterre",
  "Charlestown",
  "Ponce",
  "San Juan",
  "Puerto Nuevo",
  "Curacao",
  "Kralendijk",
  "Philipspburg",
  "Little Bay",
  "Fort-de-France",
  "Kingston",
  "Montego Bay",
  "Cap Haitien",
  "Corail",
  "Port-au-Prince",
  "Gustavia",
  "Pointe-a-Pitre",
  "Saint George's",
];

const Filters = [
  {
    key: "Customer",
    type: "checkbox",
    value: ["Orca Enterprise", "Famous Company1"],
  },
  {
    key: "Inspector",
    type: "checkbox",
    value: ["Andre"],
  },
];

const paymentTerms = [
  "2% 10, NET 30",
  "5% 10, NET 30",
  "50% 120 Days, 50% 150 Days",
  "50% 120 Days, 50% 180 Days",
  "50% 150 Days, 50% 180 Days",
  "50% 90 Days, 50% 120 Days",
  "50% 90 Days, 50% 150 Days",
  "CAD - Cash Against Documents",
  "CASH",
  "CIA - Cash in Advance",
  "COD - Cash on Delivery",
  "NET 10 Days",
  "NET 120 Days",
  "NET 14 Days",
  "NET 150 Days",
  "NET 180 Days",
  "NET 30 Days",
  "NET 60 Days",
  "NET 7 Days",
  "NET 90 Days",
];

const incoterms = [
  "CFR - Cost and Freight",
  "CIF - Cost, Insurance & Freight",
  "CIP - Carriage & Insurance Paid",
  "CPT - Carriage Paid to",
  "DAP - Delivered at Place",
  "DDP - Delivered Duty Paid",
  "DPU - Delivered at Place Unloaded",
  "EXW - Ex Works",
  "FAS - Free Alongside Ship",
  "FCA - Free Carrier",
  "FOB - Free on Board",
];

const paymentMethods = [
  "ACH",
  "Auto Pay",
  "Cash",
  "Check",
  "Credit Card - AMEX",
  "Credit Card - Master",
  "Credit Card - Other",
  "Credit Card - Visa",
  "Debit Card",
  "Other",
  "Wire",
];

const shippingMode = ["Road", "Ocean"];

const unloadingAddress = countryArr;

const countryOfOrigin = countryArr;

const destinationCntry = countryArr;

const portOfLoading = portCities;

const destinationPort = portCities;

const shippingLine = [
  "AAL Shipping Agencies",
  "ACL",
  "Alaska Marine Lines",
  "Alianca",
  "Aloha Marine Lines",
  "ANL",
  "Antillean Marine Shipping Corporation",
  "APL",
  "Atlantic Cargo",
  "Atlantic Ro-Ro Carriers",
  "Bahri",
  "Bermuda Container Line",
  "Bernuth Lines",
  "Caribbean Feeder Services",
  "China Shipping",
  "Contenemar",
  "COSCO",
  "Crowley Liner Services",
  "CSAV",
  "Dole Ocean Cargo Express",
  "Eimskip",
  "Evergreen Marine Corp.",
  "FESCO",
  "Great White Fleet",
  "Hamburg Sud",
  "Hapag-Lloyd",
  "Islamic Republic of Iran Shipping Lines",
  "K Line",
  "Kent Line International",
  "King Ocean Services",
  "Libra",
  "MACS Shipping",
  "Maersk Line",
  "Matson",
  "Mediterranean Shipping Company (MSC)",
  "MISC Berhad",
  "MOL",
  "Norasia",
  "Nordo Link",
  "Norfolk Line",
  "NSA",
  "NYK",
  "OOCL",
  "OT Africa Line",
  "PIL",
  "Regional Container Lines",
  "Scotline",
  "Seaboard Marine",
  "Senator Lines",
  "Star Shipping",
  "Totem Ocean Trailer Express",
  "Tropical Shipping",
  "United Arab Shipping Company",
  "Wan Hai Lines, Ltd.",
  "WEC Lines",
  "West Coast Industrial Express",
  "Westwood",
  "Wing Bridge Shipping Co.",
  "Yang Ming",
  "Zim",
];

const containerType = ["20ft closed box", "40ft closed box", "45ft closed box"];

const vessel = ["temp1", "temp2", "temp3", "temp4", "temp4"];

const freightHandler = [
  "AAL Shipping Agencies",
  "ACL",
  "Alaska Marine Lines",
  "Alianca",
  "Aloha Marine Lines",
  "ANL",
  "Antillean Marine Shipping Corporation",
  "APL",
  "Atlantic Cargo",
  "Atlantic Ro-Ro Carriers",
  "Bahri",
  "Bermuda Container Line",
  "Bernuth Lines",
  "Caribbean Feeder Services",
  "China Shipping",
  "Contenemar",
  "COSCO",
  "Crowley Liner Services",
  "CSAV",
  "Dole Ocean Cargo Express",
  "Eimskip",
  "Evergreen Marine Corp.",
  "FESCO",
  "Great White Fleet",
  "Hamburg Sud",
  "Hapag-Lloyd",
  "Islamic Republic of Iran Shipping Lines",
  "K Line",
  "Kent Line International",
  "King Ocean Services",
  "Libra",
  "MACS Shipping",
  "Maersk Line",
  "Matson",
  "Mediterranean Shipping Company (MSC)",
  "MISC Berhad",
  "MOL",
  "Norasia",
  "Nordo Link",
  "Norfolk Line",
  "NSA",
  "NYK",
  "OOCL",
  "OT Africa Line",
  "PIL",
  "Regional Container Lines",
  "Scotline",
  "Seaboard Marine",
  "Senator Lines",
  "Star Shipping",
  "Totem Ocean Trailer Express",
  "Tropical Shipping",
  "United Arab Shipping Company",
  "Wan Hai Lines, Ltd.",
  "WEC Lines",
  "West Coast Industrial Express",
  "Westwood",
  "Wing Bridge Shipping Co.",
  "Yang Ming",
  "Zim",
];

const insurance = ["temp1", "temp2", "temp3", "temp4", "temp4"];

export const salesConstants = {
  Filters,
  paymentTerms,
  incoterms,
  insurance,
  freightHandler,
  vessel,
  containerType,
  shippingLine,
  destinationPort,
  portOfLoading,
  destinationCntry,
  countryOfOrigin,
  unloadingAddress,
  shippingMode,
  paymentMethods,
};
