import { DownOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import "./style.scss";

const { Panel } = Collapse;

const CollapsiblePanelHeader = ({
  collapseProps,
  panelProps,
  children,
  expanded = false,
}) => {
  const [isActive, setIsActive] = useState(expanded);

  const handlePanelClick = () => {
    setIsActive(!isActive);
  };

  const { expandIcon } = collapseProps || {};

  const handleExpandIcon = ({ isActive }) => {
    return (
      <DownOutlined
        rotate={isActive ? 0 : -90}
        style={{
          color: "#0066FF",
        }}
      />
    );
  };

  return (
    <Collapse
      {...collapseProps}
      onChange={handlePanelClick}
      defaultActiveKey={
        _.has(collapseProps, "useKey")
          ? [collapseProps.key]
          : isActive
          ? ["1"]
          : ["0"]
      }
      expandIcon={expandIcon || handleExpandIcon}
      style={{
        marginLeft: "-1.5vw",
      }}
    >
      <Panel {...panelProps}>{children}</Panel>
    </Collapse>
  );
};

export default CollapsiblePanelHeader;
