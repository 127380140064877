import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
  MessageOutlined,
  PictureFilled,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { AddAPhoto } from "@mui/icons-material";
import { Autocomplete, Box, TextField } from "@mui/material";
import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Skeleton,
  Space,
  Steps,
  Table,
} from "antd";
import { Content } from "antd/es/layout/layout";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsListOl } from "react-icons/bs";
import { useQueryClient } from "react-query";
import {
  useFetchSlabsInventory,
  useGetBundleImage,
  useUpdateSlabDetails,
} from "../../queries/react-query/inventory";
import { useFetchSlabs } from "../../queries/react-query/marketplace";
import { Flags } from "../../utils/featureflags";
import { globalConstants } from "../../utils/global";
import { inventoryConstants } from "../../utils/inventory";
import Slider from "../Common/MediaSlider/MediaSlider";
import { Popup, usePopup } from "../CreatePopup";
import EmptyNotesForm from "../EmptyNotesForm";
import InventoryImageForm from "../InventoryImageForm";
import { Lock } from "../Lock";
import PrintSlabDetails from "../PrintComponents/PrintSlabDetails";
import styles from "../SlabTable/slabtable.module.scss";
import SuccessComponent from "../SuccessComponent";

export default function MarketplaceExpand({
  handleCloseDrawerCallBack,
  toggleDrawer,
  ...props
}) {
  const { t } = useTranslation();
  const isPrintFeatureEnabled = Flags.enablePrint();
  const { openPopup, togglePopup } = usePopup(false);
  const [editId, setEditId] = useState({ slabNumber: -1, id: "-1" });
  const [editValue, setEditValue] = useState({});
  const [notesOf, setNotesOf] = useState([]);
  const [popUpContent, setPopUpContent] = useState(1);
  const [loadingState, setLoadingState] = useState(false);
  const [selectedParentRowKeys] = useState([]);
  const finish = inventoryConstants.finishTypes;
  const quality = inventoryConstants.qualityLevels;
  const thicknessMetric = inventoryConstants.thicknessUnit;
  const currency = Object.keys(inventoryConstants.currencies);
  const slabDetailsMarketplace = useFetchSlabs([
    "marketSlabs",
    props.slabData,
    { filterContext: props.filterObject },
  ]);

  const slabDetailsInventory = useFetchSlabsInventory([
    "inventorySlabs",
    props.slabData,
    { filterContext: props.filterObject },
  ]);

  const bundleDetailsInventory = useGetBundleImage([
    "bundles",
    props.slabData.productName,
    { bundleNumber: props.slabData.bundleNumber },
  ]);
  const [inventory, setInventory] = useState({
    id: "",
    productName: "",
    price: "",
    finish: "",
    quality: "",
    type: "",
    thickness: "",
    bundleNumber: "",
    blockNumber: "",
  });
  const [addImageTo, setAddImageTo] = useState({
    bundle: "-1",
    slab: "-1",
    block: "-1",
  });
  const [imageAddedCheck, setImageAddedCheck] = useState([]);
  const [blockDisabled, setBlockDisabled] = useState([]);
  const [stepImage, setStepImage] = useState(0);
  const [updateImages, setUpdateImages] = useState(false);
  const [slabNumber, setSlabNumber] = useState("");
  const [images, setImages] = useState(props.bundleImages);
  const [activeStep, setActiveStep] = React.useState(0);
  const [viewing, setViewing] = useState(0);
  const [addImageSuccess, setAddImageSuccess] = useState(false);

  useEffect(() => {
    if (props.isInventory) {
      if (selectedParentRowKeys.includes(props.slabData.bundleNumber)) {
        const childRowKeys = slabDetailsInventory.isSuccess
          ? slabDetailsInventory.data.map((slab) => slab.slabNumber)
          : [];
        props.setSelectedSlab(childRowKeys);
      } else {
        props.setSelectedSlab([]);
      }
    }
  }, [
    props.slabData.bundleNumber,
    selectedParentRowKeys,
    slabDetailsInventory.data,
    slabDetailsInventory.isSuccess,
  ]);

  const handleRowSelection = (selectedRowKey, selectedRow) => {
    if (props.isInventory) {
      props.setSelectedSlab(selectedRowKey);
      props.onItemsSelected(selectedRow);
    }
  };

  const queryClient = useQueryClient();
  const updateSlabSuccess = () => {
    setLoadingState(false);
    setEditId({ slabNumber: -1, id: "-1" });
    togglePopup();
    setPopUpContent(1);
    setTimeout(() => {
      queryClient.refetchQueries(["inventorySlabs"]);
    }, [1000]);
  };
  const updateSlabDetails = useUpdateSlabDetails(
    ["inventorySlabs"],
    updateSlabSuccess
  );

  const columns = [
    {
      title: t("slabHeader.slabNo"),
      dataIndex: "slabNumber",
      className: "values",
    },
    {
      title: t("slabHeader.thickness"),
      dataIndex: "thickness",
      className: "values",
      render: (text, record) =>
        !_.isUndefined(text) ? `${text} ${record.thicknessUnit}` : "-",
    },
    {
      title: t("slabHeader.netLength"),
      dataIndex: "width",
      className: "values",
      render: (text, record) =>
        !_.isUndefined(text) ? `${text} ${record.thicknessUnit}` : "-",
    },
    {
      title: t("slabHeader.netHeight"),
      dataIndex: "height",
      className: "values",
      render: (text, record) =>
        !_.isUndefined(text) ? `${text} ${record.thicknessUnit}` : "-",
    },
    {
      title: t("slabHeader.finish"),
      dataIndex: "finish",
      className: "values",
    },
    {
      title: t("slabHeader.price"),
      dataIndex: "price",
      className: "values",
      render: (text, record) => (!_.isUndefined(text) ? `${text} USD` : "-"),
    },
    {
      title: t("slabHeader.totalPrice"),
      dataIndex: "totalPrice",
      className: "values",
      render: (text, record) => {
        return !_.isUndefined(text)
          ? `${globalConstants.set2placeToDecimal(text)} ${
              !inventoryConstants.currencies[record.currencyCode] ? "USD" : ""
            }`
          : "-";
      },
    },
    {
      title: t("slabHeader.images"),
      dataIndex: "photo",
      className: "values",
      render: (text, record) => {
        return (
          <PictureFilled
            style={{
              color: "#0C25F3",
              fontSize: viewing === record.slabNumber ? "x-large" : "large",
            }}
            onClick={() => {
              setViewing(record.slabNumber);
              setImages(
                record.images === undefined || record.images.length === 0
                  ? []
                  : record.images
              );
              setSlabNumber(record.slabNumber);
              setActiveStep(0);
            }}
          />
        );
      },
    },
  ];

  const handleNotesClick = (record) => {
    localStorage.setItem("slabId", record.id);
    setNotesOf(record.notes ? record.notes : []);
    setPopUpContent(2);
    togglePopup();
  };

  const columnsInventory = [
    {
      title: t("slabHeader.slabNo"),
      dataIndex: "slabNumber",
      key: "slabNumber",
      className: "values",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.blockNo"),
      dataIndex: "blockNumber",
      key: "blockNumber",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <TextField
            sx={{ width: "5vw" }}
            id="outlined-basic"
            variant="outlined"
            defaultValue={!_.isUndefined(text) ? text : ""}
            onChange={(e) => {
              e.preventDefault();
              setEditValue((prevState) => ({
                ...prevState,
                blockNumber: e.target.value,
              }));
            }}
          />
        ) : !_.isUndefined(text) ? (
          text
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.grossHeight"),
      dataIndex: "grossHeight",
      key: "grossHeight",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <TextField
            sx={{ width: "5vw" }}
            id="outlined-basic"
            variant="outlined"
            defaultValue={!_.isUndefined(text) ? text : ""}
            onChange={(e) => {
              e.preventDefault();
              setEditValue((prevState) => ({
                ...prevState,
                grossHeight: e.target.value,
              }));
            }}
          />
        ) : !_.isUndefined(text) ? (
          `${text} cm`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.grossWidth"),
      dataIndex: "grossWidth",
      key: "grossWidth",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <TextField
            sx={{ width: "5vw" }}
            id="outlined-basic"
            variant="outlined"
            defaultValue={!_.isUndefined(text) ? text : ""}
            onChange={(e) => {
              e.preventDefault();
              setEditValue((prevState) => ({
                ...prevState,
                grossWidth: e.target.value,
              }));
            }}
          />
        ) : !_.isUndefined(text) ? (
          `${text} cm`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.netHeight"),
      dataIndex: "netHeight",
      key: "netHeight",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <TextField
            sx={{ width: "5vw" }}
            id="outlined-basic"
            variant="outlined"
            defaultValue={!_.isUndefined(text) ? text : ""}
            onChange={(e) => {
              e.preventDefault();
              setEditValue((prevState) => ({
                ...prevState,
                netHeight: e.target.value,
              }));
            }}
          />
        ) : !_.isUndefined(text) ? (
          `${text} cm`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.netWidth"),
      dataIndex: "netWidth",
      key: "netWidth",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <TextField
            sx={{ width: "5vw" }}
            id="outlined-basic"
            variant="outlined"
            defaultValue={!_.isUndefined(text) ? text : ""}
            onChange={(e) => {
              e.preventDefault();
              setEditValue((prevState) => ({
                ...prevState,
                netWidth: e.target.value,
              }));
            }}
          />
        ) : !_.isUndefined(text) ? (
          `${text} cm`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.finish"),
      dataIndex: "finish",
      key: "finish",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <Autocomplete
            className="slabAutocomplete"
            options={finish.map((option) => ({ label: option }))}
            getOptionLabel={(option) => option.label}
            defaultValue={{
              label: !_.isUndefined(text) ? text : "",
            }}
            id="combo-box-demo"
            onChange={(event, newValue) => {
              setEditValue((prev) => ({
                ...prev,
                finish: newValue ? newValue.label : "Select",
              }));
            }}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: "10vw" }} />
            )}
          />
        ) : !_.isUndefined(text) ? (
          text
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.quality"),
      dataIndex: "quality",
      key: "quality",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <Autocomplete
            className="slabAutocomplete"
            options={quality.map((option) => ({ label: option }))}
            getOptionLabel={(option) => option.label}
            defaultValue={{
              label: !_.isUndefined(text) ? text : "",
            }}
            id="combo-box-demo"
            onChange={(event, newValue) => {
              setEditValue((prev) => ({
                ...prev,
                quality: newValue ? newValue.label : "Select",
              }));
            }}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: "10vw" }} />
            )}
          />
        ) : !_.isUndefined(text) ? (
          text
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.price"),
      dataIndex: "price",
      key: "price",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <div style={{ display: "flex" }}>
            <TextField
              sx={{ width: "5vw" }}
              id="outlined-basic"
              variant="outlined"
              defaultValue={!_.isUndefined(text) ? text : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  price: e.target.value,
                }));
              }}
            />
            <Autocomplete
              className="slabAutocomplete"
              options={currency.map((option) => ({ label: option }))}
              getOptionLabel={(option) => option.label}
              defaultValue={{
                label: !_.isUndefined(record.currencyCode)
                  ? record.currencyCode
                  : "",
              }}
              id="combo-box-demo"
              onChange={(event, newValue) => {
                setEditValue((prev) => ({
                  ...prev,
                  priceMetric: newValue ? newValue.label : "Select",
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "10vw" }} />
              )}
            />
          </div>
        ) : !_.isUndefined(text) ? (
          `${text} ${inventoryConstants.currencies[record.currencyCode]}`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.thickness"),
      dataIndex: "thickness",
      key: "thickness",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <div style={{ display: "flex" }}>
            <TextField
              sx={{ width: "5vw" }}
              id="outlined-basic"
              variant="outlined"
              defaultValue={!_.isUndefined(text) ? text : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  thickness: e.target.value,
                }));
              }}
            />
            <Autocomplete
              className="slabAutocomplete"
              options={thicknessMetric.map((option) => ({ label: option }))}
              getOptionLabel={(option) => option.label}
              defaultValue={{
                label: !_.isUndefined(record.thicknessUnit)
                  ? record.thicknessUnit
                  : "",
              }}
              id="combo-box-demo"
              onChange={(event, newValue) => {
                setEditValue((prev) => ({
                  ...prev,
                  thicknessUnit: newValue ? newValue.label : "Select",
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "10vw" }} />
              )}
            />
          </div>
        ) : !_.isUndefined(text) ? (
          `${text} ${record.thicknessUnit}`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.images"),
      dataIndex: "images",
      key: "images",
      className: "values",
      render: (text, record) => {
        return (
          <PictureFilled
            style={{
              color: "#0C25F3",
              fontSize: viewing === record.slabNumber ? "x-large" : "large",
            }}
            onClick={() => {
              setViewing(record.slabNumber);
              setImages(
                record.images === undefined || record.images.length === 0
                  ? []
                  : record.images
              );
              setSlabNumber(record.slabNumber);
              setActiveStep(0);
            }}
          />
        );
      },
    },
    {
      title: t("slabHeader.print"),
      dataIndex: "slabPrint",
      key: "slabPrint",
      className: "values",
      hidden: isPrintFeatureEnabled,
      render: (text, record) => {
        const slabData = [];
        slabData.push(record);
        return <PrintSlabDetails slabData={slabData} />;
      },
    },
    {
      title: t("slabHeader.status"),
      dataIndex: "status",
      key: "status",
      className: "values",
      render: (text, record) => {
        if (record.status === "HOLD") {
          return <Lock state={false} text={"On Hold"} />;
        } else {
          return <Lock state={true} text={"Available"} />;
        }
      },
    },

    {
      title: t("slabHeader.notes"),
      dataIndex: "notes",
      key: "notes",
      className: "values",
      render: (text, record) => {
        return (
          <Button
            onClick={() => handleNotesClick(record)}
            type="text"
            disabled={localStorage.getItem("guest") === "true" ? true : false}
            icon={
              <BsListOl
                style={{
                  color:
                    localStorage.getItem("guest") === "true"
                      ? "grey"
                      : "#2c3fff",
                }}
              />
            }
          />
        );
      },
    },
    {
      title: t("slabHeader.edit"),
      dataIndex: "slabNumber",
      key: "slabNumber",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <Button
            type="text"
            icon={
              loadingState ? (
                <></>
              ) : (
                <CheckOutlined style={{ color: "green" }} />
              )
            }
            onClick={() => {
              setLoadingState(true);
              updateSlabDetails.mutate(editValue);
            }}
          >
            {loadingState ? <LoadingOutlined /> : <></>}
          </Button>
        ) : (
          <Button
            type="text"
            icon={
              <EditOutlined
                style={{
                  color:
                    localStorage.getItem("guest") === "true"
                      ? "grey"
                      : "#2c3fff",
                }}
              />
            }
            disabled={localStorage.getItem("guest") === "true" ? true : false}
            onClick={() => {
              setEditId({ slabNumber: record.slabNumber, id: record.id });
              setEditValue({
                entityId: record.id,
                slabNumber: record.slabNumber,
                blockNumber: record.blockNumber,
                status: record.status,
                netHeight: record.netHeight,
                netWidth: record.netWidth,
                grossHeight: record.grossHeight,
                grossWidth: record.grossWidth,
                finish: record.finish,
                quality: record.quality,
                price: record.price,
                priceMetric: record.currencyCode,
                thickness: record.thickness,
                thicknessMetric: record.thicknessUnit,
              });
            }}
          />
        ),
    },
  ].filter(item => !item.hidden);

  const contactSupplier = () => {
    props.setContactMsg((prevState) => ({
      ...prevState,
      supplierTenantId: props.supplierId,
    }));
    props.setFormView(true);
    handleCloseDrawerCallBack && handleCloseDrawerCallBack();
    toggleDrawer();
    props.togglePopup();
  };

  const goToSupplier = () => {
    props.setSellerPreview(true);
  };
  return props.isInventory ? (
    <>
      {slabDetailsInventory.isLoading || bundleDetailsInventory.isLoading ? (
        <Skeleton active />
      ) : (
        <Content
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            height: "100%",
            width: "100%",
          }}
        >
          <Content style={{ width: "40%" }}>
            <p className="imageHeadingInventory" style={{ marginTop: "10vh" }}>
              {props.slabData.productName}
            </p>
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="imageHeadingInventory" style={{ marginTop: "2vh" }}>
                Bundle {props.slabData.bundleNumber}
                {slabNumber === "" ? "" : ` - Slab ${slabNumber}`}
              </p>
              {localStorage.getItem("guest") === "true" ? (
                <></>
              ) : (
                <AddAPhoto
                  style={{
                    // marginTop: "2vh",
                    cursor: "pointer",
                    color: "#0C25F3",
                  }}
                  onClick={() => {
                    setUpdateImages(true);
                    setAddImageTo(
                      viewing === 0
                        ? {
                            bundle: props.slabData.bundleNumber,
                            slab: "-1",
                            block: "-1",
                          }
                        : {
                            bundle: props.slabData.bundleNumber,
                            slab: slabNumber,
                            block: "-1",
                          }
                    );
                    setInventory({
                      product: { name: props.slabData.productName },
                    });
                  }}
                />
              )}
            </Content>
            <Box sx={{ maxWidth: "100%", flexGrow: 1, marginTop: "3vh" }}>
              <Slider
                slides={images}
                allowDelete={true}
                setImages={setImages}
                queryClient={queryClient}
                viewing={viewing}
                productId={props.slabData.productName}
                selectedImageSourceData={props.slabData}
              />
            </Box>
          </Content>
          <Divider
            type="vertical"
            style={{
              height: "100%",
              backgroundColor: "#D3D3D3",
              marginLeft: "2vw",
              marginRight: "2vw",
            }}
          />

          <Content style={{ width: "55%", paddingTop: "3vh" }}>
            <Content
              style={{
                width: "100%",
                marginBottom: "1vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className="drawerTitle" style={{ marginBottom: "0" }}>
                {props.slabData.productName}
              </p>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {localStorage.getItem("guest") === "true" ? (
                  <></>
                ) : (
                  <Button
                    className="contactSupplierBtn"
                    icon={<ShoppingCartOutlined />}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "2vh 1vw",
                    }}
                    onClick={props.addToCartClicked}
                  >
                    <span style={{ marginLeft: 8 }}>
                      {t("globalBtn.addToCart")}
                    </span>
                  </Button>
                )}
              </Space>
            </Content>
            <Row style={{ margin: "3vh 2vw 1vh 2vw" }}>
              <Col className="colHeadingMarket" span={5}>
                {t("bundleHeader.bundle")}
              </Col>
              <Col
                style={{ marginLeft: "-0.5vw" }}
                className="colHeadingMarket"
                span={5}
              >
                {t("bundleHeader.block")}
              </Col>
              <Col className="colHeadingMarket" span={4}>
                {t("bundleHeader.inStock")}
              </Col>
              <Col className="colHeadingMarket" span={4}>
                {t("bundleHeader.onHold")}
              </Col>
              <Col className="colHeadingMarket" span={4}>
                {t("bundleHeader.available")}
              </Col>
            </Row>
            <Row className="bundleRow" style={{ padding: "2vh 2vw 2vh 2vw" }}>
              <Col className="valueStyle1" span={5}>
                {props.slabData.bundleNumber
                  ? props.slabData.bundleNumber.slice(0, 8)
                  : "--"}
              </Col>
              <Col className="valueStyle1" span={5}>
                {props.slabData.blockNumber ? props.slabData.blockNumber : "--"}
              </Col>
              <Col className="valueStyle1" span={4}>
                {slabDetailsInventory.data.length}
              </Col>
              <Col className="valueStyle1" span={4}>
                0
              </Col>
              <Col className="valueStyle1" span={4}>
                {slabDetailsInventory.data.length}
              </Col>
              <Col className="valueStyle1">
                <PictureFilled
                  style={{
                    color: "#0C25F3",
                    fontSize: viewing === 0 ? "x-large" : "large",
                  }}
                  onClick={() => {
                    setViewing(0);
                    setImages(
                      bundleDetailsInventory.data.images === undefined ||
                        bundleDetailsInventory.data.images.length === 0
                        ? []
                        : bundleDetailsInventory.data.images
                    );
                    setSlabNumber("");
                    setActiveStep(0);
                  }}
                />
              </Col>
            </Row>
            <Table
              columns={columnsInventory}
              dataSource={slabDetailsInventory.data}
              size="small"
              pagination={false}
              rowKey="id"
              className={`marketPlaceTable ${styles.slabTable}`}
              rowSelection={{
                type: "checkbox",
                selectedRowKeys: props.selectedSlab,
                onChange: handleRowSelection,
                getCheckboxProps: (record) => ({
                  disabled: record.status === "HOLD",
                }),
              }}
              loading={slabDetailsInventory.isLoading}
              scroll={{ x: 150 }}
            />
          </Content>
          <Popup
            title={popUpContent === 1 ? "Success" : "Add Notes"}
            openPopup={openPopup}
            togglePopup={togglePopup}
          >
            {popUpContent === 1 ? (
              <SuccessComponent detail={"Slab updated successfully!!"} />
            ) : (
              <EmptyNotesForm onClosePopup={togglePopup} data={notesOf} />
            )}
          </Popup>
          {addImageSuccess ? (
            <Content
              style={{
                backgroundColor: "rgba(128, 128, 128, 0.5)",
                width: "100%",
                height: "100%",
                zIndex: 2,
                position: "absolute",
                top: "0",
                left: "0",
                borderRadius: "5px",
              }}
            >
              <Card
                style={{
                  width: "30%",
                  height: "auto",
                  backgroundColor: "white",
                  margin: "20vh 0 0 30vw",
                }}
                extra={
                  <CloseOutlined
                    style={{ color: "#0066FF" }}
                    onClick={() => {
                      setAddImageSuccess(false);
                    }}
                  />
                }
              >
                <SuccessComponent detail={"Success"} />
              </Card>
            </Content>
          ) : (
            <></>
          )}
          {updateImages ? (
            <Content
              style={{
                backgroundColor: "rgba(128, 128, 128, 0.5)",
                width: "100%",
                height: "100%",
                zIndex: 2,
                position: "absolute",
                top: "0",
                left: "0",
                borderRadius: "5px",
              }}
            >
              <Card
                title={<p id="popUpHeader">Upload Image</p>}
                style={{
                  margin: "3vh 5vw 0 5vw",
                }}
                extra={
                  <CloseOutlined
                    style={{ color: "red" }}
                    onClick={() => {
                      setAddImageTo({ bundle: "-1", slab: "-1" });
                      setStepImage(0);
                      setUpdateImages(false);
                    }}
                  />
                }
              >
                <Content style={{ width: "100%" }}>
                  <Steps
                    current={stepImage}
                    style={{ width: "100%", fontFamily: "Roboto" }}
                    direction="horizontal"
                    items={[
                      {
                        title: "Select Image(s)",
                        // description: "Product details",
                      },
                      {
                        title: "Review Selection",
                        // description: "Bundle details",
                      },
                      {
                        title: "Upload Image",
                        // description: "Slab details",
                      },
                    ]}
                  />
                  <Divider
                    type="horizontal"
                    style={{ backgroundColor: "#656564" }}
                  />
                  <Content style={{ width: "100%" }}>
                    <InventoryImageForm
                      step={stepImage}
                      setStep={setStepImage}
                      addImageTo={addImageTo}
                      setAddImageTo={setAddImageTo}
                      inventory={inventory}
                      setBlockDisabled={setBlockDisabled}
                      updateFlag={true}
                      images={images}
                      setImages={setImages}
                      setUpdateImages={setUpdateImages}
                      setImageAddedCheck={setImageAddedCheck}
                      imageAddedCheck={imageAddedCheck}
                      blockDisabled={blockDisabled}
                      addCartSuccess={addImageSuccess}
                      setAddCartSuccess={setAddImageSuccess}
                    />
                  </Content>
                </Content>
              </Card>
            </Content>
          ) : (
            <></>
          )}
        </Content>
      )}
    </>
  ) : (
    <>
      {slabDetailsMarketplace.isLoading ? (
        <Skeleton active />
      ) : (
        <Content
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            height: "100%",
            width: "100%",
          }}
        >
          <Content style={{ width: "40%" }}>
            <p className="drawerTitle" style={{ marginTop: "10vh" }}>
              {props.slabData.productName}
            </p>
            <p className="imageHeadingInventory" style={{ marginTop: "2vh" }}>
              Bundle {props.slabData.bundleNumber}
              {slabNumber === "" ? "" : ` - Slab ${slabNumber}`}
            </p>
            <Box sx={{ maxWidth: "100%", flexGrow: 1, marginTop: "3vh" }}>
              <Slider slides={images} allowDelete={false} />
            </Box>
          </Content>
          <Divider
            type="vertical"
            style={{
              height: "100%",
              backgroundColor: "#D3D3D3",
              marginLeft: "2vw",
              marginRight: "2vw",
            }}
          />

          <Content style={{ width: "55%", paddingTop: "3vh" }}>
            <p className="drawerTitle">{props.slabData.productName}</p>
            {props.sellerPerspective ? (
              <></>
            ) : (
              <Content
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "0",
                  width: "100%",
                }}
              >
                <p
                  // shape="round"
                  style={{ margin: "0", cursor: "pointer" }}
                  className="supplierBtn"
                  onClick={goToSupplier}
                >
                  {props.supplierName}
                </p>
                <Button
                  className="contactSupplierBtn"
                  onClick={contactSupplier}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "2.5vh 1vw",
                  }}
                  icon={<MessageOutlined />}
                >
                  <span style={{ marginLeft: 8, marginBottom: "-0.15vh" }}>
                    Contact Supplier
                  </span>
                </Button>
              </Content>
            )}
            <Row style={{ margin: "3vh 2vw 1vh 2vw" }}>
              <Col className="colHeadingMarket" span={5}>
                {t("bundleHeader.bundle")}
              </Col>
              <Col
                style={{ marginLeft: "-0.5vw" }}
                className="colHeadingMarket"
                span={5}
              >
                {t("bundleHeader.block")}
              </Col>
              <Col className="colHeadingMarket" span={4}>
                {t("bundleHeader.inStock")}
              </Col>
              <Col className="colHeadingMarket" span={4}>
                {t("bundleHeader.onHold")}
              </Col>
              <Col className="colHeadingMarket" span={4}>
                {t("bundleHeader.available")}
              </Col>
            </Row>
            <Row className="bundleRow" style={{ padding: "2vh 2vw 2vh 2vw" }}>
              <Col className="valueStyle1" span={5}>
                {props.slabData.bundleNumber
                  ? props.slabData.bundleNumber.slice(0, 8)
                  : "--"}
              </Col>
              <Col className="valueStyle1" span={5}>
                {props.slabData.blockNumber ? props.slabData.blockNumber : "--"}
              </Col>
              <Col className="valueStyle1" span={4}>
                {slabDetailsMarketplace.data.length}
              </Col>
              <Col className="valueStyle1" span={4}>
                0
              </Col>
              <Col className="valueStyle1" span={4}>
                {slabDetailsMarketplace.data.length}
              </Col>
              <Col className="valueStyle1">
                <PictureFilled
                  style={{
                    color: "#0C25F3",
                    fontSize: viewing === 0 ? "x-large" : "large",
                  }}
                  onClick={() => {
                    setViewing(0);
                    setImages(props.bundleImages);
                    setSlabNumber("");
                    setActiveStep(0);
                  }}
                />
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={slabDetailsMarketplace.data}
              size="small"
              pagination={false}
              className="marketPlaceTable"
            />
          </Content>
        </Content>
      )}
    </>
  );
}
