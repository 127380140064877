import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { Button, Layout, Space } from "antd";
import { getStates } from "country-state-picker";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomerConstants } from "../../utils/customers";
import { userProfileConstants } from "../../utils/userProfile";
const { Content } = Layout;

export default function UserAdd(props) {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({
    fullName: "",
    jobTitle: "",
    department: "",
    phoneNumber: "",
    email: "",
    timeZone: "",
    language: "",
    employeeNumber: "",
    address1: "",
    address2: "",
    zipCode: "",
    state: "",
    country: "",
    joinDate: "",
    languageCode: "en",
    accessType: "",
  });
  const timezone = userProfileConstants.timeZone;
  const language = userProfileConstants.language;
  const country = userProfileConstants.country;
  const state = getStates(userProfileConstants.countryRef[userData.country])
    ? getStates(userProfileConstants.countryRef[userData.country])
    : [];
  const phoneNoCode = CustomerConstants.phoneNoCode;
  const department = userProfileConstants.department;
  const jobTitle = userProfileConstants.jobTitle;
  const access = userProfileConstants.access;
  // console.log(userData);
  const closeClicked = () => {
    props.setAddClicked(false);
  };

  const SaveClicked = () => {
    if (
      userData.fullName === "" ||
      userData.accessType === "" ||
      userData.email === ""
    ) {
      alert("Please fill the required fields!!");
    } else {
      props.profileMutate.mutate(userData);
    }
  };

  return (
    <Content style={{ width: "100%" }}>
      <Content style={{ width: "100%" }}>
        <h1 className="addEmployeeProfile">Employee Profile</h1>
        <Content style={{ width: "100%" }}>
          <p className="subHeaderProfile">Employee details</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ width: "30vw" }}
                id="outlined-basic"
                label={
                  <p style={userData.fullName === "" ? { color: "red" } : {}}>
                    Employee Full name
                  </p>
                }
                variant="outlined"
                onChange={(e) => {
                  e.preventDefault();
                  setUserData((prevState) => ({
                    ...prevState,
                    fullName: e.target.value,
                  }));
                }}
              />
            </Content>
          </Content>
        </Content>
        <Content
          style={{ paddingTop: "3vh", paddingBottom: "2vh", width: "100%" }}
        >
          <Content
            style={{
              borderBottom:
                "0.5px solid var(--neutral-background-text-white-900, #797979)",
              width: "100%",
            }}
          ></Content>
        </Content>
        <Content style={{ width: "100%" }}>
          <p className="subHeaderProfile">Job details</p>
          <Content
            style={{ width: "100%", paddingBottom: "2vh" }}
            className="ProfileRow"
          >
            <Content
              style={{ width: "100%", textAlign: "center", padding: "0 1vw" }}
            >
              <TextField
                sx={{ width: "100%" }}
                id="outlined-basic"
                label="Employee number"
                variant="outlined"
                onChange={(e) => {
                  e.preventDefault();
                  setUserData((prevState) => ({
                    ...prevState,
                    employeeNumber: e.target.value,
                  }));
                }}
              />
            </Content>
            <Content
              style={{ width: "100%", textAlign: "center", padding: "0 1vw" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  sx={{ width: "100%" }}
                  onChange={(date) => {
                    if (date) {
                      setUserData((prevState) => ({
                        ...prevState,
                        joinDate: `${date.date()}-${
                          date.month() + 1
                        }-${date.year()}`,
                      }));
                    }
                  }}
                  label="Joining Date"
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </LocalizationProvider>
            </Content>
          </Content>
          <Content
            style={{ width: "100%", paddingBottom: "2vh" }}
            className="ProfileRow"
          >
            <Content
              style={{ width: "100%", textAlign: "center", padding: "0 1vw" }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Department
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Department"
                  onChange={(e) => {
                    e.preventDefault();
                    setUserData((prevState) => ({
                      ...prevState,
                      department: e.target.value,
                    }));
                  }}
                >
                  {department.map((data, i) => {
                    return (
                      <MenuItem key={i} value={data.value}>
                        {data.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Content>
            <Content
              style={{ width: "100%", textAlign: "center", padding: "0 1vw" }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Job Title</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Job Title"
                  onChange={(e) => {
                    e.preventDefault();
                    setUserData((prevState) => ({
                      ...prevState,
                      jobTitle: e.target.value,
                    }));
                  }}
                >
                  {jobTitle.map((data, i) => {
                    return (
                      <MenuItem key={i} value={data.value}>
                        {data.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Content>
          </Content>
        </Content>
        <Content
          style={{ paddingTop: "3vh", paddingBottom: "2vh", width: "100%" }}
        >
          <Content
            style={{
              borderBottom:
                "0.5px solid var(--neutral-background-text-white-900, #797979)",
              width: "100%",
            }}
          ></Content>
        </Content>
        <Content style={{ width: "100%" }}>
          <p className="subHeaderProfile">Account details</p>
          <Content
            style={{ width: "100%", paddingBottom: "2vh" }}
            className="ProfileRow"
          >
            <Content
              style={{ width: "100%", textAlign: "center", padding: "0 1vw" }}
            >
              <TextField
                sx={{ width: "100%" }}
                id="outlined-basic"
                label={
                  <p style={userData.email === "" ? { color: "red" } : {}}>
                    Employee Email ID
                  </p>
                }
                variant="outlined"
                onChange={(e) => {
                  e.preventDefault();
                  setUserData((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }));
                }}
              />
            </Content>
            <Content
              style={{ width: "100%", textAlign: "center", padding: "0 1vw" }}
            >
              <FormControl fullWidth>
                <InputLabel
                  style={userData.accessType === "" ? { color: "red" } : {}}
                  id="demo-simple-select-label"
                >
                  Access Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={
                    <p
                      style={userData.accessType === "" ? { color: "red" } : {}}
                    >
                      Access Level
                    </p>
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    setUserData((prevState) => ({
                      ...prevState,
                      accessType: e.target.value,
                    }));
                  }}
                >
                  {access.map((data, i) => {
                    return (
                      <MenuItem key={i} value={data.value}>
                        {data.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Content>
          </Content>
        </Content>
        <Content
          style={{ paddingTop: "3vh", paddingBottom: "2vh", width: "100%" }}
        >
          <Content
            style={{
              borderBottom:
                "0.5px solid var(--neutral-background-text-white-900, #797979)",
              width: "100%",
            }}
          ></Content>
        </Content>
        <Content style={{ width: "100%" }}>
          <p className="subHeaderProfile">Contact details</p>
          <Content
            style={{ width: "100%", paddingBottom: "2vh" }}
            className="ProfileRow"
          >
            <Content style={{ width: "100%", textAlign: "center" }}>
              <Space>
                <FormControl sx={{ width: "5vw" }}>
                  <InputLabel id="demo-simple-select-label">Code</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Code"
                    onChange={(e) => {
                      if (userData.phoneNumber === undefined) {
                        setUserData((prevState) => ({
                          ...prevState,
                          phoneNumber: e.target.value,
                        }));
                      } else {
                        const firstWord = userData.phoneNumber.split(" ");
                        if (firstWord.length === 1)
                          setUserData((prevState) => ({
                            ...prevState,
                            phoneNumber: e.target.value,
                          }));
                        else
                          setUserData((prevState) => ({
                            ...prevState,
                            phoneNumber: `${e.target.value} ${firstWord[1]}`,
                          }));
                      }
                    }}
                  >
                    {phoneNoCode.map((phoneNumber, i) => {
                      return (
                        <MenuItem key={i} value={phoneNumber.value}>
                          {phoneNumber.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label="Phone number"
                  variant="outlined"
                  sx={{ width: "25vw" }}
                  onChange={(e) => {
                    e.preventDefault();
                    const firstWord = userData.phoneNumber.split(" ");

                    setUserData((prevState) => ({
                      ...prevState,
                      phoneNumber: `${firstWord[0]} ${e.target.value}`,
                    }));
                  }}
                />
              </Space>
            </Content>
          </Content>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ marginBottom: "2vh", width: "30.5vw" }}
                id="outlined-basic"
                label="Address 1"
                variant="outlined"
                // defaultValue={inputValue.address1 ? inputValue.address1 : ""}
                onChange={(e) => {
                  e.preventDefault();
                  setUserData((prevState) => ({
                    ...prevState,
                    address1: e.target.value,
                  }));
                }}
              />
              <br />
              <TextField
                sx={{ marginBottom: "2vh", width: "30.5vw" }}
                id="outlined-basic"
                label="Address 2"
                variant="outlined"
                // defaultValue={inputValue.address2 ? inputValue.address2 : ""}
                onChange={(e) => {
                  e.preventDefault();
                  setUserData((prevState) => ({
                    ...prevState,
                    address2: e.target.value,
                  }));
                }}
              />
              <br />
              <Space>
                <Autocomplete
                  options={country.map((option) => ({ label: option }))}
                  getOptionLabel={(option) => option.label}
                  defaultValue={{
                    label: userData.country ? userData.country : "Select",
                  }}
                  id="combo-box-demo"
                  onChange={(event, newValue) => {
                    setUserData((prev) => ({
                      ...prev,
                      country: newValue ? newValue.label : "Select",
                    }));
                  }}
                  sx={{ width: "10vw" }}
                  renderInput={(params) => (
                    <TextField {...params} label={t("myProfile.country")} />
                  )}
                />
                <Autocomplete
                  options={state.map((option) => ({ label: option }))}
                  getOptionLabel={(option) => option.label}
                  defaultValue={{
                    label: userData.state ? userData.state : "Select",
                  }}
                  id="combo-box-demo"
                  onChange={(event, newValue) => {
                    setUserData((prev) => ({
                      ...prev,
                      state: newValue ? newValue.label : "Select",
                    }));
                  }}
                  sx={{ width: "10vw" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("myProfile.state")}
                      disabled={userData.country === "" ? true : false}
                    />
                  )}
                />
                <TextField
                  id="outlined-basic"
                  label="Zip Code"
                  variant="outlined"
                  sx={{ width: "10vw" }}
                  // defaultValue={inputValue.zipCode ? inputValue.zipCode : ""}
                  onChange={(e) => {
                    e.preventDefault();
                    setUserData((prevState) => ({
                      ...prevState,
                      zipCode: e.target.value,
                    }));
                  }}
                />
              </Space>
            </Content>
          </Content>
        </Content>
        <Content
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "2vw",
            paddingRight: "2vw",
            paddingBottom: "2vh",
          }}
        >
          <Button
            className="companyEditButton"
            shape="round"
            size="large"
            style={{ borderRadius: "100px" }}
            onClick={closeClicked}
          >
            {t("globalBtn.cancel")}
          </Button>
          <Button
            className="contactSupplierBtn"
            shape="round"
            size="large"
            style={{
              borderRadius: "100px",
            }}
            onClick={SaveClicked}
            loading={props.profileMutate.isLoading}
          >
            {t("globalBtn.save")}
          </Button>
        </Content>
      </Content>
    </Content>
  );
}
