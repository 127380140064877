import { Layout, Skeleton } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFetchResults } from "../../queries/react-query/search";
import Filter from "../Filter";
import SellerPageInBuyerView from "../Marketplace/SellerPageInBuyerView";
import SearchCustomers from "./SearchCustomers";
import SearchInventory from "./SearchInventory";
import SearchMarketplace from "./SearchMarketplace";
import SearchProducts from "./SearchProducts";
import SearchSales from "./SearchSales";
import SearchSuppliers from "./SearchSuppliers";
import SearchUsers from "./SearchUsers";
import "./search.css";

export default function MainLayout() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const [sellerPreview, setSellerPreview] = useState(false);
  const [supplierName, setSupplierName] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [presentColorIndex, setPresentColorIndex] = useState(0);
  const searchQueryParam = searchParams.get("searchText");
  const searchModuleParam = searchParams.get("module");
  const colors = [
    "#008018",
    "#F59E0B",
    "#d10067",
    "#db2a12",
    "#0078ed",
    "#dede02",
  ];
  useEffect(() => {}, [
    searchQueryParam,
    searchModuleParam,
    filterObject,
    presentColorIndex,
  ]);
  const searchResults = useFetchResults([
    searchModuleParam === "INVENTORY"
      ? "ISearch"
      : searchModuleParam === "MARKETPLACE"
      ? "MSearch"
      : searchModuleParam === "SALES"
      ? "SaSearch"
      : searchModuleParam === "SUPPLIERS"
      ? "SuSearch"
      : searchModuleParam === "CUSTOMERS"
      ? "CSearch"
      : searchModuleParam === "PRODUCTS"
      ? "PSearch"
      : "USearch",
    {
      module: searchModuleParam,
      searchText: searchQueryParam,
      filterContext: filterObject,
    },
  ]);
  const dynamicFilters = (inputJSON) => {
    const dFilters = Object.keys(inputJSON).map((key) => ({
      key,
      type: "checkbox",
      value: Object.keys(inputJSON[key]).map((paramName) => ({
        paramName,
        cnt: inputJSON[key][paramName],
      })),
    }));
    return dFilters;
  };
  return sellerPreview ? (
    <SellerPageInBuyerView
      setSellerPreview={setSellerPreview}
      supplierName={supplierName}
      supplierId={supplierId}
      colors={colors}
      queryKey={
        searchModuleParam === "INVENTORY"
          ? "ISearch"
          : searchModuleParam === "MARKETPLACE"
          ? "MSearch"
          : searchModuleParam === "SALES"
          ? "SaSearch"
          : searchModuleParam === "SUPPLIERS"
          ? "SuSearch"
          : searchModuleParam === "CUSTOMERS"
          ? "CSearch"
          : searchModuleParam === "PRODUCTS"
          ? "PSearch"
          : "USearch"
      }
      presentColorIndex={presentColorIndex}
    />
  ) : (
    <Layout style={{ paddingTop: "2vh" }}>
      {searchResults.isLoading ? (
        <Sider
          className="SiderNav"
          width="15%"
          style={{
            background: "var(--neutral-background-text-white-000, #FFF)",
            height: "67vh",
            borderRadius: "10px",
            overflowY: "auto",
          }}
        >
          <Skeleton active />
        </Sider>
      ) : (
        <Filter
          dynamic={true}
          queryClient={queryClient}
          Filters={dynamicFilters(searchResults.data.aggregations)}
          setFilterObject={setFilterObject}
          filterObject={filterObject}
        />
      )}
      <Content
        style={{
          width: "85%",
        }}
      >
        <Content
          style={{
            width: "100%",
            paddingBottom: "2vh",
            paddingRight: "2vw",
            paddingLeft: "1vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ margin: 0, width: "3%" }}>in : </p>
          <Content
            className="searchNav"
            style={{
              width: "97%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {localStorage.getItem("isInventoryEnabled") === "false" ? (
              <></>
            ) : (
              <Content
                onClick={() => {
                  searchParams.set("module", "INVENTORY");
                  searchParams.set("searchText", searchQueryParam);
                  const searchString = searchParams.toString();
                  setFilterObject({});
                  navigate(`/search?${searchString}`);
                  // queryClient.refetchQueries(['search'])
                }}
                className={
                  searchModuleParam === "INVENTORY"
                    ? "searchNavTextActive"
                    : "searchNavText"
                }
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "center",
                  padding: "2vh 0",
                }}
              >
                {`${t(`navbar.inventory`)}`}
              </Content>
            )}
            {localStorage.getItem("isSalesEnabled") === "false" ||
            localStorage.getItem("guest") === "true" ? (
              <></>
            ) : (
              <Content
                onClick={() => {
                  searchParams.set("module", "SALES");
                  searchParams.set("searchText", searchQueryParam);
                  const searchString = searchParams.toString();
                  setFilterObject({});
                  navigate(`/search?${searchString}`);
                  // queryClient.refetchQueries(['search'])
                }}
                className={
                  searchModuleParam === "SALES"
                    ? "searchNavTextActive"
                    : "searchNavText"
                }
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "center",
                  padding: "2vh 0",
                }}
              >
                {`${t(`navbar.sales`)}`}
              </Content>
            )}
            {(localStorage.getItem("isMarketplaceEnabled") === "true" &&
              (localStorage.getItem("marketplaceType") === "BUYER" ||
                localStorage.getItem("marketplaceType") === "BOTH")) ||
            localStorage.getItem("guest") === "false" ? (
              <Content
                onClick={() => {
                  searchParams.set("module", "MARKETPLACE");
                  searchParams.set("searchText", searchQueryParam);
                  const searchString = searchParams.toString();
                  setFilterObject({});
                  navigate(`/search?${searchString}`);
                  // queryClient.refetchQueries(['search'])
                }}
                className={
                  searchModuleParam === "MARKETPLACE"
                    ? "searchNavTextActive"
                    : "searchNavText"
                }
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "center",
                  padding: "2vh 0",
                }}
              >
                {`${t(`navbar.marketplace`)}`}
              </Content>
            ) : (
              <></>
            )}
            {(localStorage.getItem("isMarketplaceEnabled") === "true" &&
              (localStorage.getItem("marketplaceType") === "BUYER" ||
                localStorage.getItem("marketplaceType") === "BOTH")) ||
            localStorage.getItem("guest") === "false" ? (
              <Content
                onClick={() => {
                  searchParams.set("module", "SUPPLIERS");
                  searchParams.set("searchText", searchQueryParam);
                  const searchString = searchParams.toString();
                  setFilterObject({});
                  navigate(`/search?${searchString}`);
                  // queryClient.refetchQueries(['search'])
                }}
                className={
                  searchModuleParam === "SUPPLIERS"
                    ? "searchNavTextActive"
                    : "searchNavText"
                }
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "center",
                  padding: "2vh 0",
                }}
              >
                {`${t(`navbar.suppliers`)}`}
              </Content>
            ) : (
              <></>
            )}
            {localStorage.getItem("isSalesEnabled") === "false" ||
            localStorage.getItem("guest") === "true" ? (
              <></>
            ) : (
              <Content
                onClick={() => {
                  searchParams.set("module", "CUSTOMERS");
                  searchParams.set("searchText", searchQueryParam);
                  const searchString = searchParams.toString();
                  setFilterObject({});
                  navigate(`/search?${searchString}`);
                  // queryClient.refetchQueries(['search'])
                }}
                className={
                  searchModuleParam === "CUSTOMERS"
                    ? "searchNavTextActive"
                    : "searchNavText"
                }
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "center",
                  padding: "2vh 0",
                }}
              >
                {`${t(`subNav.customers`)}`}
              </Content>
            )}
            {localStorage.getItem("isInventoryEnabled") === "false" ||
            localStorage.getItem("guest") === "true" ? (
              <></>
            ) : (
              <Content
                onClick={() => {
                  searchParams.set("module", "PRODUCTS");
                  searchParams.set("searchText", searchQueryParam);
                  const searchString = searchParams.toString();
                  setFilterObject({});
                  navigate(`/search?${searchString}`);
                  // queryClient.refetchQueries(['search'])
                }}
                className={
                  searchModuleParam === "PRODUCTS"
                    ? "searchNavTextActive"
                    : "searchNavText"
                }
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "center",
                  padding: "2vh 0",
                }}
              >
                {`${t(`subNav.products`)}`}
              </Content>
            )}
            {localStorage.getItem("guest") === "true" ? (
              <></>
            ) : (
              <Content
                onClick={() => {
                  searchParams.set("module", "USERS");
                  searchParams.set("searchText", searchQueryParam);
                  const searchString = searchParams.toString();
                  setFilterObject({});
                  navigate(`/search?${searchString}`);
                  // queryClient.refetchQueries(['search'])
                }}
                className={
                  searchModuleParam === "USERS"
                    ? "searchNavTextActive"
                    : "searchNavText"
                }
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "center",
                  padding: "2vh 0",
                }}
              >
                {`${t(`subNav.users`)}`}
              </Content>
            )}
          </Content>
        </Content>
        {searchResults.isLoading ? (
          <Skeleton active />
        ) : searchModuleParam === "INVENTORY" &&
          localStorage.getItem("isInventoryEnabled") !== "false" ? (
          <SearchInventory
            searchResult={searchResults}
            filterObject={filterObject}
          />
        ) : searchModuleParam === "MARKETPLACE" &&
          localStorage.getItem("isMarketplaceEnabled") === "true" &&
          (localStorage.getItem("marketplaceType") === "BUYER" ||
            localStorage.getItem("marketplaceType") === "BOTH") ? (
          <SearchMarketplace
            sellerPreview={sellerPreview}
            setSellerPreview={setSellerPreview}
            supplierName={supplierName}
            setSupplierName={setSupplierName}
            supplierId={supplierId}
            setSupplierId={setSupplierId}
            searchResult={searchResults}
            filterObject={filterObject}
          />
        ) : searchModuleParam === "SUPPLIERS" &&
          localStorage.getItem("isMarketplaceEnabled") === "true" &&
          (localStorage.getItem("marketplaceType") === "BUYER" ||
            localStorage.getItem("marketplaceType") === "BOTH") ? (
          <SearchSuppliers
            colors={colors}
            setPresentColorIndex={setPresentColorIndex}
            sellerPreview={sellerPreview}
            setSellerPreview={setSellerPreview}
            supplierName={supplierName}
            setSupplierName={setSupplierName}
            supplierId={supplierId}
            setSupplierId={setSupplierId}
            searchResult={searchResults}
          />
        ) : searchModuleParam === "USERS" ? (
          <SearchUsers searchResult={searchResults} />
        ) : searchModuleParam === "PRODUCTS" &&
          localStorage.getItem("isInventoryEnabled") !== "false" ? (
          <SearchProducts searchResult={searchResults} />
        ) : searchModuleParam === "CUSTOMERS" &&
          localStorage.getItem("isSalesEnabled") !== "false" ? (
          <SearchCustomers searchResult={searchResults} />
        ) : searchModuleParam === "SALES" &&
          localStorage.getItem("isSalesEnabled") !== "false" ? (
          <SearchSales searchResult={searchResults.data} searchView={true} />
        ) : (
          <SearchMarketplace
            sellerPreview={sellerPreview}
            setSellerPreview={setSellerPreview}
            supplierName={supplierName}
            setSupplierName={setSupplierName}
            supplierId={supplierId}
            setSupplierId={setSupplierId}
            searchResult={searchResults}
          />
        )}
      </Content>
    </Layout>
  );
}
