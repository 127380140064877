import { useMutation, useQuery } from "react-query";
import { editMyProfileDetails, getMyProfileDetails } from "../api/myProfile";

export const useFetchMyProfile = (queryKey) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: getMyProfileDetails,
  });
};

export const useEditMyProfile = (queryKey, onSuccess) => {
  return useMutation({
    mutationKey: queryKey,
    mutationFn: editMyProfileDetails,
    onSuccess: onSuccess,
  });
};
