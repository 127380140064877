import { useMutation, useQuery } from "react-query";
import { fetchInventoryAnalytics, updateGuest } from "../api/home";

export const useFetchInventoryAnalytics = (queryKey) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: fetchInventoryAnalytics,
  });
};

export const useUpdateGuest = (queryKey, onSuccess) => {
  return useMutation({
    mutationKey: queryKey,
    mutationFn: updateGuest,
    onSuccess: onSuccess,
  });
};
