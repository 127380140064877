import { Tooltip } from "antd";
import React, { useState } from "react";
import { BsLock, BsUnlock } from "react-icons/bs";

export function Lock(props) {
  const [isOpen, setIsOpen] = useState(props.state);

  return (
    <div>
      {isOpen ? (
        <Tooltip title={props.text}>
          <BsUnlock style={{ color: "#2c3fff" }} />
        </Tooltip>
      ) : (
        <Tooltip title={props.text}>
          <BsLock style={{ color: "#2c3fff" }} />
        </Tooltip>
      )}
    </div>
  );
}
