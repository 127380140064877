import Offer from "../../pages/Offer/Offer";

export const Widgets = () => {
  return [
    {
      centered: true,
      tabItems: [
        {
          key: "Offer",
          children: <Offer />,
        },
      ],
      type: "tab",
    },
  ];
};
