import { Col, Form, InputNumber, Row, Select } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { fields } from "./config";

const SubProductForm = ({ formItemName, formItemFields }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[24, 12]} style={{ padding: "1vh 2vw" }}>
      {_.map(fields, ({ column, name, label, ...props }) => (
        <Col span={column} key={name}>
          <Form.Item
            name={[formItemName, name]}
            required={true}
            label={t(label)}
            {...formItemFields}
          >
            {/* <props.component {...props} /> */}
            {props.type === "number" ? (
              <InputNumber min={0} style={{ width: "100%" }} bordered={false} />
            ) : (
              <Select bordered={false} options={props.options} />
            )}
          </Form.Item>
        </Col>
      ))}
    </Row>
  );
};

export default SubProductForm;
