import { ExpandAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "../styles/Drawer.css";

export const useDrawer = (initValue) => {
  const [openDrawer, setOpenDrawer] = useState(initValue);
  const [drawerTitle, setDrawerTitle] = useState("");
  const toggleDrawer = () => setOpenDrawer(!openDrawer);
  const [expand, setExpand] = useState(false);

  return {
    openDrawer,
    setOpenDrawer,
    toggleDrawer,
    drawerTitle,
    setDrawerTitle,
    expand,
    setExpand,
  };
};

export const ExpandableDrawer = (props) => {
  const {
    title,
    children,
    openDrawer,
    toggleDrawer,
    closeDrawerCallback,
    expand,
    onDrawerExpand,
    drawerExpand,
  } = props;

  const drawerTitleStyle = {
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Open Sans",
    color: "#A2A3A5",
  };

  const CustomTitle = () => {
    return <div className="drawerTitleSales">{title}</div>;
  };

  return (
    <CSSTransition
      in={openDrawer}
      timeout={300}
      classNames="drawer"
      unmountOnExit
    >
      <Drawer
        open={openDrawer}
        onClose={() => {
          closeDrawerCallback && closeDrawerCallback();
          onDrawerExpand(false);
          toggleDrawer();
        }}
        title={<CustomTitle />}
        width={expand || drawerExpand ? "97%" : "50%"}
      >
        {children}
      </Drawer>
    </CSSTransition>
  );
};
