// import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Avatar, Button, Col, Layout, Row } from "antd";
import React from "react";

const { Content } = Layout;

export default function ProfileRow(props) {
  const editClicked = () => {
    props.setEditData(props.data);
    props.setEditClicked(true);
  };
  const deleteClicked = () => {
    props.setLoadingIndex(props.index);
    props.setConfirmDelete(true);
    props.setDeleteData({
      email: props.data.email,
      fullName: props.data.fullName,
    });
  };
  return (
    <Row
      style={{
        margin: "0",
        padding: "10px 0",
        width: "100%",
      }}
    >
      <Col span={11} className="TableColumn">
        <Avatar
          id="Avatar"
          style={{
            backgroundColor: "#1055BB",
            verticalAlign: "middle",
          }}
          size={"large"}
          gap={4}
        >
          {props.data.fullName ? props.data.fullName.charAt(0) : "A"}
        </Avatar>
        <p className="userCollapseRow" style={{ marginBottom: "0" }}>
          {props.data.fullName ? props.data.fullName : "--"}
        </p>
      </Col>
      {/* <Col span={6} className="TableColumn tableValue">
        {props.data.organization}{" "}
      </Col> */}
      <Col span={7} className="TableColumn tableValue userCollapseRow">
        {props.data.jobTitle ? props.data.jobTitle : "--"}
      </Col>
      <Col span={6} className="TableColumn tableValue">
        <Content className="TableColumn1" style={{ width: "100%" }}>
          <p
            className="tableValue userCollapseRow"
            style={
              props.searchView
                ? {
                    marginBottom: "0",
                    marginRight: "10px",
                    width: "100%",
                    textAlign: "right",
                  }
                : { marginBottom: "0", width: "50%" }
            }
          >
            {props.data.department ? props.data.department : "--"}
          </p>

          {props.searchView ? (
            <></>
          ) : (
            <Content style={{ width: "50%", textAlign: "right" }}>
              {props.deleteMutate.isLoading &&
              props.loadingIndex === props.index ? (
                <LoadingOutlined
                  style={{
                    fontSize: "x-large",
                    color: "#0066FF",
                  }}
                />
              ) : (
                <DeleteOutline
                  style={{
                    fontSize: "x-large",
                    color: "red",
                  }}
                  onClick={deleteClicked}
                />
              )}
              <Button
                type="text"
                style={{
                  fontSize: "medium",
                  color: "#0066FF",
                  fontFamily: "'Open Sans', sans-serif",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "100%",
                  letterSpacing: "0.005em",
                }}
                onClick={editClicked}
              >
                <EditOutlined
                  style={{ fontSize: "x-large", color: "#0066FF" }}
                />
              </Button>
            </Content>
          )}
        </Content>
      </Col>
    </Row>
  );
}
