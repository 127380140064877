import { CheckCircleFilled, WarningOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { useTranslation } from "react-i18next";

export default function SuccessComponent(props) {
  const { t } = useTranslation();
  return (
    <Content style={{ textAlign: "center", width: "100%" }}>
      {props.isError ? (
        <WarningOutlined
          style={{ fontSize: "400%", color: "red", marginBottom: "2vh" }}
        />
      ) : (
        <CheckCircleFilled
          style={{ fontSize: "400%", color: "green", marginBottom: "2vh" }}
        />
      )}
      <p
        style={{
          fontSize: "large",
          fontWeight: "bold",
          marginBottom: "1vh",
        }}
      >
        {props.isError ? t("globalBtn.error") : t("globalBtn.success")}
      </p>
      <p>{props.detail}</p>
    </Content>
  );
}
