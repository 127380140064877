import { DownOutlined } from "@ant-design/icons";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Col, Divider, Empty, Image, Layout, List, Row, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../styles/inventory.css";
import CollapsiblePanel from "../CollapsiblePanel";
import Slider from "../Common/MediaSlider/MediaSlider";
import { Popup, usePopup } from "../CreatePopup";
import EmptyNotesFormGeneral from "../EmptyNotesFormGeneral";
import { ExpandableDrawer, useDrawer } from "../ExpandableDrawer";
import BundleCardSales from "../Sales/BundleCardSales";
import InspectionCollapse from "../Sales/InspectionCollapse";
import ShipmentView from "../Sales/ShipmentView";
import "../Sales/sales.css";

export default function SearchSales({ searchResult, searchView, ...props }) {
  const { t } = useTranslation();
  const {
    openDrawer,
    setOpenDrawer,
    toggleDrawer,
    setDrawerTitle,
    expand,
    setExpand,
  } = useDrawer(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [imagePopUp, setImagePopUp] = useState(false);
  const [imagePopUpValue, setImagePopUpValue] = useState("");
  const [selectedSlab, setSelectedSlab] = useState([]);
  const [index, setIndex] = useState(0);
  const [drawerClick, setDrawerClick] = useState(0);
  const [value, setValue] = useState(2);
  const [changeInspector, setChangeInspector] = useState(false);
  const [changeCustomer, setChangeCustomer] = useState(false);
  const { openPopup, togglePopup } = usePopup(false);
  const [notesOf, setNotesOf] = useState([]);
  const [isPosting, setIsPosting] = useState(false);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const [isLoadingInspector, setIsLoadingInspector] = useState(false);
  const [preSignedURL, setPreSignedURL] = useState("");
  const [popUpURL, setPopUpURL] = useState("");
  const [toggleClick, setToggleClick] = useState(0);
  const [images, setImages] = useState([]);
  const [shipmentInfo, setShipmentInfo] = useState(null);
  const [editClicked, setEditClicked] = useState(false);
  const [viewing, setViewing] = useState({ bundle: "-1", slab: "-1" });
  const listItemStyle = {
    padding: "10px",
    paddingRight: "35px",
    background: "var(--neutral-background-text-white-100, #F9F9F9)",
  };

  const handleCloseDrawerCallBack = () => {};

  const handleItemsSelected = (data) => {
    setSelectedItems(data);
  };

  const handleDrawerExpand = (expanded) => {
    setExpand(expanded);
  };

  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {}, [
    index,
    drawerClick,
    value,
    changeInspector,
    changeCustomer,
    isPosting,
  ]);
  return (
    <Layout>
      <Content
        style={{
          width: "85%",
          paddingRight: "3vw",
        }}
      >
        <List
          itemLayout="horizontal"
          style={{
            marginTop: "40px",
          }}
        >
          <Row
            style={{
              padding: "0 0 0 20px",
            }}
            className="tableHeader"
          >
            <Col span={6} className="salesHeaderList">
              {t("salesHeaders.orderNumber")}
            </Col>
            <Col span={4} className="salesHeaderList">
              {t("salesHeaders.customer")}
            </Col>
            <Col span={6} className="salesHeaderList">
              {t("salesHeaders.products")}
            </Col>
            <Col span={4} className="salesHeaderList">
              {t("salesHeaders.type")}
            </Col>
            <Col
              span={3}
              style={{ paddingLeft: "1.5vw" }}
              className="salesHeaderList"
            >
              {t("salesHeaders.orderCreated")}
            </Col>
            <Col
              span={1}
              style={{ paddingLeft: "2vw" }}
              className="salesHeaderList"
            >
              {t("salesHeaders.status")}
            </Col>
          </Row>
        </List>
        <Content
          style={{ width: "100%", overflowX: "hidden" }}
          className="ListViewSales"
        >
          {searchResult.saleList.length === 0 ? (
            <Empty
              style={{ width: "100%", marginTop: "2vh", fontFamily: "Roboto" }}
              description={<span>No results found</span>}
            />
          ) : (
            searchResult.saleList.map((item, i) => {
              return (
                <InspectionCollapse
                  key={item.entityId}
                  itemIndex={i}
                  setIndex={setIndex}
                  listItemStyle={listItemStyle}
                  data={item}
                  setDrawerTitle={setDrawerTitle}
                  setOpenDrawer={setOpenDrawer}
                  setDrawerClick={setDrawerClick}
                  changeInspector={changeInspector}
                  setChangeInspector={setChangeInspector}
                  changeCustomer={changeCustomer}
                  setChangeCustomer={setChangeCustomer}
                  setNotesOf={setNotesOf}
                  togglePopup={togglePopup}
                  isLoadingCustomer={isLoadingCustomer}
                  setIsLoadingCustomer={setIsLoadingCustomer}
                  isLoadingInspector={isLoadingInspector}
                  setIsLoadingInspector={setIsLoadingInspector}
                  type={
                    item.status === "HOLD"
                      ? -1
                      : item.status === "INSPECTION"
                      ? 0
                      : item.status === "ORDER"
                      ? 1
                      : item.status === "SHIPPED"
                      ? 2
                      : 3
                  }
                  setPreSignedURL={setPreSignedURL}
                  setToggleClick={setToggleClick}
                  searchView={true}
                  setInvoice={
                    item.status === "ORDER"
                      ? true
                      : item.status === "SHIPPED"
                      ? true
                      : false
                  }
                  setShipmentInfo={setShipmentInfo}
                />
              );
            })
          )}
        </Content>
        {drawerClick === 0 ? (
          <ExpandableDrawer
            toggleDrawer={toggleDrawer}
            openDrawer={openDrawer}
            closeDrawerCallback={handleCloseDrawerCallBack}
            title={""}
            expand={true}
            onDrawerExpand={handleDrawerExpand}
          >
            <Content
              style={{
                display: "flex",
                justifyContent: "flex-start",
                height: "100%",
                alignItems: " flex-start",
                width: "100%",
              }}
            >
              <Content style={{ width: "40%" }}>
                <p
                  className="imageHeadingInventory"
                  style={{ marginTop: "10vh" }}
                >
                  {images.length === 0
                    ? "Click on image icon in table"
                    : viewing.slab === "-1"
                    ? `Bundle ${viewing.bundle}`
                    : `Bundle ${viewing.bundle} : Slab ${viewing.slab}`}
                </p>
                <Box sx={{ maxWidth: "100%", flexGrow: 1, marginTop: "3vh" }}>
                  <Slider slides={images} />
                </Box>
              </Content>
              <Divider
                type="vertical"
                style={{
                  height: "100%",
                  backgroundColor: "#D3D3D3",
                  marginLeft: "2vw",
                  marginRight: "2vw",
                }}
              />
              <Content style={{ width: "55%" }}>
                <Space
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <h2 style={{ margin: 0, color: "#545454" }}>View List</h2>
                </Space>
                <Content style={{ marginTop: "30px", width: "100%" }}>
                  <Content
                    direction="vertical"
                    style={{ width: "100%" }}
                    size={24}
                  >
                    <Content className="ListViewInner">
                      {searchResult.saleList[index] ? (
                        searchResult.saleList[index].productInformationList.map(
                          (data, i) => {
                            return (
                              <CollapsiblePanel
                                key={i}
                                panelProps={{
                                  header: data.productName,
                                  key: "1",
                                }}
                                collapseProps={{
                                  ghost: true,
                                  size: "large",
                                  style: { marginBottom: "2vh" },
                                  key: data.productName,
                                  className: "SalesProductCollapse",
                                }}
                                title={data.productName}
                              >
                                <List
                                  itemLayout="horizontal"
                                  style={{
                                    marginTop: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <List.Item
                                    style={{
                                      width: "96%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      paddingLeft: "18px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    <Space className="tableHeader1">
                                      Bundle No.
                                    </Space>
                                    <Space className="tableHeader1">
                                      Slab in stock
                                    </Space>
                                    <Space className="tableHeader1">
                                      Total price
                                    </Space>
                                    <Space className="tableHeader1">
                                      Quality
                                    </Space>
                                    <Space className="tableHeader1">
                                      Finish
                                    </Space>
                                    <Space className="tableHeader1">
                                      Image
                                    </Space>
                                  </List.Item>
                                </List>
                                {data.bundles.map((bundle, j) => {
                                  return (
                                    <BundleCardSales
                                      key={bundle}
                                      bundles={bundle}
                                      bundleIndex={j}
                                      productId={data.productName}
                                      entityId={
                                        searchResult.saleList &&
                                        searchResult.saleList[index]
                                          ? searchResult.saleList[index]
                                              .entityId
                                          : ""
                                      }
                                      expand={expand}
                                      onDrawerExpand={handleDrawerExpand}
                                      selectedItems={selectedItems}
                                      onItemsSelected={handleItemsSelected}
                                      setImagePopUp={setImagePopUp}
                                      setImagePopUpValue={setImagePopUpValue}
                                      setSelectedSlab={setSelectedSlab}
                                      selectedSlab={selectedSlab}
                                      setImages={setImages}
                                      images={images}
                                      viewing={viewing}
                                      setViewing={setViewing}
                                      setActiveStep={setActiveStep}
                                      searchView={true}
                                      setIndex={setIndex}
                                    />
                                  );
                                })}
                              </CollapsiblePanel>
                            );
                          }
                        )
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </Content>
                  </Content>
                </Content>
              </Content>
            </Content>
          </ExpandableDrawer>
        ) : drawerClick === 2 ? (
          <ExpandableDrawer
            toggleDrawer={toggleDrawer}
            openDrawer={openDrawer}
            closeDrawerCallback={handleCloseDrawerCallBack}
            title={searchResult.saleList[index].customer}
            expand={expand}
            onDrawerExpand={handleDrawerExpand}
          >
            <Content
              className="tableDiv"
              style={{ maxHeight: "90%", overflowY: "auto", width: "100%" }}
            >
              <ShipmentView
                setShipmentInfo={setShipmentInfo}
                shipmentInfo={shipmentInfo}
                setEditClicked={setEditClicked}
                searchView={true}
              />
            </Content>
          </ExpandableDrawer>
        ) : (
          <ExpandableDrawer
            toggleDrawer={toggleDrawer}
            openDrawer={openDrawer}
            closeDrawerCallback={handleCloseDrawerCallBack}
            title={searchResult.saleList[index].customer}
            expand={expand}
            onDrawerExpand={handleDrawerExpand}
          >
            <Space
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <h2 style={{ margin: 0, color: "#545454" }}>View Report</h2>
            </Space>
            <Content style={{ marginTop: "30px", width: "100%" }}>
              <Content direction="vertical" style={{ width: "100%" }} size={24}>
                <CollapsiblePanel
                  panelProps={{
                    header: (
                      <Content
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingLeft: "1vw",
                          paddingRight: "1vw",
                          width: "100%",
                        }}
                      >
                        <h3 style={{ margin: 0 }}>Report</h3>
                        <DownOutlined style={{ color: "#0677ff" }} />
                      </Content>
                    ),
                    key: "1",
                  }}
                  collapseProps={{
                    ghost: true,
                    size: "large",
                    key: "Folders",
                    className: "MoveToCard",
                  }}
                  title={"Report"}
                >
                  {searchResult.saleList[index].inspectionPreSignedUrls ? (
                    searchResult.saleList[index].inspectionPreSignedUrls
                      .length !== 0 ? (
                      <Content
                        style={{
                          backgroundColor: "white",
                          padding: "1vh 1vw",
                          width: "100%",
                        }}
                      >
                        <h3 style={{ marginLeft: "1vw" }}>View Reports</h3>
                        <Row
                          style={{
                            maxHeight: "50vh",
                            overflowY: "auto",
                            textAlign: "center",
                          }}
                        >
                          {searchResult.saleList[
                            index
                          ].inspectionPreSignedUrls.map((data) => {
                            return (
                              <Col
                                key={data.fileId}
                                style={{ margin: "0.5vw" }}
                              >
                                <Image
                                  preview={{
                                    visible: false,
                                  }}
                                  width={160}
                                  style={{ marginTop: "1vh" }}
                                  src="https://cdn-icons-png.flaticon.com/512/2235/2235790.png"
                                  onClick={() => {
                                    togglePopup();
                                    setToggleClick(1);
                                    setPopUpURL(data.preSignedUrl);
                                  }}
                                />
                                {/* <PreviewImage fileUrl = {data.preSignedUrl}/> */}
                              </Col>
                            );
                          })}
                        </Row>
                      </Content>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </CollapsiblePanel>
              </Content>
            </Content>
          </ExpandableDrawer>
        )}
        <Popup
          title={
            toggleClick === 0 ? (
              <p style={{ margin: "0" }} className="notesHeader">
                View notes
              </p>
            ) : (
              <Space>Preview report</Space>
            )
          }
          openPopup={openPopup}
          togglePopup={togglePopup}
          flag={toggleClick === 1 ? true : false}
        >
          {toggleClick === 0 ? (
            <EmptyNotesFormGeneral
              onClosePopup={togglePopup}
              data={notesOf}
              isPosting={isPosting}
              setIsPosting={setIsPosting}
              saleListValue={searchResult}
              index={index}
              searchView={true}
            />
          ) : (
            <iframe
              title=""
              src={popUpURL}
              style={{
                width: "100%",
                height: "64vh",
                resize: "both",
                overflow: "auto",
              }}
            />
          )}
        </Popup>
      </Content>
    </Layout>
  );
}
