import { useTranslation } from "react-i18next";
import Messages from "../../pages/Message/Messages";
export const Widgets = () => {
  const { t } = useTranslation();
  return [
    {
      centered: true,
      tabItems: [
        {
          key: "Messages",
          children: <Messages />,
        },
      ],
      type: "tab",
    },
  ];
};
