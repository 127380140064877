import { CloseOutlined } from "@ant-design/icons";
import { Card, Layout, Table } from "antd";
import React from "react";
import { useGetSalesHistory } from "../../queries/react-query/customers";
import { CustomerConstants } from "../../utils/customers";

const { Content } = Layout;

export default function PopUp(props) {
  const columns = CustomerConstants.Columns;
  const data = useGetSalesHistory(["customerSalesHistory"]);

  return (
    <Layout
      className="popUpClass"
      style={{
        backgroundColor: "rgba(128, 128, 128, 0.5)",
        width: "100%",
        height: "76vh",
        zIndex: 2,
        position: "absolute",
        top: "0",
        left: "0",
        borderRadius: "5px",
      }}
    >
      <Card
        className="innerPopUp"
        title={<p id="popUpHeader">{props.userDetails.userName}</p>}
        extra={
          <CloseOutlined
            style={{ color: "#0066FF" }}
            onClick={() => {
              props.stateClickChange(false);
            }}
          />
        }
      >
        <select name="dropDown" id="dropDown">
          <option value="Sales History">Sales History</option>
        </select>
        <Content className="tableDiv">
          <Table
            pagination={false}
            columns={columns}
            dataSource={data.data}
            rowKey="salesOrder"
            style={{ borderRadius: "12px" }}
          />
        </Content>
      </Card>
    </Layout>
  );
}
