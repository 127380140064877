import { useTranslation } from "react-i18next";
import Help from "../../pages/Help/Help";

export const Widgets = () => {
  const { t } = useTranslation();
  return [
    {
      centered: true,
      tabItems: [
        {
          key: "Help",
          children: <Help />,
        },
      ],
      type: "tab",
    },
  ];
};
