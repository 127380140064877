import { useTranslation } from "react-i18next";
import CompanyProfile from "../../pages/CompanyProfile/CompanyProfile";
import UserProfile from "../../pages/UserProfile/UserProfile";

export const Widgets = () => {
  const { t } = useTranslation();
  return [
    {
      centered: true,
      tabItems: [
        {
          label: t("subNav.users"),
          key: "Users",
          children: <UserProfile />,
        },
        {
          label: t("subNav.CompanyProfile"),
          key: "CompanyProfile",
          children: <CompanyProfile />,
        },
      ],
      type: "tab",
    },
  ];
};
