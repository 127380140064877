import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";

export default function SuccessLoading(props) {
  return (
    <Content style={{ textAlign: "center", width: "100%" }}>
      {/* <LoadingOutlined
        style={{ fontSize: "400%", color: "#0c25f3", marginBottom: "2vh" }}
      /> */}
      <Spin
        size="large"
        style={{ marginTop: "1vh", color: "#0c25f3", marginBottom: "2vh" }}
      ></Spin>
      <p
        style={{
          fontSize: "large",
          fontWeight: "bold",
          marginBottom: "1vh",
        }}
      >
        Loading...
      </p>
      <p>{props.detail}</p>
    </Content>
  );
}
