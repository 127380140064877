import { useMutation, useQuery } from "react-query";
import {
  addUser,
  deleteUser,
  editProfile,
  getUserProfile,
} from "../api/userProfile";

export const useGetProfile = (queryName) => {
  return useQuery({
    queryKey: queryName,
    queryFn: getUserProfile,
  });
};

export const useEditProfile = (queryName, onSuccess) => {
  return useMutation({
    mutationKey: queryName,
    mutationFn: editProfile,
    onSuccess: onSuccess,
  });
};

export const useDeleteUser = (queryName, onSuccess) => {
  return useMutation({
    mutationKey: queryName,
    mutationFn: deleteUser,
    onSuccess: onSuccess,
  });
};

export const useAddUser = (queryName, onSuccess, addUserError) => {
  return useMutation({
    mutationKey: queryName,
    mutationFn: addUser,
    onSuccess: onSuccess,
    onError: (error) => {
      if (error.response.status === 500) {
        // notify(
        //   t("toast.reachAdmin", { item: t("toast.inventory") }),
        //   TOAST_TYPE.FAIL
        // );
      } else if (
        error.response.status / 100 >= 4 &&
        error.response.status / 100 < 5
      ) {
        addUserError(error.response.data);
      }
    },
  });
};
