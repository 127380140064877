import axios from "axios";
import {
  CART_ROUTE,
  SALES_ROUTE,
  getEndPointFor,
  getHeaders,
} from "../../utils/api_endpoints";

export const fetchCart = async (queryParam) => {
  const headers = await getHeaders();
  const response = await axios
    .get(`${getEndPointFor(CART_ROUTE.base)}`, { headers: headers })
    .then((response) => response.data);
  return response;
};

export const getBundleList = async ({ queryKey }) => {
  const [, productId] = queryKey;
  const headers = await getHeaders();
  const response = await axios
    .post(
      `${getEndPointFor(CART_ROUTE.base)}/bundles`,
      {
        productId: productId,
      },
      {
        headers: headers,
      }
    )
    .then((response) => response.data);
  return response;
};

export const getSlabsByBundleId = async ({ queryKey }) => {
  const [, productId, bundleNumber] = queryKey;
  const headers = await getHeaders();
  const response = await axios
    .post(
      `${getEndPointFor(CART_ROUTE.base)}/slabs`,
      {
        productId: productId,
        bundleNumber: bundleNumber,
      },
      {
        headers: headers,
      }
    )
    .then((response) => response.data);
  return response;
};

export const getSummary = async (queryParam) => {
  const headers = await getHeaders();
  const response = await axios
    .get(`${getEndPointFor(CART_ROUTE.base)}/summary`, { headers: headers })
    .then((response) => response.data);
  return response;
};

export const createSales = async (obj) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SALES_ROUTE.base)}/create`, obj, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const deleteCart = async (obj) => {
  const headers = await getHeaders();
  const response = await axios
    .delete(`${getEndPointFor(CART_ROUTE.base)}`, {
      headers: headers,
      data: obj,
    })
    .then((response) => response.data);
  return response;
};
