import { Button, Space, Tabs } from "antd";
import _ from "lodash";

const Tab = ({ centered, extraBtns, onTabClick, tabItems }) => {
  return (
    <Tabs
      centered={centered}
      defaultActiveKey="Products"
      items={tabItems}
      size="small"
      tabBarExtraContent={
        <Space>
          {_.map(extraBtns, (btn) => (
            <Button key={btn.text} type={btn.type} icon={btn.icon}>
              {btn.text}
            </Button>
          ))}
        </Space>
      }
      onTabClick={onTabClick}
    />
  );
};

export default Tab;
