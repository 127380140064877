import _ from "lodash";
import React from "react";
import MobileMainLayout from "../MobileView/Components/MobileSearch/MobileMainLayout";
import MobileHome from "../MobileView/Pages/MobileHome";
import MobileInventory from "../MobileView/Pages/MobileInventory";
import MarketplaceBuyer from "../components/Marketplace/MarketplaceBuyer";
import MarketplaceSeller from "../components/Marketplace/MarketplaceSeller";
import MainLayout from "../components/Search/MainLayout";
import Help from "../pages/Help/Help";
import HomePage from "../pages/Home/HomePage";
import { LiveInventory } from "../pages/LiveInventory";
import Messages from "../pages/Message/Messages";
import Offer from "../pages/Offer/Offer";
import { widgetOptions } from "../utils/constants";
import { globalConstants } from "../utils/global";
import { Widgets as Cart } from "../views/Cart/config";
import { Widgets as GreetHome } from "../views/GreetHome/config";
import { Widgets as Support } from "../views/Help/config";
import { Widgets as Home } from "../views/Home/config";
import { Widgets as Marketplace } from "../views/Marketplace/config";
import { Widgets as Message } from "../views/Messages/config";
import { Widgets as MyProfile } from "../views/MyProfile/config";
import { Widgets as MyStore } from "../views/MyStore/config";
import { Widgets as Offers } from "../views/Offer/config";
import { Widgets as Sales } from "../views/Sales/config";
import { Widgets as Search } from "../views/Search/config";
import { Widgets as Settings } from "../views/Settings/config";
import Widget from "./Widget";

const Widgets = ({ section, ...props }) => {
  const cases = {
    Home: () => GreetHome(),
    Inventory: () => Home(),
    Sales: () => Sales(),
    Marketplace: () => Marketplace(),
    MyStore: () => MyStore(),
    Settings: () => Settings(),
    Cart: () => Cart(),
    MyProfile: () => MyProfile(),
    Search: () => Search(),
    Messages: () => Message(),
    Help: () => Support(),
    Offer: () => Offers(),
  };

  const widgets = cases[section]();
  const isMobile = globalConstants.isMobile();
  return (
    <div>
      {widgets[0].tabItems.length === 1 &&
      section !== "Cart" &&
      section !== "MyProfile" ? (
        section === "Marketplace" ? (
          <MarketplaceBuyer />
        ) : section === "Home" ? (
          isMobile ? (
            <MobileHome />
          ) : (
            <HomePage />
          )
        ) : section === "MyStore" ? (
          <MarketplaceSeller supplierId={localStorage.getItem("tenantId")} />
        ) : section === "Search" ? (
          isMobile ? (
            <MobileMainLayout />
          ) : (
            <MainLayout />
          )
        ) : section === "Messages" ? (
          <Messages />
        ) : section === "Help" ? (
          <Help />
        ) : section === "Offer" ? (
          <Offer />
        ) : (
          <></>
        )
      ) : isMobile && section === "Inventory" ? (
        <MobileInventory />
      ) : localStorage.getItem("guest") === "true" &&
        section === "Inventory" ? (
        <LiveInventory />
      ) : (
        widgets?.map((widget) => {
          const widgetOption = _.find(widgetOptions, ["value", widget.type]);
          return (
            <Widget
              key={widget.type}
              widget={widget}
              widgetOption={widgetOption}
            />
          );
        })
      )}
    </div>
  );
};

export default Widgets;
