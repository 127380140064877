const getEmptyBundle = (inventory) => {
  const { price, quality, thickness, finish } = inventory;
  let emptySlab = {
    price,
    quality,
    thickness,
    finish,
    qty: 0,
    grossLength: "",
    grossWidth: "",
    netLength: "",
    netWidth: "",
    isValid: true,
  };
  return { bundleNumber: "", slabs: [emptySlab], isValid: true };
};

const Filters = [
  {
    key: "Thickness",
    type: "checkbox",
    value: [2, 3],
  },
  {
    key: "Category",
    type: "checkbox",
    value: ["Granite", "Marble", "Quartz", "SandStone"],
  },
  {
    key: "Height",
    type: "slider",
    maxValue: 100,
    value: [0, 100],
  },
  {
    key: "Width",
    type: "slider",
    maxValue: 100,
    value: [0, 100],
  },
  {
    key: "Finish",
    type: "checkbox",
    value: ["Brushed", "Natural", "Polished", "Silk"],
  },
  {
    key: "Price",
    type: "slider",
    maxValue: 2000,
    value: [0, 2000],
  },
  {
    key: "Quality",
    type: "checkbox",
    value: ["Medium", "Premium", "High-Standard"],
  },
  {
    key: "Colour",
    type: "checkbox",
    value: ["white", "black", "blue", "brown"],
  },
  {
    key: "Country",
    type: "checkbox",
    value: [
      "Brazil",
      "India",
      "Italy",
      "Spain",
      "United States",
      "United Kingdom",
    ],
  },
];

const finishTypes = [
  "Aged",
  "Brushed",
  "Bush Hammered",
  "Flamed/ Thermal",
  "Honed",
  "Leathered",
  "Mushroom",
  "Natural",
  "Polished",
  "Sandblasted",
  "Sawn",
  "Silk",
  "Soft Leather",
  "Tumbled",
  "Unfinished",
];

const getEmptySlab = (inventory) => {
  const { price, quality, thickness, finish } = inventory;
  return {
    price,
    quality,
    thickness,
    finish,
    qty: 0,
    grossLength: "",
    grossWidth: "",
    netLength: "",
    netWidth: "",
    isValid: true,
  };
};

const qualityLevels = [
  "Commercial",
  "High-Standard",
  "Low-Standard",
  "Premium",
  "Standard",
];
const thicknessUnit = ["in", "cm"];
const currencies = {
  "Australian Dollar": "AUD",
  "Brazilian Real": "BRL",
  Euro: "EUR",
  "Indian Rupee": "INR",
  Pound: "GBP",
  "Saudi Riyal": "SAR",
  "Turkish Lira": "TRY",
  "UAE Dirham": "AED",
  "US Dollar": "USD",
  USD: "USD",
};

export const inventoryConstants = {
  getEmptyBundle,
  getEmptySlab,
  finishTypes,
  Filters,
  qualityLevels,
  thicknessUnit,
  currencies,
};
