import { ExpandOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React from "react";

export default function InspectionRow(props) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleListClick = () => {
    props.setOpenDrawer(true);
    props.setIndex(props.itemIndex);
    props.setDrawerClick(0);
  };

  const TimeAgo = (milliseconds) => {
    let d = new Date(milliseconds);
    let month = d.getMonth();
    let date = d.getDate();
    if (date < 10) {
      date = `0${date}`;
    }
    let year = d.getFullYear();

    return `${months[month]} ${date}, ${year}`;
  };

  return (
    <Row style={props.listItemStyle}>
      <Col span={5} className="TableColumn mainCollapseSales">
        {props.data.id ? props.data.id : "--"}
      </Col>
      <Col span={4} className="TableColumn mainCollapseSales">
        {props.data.customer ? props.data.customer : "--"}
      </Col>
      <Col span={5} className="TableColumn mainCollapseSales">
        {props.data.saleSummary
          ? props.data.saleSummary.productSummaryList
            ? props.data.saleSummary.productSummaryList.length
            : 0
          : 0}
      </Col>
      <Col span={4} className="TableColumn mainCollapseSales">
        Slab
      </Col>
      <Col span={6} className="TableColumn mainCollapseSales">
        <p style={{ width: props.searchView ? "47%" : "80%", marginBottom: 0 }}>
          {!props.searchView
            ? TimeAgo(props.data.creationTimeInMs)
            : props.data.creationTime.split(" ")[0]}
        </p>
        {props.searchView ? (
          <p
            style={{
              marginBottom: 0,
              width: "33%",
              // width: "9vw", marginLeft: "3vw"
            }}
          >
            {props.data.status}
          </p>
        ) : (
          <></>
        )}
        <Button
          type="text"
          style={{
            fontFamily: "Roboto",
            color: "#0C25F3",
            width: "20%",
            // marginLeft: props.searchView ? "" : "10vw",
          }}
          onClick={handleListClick}
          icon={<ExpandOutlined />}
        ></Button>
      </Col>
    </Row>
  );
}
