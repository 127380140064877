import axios from "axios";
import {
  INVENTORY_ROUTE,
  USER_ROUTE,
  getEndPointFor,
  getHeaders,
} from "../../utils/api_endpoints";

export const fetchInventoryAnalytics = async (queryParam) => {
  const headers = await getHeaders();
  const response = await axios
    .get(`${getEndPointFor(INVENTORY_ROUTE.base)}/analytics`, {
      headers: headers,
    })
    .then((response) => response.data);
  return {
    inventoryTotalSize: response.inventoryTotalSize,
    aggregations: Object.entries(response.aggregations)
      .filter(([key, value]) => Object.keys(value).length > 0)
      .map(([key, value]) => {
        return {
          name: key,
          labels: Object.keys(value),
          values: Object.values(value),
          total: Object.values(value).reduce((acc, cur) => acc + cur, 0),
        };
      }),
  };
};

export const updateGuest = async (obj) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(USER_ROUTE.base)}/upgradeToPremium`, obj, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};
