import { Auth } from "aws-amplify";
const subDomains = [
  "localhost",
  "release-test.stonovation.com",
  "release-test-seller.stonovation.com",
];

const checkPresence = (path) => {
  for (let i = 0; i < subDomains.length; i++) {
    if (subDomains[i] === path) {
      return true;
    }
  }
  return false;
};

export const API_BASE = !isDev()
  ? "https://test.stonovation.com/"
  : "https://api.stonovation.com/";

export const CUSTOMER_ROUTE = {
  list: "customers/list",
  base: "customers",
};

export const MARKET_ROUTE = {
  base: "marketplace",
};

export const SALES_ROUTE = {
  base: "sales",
};

export const CART_ROUTE = {
  slab: "cart/slabs",
  bundles: "cart/bundles",
  base: "cart",
};

export const OFFERS_ROUTE = {
  create: "offers/create",
  get: "offers/get",
  update: "offers/updateStatus",
  base: "offers",
};

export const USER_ROUTE = {
  list: "users/list",
  base: "users",
};

export const INVENTORY_ROUTE = {
  base: "inventory",
  bundles: `inventory/:id/bundles`,
  slabs: `inventory/:id/bundles/:bundle_id`,
  putTohold: `putSlabsOnHold`,
  removeFromHold: `removeHoldOnSlabs`,
  update: "update",
  deleteFile: "inventory/deleteImages",
};

export const SEARCH_ROUTE = {
  base: "search",
};

export const SETTINGS_ROUTE = {
  base: "settings",
};

export const PRODUCT_ROUTE = {
  base: "product",
  products: "products",
};

export const SLAB_ROUTE = {
  base: "prod/slab",
};

export const CRUD_OPS = {
  ALL: "/all",
  ADD: "/add",
  EDIT: "/edit",
  DELETE: "/delete",
  FIND: "/find",
};

export const BASE_TYPES = {
  INVENTORY: INVENTORY_ROUTE,
  PRODUCT: PRODUCT_ROUTE,
  SLAB: SLAB_ROUTE,
};

export const getEndPointFor = (type, op) => {
  return `${API_BASE}${type}${op ? op : ""}`;
};

export const getToken = async () => {
  const currentSession = await Auth.currentSession();
  const IdToken = await currentSession.getIdToken().getJwtToken();
  return IdToken;
};

export const getHeaders = async () => {
  let token = await getToken();
  let bearerToken = `Bearer ${token}`;
  return {
    "Content-Type": "application/json",
    Authorization: bearerToken,
  };
};

function isDev() {
  return !checkPresence(window.location.hostname) ? true : false;
}
