import { Col, Collapse, Descriptions, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomerRow from "./CustomerRow";
const { Panel } = Collapse;

export default function Customer(props) {
  const { t } = useTranslation();
  return (
    <Collapse
      className="salesCollapse"
      style={{
        marginBottom: "24px",
        borderTop: "0",
        borderRight: "0",
        borderLeft: "0",
        borderRadius: "8px",
        borderBottom:
          "0.75px solid var(--neutral-background-text-white-300, #E6E6E6)",
        background: "var(--neutral-background-text-white-100, #F9F9F9)",
      }}
    >
      <Panel
        header={
          <CustomerRow
            data={props.data}
            setEditValue={props.setEditValue}
            setEditClicked={props.setEditClicked}
            deleteCustomerMutation={props.deleteCustomerMutation}
            setUserDetails={props.setUserDetails}
            stateClickChange={props.stateClickChange}
            setDeleteClicked={props.setDeleteClicked}
            setDeleteData={props.setDeleteData}
            searchView={props.searchView}
          />
        }
        showArrow={false}
        key="1"
      >
        <Content
          style={{
            width: "100%",
            borderBottom:
              "0.5px solid var(--neutral-background-text-white-900, #797979)",
          }}
        >
          <p className="CollapseSubHeader">Contact details</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(
                `customers.contactName`
              )}`}</p>
              <p className="profileValues">
                {props.data.contactName ? props.data.contactName : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(`customers.phoneNo`)}`}</p>
              <p className="profileValues">
                {props.data.phoneNumber ? props.data.phoneNumber : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(`customers.email`)}`}</p>
              <p className="profileValues">
                {props.data.email ? props.data.email : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(
                `customers.shareInventory`
              )}`}</p>
              <p className="profileValues">
                {props.data.shareInventory === true ||
                props.data.shareInventory === false
                  ? `${
                      props.data.shareInventory === true
                        ? "Shared"
                        : "Not Shared"
                    }`
                  : "--"}
              </p>
            </Content>
          </Content>
        </Content>
        {props.data.additionalContactList !== undefined &&
        props.data.additionalContactList.length > 0 ? (
          <Content
            style={{
              width: "100%",
              borderBottom:
                "0.5px solid var(--neutral-background-text-white-900, #797979)",
              paddingTop: "2vh",
            }}
          >
            <p className="CollapseSubHeader">{`${t(
              `customers.additionalContactDetails`
            )}`}</p>
            {props.data.additionalContactList.map((contact, i) => {
              return (
                <Content
                  key={i}
                  style={{ width: "100%" }}
                  className="ProfileRow"
                >
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="profileHeaders">{`${t(
                      `customers.contactName`
                    )}`}</p>
                    <p className="profileValues">
                      {contact.contactName ? contact.contactName : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="profileHeaders">{`${t(
                      `customers.phoneNo`
                    )}`}</p>
                    <p className="profileValues">
                      {contact.phoneNumber ? contact.phoneNumber : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="profileHeaders">{`${t(
                      `customers.email`
                    )}`}</p>
                    <p className="profileValues">
                      {contact.email ? contact.email : "--"}
                    </p>
                  </Content>
                </Content>
              );
            })}
          </Content>
        ) : (
          <></>
        )}
        <Content
          style={{
            width: "100%",
            borderBottom:
              "0.5px solid var(--neutral-background-text-white-900, #797979)",
            paddingTop: "2vh",
          }}
        >
          <p className="CollapseSubHeader">Purchasing</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(`customers.currency`)}`}</p>
              <p className="profileValues">
                {props.data.currency ? props.data.currency : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(
                `customers.paymentTerms`
              )}`}</p>
              <p className="profileValues">
                {props.data.paymentTerms ? props.data.paymentTerms : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(
                `customers.paymentMethod`
              )}`}</p>
              <p className="profileValues">
                {props.data.paymentMethod ? props.data.paymentMethod : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(
                `customers.measurementSystem`
              )}`}</p>
              <p className="profileValues">
                {props.data.measurementSystem
                  ? props.data.measurementSystem
                  : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(`customers.incoTerms`)}`}</p>
              <p className="profileValues">
                {props.data.incoTerms ? props.data.incoTerms : "--"}
              </p>
            </Content>
          </Content>
        </Content>
        <Content
          style={{
            width: "100%",
            borderBottom:
              "0.5px solid var(--neutral-background-text-white-900, #797979)",
            paddingTop: "2vh",
          }}
        >
          <p className="CollapseSubHeader">{`${t(
            `customers.shippingDetails`
          )}`}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(
                `customers.shippingMode`
              )}`}</p>
              <p className="profileValues">
                {props.data.shippingMode ? props.data.shippingMode : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(
                `customers.shippingPort`
              )}`}</p>
              <p className="profileValues">
                {props.data.shippingPort ? props.data.shippingPort : "--"}
              </p>
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingTop: "2vh" }}>
          <p className="CollapseSubHeader">{`${t(
            `customers.addressDetails`
          )}`}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(
                `customers.addressLine1`
              )}`}</p>
              <p className="profileValues">
                {props.data.address1 ? props.data.address1 : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(
                `customers.addressLine2`
              )}`}</p>
              <p className="profileValues">
                {props.data.address2 ? props.data.address2 : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(`customers.zipCode`)}`}</p>
              <p className="profileValues">
                {props.data.zipCode ? props.data.zipCode : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(`customers.state`)}`}</p>
              <p className="profileValues">
                {props.data.state ? props.data.state : "--"}
              </p>
            </Content>
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileHeaders">{`${t(`customers.country`)}`}</p>
              <p className="profileValues">
                {props.data.country ? props.data.country : "--"}
              </p>
            </Content>
          </Content>
        </Content>
      </Panel>
    </Collapse>
  );
}
