import { Skeleton, Space } from "antd";
import Layout from "antd/es/layout/layout";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import React from "react";
import { Pie } from "react-chartjs-2";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useFetchInventoryAnalytics } from "../../queries/react-query/home";
import { QuotesConstants } from "../../utils/quotes";
import "../Styles/home.css";

ChartJS.register(
  ArcElement,
  CategoryScale,
  Tooltip,
  Legend,
  LinearScale,
  BarElement
);
export default function MobileHome() {
  const { t } = useTranslation();
  const quotes = QuotesConstants.quotes;
  const currentDate = new Date();
  const inventoryAnalytics = useFetchInventoryAnalytics(["InventoryAnalytics"]);
  const getText = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    let timeOfDay;

    if (currentHour >= 5 && currentHour < 12) {
      timeOfDay = "Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      timeOfDay = "Afternoon";
    } else {
      timeOfDay = "Evening";
    }
    return t(`homePage.good${timeOfDay}`);
  };
  return (
    <Layout>
      <div className="HomeLayout">
        <div style={{ width: "100%", height: "100%" }} className="HomeContent">
          <div
            className="greetContent"
            style={{ width: "100%", height: "86%" }}
          >
            <div style={{ textAlign: "center" }}>
              <h1 className="greetText">
                {getText() + localStorage.getItem("userName")}
              </h1>
              <h3 className="quoteTxt">{t("homePage.beYourself")}</h3>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <p className="subQuote">
                {quotes[currentDate.getDate() % 32].quote}
              </p>
              <p className="author">
                -{quotes[currentDate.getDate() % 32].author}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", padding: "2vh 0" }}>
        <div style={{ width: "100%" }} className="mobileAnalyticsMainHeader">
          <h2>{t("homePage.inventoryAnalytics")}</h2>
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className="subHeaderAnalytics">
            {t("homePage.totalItemsInInventory")}
          </p>
          {inventoryAnalytics.isLoading ? (
            <Skeleton active />
          ) : (
            <CountUp
              end={inventoryAnalytics.data.inventoryTotalSize}
              duration={6}
              className="counterClass"
            />
          )}
        </div>
        {inventoryAnalytics.isLoading ? (
          <Skeleton active />
        ) : (
          <div style={{ padding: "2vh 0" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
              }}
            >
              {inventoryAnalytics.data.aggregations.map((pieValues) => {
                return (
                  <div
                    style={{
                      marginLeft: "2vw",
                      marginRight: "2vw",
                      padding: "2vh 2vw",
                      width: "100%",
                    }}
                    key={pieValues.name}
                  >
                    <div
                      className="chartBody"
                      style={{ textAlign: "center", width: "100%" }}
                    >
                      <p
                        className="subHeaderAnalytics"
                        style={{ marginBottom: "2vh" }}
                      >
                        {t(`filters.${pieValues.name}`)}
                      </p>
                      <Space>
                        <Pie
                          data={{
                            labels: pieValues.labels,
                            datasets: [
                              {
                                data: pieValues.values,
                                backgroundColor: [
                                  "#3498db",
                                  "#2ecc71",
                                  "#e74c3c",
                                  "#f1c40f",
                                  "#9b59b6",
                                  "#1abc9c",
                                  "#f39c12",
                                  "#e74c3c",
                                  "#2c3e50",
                                  "#d35400",
                                  "#27ae60",
                                  "#2980b9",
                                  "#c0392b",
                                  "#8e44ad",
                                  "#d35400",
                                  "#f39c12",
                                  "#34495e",
                                  "#16a085",
                                  "#8e44ad",
                                  "#c0392b",
                                  "#f1c40f",
                                  "#7f8c8d",
                                  "#3498db",
                                  "#2ecc71",
                                  "#f1c40f",
                                  "#e74c3c",
                                  "#34495e",
                                  "#16a085",
                                  "#9b59b6",
                                  "#e67e22",
                                  "#f39c12",
                                  "#c0392b",
                                  "#2980b9",
                                  "#3498db",
                                  "#2ecc71",
                                  "#e74c3c",
                                  "#f1c40f",
                                  "#9b59b6",
                                  "#1abc9c",
                                  "#f39c12",
                                  "#e74c3c",
                                  "#2c3e50",
                                  "#d35400",
                                  "#27ae60",
                                  "#2980b9",
                                  "#c0392b",
                                  "#8e44ad",
                                  "#d35400",
                                  "#f39c12",
                                  "#34495e",
                                  "#16a085",
                                ],
                                hoverBackgroundColor: [
                                  "#3498db",
                                  "#2ecc71",
                                  "#e74c3c",
                                  "#f1c40f",
                                  "#9b59b6",
                                  "#1abc9c",
                                  "#f39c12",
                                  "#e74c3c",
                                  "#2c3e50",
                                  "#d35400",
                                  "#27ae60",
                                  "#2980b9",
                                  "#c0392b",
                                  "#8e44ad",
                                  "#d35400",
                                  "#f39c12",
                                  "#34495e",
                                  "#16a085",
                                  "#8e44ad",
                                  "#c0392b",
                                  "#f1c40f",
                                  "#7f8c8d",
                                  "#3498db",
                                  "#2ecc71",
                                  "#f1c40f",
                                  "#e74c3c",
                                  "#34495e",
                                  "#16a085",
                                  "#9b59b6",
                                  "#e67e22",
                                  "#f39c12",
                                  "#c0392b",
                                  "#2980b9",
                                  "#3498db",
                                  "#2ecc71",
                                  "#e74c3c",
                                  "#f1c40f",
                                  "#9b59b6",
                                  "#1abc9c",
                                  "#f39c12",
                                  "#e74c3c",
                                  "#2c3e50",
                                  "#d35400",
                                  "#27ae60",
                                  "#2980b9",
                                  "#c0392b",
                                  "#8e44ad",
                                  "#d35400",
                                  "#f39c12",
                                  "#34495e",
                                  "#16a085",
                                ],
                              },
                            ],
                          }}
                          width={350}
                          height={400}
                          options={{
                            responsive: false,
                            maintainAspectRatio: false,
                            defaultFontSize: "20px",
                            plugins: {
                              tooltip: {
                                callbacks: {
                                  label: (tooltipItem) => {
                                    return `${tooltipItem.parsed} (${(
                                      (tooltipItem.parsed /
                                        inventoryAnalytics.data
                                          .inventoryTotalSize) *
                                      100
                                    ).toFixed(2)}%)`;
                                  },
                                },
                              },
                              legend: {
                                display: true,
                                responsive: false,
                                position: "bottom",
                                labels: {
                                  boxWidth: 15,
                                },
                                align: "center",
                              },
                            },
                          }}
                        />
                      </Space>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}
