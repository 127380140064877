import { CameraOutlined, PictureFilled } from "@ant-design/icons";
import { Button, Divider, Table } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsListOl } from "react-icons/bs";
import { useGetSlabsByBundleIdCart } from "../../queries/react-query/cart";
import { Popup, usePopup } from "../CreatePopup";
import EmptyNotesForm from "../EmptyNotesForm";
import { Lock } from "../Lock";
import "./style.css";

const SlabTableCart = ({
  productId,
  bundleId,
  onItemsSelected,
  selectedParentRowKeys,
  setSelectedSlab,
  selectedSlab,
  setImages,
  viewing,
  setViewing,
  isPrintFeatureEnabled,
  ...props
}) => {
  const { openPopup, setOpenPopup, togglePopup } = usePopup(false);
  const [notesOf, setNotesOf] = useState([]);
  const handleNotesClick = (record) => {
    localStorage.setItem("slabId", record.id);
    setNotesOf(record.notes ? record.notes : []);
    togglePopup();
  };

  const { t } = useTranslation();
  const columns = [
    {
      title: t("slabHeader.slabNo"),
      dataIndex: "slabNumber",
      key: "slabNumber",
      className: "values",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.blockNo"),
      dataIndex: "blockNumber",
      key: "blockNumber",
      className: "values",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    ,
    {
      title: t("slabHeader.grossHeight"),
      dataIndex: "grossHeight",
      key: "grossHeight",
      className: "values",
      render: (text, record) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.grossWidth"),
      dataIndex: "grossWidth",
      key: "grossWidth",
      className: "values",
      render: (text, record) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.netHeight"),
      dataIndex: "netHeight",
      key: "netHeight",
      className: "values",
      render: (text, record) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.netWidth"),
      dataIndex: "netWidth",
      key: "netWidth",
      className: "values",
      render: (text, record) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.finish"),
      dataIndex: "finish",
      key: "finish",
      className: "values",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.quality"),
      dataIndex: "quality",
      key: "quality",
      className: "values",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.price"),
      dataIndex: "price",
      key: "price",
      className: "values",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.currencyCode"),
      dataIndex: "currencyCode",
      key: "currencyCode",
      className: "values",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.thickness"),
      dataIndex: "thickness",
      key: "thickness",
      className: "values",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.thicknessUnit"),
      dataIndex: "thicknessUnit",
      key: "thicknessUnit",
      className: "values",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.images"),
      dataIndex: "photo",
      key: "photo",

      render: (text, record) => {
        if (record.images !== undefined && record.images.length > 0) {
          return (
            <PictureFilled
              style={{
                color: "#0066FF",
                cursor: "pointer",
                fontSize:
                  viewing.bundle === bundleId &&
                  viewing.slab === record.slabNumber
                    ? "x-large"
                    : "large",
              }}
              onClick={() => {
                setImages(record.images);
                setViewing({ bundle: bundleId, slab: record.slabNumber });
                props.setActiveStep(0);
              }}
            />
          );
        }
        return (
          <PictureFilled
            style={{ color: "grey", fontSize: "large", cursor: "not-allowed" }}
          />
        );
      },
    },
    {
      title: t("slabHeader.status"),
      dataIndex: "status",
      key: "status",

      render: (text, record) => {
        if (record.status === "HOLD") {
          return <Lock />;
        }
      },
    },

    {
      title: t("slabHeader.notes"),
      dataIndex: "notes",
      key: "notes",
      render: (text, record) => {
        return (
          <Button
            onClick={() => handleNotesClick(record)}
            type="text"
            icon={<BsListOl style={{ color: "#0066FF" }} />}
          />
        );
      },
    },
  ];
  //const [selectedSlab, setSelectedSlab] = useState([]);
  const slabsByBundleIdQuery = useGetSlabsByBundleIdCart(
    ["bundles", productId, bundleId],
    null,
    !!bundleId
  );

  useEffect(() => {
    if (selectedParentRowKeys.includes(bundleId)) {
      const childRowKeys = slabsByBundleIdQuery.isSuccess
        ? slabsByBundleIdQuery.data.availableSlabs.map(
            (slab) => slab.slabNumber
          )
        : [];
      const childRows = slabsByBundleIdQuery.isSuccess
        ? slabsByBundleIdQuery.data.availableSlabs.map((slab) => slab)
        : [];
      setSelectedSlab(childRowKeys);
    } else {
      setSelectedSlab([]);
    }
  }, [
    bundleId,
    selectedParentRowKeys,
    slabsByBundleIdQuery.data,
    slabsByBundleIdQuery.isSuccess,
  ]);

  const createBaseObject = () => {
    return {
      productId: props.productDetails.productId,
      totalSlabs: 0,
      totalBundles: 0,
      bundles: [],
    };
  };
  const createDesiredBundle = (selectedRow) => {
    return {
      bundleNumber: bundleId,
      slabs: [selectedRow],
    };
  };

  const addSlabToExisting = (selectedRow) => {
    return {
      bundleNumber: bundleId,
      slabs: [...selectedSlab, selectedRow],
    };
  };

  const handleRowSelection = (selectedRowKey, selectedRow) => {
    setSelectedSlab(selectedRowKey);
    onItemsSelected(selectedRow);
  };

  return (
    <>
      <Divider style={{ margin: 0 }} />
      <Table
        style={{ background: "rgba(238, 244, 253, 0.8)" }}
        columns={columns}
        dataSource={
          slabsByBundleIdQuery.data
            ? slabsByBundleIdQuery.data.availableSlabs
            : []
        }
        pagination={{
          hideOnSinglePage: true,
        }}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedSlab,
          onChange: handleRowSelection,
        }}
        loading={slabsByBundleIdQuery.isLoading}
        className="slab-table"
        scroll={{ x: 150 }}
      ></Table>
      <Popup
        title={"Add notes"}
        openPopup={openPopup}
        togglePopup={togglePopup}
      >
        <EmptyNotesForm onClosePopup={togglePopup} data={notesOf} />
      </Popup>
    </>
  );
};

export default SlabTableCart;
