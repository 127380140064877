import { LoadingOutlined } from "@ant-design/icons";
import { Check } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Divider, Step, StepLabel, Stepper } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { Skeleton } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "../../components/Common/MediaSlider/MediaSlider";
import { Popup, usePopup } from "../../components/CreatePopup";
import InventoryImageForm from "../../components/InventoryImageForm";
import SuccessComponent from "../../components/SuccessComponent";
import { useGetSlabsByBundleId } from "../../queries/react-query/inventory";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#2c3fff",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#2c3fff",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#2c3fff",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#2c3fff",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

export default function InventoryBundle(props) {
  const { t } = useTranslation();
  const { openPopup, togglePopup } = usePopup(false);
  const [images, setImages] = useState(
    props.currentItem.images === undefined ||
      props.currentItem.images.length === 0
      ? []
      : props.currentItem.images
  );
  const [addImageTo, setAddImageTo] = useState({
    bundle: "-1",
    slab: "-1",
    block: "-1",
  });
  const [blockDisabled, setBlockDisabled] = useState([]);
  const [updateImages, setUpdateImages] = useState(false);
  const [imageAddedCheck, setImageAddedCheck] = useState([]);
  const [addImageSuccess, setImageSuccess] = useState(false);
  const [stepImage, setStepImage] = useState(0);
  const slabsByBundleIdQuery = useGetSlabsByBundleId(
    [
      "bundles",
      props.productId,
      props.currentItem.bundleNumber,
      { filterContext: props.filterObject },
    ],
    null,
    !!props.currentItem.bundleNumber
  );

  useEffect(() => {
    if (slabsByBundleIdQuery.data !== undefined && props.slabIndex !== -1) {
      props.setCurrentSlab(slabsByBundleIdQuery.data[props.slabIndex]);
    }
  }, [slabsByBundleIdQuery.data]);
  return (
    <Content style={{ width: "100%" }}>
      <Divider style={{ marginBottom: "2vh" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
        }}
      >
        <ArrowBackIcon
          style={{ color: "#0c25f3", width: "15%" }}
          onClick={() => {
            if (props.type === 0) {
              props.setComponentNumber(2);
            } else {
              props.setComponentNumber(1);
            }
          }}
        />
        <p
          className="bundleNameHeader"
          style={{ width: "70%", textAlign: "center", marginBottom: "0" }}
        >
          {t("bundleHeader.bundle")}
        </p>
        <div style={{ width: "15%" }}></div>
      </div>
      <Divider style={{ marginTop: "2vh", marginBottom: "2vh" }} />

      <Box
        sx={{
          maxWidth: "100%",
          flexGrow: 1,
          marginTop: "3vh",
          marginLeft: "3vw",
          marginRight: "3vw",
        }}
      >
        <Slider slides={images} />
      </Box>
      <div style={{ textAlign: "left", marginLeft: "4vw" }}>
        <Button
          className="addImageBtn"
          onClick={() => {
            setAddImageTo({
              bundle: props.currentItem.bundleNumber,
              slab: "-1",
            });
            setStepImage(0);
            togglePopup();
          }}
        >
          {t("bundleHeader.addImage")}
        </Button>
      </div>
      <div style={{ width: "100%", paddingLeft: "4vw" }}>
        <p className="BundleNumberHeader" style={{ marginBottom: "0" }}>{`${t(
          "bundleHeader.bundleNo1"
        )} ${props.currentItem.bundleNumber}`}</p>
        <p className="productNameInBundleView">{props.productId}</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
        className="blueBoxInventoryBundle"
      >
        <div style={{ textAlign: "center" }}>
          <p className="blueBoxHeaders">{t("bundleHeader.slabInStock")}</p>
          {slabsByBundleIdQuery.isLoading ? (
            <LoadingOutlined />
          ) : (
            <p className="blueBoxContent">
              {props.type === 1
                ? slabsByBundleIdQuery.data.length === 0
                  ? 0
                  : slabsByBundleIdQuery.data[0].totalSlabs
                : props.currentItem.totalSlabs}
            </p>
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          <p className="blueBoxHeaders">{t("bundleHeader.slabAvailable")}</p>
          {slabsByBundleIdQuery.isLoading ? (
            <LoadingOutlined />
          ) : (
            <p className="blueBoxContent">
              {props.type === 1
                ? slabsByBundleIdQuery.data.length === 0
                  ? 0
                  : slabsByBundleIdQuery.data[0].availableSlabs
                : props.currentItem.availableSlabs}
            </p>
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          <p className="blueBoxHeaders">{t("bundleHeader.slabOnHold")}</p>
          {slabsByBundleIdQuery.isLoading ? (
            <LoadingOutlined />
          ) : (
            <p className="blueBoxContent">
              {props.type === 1
                ? slabsByBundleIdQuery.data.length === 0
                  ? 0
                  : slabsByBundleIdQuery.data[0].slabsOnHold
                : props.currentItem.slabsOnHold}
            </p>
          )}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            marginLeft: "4vw",
            paddingLeft: "3vw",
            marginRight: "4vw",
            marginBottom: "2vh",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="slabColHeader"
            style={{ marginBottom: "0", width: "33.33%" }}
          >
            {t("slabHeader.slabNo")}
          </div>
          <div
            className="slabColHeader"
            style={{ marginBottom: "0", width: "33.33%" }}
          >
            {t("slabHeader.status")}
          </div>
          <div
            className="slabColHeader"
            style={{ marginBottom: "0", width: "33.33%" }}
          >
            {t("slabHeader.more")}
          </div>
        </div>
        {slabsByBundleIdQuery.isLoading ? (
          <Skeleton
            active
            style={{ width: "93%", marginLeft: "3vw", marginRight: "3vw" }}
          />
        ) : (
          slabsByBundleIdQuery.data.map((value, index) => {
            return (
              <div
                key={index}
                className="slabRow"
                style={{
                  marginLeft: "4vw",
                  marginRight: "4vw",
                  marginBottom: "2vh",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => {
                  props.setCurrentBundleNumber(props.currentItem.bundleNumber);
                  props.setCurrentSlab(value);
                  props.setSlabIndex(index);
                  props.setComponentNumber(4);
                }}
              >
                <div
                  style={{
                    borderLeft: "5px",
                    borderRight: 0,
                    borderTop: 0,
                    borderBottom: 0,
                    borderStyle: "solid",
                    borderColor: "#CD5EDF",
                    height: "40px",
                    marginRight: "10px",
                  }}
                ></div>
                <div
                  className="slabColValue"
                  style={{ marginBottom: "0", width: "33.33%" }}
                >
                  {value.slabNumber}
                </div>
                <div
                  className="slabColValue"
                  style={{ marginBottom: "0", width: "33.33%" }}
                >
                  {value.status}
                </div>
                <div style={{ marginBottom: "0", width: "33.33%" }}>
                  <Button
                    className="detailsButton"
                    onClick={() => {
                      props.setCurrentBundleNumber(
                        props.currentItem.bundleNumber
                      );
                      props.setCurrentSlab(value);
                      props.setSlabIndex(index);
                      props.setComponentNumber(4);
                    }}
                  >
                    {t("globalBtn.details")}
                  </Button>
                </div>
              </div>
            );
          })
        )}
      </div>
      <Popup title={""} openPopup={openPopup} togglePopup={togglePopup}>
        {addImageSuccess ? (
          <SuccessComponent detail={"Image added successfully!!"} />
        ) : (
          <div style={{ width: "100%" }}>
            <Stepper
              sx={{ marginBottom: "2vh" }}
              activeStep={stepImage}
              alternativeLabel
              connector={<QontoConnector />}
            >
              <Step>
                <StepLabel
                  className="stepperName"
                  StepIconComponent={QontoStepIcon}
                >
                  Select Image(s)
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  className="stepperName"
                  StepIconComponent={QontoStepIcon}
                >
                  Review Selection
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  className="stepperName"
                  StepIconComponent={QontoStepIcon}
                >
                  Upload Image
                </StepLabel>
              </Step>
            </Stepper>

            <Divider
              type="horizontal"
              style={{ backgroundColor: "#656564", marginBottom: "2vh" }}
            />
            <div style={{ width: "100%" }}>
              <InventoryImageForm
                step={stepImage}
                setStep={setStepImage}
                addImageTo={addImageTo}
                setAddImageTo={setAddImageTo}
                inventory={{ product: { name: props.productId } }}
                setBlockDisabled={setBlockDisabled}
                updateFlag={true}
                images={images}
                setImages={setImages}
                setUpdateImages={setUpdateImages}
                setImageAddedCheck={setImageAddedCheck}
                imageAddedCheck={imageAddedCheck}
                blockDisabled={blockDisabled}
                addCartSuccess={addImageSuccess}
                setAddCartSuccess={setImageSuccess}
                mobileView={true}
                togglePopup={togglePopup}
              />
            </div>
          </div>
        )}
      </Popup>
    </Content>
  );
}
