import { DeleteOutlined } from "@ant-design/icons";
import { Image, Skeleton } from "antd";
import cx from "classnames";
import { useEffect, useState } from "react";
import { GoDot, GoDotFill } from "react-icons/go";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { useDeleteBundleOrSlabImages } from "../../../queries/react-query/inventory";
import { NOT_AVAILABLE_IMAGE } from "../../../utils/constants";
import styles from "./mediaslider.module.scss";

export default function Slider({
  slides,
  setImages,
  queryClient,
  viewing,
  selectedImageSourceData,
  productId,
  allowDelete,
}) {
  const [activeIndex, setActiveIndex] = useState(0);

  /**
   * Delete functionalities for file from bundle/slab
   */
  const onFileDeleteSuccess = (data) => {
    // Remove the file from the images array once it is deleted
    setImages((images) =>
      images.filter((image) => image.fileId !== data.deletePayload.fileId)
    );
    queryClient.invalidateQueries(["bundles"]);
  };

  const deleteFile = useDeleteBundleOrSlabImages(
    ["bundleFileDeletion"],
    onFileDeleteSuccess
  );

  const deleteBundleFile = (fileId) => {
    let fileSourcetype = "BUNDLE"; //By default
    if (viewing.bundle !== "-1" && viewing.slab === "-1") {
      fileSourcetype = "BUNDLE";
    }
    if (viewing.bundle === "-1" && viewing.slab !== "-1") {
      fileSourcetype = "SLAB";
    }

    if (selectedImageSourceData.hasOwnProperty("slabNumber")) {
      fileSourcetype = "SLAB";
    }

    const deletePayload = {
      bundleNumber: selectedImageSourceData.bundleNumber,
      productId: productId,
      type: fileSourcetype,
      fileId: fileId,
      slabNumber: selectedImageSourceData.hasOwnProperty("slabNumber")
        ? selectedImageSourceData.slabNumber
        : "",
    };

    deleteFile.mutate(deletePayload);
  };
  /** Delete functionalities end */

  // // Reinitializing states on prop update
  useEffect(() => {
    setActiveIndex(0);
  }, [slides]);

  const onLeftArrowClick = () => {
    if (activeIndex !== 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const onRightArrowClick = () => {
    if (activeIndex !== slides.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  if (slides.length < 1) {
    return (
      <Image
        width={"100%"}
        height={400}
        className={styles.notAvailableImage}
        src={NOT_AVAILABLE_IMAGE}
      />
    );
  }

  return (
    <div className={styles.sliderContainer}>
      <div className={styles.slidesContainer}>
        {slides.map((slide, idx) => {
          if (slide.fileType.includes("image")) {
            return (
              <div className={styles.slideWrapper}>
                {allowDelete && (
                  <div
                    className={cx(
                      { [styles.slideDisplayNone]: activeIndex !== idx },
                      { [styles.deleteBtnWrapper]: activeIndex === idx }
                    )}
                    onClick={() => deleteBundleFile(slide.fileId)}
                    title="Delete Image"
                  >
                    <DeleteOutlined className={styles.deleteBtn} />
                  </div>
                )}
                <img
                  key={slide.fileId}
                  src={slide.preSignedUrl}
                  alt=""
                  width={"100%"}
                  className={activeIndex !== idx ? styles.slideDisplayNone : ""}
                />
              </div>
            );
          }

          if (slide.fileType.includes("video")) {
            return (
              <div className={styles.slideWrapper}>
                {allowDelete && (
                  <div
                    className={cx(
                      { [styles.slideDisplayNone]: activeIndex !== idx },
                      { [styles.deleteBtnWrapper]: activeIndex === idx }
                    )}
                    onClick={() => deleteBundleFile(slide.fileId)}
                    title="Delete Video"
                  >
                    <DeleteOutlined className={styles.deleteBtn} />
                  </div>
                )}
                <video
                  key={slide.fileId}
                  src={slide.preSignedUrl}
                  width={"100%"}
                  controls
                  className={activeIndex !== idx ? styles.slideDisplayNone : ""}
                />
              </div>
            );
          }
          return <Skeleton active />;
        })}
      </div>

      {/* Pagination container */}
      <div className={styles.paginationContainer}>
        <div>
          <MdArrowBackIosNew
            className={cx(
              { [styles.disabledArrow]: activeIndex === 0 },
              styles.paginationIcon
            )}
            onClick={() => onLeftArrowClick()}
          />
        </div>
        <div className={styles.dotContainer}>
          {slides.map((slide, idx) => {
            return activeIndex === idx ? (
              <GoDotFill
                key={`${slide.fileId}_${idx}`}
                className={`${styles.sliderIndexDot} ${styles.selectedSliderIndexDot}`}
              />
            ) : (
              <GoDot
                key={`${slide.fileId}_${idx}`}
                className={styles.sliderIndexDot}
              />
            );
          })}
        </div>
        <div>
          <MdArrowForwardIos
            className={cx(
              { [styles.disabledArrow]: activeIndex === slides.length - 1 },
              styles.paginationIcon
            )}
            onClick={() => onRightArrowClick()}
          />
        </div>
      </div>
    </div>
  );
}
