import axios from "axios";
import {
  MARKET_ROUTE,
  getEndPointFor,
  getHeaders,
} from "../../utils/api_endpoints";

export const fetchBundles = async (queryKey, page) => {
  const headers = await getHeaders();
  // console.log(page);
  // localStorage.setItem("MarketPlacePageNo",page);
  const response = await axios
    .post(
      `${getEndPointFor(MARKET_ROUTE.base)}/bundles`,
      {
        from: (page - 1) * 24,
        size: 24,
        filterContext: queryKey[1].filterContext,
        apiVersion: 2,
      },
      { headers: headers }
    )
    .then((response) => {
      let responseArr = [];
      for (let i = 0; i < response.data.marketPlaceBundleList.length; ++i) {
        let tempObj = response.data.marketPlaceBundleList[i];
        tempObj.aggregations = response.data.aggregations;
        responseArr.push(tempObj);
      }
      return responseArr;
    });
  return response;
};

export const fetchBundlesSeller = async (page, obj) => {
  const headers = await getHeaders();
  const response = await axios
    .post(
      `${getEndPointFor(MARKET_ROUTE.base)}/bundles`,
      {
        supplierTenantId: obj.supplierTenantId,
        from: (page - 1) * 24,
        size: 24,
        filterContext: obj.filterContext,
        apiVersion: 2,
      },
      { headers: headers }
    )
    .then((response) => {
      let responseArr = [];
      for (let i = 0; i < response.data.marketPlaceBundleList.length; ++i) {
        let tempObj = response.data.marketPlaceBundleList[i];
        tempObj.aggregations = response.data.aggregations;
        responseArr.push(tempObj);
      }
      return responseArr;
    });
  return response;
};

export const fetchSlabs = async ({ queryKey }) => {
  const headers = await getHeaders();
  const response = await axios
    .post(
      `${getEndPointFor(MARKET_ROUTE.base)}/slabs`,
      {
        bundleNumber: queryKey[1].bundleNumber,
        productName: queryKey[1].productName,
        supplierTenantId: queryKey[1].supplierTenantId,
        filterContext: queryKey[2].filterContext,
      },
      {
        headers: headers,
      }
    )
    .then((response) => response.data);
  return response;
};

export const getSellerInfo = async ({ queryKey }) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(MARKET_ROUTE.base)}/getSupplierInfo`, queryKey[1], {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const contactSupplier = async (obj) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(MARKET_ROUTE.base)}/contactSupplier`, obj, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};
