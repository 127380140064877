import { ProductConstants } from "../../utils/product";
import FormInputNumber from "../FormInputNumber";
import FormSelect from "../FormSelect";

const { units, currencyCode, qualityList, finishTypes } = ProductConstants;

export const fields = [
  {
    column: 8,
    name: "thickness",
    label: "slab.labels.thickness",
    component: FormInputNumber,
    type: "number",
  },
  {
    column: 8,
    name: "thicknessUnit",
    label: "slab.labels.unit",
    component: FormSelect,
    type: "Select",
    options: units,
  },
  {
    column: 8,
    name: "quality",
    label: "slab.labels.quality",
    component: FormSelect,
    type: "Select",
    options: qualityList,
  },
  {
    column: 8,
    name: "finish",
    label: "slab.labels.finish",
    component: FormSelect,
    type: "Select",
    options: finishTypes,
  },
  {
    column: 8,
    name: "price",
    label: "slab.labels.price",
    component: FormInputNumber,
    type: "number",
  },
  {
    column: 8,
    name: "currencyCode",
    label: "slab.labels.currency",
    component: FormSelect,
    type: "Select",
    options: currencyCode,
  },
];
