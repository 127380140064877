import {
  CheckOutlined,
  EditOutlined,
  LoadingOutlined,
  PictureFilled,
} from "@ant-design/icons";
import { Autocomplete, TextField } from "@mui/material";
import { Button, Divider, Table } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsListOl } from "react-icons/bs";
import { useQueryClient } from "react-query";
import { useUpdateSlabDetailsOffers } from "../../queries/react-query/offers";
import { useUpdateSlabDetailsSales } from "../../queries/react-query/sales";
import { Flags } from "../../utils/featureflags";
import { globalConstants } from "../../utils/global";
import { inventoryConstants } from "../../utils/inventory";
import { Popup, usePopup } from "../CreatePopup";
import EmptyNotesForm from "../EmptyNotesForm";
import PrintSlabDetails from "../PrintComponents/PrintSlabDetails";
import styles from "../SlabTable/slabtable.module.scss";
import "../SlabTable/style.css";
import SuccessComponent from "../SuccessComponent";

const SlabTableSales = ({
  productId,
  bundleId,
  onItemsSelected,
  selectedParentRowKeys,
  setSelectedSlab,
  selectedSlab,
  setImages,
  images,
  viewing,
  setViewing,
  value,
  setSelectedProductId,
  setSelectedImagesSourceData,
  ...props
}) => {
  const { t } = useTranslation();
  const isPrintFeatureEnabled = Flags.enablePrint();
  const { openPopup, togglePopup } = usePopup(false);
  const [notesOf, setNotesOf] = useState([]);
  const [editValue, setEditValue] = useState({});
  const [loadingState, setLoadingState] = useState(false);
  const [popUpContent, setPopUpContent] = useState(1);
  const [editId, setEditId] = useState({ slabNumber: -1, id: "-1" });
  const handleNotesClick = (record) => {
    localStorage.setItem("slabId", record.id);
    setPopUpContent(2);
    setNotesOf(record.notes ? record.notes : []);
    togglePopup();
  };
  const queryClient = useQueryClient();
  const finish = inventoryConstants.finishTypes;
  const quality = inventoryConstants.qualityLevels;
  const thicknessMetric = inventoryConstants.thicknessUnit;
  const currency = Object.keys(inventoryConstants.currencies);
  const updateSlabSuccess = () => {
    setLoadingState(false);
    setEditId({ slabNumber: -1, id: "-1" });
    togglePopup();
    setPopUpContent(1);
    setTimeout(() => {
      queryClient.refetchQueries(
        props.searchView
          ? ["SaSearch"]
          : value === -1
          ? ["HSales"]
          : value === 0
          ? ["ISales"]
          : value === 1
          ? ["OSales"]
          : value === 2
          ? ["SSales"]
          : value === 3
          ? ["Offers"]
          : ["CSales"]
      );
    }, [1000]);
  };

  // Update via offer page
  const updateSlabDetailsWithOffer = useUpdateSlabDetailsOffers(
    "Offers",
    updateSlabSuccess
  );

  // Update via sales page
  const updateSlabDetails = useUpdateSlabDetailsSales(
    props.searchView
      ? ["SaSearch"]
      : value === -1
      ? ["HSales"]
      : value === 0
      ? ["ISales"]
      : value === 1
      ? ["OSales"]
      : value === 2
      ? ["SSales"]
      : ["CSales"],
    updateSlabSuccess
  );

  const columns = [
    {
      title: t("slabHeader.slabNo"),
      dataIndex: "slabNumber",
      key: "slabNumber",
      className: "values",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.blockNo"),
      dataIndex: "blockNumber",
      key: "blockNumber",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <div style={{ display: "flex", aspectRatio: 3 }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              defaultValue={!_.isUndefined(text) ? text : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  blockNumber: e.target.value,
                }));
              }}
              className="slabTextInput"
            />
          </div>
        ) : !_.isUndefined(text) ? (
          text
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.grossHeight"),
      dataIndex: "grossHeight",
      key: "grossHeight",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <div style={{ display: "flex", aspectRatio: 3 }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              defaultValue={!_.isUndefined(text) ? text : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  grossHeight: e.target.value,
                }));
              }}
              className="slabTextInput"
            />
          </div>
        ) : !_.isUndefined(text) ? (
          `${text} cm`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.grossWidth"),
      dataIndex: "grossWidth",
      key: "grossWidth",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <div style={{ display: "flex", aspectRatio: 3 }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              defaultValue={!_.isUndefined(text) ? text : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  grossWidth: e.target.value,
                }));
              }}
              className="slabTextInput"
            />
          </div>
        ) : !_.isUndefined(text) ? (
          `${text} cm`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.netHeight"),
      dataIndex: "netHeight",
      key: "netHeight",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <div style={{ display: "flex", aspectRatio: 3 }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              defaultValue={!_.isUndefined(text) ? text : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  netHeight: e.target.value,
                }));
              }}
              className="slabTextInput"
            />
          </div>
        ) : !_.isUndefined(text) ? (
          `${text} cm`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.netWidth"),
      dataIndex: "netWidth",
      key: "netWidth",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <div style={{ display: "flex", aspectRatio: 3 }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              defaultValue={!_.isUndefined(text) ? text : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  netWidth: e.target.value,
                }));
              }}
              className="slabTextInput"
            />
          </div>
        ) : !_.isUndefined(text) ? (
          `${text} cm`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.finish"),
      dataIndex: "finish",
      key: "finish",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <Autocomplete
            className="slabAutocomplete"
            options={finish.map((option) => ({ label: option }))}
            getOptionLabel={(option) => option.label}
            defaultValue={{
              label: !_.isUndefined(text) ? text : "",
            }}
            onChange={(event, newValue) => {
              setEditValue((prev) => ({
                ...prev,
                finish: newValue ? newValue.label : "Select",
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        ) : !_.isUndefined(text) ? (
          text
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.quality"),
      dataIndex: "quality",
      key: "quality",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <Autocomplete
            className="slabAutocomplete"
            options={quality.map((option) => ({ label: option }))}
            getOptionLabel={(option) => option.label}
            defaultValue={{
              label: !_.isUndefined(text) ? text : "",
            }}
            onChange={(event, newValue) => {
              setEditValue((prev) => ({
                ...prev,
                quality: newValue ? newValue.label : "Select",
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        ) : !_.isUndefined(text) ? (
          text
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.price"),
      dataIndex: "price",
      key: "price",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <div style={{ display: "flex" }}>
            <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              variant="outlined"
              defaultValue={!_.isUndefined(text) ? text : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  price: e.target.value,
                }));
              }}
              className="slabTextInput"
            />
            <Autocomplete
              className="slabAutocomplete"
              options={currency.map((option) => ({ label: option }))}
              getOptionLabel={(option) => option.label}
              defaultValue={{
                label: !_.isUndefined(record.currencyCode)
                  ? record.currencyCode
                  : "",
              }}
              id="combo-box-demo"
              onChange={(event, newValue) => {
                setEditValue((prev) => ({
                  ...prev,
                  priceMetric: newValue ? newValue.label : "Select",
                }));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        ) : !_.isUndefined(text) ? (
          `${text} ${inventoryConstants.currencies[record.currencyCode]}`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.totalPrice"),
      dataIndex: "totalPrice",
      key: "totalPrice",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <div style={{ display: "flex" }}>
            <TextField
              variant="outlined"
              defaultValue={!_.isUndefined(text) ? text : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  totalPrice: e.target.value,
                }));
              }}
              className="slabTextInput"
            />
          </div>
        ) : !_.isUndefined(text) ? (
          `${globalConstants.set2placeToDecimal(text)} ${
            inventoryConstants.currencies[record.currencyCode]
          }`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.thickness"),
      dataIndex: "thickness",
      key: "thickness",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <div style={{ display: "flex" }}>
            <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              variant="outlined"
              defaultValue={!_.isUndefined(text) ? text : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  thickness: e.target.value,
                }));
              }}
              className="slabTextInput"
            />
            <Autocomplete
              className="slabAutocomplete"
              options={thicknessMetric.map((option) => ({ label: option }))}
              getOptionLabel={(option) => option.label}
              defaultValue={{
                label: !_.isUndefined(record.thicknessUnit)
                  ? record.thicknessUnit
                  : "",
              }}
              id="combo-box-demo"
              onChange={(event, newValue) => {
                setEditValue((prev) => ({
                  ...prev,
                  thicknessUnit: newValue ? newValue.label : "Select",
                }));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        ) : !_.isUndefined(text) ? (
          `${text} ${record.thicknessUnit}`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.images"),
      dataIndex: "images",
      key: "images",
      className: "values",
      render: (text, record) => {
        if (record.images !== undefined && record.images.length > 0) {
          return (
            <PictureFilled
              style={{
                color: "#2c3fff",
                cursor: "pointer",
                fontSize:
                  viewing.bundle === bundleId &&
                  viewing.slab === record.slabNumber
                    ? "x-large"
                    : "large",
              }}
              onClick={() => {
                setImages(record.images);
                setSelectedProductId(productId);
                setSelectedImagesSourceData(record);
                setViewing({ bundle: bundleId, slab: record.slabNumber });
                props.setActiveStep(0);
              }}
            />
          );
        }
        return (
          <PictureFilled
            style={{ color: "grey", fontSize: "large", cursor: "not-allowed" }}
          />
        );
      },
    },

    {
      title: t("slabHeader.notes"),
      dataIndex: "notes",
      key: "notes",
      className: "values",
      render: (text, record) => {
        return (
          <Button
            onClick={() => handleNotesClick(record)}
            type="text"
            icon={
              <BsListOl
                style={{ color: "#2c3fff", marginBottom: "-0.125vh" }}
              />
            }
          />
        );
      },
    },
    {
      title: t("slabHeader.print"),
      dataIndex: "slabPrint",
      key: "slabPrint",
      className: "values",
      hidden: isPrintFeatureEnabled,
      render: (text, record) => {
        const slabData = [];
        slabData.push(record);
        return <PrintSlabDetails slabData={slabData} />;
      },
    },
    {
      title: t("slabHeader.edit"),
      dataIndex: "slabNumber",
      key: "slabNumber",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <Button
            type="text"
            icon={
              loadingState ? (
                <></>
              ) : (
                <CheckOutlined style={{ color: "green" }} />
              )
            }
            onClick={() => {
              setLoadingState(true);
              console.log(props.entityId, props.offerId);
              if (props.entityId === null && props.offerId) {
                // In case of edit through offer page
                updateSlabDetailsWithOffer.mutate({
                  offerId: props.offerId,
                  slabEntityId: record.id,
                  slab: editValue,
                });
              } else {
                updateSlabDetails.mutate({
                  salesEntityId: props.entityId,
                  slabEntityId: record.id,
                  slab: editValue,
                });
              }
            }}
          >
            {loadingState ? <LoadingOutlined /> : <></>}
          </Button>
        ) : (
          <Button
            type="text"
            icon={<EditOutlined style={{ color: "#2c3fff" }} />}
            onClick={() => {
              setEditId({ slabNumber: record.slabNumber, id: record.id });
              setEditValue({
                entityId: record.id,
                slabNumber: record.slabNumber,
                blockNumber: record.blockNumber,
                status: record.status,
                netHeight: record.netHeight,
                netWidth: record.netWidth,
                grossHeight: record.grossHeight,
                grossWidth: record.grossWidth,
                finish: record.finish,
                quality: record.quality,
                price: record.price,
                priceMetric: record.currencyCode,
                thickness: record.thickness,
                thicknessMetric: record.thicknessUnit,
              });
            }}
          />
        ),
    },
  ].filter((item) => !item.hidden);
  //const [selectedSlab, setSelectedSlab] = useState([]);
  const slabsByBundleIdQuery = props.salesData;

  useEffect(() => {
    if (selectedParentRowKeys.includes(bundleId)) {
      const childRowKeys = slabsByBundleIdQuery.map((slab) => slab.slabNumber);
      setSelectedSlab(childRowKeys);
    } else {
      setSelectedSlab([]);
    }
  }, [bundleId, selectedParentRowKeys, slabsByBundleIdQuery]);

  return (
    <>
      <Divider style={{ margin: 0 }} />
      <Table
        style={{ background: "rgba(238, 244, 253, 0.8)" }}
        columns={columns}
        dataSource={slabsByBundleIdQuery}
        pagination={{
          hideOnSinglePage: true,
        }}
        rowKey="id"
        loading={slabsByBundleIdQuery.isLoading}
        className={styles.slabTable}
        scroll={{ x: 150 }}
      ></Table>
      <Popup
        title={popUpContent === 1 ? "Success" : "Add Notes"}
        openPopup={openPopup}
        togglePopup={togglePopup}
      >
        {popUpContent === 1 ? (
          <SuccessComponent detail={"Slab updated successfully!!"} />
        ) : (
          <EmptyNotesForm
            onClosePopup={togglePopup}
            data={notesOf}
            isSales={true}
            entityId={props.entityId}
          />
        )}
      </Popup>
    </>
  );
};

export default SlabTableSales;
