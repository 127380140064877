import { useTranslation } from "react-i18next";
import MarketplaceBuyer from "../../components/Marketplace/MarketplaceBuyer";
import MarketplaceSeller from "../../components/Marketplace/MarketplaceSeller";

export const Widgets = () => {
  const { t } = useTranslation();
  return [
    {
      centered: true,
      tabItems: [
        {
          key: "Market",
          children: <MarketplaceBuyer />,
        },
      ],
      type: "tab",
    },
  ];
};
