import React, { useState } from "react";
import Cancelled from "../components/Sales/Cancelled";
import Hold from "../components/Sales/Hold";
import Inspection from "../components/Sales/Inspection";
import Offers from "../components/Sales/Offers";
import Order from "../components/Sales/Order";
import Shipped from "../components/Sales/Shipped";
import { useGetCustomers } from "../queries/react-query/customers";
import { useGetSalesList } from "../queries/react-query/sales";
import { useGetProfile } from "../queries/react-query/userProfile";

export default function Sales(props) {
  const [filterObject, setFilterObject] = useState({});
  const listSales = useGetSalesList([
    props.route === "HOLD"
      ? "HSales"
      : props.route === "INSPECTION"
      ? "ISales"
      : props.route === "ORDER"
      ? "OSales"
      : props.route === "SHIPPED"
      ? "SSales"
      : "CSales",
    {
      status: props.route,
      from: localStorage.getItem("pageStartInspection")
        ? localStorage.getItem("pageStartInspection")
        : 0,
      size: 100,
      filterContext: filterObject,
    },
  ]);

  const listCustomers = useGetCustomers([
    "customers",
    {
      from: 0,
      size: 100,
    },
  ]);

  const listUsers = useGetProfile([
    "users",
    {
      from: 0,
      size: 100,
    },
  ]);
  return (
    <>
      {props.route === "OFFERS" ? <Offers /> : <></>}
      {props.route === "HOLD" ? (
        <Hold
          listSales={listSales}
          listCustomers={listCustomers}
          listUsers={listUsers}
          setFilterObject={setFilterObject}
          filterObject={filterObject}
          queryKey={"HSales"}
        />
      ) : (
        <></>
      )}
      {props.route === "INSPECTION" ? (
        <Inspection
          listSales={listSales}
          listCustomers={listCustomers}
          listUsers={listUsers}
          setFilterObject={setFilterObject}
          filterObject={filterObject}
          queryKey={"ISales"}
        />
      ) : (
        <></>
      )}
      {props.route === "ORDER" ? (
        <Order
          listSales={listSales}
          listCustomers={listCustomers}
          listUsers={listUsers}
          setFilterObject={setFilterObject}
          filterObject={filterObject}
          queryKey={"OSales"}
        />
      ) : (
        <></>
      )}
      {props.route === "SHIPPED" ? (
        <Shipped
          listSales={listSales}
          listCustomers={listCustomers}
          listUsers={listUsers}
          setFilterObject={setFilterObject}
          filterObject={filterObject}
          queryKey={"SSales"}
        />
      ) : (
        <></>
      )}
      {props.route === "CANCELLED" ? (
        <Cancelled
          listSales={listSales}
          listCustomers={listCustomers}
          listUsers={listUsers}
          setFilterObject={setFilterObject}
          filterObject={filterObject}
          queryKey={"CSales"}
        />
      ) : (
        <></>
      )}
    </>
  );
}
