import {
  CloseOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { AddAPhoto, FormatListBulleted, GridView } from "@mui/icons-material";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Layout,
  List,
  Row,
  Skeleton,
  Space,
  Steps,
  Tooltip,
} from "antd";
import Meta from "antd/es/card/Meta";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import BundleCard from "../components/BundleCard";
import Slider from "../components/Common/MediaSlider/MediaSlider";
import { ExpandableDrawer, useDrawer } from "../components/ExpandableDrawer";
import { ExpandableDrawerOnlyExpanded } from "../components/ExpandableDrawerOnlyExpanded";
import Filter from "../components/Filter";
import ImagePopUp from "../components/ImagePopUp";
import InventoryForm from "../components/InventoryForm";
import InventoryImageForm from "../components/InventoryImageForm";
import MarketplaceExpand from "../components/Marketplace/MarketplaceExpand";
import SuccessComponent from "../components/SuccessComponent";
import {
  useAddToCart,
  useFetchBundles,
  useGetBundleList,
  useGetInventoryList,
} from "../queries/react-query/inventory";
import "../styles/inventory.css";
import { NOT_AVAILABLE_IMAGE } from "../utils/constants";
import { Flags } from "../utils/featureflags";
import "./inventory.css";

export function LiveInventory() {
  const { t } = useTranslation();
  const {
    openDrawer,
    setOpenDrawer,
    toggleDrawer,
    drawerTitle,
    setDrawerTitle,
    expand,
    setExpand,
  } = useDrawer(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [addClicked, setAddClicked] = useState(false);
  const [step, goToPage] = useState(0);
  const [inventory1, addInventory] = useState([]);
  const [imagePopUp, setImagePopUp] = useState(false);
  const [imagePopUpValue, setImagePopUpValue] = useState("");
  const queryClient = useQueryClient();
  const [selectedSlab, setSelectedSlab] = useState([]);
  const [addCartSuccess, setAddCartSuccess] = useState(false);
  const [addInventorySuccess, setAddInventorySuccess] = useState(false);
  const [view, setView] = useState("list");
  const [sellerPreview, setSellerPreview] = useState(false);
  const [bundleImages, setBundleImages] = useState([]);
  const [slabData, setSlabData] = useState({});
  const [supplierName, setSupplierName] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [nextVal, setNextVal] = useState([]);
  const [images, setImages] = useState([]);
  const [viewing, setViewing] = useState({ bundle: "-1", slab: "-1" });
  const [addImageTo, setAddImageTo] = useState({
    bundle: "-1",
    slab: "-1",
    block: "-1",
  });
  // console.log(images)
  const [inventory, setInventory] = useState({
    id: "",
    productName: "",
    price: "",
    finish: "",
    quality: "",
    type: "",
    thickness: "",
    bundleNumber: "",
    blockNumber: "",
  });
  const [stepImage, setStepImage] = useState(0);
  const [filterObject, setFilterObject] = useState({});
  const [blockDisabled, setBlockDisabled] = useState([]);
  const [imageAddedCheck, setImageAddedCheck] = useState([]);
  const [showAddImages, setShowAddImages] = useState(
    images.length === 0 && viewing.bundle !== "-1"
      ? true
      : viewing.slab !== "-1"
      ? true
      : false
  );
  const [updateImages, setUpdateImages] = useState(false);
  const [selectedImageSourceData, setSelectedImagesSourceData] = useState({}); //To store the parent object of selected image to be shown in the left side
  const inventoryListQuery = useGetInventoryList([
    "inventory",
    { filterContext: filterObject, apiVersion: 2 },
  ]);
  const getBundles = useFetchBundles([
    "inventoryCardView",
    { filterContext: filterObject, apiVersion: 2 },
  ]);
  const inventoryBundleQuery = useGetBundleList(
    ["bundles", drawerTitle, { filterContext: filterObject }],
    null,
    !!drawerTitle
  );

  const isPrintFeatureEnabled = Flags.enablePrint();

  useEffect(() => {
    const inventoryData = inventoryBundleQuery.data;
    if (inventoryData && viewing.bundle === "-1" && viewing.slab === "-1") {
      setSelectedImagesSourceData(inventoryData[0]);
      setImages(inventoryData[0].images); // Setting initial images for left side image preview
    }
  }, [inventoryBundleQuery.data, viewing]);

  const onSuccessAddCart = () => {
    queryClient.invalidateQueries(["cart"]);
    setAddCartSuccess(true);
    setTimeout(() => {
      setAddCartSuccess(false);
    }, [5000]);
  };

  const addToCart = useAddToCart(["cart"], onSuccessAddCart);
  const listItemStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "80px",
    padding: "16px",
    paddingRight: "35px",
    marginBottom: "10px",
    cursor: "pointer",
  };

  const handleInfinteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        getBundles.fetchNextPage();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseDrawerCallBack = () => {
    setImages([]);
  };

  const handleListClick = function (title) {
    setViewing({ bundle: "-1", slab: "-1" });
    setDrawerTitle(title);
    setOpenDrawer(true);
  };

  const handleItemsSelected = (data) => {
    setSelectedItems(data);
  };

  const handleDrawerExpand = (expanded) => {
    setExpand(expanded);
  };

  const addInventoryFn = () => {
    setAddClicked(true);
  };

  const addToCartClicked = () => {
    addToCart.mutate({ slabEntityIds: selectedSlab });
  };

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  const [activeStep, setActiveStep] = React.useState(0);

  // TODO need to handle block and slab click
  const dynamicFilters = (inputJSON) => {
    const dFilters = Object.keys(inputJSON).map((key) => ({
      key,
      type: "checkbox",
      value: Object.keys(inputJSON[key]).map((paramName) => ({
        paramName,
        cnt: inputJSON[key][paramName],
      })),
    }));
    return dFilters;
  };

  useEffect(() => {}, [imageAddedCheck]);

  useEffect(() => {
    if (!getBundles.hasNextPage || getBundles.isFetching) {
      window.removeEventListener("scroll", handleInfinteScroll);
    } else {
      window.addEventListener("scroll", handleInfinteScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleInfinteScroll);
    };
  }, [getBundles.hasNextPage]);

  return (
    <Layout>
      {inventoryListQuery.isLoading || getBundles.isLoading ? (
        <div style={{ width: "15%" }}>
          <Sider
            className="SiderNav"
            width="100%"
            style={{
              background: "var(--neutral-background-text-white-000, #FFF)",
              height: "67vh",
              borderRadius: "10px",
              overflowY: "auto",
            }}
          >
            <Skeleton active />
          </Sider>
        </div>
      ) : (
        <Filter
          queryClient={queryClient}
          Filters={
            view === "list"
              ? dynamicFilters(inventoryListQuery.data.aggregations)
              : getBundles.data.pages[0][0]
              ? dynamicFilters(getBundles.data.pages[0][0].aggregations)
              : []
          }
          setFilterObject={setFilterObject}
          filterObject={filterObject}
          dynamic={true}
          queryKey={view === "list" ? "inventory" : "inventoryCardView"}
        />
      )}
      <Content
        style={{
          width: "85%",
        }}
      >
        <Content
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            padding: "1vh 1vw",
            width: "100%",
          }}
        >
          <ToggleButtonGroup
            orientation="horizontal"
            value={view}
            exclusive
            size="small"
            sx={{ borderRadius: 20 }}
            onChange={handleChange}
          >
            <ToggleButton value="module" aria-label="module">
              {view === "module" ? (
                <GridView sx={{ color: "#0C25F3" }} />
              ) : (
                <GridView />
              )}
            </ToggleButton>
            <ToggleButton value="list" aria-label="list">
              {view === "list" ? (
                <FormatListBulleted sx={{ color: "#0C25F3" }} />
              ) : (
                <FormatListBulleted />
              )}
            </ToggleButton>
          </ToggleButtonGroup>
        </Content>
        {view === "list" ? (
          <>
            <List
              itemLayout="horizontal"
              style={{
                marginTop: "10px",
                width: "100%",
              }}
            >
              <List.Item
                style={{
                  width: "81vw",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: "18px",
                  marginBottom: "0",
                }}
              >
                <Space className="tableHeader1">
                  {t("inventoryHeaders.products")}
                </Space>
                <Space style={{ marginLeft: "190px" }} className="tableHeader1">
                  {t("inventoryHeaders.slabsInStock")}
                </Space>
                <Space className="tableHeader1">
                  {t("inventoryHeaders.slabsAvailable")}
                </Space>
                <Space className="tableHeader1">
                  {t("inventoryHeaders.slabsOnHold")}
                </Space>
              </List.Item>
            </List>
            {inventoryListQuery.isLoading ? (
              <Skeleton active />
            ) : inventoryListQuery.data.productSlabs === undefined ? (
              <Empty
                style={{
                  width: "100%",
                  marginTop: "2vh",
                  fontFamily: "Roboto",
                }}
                description={<span>Error in loading data</span>}
              />
            ) : inventoryListQuery.data.productSlabs.length === 0 ? (
              <Empty
                style={{
                  width: "100%",
                  marginTop: "2vh",
                  fontFamily: "Roboto",
                }}
                description={<span>No results found</span>}
              />
            ) : (
              <>
                <List
                  className="ListView"
                  itemLayout="horizontal"
                  dataSource={inventoryListQuery.data.productSlabs}
                  renderItem={(item) => (
                    <List.Item
                      style={listItemStyle}
                      className="ListViewItem"
                      onClick={() => handleListClick(item.productId)}
                    >
                      <Space
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          flexBasis: "200px",
                        }}
                      >
                        <Avatar
                          style={{ backgroundColor: "#A2A3A5" }}
                          src={
                            "https://media.istockphoto.com/id/1393792645/photo/part-of-a-stone-wall-for-background-or-texture.jpg?s=1024x1024&w=is&k=20&c=z5RBPMHwGV0UfxVmdOpulDzPYqhIe-uDt0FB_lTTsm8="
                          }
                        />
                        <Space
                          style={{
                            fontWeight: "600",
                            fontSize: "1rem",
                            marginLeft: "0.5vw",
                          }}
                          id="productName"
                        >
                          {item.productId}
                        </Space>
                      </Space>
                      <Space className="otherDetails">{item.totalSlabs}</Space>
                      <Space className="otherDetails">
                        {item.availableSlabs}
                      </Space>
                      <Space className="otherDetails">{item.slabsOnHold}</Space>
                    </List.Item>
                  )}
                />
                <ExpandableDrawer
                  toggleDrawer={toggleDrawer}
                  openDrawer={openDrawer}
                  closeDrawerCallback={handleCloseDrawerCallBack}
                  title={""}
                  expand={true}
                  onDrawerExpand={handleDrawerExpand}
                >
                  <Content
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      height: "100%",
                      width: "100%",
                      alignItems: " flex-start",
                    }}
                  >
                    <Content style={{ width: "40%" }}>
                      <p
                        className="imageHeadingInventory"
                        style={{ marginTop: "10vh" }}
                      >
                        {" "}
                        {drawerTitle}
                      </p>
                      {inventoryBundleQuery.isLoading ||
                      inventoryListQuery.isLoading ||
                      getBundles.isLoading ? (
                        <Skeleton active />
                      ) : (
                        <>
                          <Content
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <p
                              className="imageHeadingInventory"
                              style={{ marginTop: "2vh" }}
                            >
                              {viewing.bundle === "-1"
                                ? `Bundle ${
                                    inventoryBundleQuery.data &&
                                    inventoryBundleQuery.data[0]
                                      ? inventoryBundleQuery.data[0]
                                          .bundleNumber
                                      : ""
                                  }`
                                : viewing.slab === "-1"
                                ? `Bundle ${viewing.bundle}`
                                : `Bundle ${viewing.bundle} : Slab ${viewing.slab}`}
                            </p>
                            {localStorage.getItem("guest") === "true" ? (
                              <></>
                            ) : viewing.bundle === "-1" ? (
                              <AddAPhoto
                                style={{
                                  // marginTop: "2vh",
                                  cursor: "pointer",
                                  color: "#0C25F3",
                                }}
                                onClick={() => {
                                  setUpdateImages(true);
                                  setAddImageTo({
                                    bundle:
                                      inventoryBundleQuery.data[0].bundleNumber,
                                    slab: "-1",
                                  });
                                  setInventory({
                                    product: { name: drawerTitle },
                                  });
                                }}
                              />
                            ) : (
                              <AddAPhoto
                                style={{
                                  // marginTop: "2vh",
                                  cursor: "pointer",
                                  color: "#0C25F3",
                                }}
                                onClick={() => {
                                  setUpdateImages(true);
                                  setAddImageTo(viewing);
                                  setInventory({
                                    product: { name: drawerTitle },
                                  });
                                }}
                              />
                            )}
                          </Content>
                          <Box
                            sx={{
                              maxWidth: "100%",
                              flexGrow: 1,
                              marginTop: "3vh",
                            }}
                          >
                            <Slider
                              slides={images}
                              setImages={setImages}
                              queryClient={queryClient}
                              selectedImageSourceData={selectedImageSourceData}
                              productId={drawerTitle}
                              viewing={viewing}
                              allowDelete={true}
                            />
                          </Box>
                        </>
                      )}
                    </Content>
                    <Divider
                      type="vertical"
                      style={{
                        height: "100%",
                        backgroundColor: "#D3D3D3",
                        marginLeft: "2vw",
                        marginRight: "2vw",
                      }}
                    />
                    <Content style={{ width: "55%" }}>
                      <Space
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px",
                        }}
                      >
                        <h2 className="drawerTitle" style={{ margin: 0 }}>
                          {drawerTitle}
                        </h2>
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          {localStorage.getItem("guest") === "true" ? (
                            <></>
                          ) : (
                            <Button
                              className="contactSupplierBtn"
                              icon={<ShoppingCartOutlined />}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "2vh 1vw",
                              }}
                              onClick={addToCartClicked}
                            >
                              <span style={{ marginLeft: 8 }}>
                                {t("globalBtn.addToCart")}
                              </span>
                            </Button>
                          )}
                        </Space>
                      </Space>
                      <Content style={{ marginTop: "30px", width: "100%" }}>
                        <Content
                          direction="vertical"
                          style={{ width: "100%" }}
                          size={24}
                        >
                          <List
                            itemLayout="horizontal"
                            style={{
                              marginTop: "10px",
                              width: "100%",
                            }}
                          >
                            <List.Item
                              style={{
                                width: "96%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingLeft: "18px",
                                marginBottom: "0",
                              }}
                            >
                              <Space className="tableHeader1">
                                {t("bundleHeader.bundleNo")}
                              </Space>
                              <Space
                                className="tableHeader1"
                                style={{ marginLeft: "-2vw" }}
                              >
                                {t("bundleHeader.slabInStock")}
                              </Space>
                              <Space
                                style={{ marginLeft: "-2vw" }}
                                className="tableHeader1"
                              >
                                {t("bundleHeader.slabAvailable")}
                              </Space>
                              <Space
                                style={{ marginLeft: "-2vw" }}
                                className="tableHeader1"
                              >
                                {t("bundleHeader.slabOnHold")}
                              </Space>
                              <Space className="tableHeader1">
                                {t("bundleHeader.image")}
                              </Space>
                              {!isPrintFeatureEnabled && (
                                <Space className="tableHeader1">
                                  {t("bundleHeader.printSlabDetails")}
                                </Space>
                              )}
                            </List.Item>
                          </List>
                          <Content className="ListViewInner">
                            {inventoryBundleQuery.isLoading ? (
                              <Skeleton active />
                            ) : drawerTitle !== "" ? (
                              inventoryBundleQuery.data.map((data, i) => {
                                return (
                                  <BundleCard
                                    key={i}
                                    bundles={data}
                                    productId={drawerTitle}
                                    expand={expand}
                                    onDrawerExpand={handleDrawerExpand}
                                    selectedItems={selectedItems}
                                    onItemsSelected={handleItemsSelected}
                                    setImagePopUp={setImagePopUp}
                                    setImagePopUpValue={setImagePopUpValue}
                                    setSelectedSlab={setSelectedSlab}
                                    selectedSlab={selectedSlab}
                                    setImages={setImages}
                                    images={images}
                                    viewing={viewing}
                                    setViewing={setViewing}
                                    setShowAddImages={setShowAddImages}
                                    addImageTo={addImageTo}
                                    setAddImageTo={setAddImageTo}
                                    setActiveStep={setActiveStep}
                                    filterObject={filterObject}
                                    setSelectedImagesSourceData={
                                      setSelectedImagesSourceData
                                    }
                                  />
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </Content>
                        </Content>
                      </Content>
                    </Content>
                  </Content>
                  {imagePopUp ? (
                    <>
                      <ImagePopUp
                        setImagePopUp={setImagePopUp}
                        imagePopUpValue={imagePopUpValue}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {addCartSuccess ? (
                    <Content
                      style={{
                        backgroundColor: "rgba(128, 128, 128, 0.5)",
                        width: "100%",
                        height: "100%",
                        zIndex: 2,
                        position: "absolute",
                        top: "0",
                        left: "0",
                        borderRadius: "5px",
                      }}
                    >
                      <Card
                        style={{
                          width: "30%",
                          height: "auto",
                          backgroundColor: "white",
                          margin: "20vh 0 0 30vw",
                        }}
                        extra={
                          <CloseOutlined
                            style={{ color: "#0066FF" }}
                            onClick={() => {
                              setAddCartSuccess(false);
                            }}
                          />
                        }
                      >
                        <SuccessComponent detail={"Success"} />
                      </Card>
                    </Content>
                  ) : (
                    <></>
                  )}
                  {updateImages ? (
                    <Content
                      style={{
                        backgroundColor: "rgba(128, 128, 128, 0.5)",
                        width: "100%",
                        height: "100%",
                        zIndex: 2,
                        position: "absolute",
                        top: "0",
                        left: "0",
                        borderRadius: "5px",
                      }}
                    >
                      <Card
                        title={<p id="popUpHeader">Upload Image</p>}
                        style={{
                          margin: "3vh 5vw 0 5vw",
                        }}
                        extra={
                          <CloseOutlined
                            style={{ color: "red" }}
                            onClick={() => {
                              setAddImageTo({ bundle: "-1", slab: "-1" });
                              setStepImage(0);
                              setUpdateImages(false);
                            }}
                          />
                        }
                      >
                        <Content style={{ width: "100%" }}>
                          <Steps
                            current={stepImage}
                            style={{ width: "100%", fontFamily: "Roboto" }}
                            direction="horizontal"
                            items={[
                              {
                                title: "Select Image(s)",
                                // description: "Product details",
                              },
                              {
                                title: "Review Selection",
                                // description: "Bundle details",
                              },
                              {
                                title: "Upload Image",
                                // description: "Slab details",
                              },
                            ]}
                          />
                          <Divider
                            type="horizontal"
                            style={{ backgroundColor: "#656564" }}
                          />
                          <Content style={{ width: "100%" }}>
                            <InventoryImageForm
                              step={stepImage}
                              setStep={setStepImage}
                              addImageTo={addImageTo}
                              setAddImageTo={setAddImageTo}
                              inventory={inventory}
                              setBlockDisabled={setBlockDisabled}
                              updateFlag={true}
                              images={images}
                              setImages={setImages}
                              setUpdateImages={setUpdateImages}
                              setImageAddedCheck={setImageAddedCheck}
                              imageAddedCheck={imageAddedCheck}
                              blockDisabled={blockDisabled}
                              addCartSuccess={addCartSuccess}
                              setAddCartSuccess={setAddCartSuccess}
                            />
                          </Content>
                        </Content>
                      </Card>
                    </Content>
                  ) : (
                    <></>
                  )}
                </ExpandableDrawer>
              </>
            )}
          </>
        ) : (
          <>
            {getBundles.isLoading || !getBundles.data ? (
              <Skeleton active />
            ) : getBundles.data.pages[0].length === 0 ? (
              <Empty
                style={{
                  width: "100%",
                  marginTop: "2vh",
                  fontFamily: "Roboto",
                }}
                description={<span>No results found</span>}
              />
            ) : (
              <>
                <Content style={{ marginTop: "2vh", width: "100%" }}>
                  <Row style={{ justifyContent: "center" }}>
                    {getBundles.data.pages.map((page, pageIndex) => (
                      <React.Fragment key={pageIndex}>
                        {page.map((data, i) => {
                          return (
                            <Col key={i} style={{ margin: "2vh 1vw" }}>
                              <Card
                                hoverable
                                style={{
                                  width: 342,
                                  height: 336,
                                  backgroundColor: "#f9f9f6",
                                }}
                                cover={
                                  <img
                                    alt="example"
                                    src={
                                      data.images[0] === undefined
                                        ? NOT_AVAILABLE_IMAGE
                                        : data.images[0].preSignedUrl
                                    }
                                    height={173}
                                    width={256}
                                  />
                                }
                                onClick={() => {
                                  setSlabData({
                                    supplierTenantId: data.supplierTenantId,
                                    productName: data.productName,
                                    bundleNumber: data.bundleNumber,
                                  });
                                  setBundleImages(
                                    data.images.length === 0
                                      ? [NOT_AVAILABLE_IMAGE]
                                      : data.images
                                  );
                                  setSupplierName(data.supplierName);
                                  setSupplierId(data.supplierTenantId);
                                  setOpenDrawer(true);
                                }}
                              >
                                <Meta
                                  className="cardHeader"
                                  title={data.productName}
                                  description={`${Number(data.price).toFixed(
                                    2
                                  )} USD per square ft.`}
                                  style={{ marginBottom: "0.5vh" }}
                                />
                                <Meta
                                  className="cardSubHeader"
                                  description={`Bundle : ${data.bundleNumber}`}
                                  style={{ marginBottom: "0.5vh" }}
                                />
                              </Card>
                            </Col>
                          );
                        })}
                      </React.Fragment>
                    ))}
                  </Row>
                  <Content style={{ textAlign: "center", width: "100%" }}>
                    {getBundles.hasNextPage &&
                      getBundles.data.pages[getBundles.data.pages.length - 1]
                        .length > 0}
                    {getBundles.isFetchingNextPage &&
                    getBundles.data.pages[getBundles.data.pages.length - 1]
                      .length > 0 ? (
                      <p style={{ color: "#0677ff", fontWeight: "700" }}>
                        Loading...
                      </p>
                    ) : (
                      ""
                    )}
                  </Content>
                </Content>
                <ExpandableDrawerOnlyExpanded
                  toggleDrawer={toggleDrawer}
                  openDrawer={openDrawer}
                  closeDrawerCallback={handleCloseDrawerCallBack}
                  title={slabData.productName}
                  expand={expand}
                  onDrawerExpand={handleDrawerExpand}
                >
                  <MarketplaceExpand
                    setSellerPreview={setSellerPreview}
                    slabData={slabData}
                    bundleImages={bundleImages}
                    supplierName={supplierName}
                    handleCloseDrawerCallBack={handleCloseDrawerCallBack}
                    toggleDrawer={toggleDrawer}
                    sellerPerspective={true}
                    filterObject={filterObject}
                    isInventory={true}
                    selectedSlab={selectedSlab}
                    setSelectedSlab={setSelectedSlab}
                    onItemsSelected={handleItemsSelected}
                    addToCartClicked={addToCartClicked}
                  />
                  {addCartSuccess ? (
                    <Content
                      style={{
                        backgroundColor: "rgba(128, 128, 128, 0.5)",
                        width: "100%",
                        height: "100%",
                        zIndex: 2,
                        position: "absolute",
                        top: "0",
                        left: "0",
                        borderRadius: "5px",
                      }}
                    >
                      <Card
                        style={{
                          width: "30%",
                          height: "auto",
                          backgroundColor: "white",
                          margin: "20vh 0 0 30vw",
                        }}
                        extra={
                          <CloseOutlined
                            style={{ color: "#0066FF" }}
                            onClick={() => {
                              setAddCartSuccess(false);
                            }}
                          />
                        }
                      >
                        <SuccessComponent detail={"Success"} />
                      </Card>
                    </Content>
                  ) : (
                    <></>
                  )}
                </ExpandableDrawerOnlyExpanded>
              </>
            )}
          </>
        )}
        {addClicked ? (
          <></>
        ) : localStorage.getItem("guest") === "true" ? (
          <></>
        ) : (
          <Tooltip>
            <Button
              style={{
                position: "fixed",
                right: "1vw",
                bottom: "3vh",
                width: "50px",
                height: "50px",
                fontSize: "18px",
                textAlign: "center",
              }}
              type="primary"
              className="addButton"
              icon={<PlusOutlined />}
              onClick={addInventoryFn}
            ></Button>
          </Tooltip>
        )}
        {addClicked ? (
          addImageTo.bundle === "-1" && addImageTo.slab === "-1" ? (
            <Content
              style={{
                backgroundColor: "rgba(128, 128, 128, 0.5)",
                width: "100%",
                height: "122%",
                zIndex: 2,
                position: "absolute",
                top: "-2vh",
                left: "0",
                borderRadius: "5px",
              }}
            >
              <Card
                title={<p id="popUpHeader">Add Inventory</p>}
                style={{
                  margin: "3vh 5vw 0 5vw",
                }}
                extra={
                  step === 3 ? (
                    <></>
                  ) : (
                    <CloseOutlined
                      style={{ color: "red" }}
                      onClick={() => {
                        setAddClicked(false);
                        setBlockDisabled(false);
                        goToPage(0);
                      }}
                    />
                  )
                }
              >
                <Content style={{ width: "100%" }}>
                  <Steps
                    current={step}
                    style={{
                      width: "100%",
                      marginBottom: "2vh",
                      fontFamily: "Roboto",
                      color: "#0c25f3",
                    }}
                    direction="horizontal"
                    items={[
                      {
                        title: "Product details",
                        // description: "Product details",
                      },
                      {
                        title: "Bundle details",
                        // description: "Bundle details",
                      },
                      {
                        title: "Slab details",
                        // description: "Slab details",
                      },
                      {
                        title: "Image upload",
                        // description: "Image upload",
                      },
                    ]}
                  />

                  <Content style={{ width: "100%" }}>
                    <InventoryForm
                      step={step}
                      goToPage={goToPage}
                      addInventory={addInventory}
                      setAddImageTo={setAddImageTo}
                      inventory={inventory}
                      setInventory={setInventory}
                      nextVal={nextVal}
                      setNextVal={setNextVal}
                      blockDisabled={blockDisabled}
                      setAddClicked={setAddClicked}
                      setBlockDisabled={setBlockDisabled}
                      queryClient={queryClient}
                      addToInventory={true}
                      imageAddedCheck={imageAddedCheck}
                    />
                  </Content>
                </Content>
              </Card>
            </Content>
          ) : (
            <Content
              style={{
                backgroundColor: "rgba(128, 128, 128, 0.5)",
                width: "100%",
                height: "122%",
                zIndex: 2,
                position: "absolute",
                top: "-2vh",
                left: "0",
                borderRadius: "5px",
              }}
            >
              <Card
                title={<p id="popUpHeader">Upload Image</p>}
                style={{
                  margin: "3vh 5vw 0 5vw",
                }}
                extra={
                  <CloseOutlined
                    style={{ color: "red" }}
                    onClick={() => {
                      setAddImageTo({ bundle: "-1", slab: "-1" });
                      setStepImage(0);
                    }}
                  />
                }
              >
                <Content style={{ width: "100%" }}>
                  <Steps
                    current={stepImage}
                    style={{
                      width: "100%",
                      marginBottom: "2vh",
                      fontFamily: "Roboto",
                    }}
                    direction="horizontal"
                    items={[
                      {
                        title: "Select Image(s)",
                        // description: "Product details",
                      },
                      {
                        title: "Review Selection",
                        // description: "Bundle details",
                      },
                      {
                        title: "Upload Image",
                        // description: "Slab details",
                      },
                    ]}
                  />
                  <Content style={{ width: "100%" }}>
                    <InventoryImageForm
                      step={stepImage}
                      setStep={setStepImage}
                      addImageTo={addImageTo}
                      setAddImageTo={setAddImageTo}
                      inventory={inventory}
                      setBlockDisabled={setBlockDisabled}
                      setImageAddedCheck={setImageAddedCheck}
                      imageAddedCheck={imageAddedCheck}
                      blockDisabled={blockDisabled}
                    />
                  </Content>
                </Content>
              </Card>
            </Content>
          )
        ) : (
          <></>
        )}
        {addInventorySuccess ? (
          <Content
            style={{
              backgroundColor: "rgba(128, 128, 128, 0.5)",
              width: "100%",
              height: "76vh",
              zIndex: 2,
              position: "absolute",
              top: "0",
              left: "0",
              borderRadius: "5px",
            }}
          >
            <Card
              style={{
                width: "30%",
                height: "auto",
                backgroundColor: "white",
                margin: "20vh 0 0 30vw",
              }}
              extra={
                <CloseOutlined
                  style={{ color: "#0066FF" }}
                  onClick={() => {
                    setAddInventorySuccess(false);
                  }}
                />
              }
            >
              <SuccessComponent
                detail={"Product added to inventory successfully"}
              />
            </Card>
          </Content>
        ) : (
          <></>
        )}
      </Content>
    </Layout>
  );
}
