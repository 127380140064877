import { PictureFilled } from "@ant-design/icons";
import { Col, Collapse, Row } from "antd";
import { useEffect, useState } from "react";
import { useGetSlabsByBundleId } from "../../queries/react-query/inventory";
import { Flags } from "../../utils/featureflags";
import PrintSlabDetails from "../PrintComponents/PrintSlabDetails";
import SlabTable from "../SlabTable";
import SlabTableCart from "../SlabTableCart";
import "./style.css";
const { Panel } = Collapse;

const BundleCard = ({
  bundles,
  productId,
  expand,
  onDrawerExpand,
  selectedItems,
  onItemsSelected,
  setImages,
  images,
  viewing,
  setViewing,
  setShowAddImages,
  addImageTo,
  setAddImageTo,
  setSelectedImagesSourceData,
  ...props
}) => {
  const [selectedParentRowKeys, setSelectedParentRowKeys] = useState([]);
  const [slabsList, setSlabsList] = useState([]);
  const { isLoading, data } = useGetSlabsByBundleId(
    [
      "bundles",
      productId,
      bundles.bundleNumber,
      { filterContext: props.filterObject },
    ],
    null,
    !!bundles.bundleNumber
  );

  const isPrintFeatureEnabled = Flags.enablePrint();

  useEffect(() => {
    if (!isLoading) {
      setSlabsList(data);
    }
  }, [isLoading, data]);

  return (
    <Collapse className="InnerCollapse" style={{ marginBottom: "2vh" }}>
      <Panel
        header={
          <Row>
            <Col span={5} className="bundleList">
              {bundles.bundleNumber.slice(0, 9)}
            </Col>
            <Col span={!isPrintFeatureEnabled ? 4 : 6} className="bundleList">
              {bundles.totalSlabs}
            </Col>
            <Col span={!isPrintFeatureEnabled ? 4 : 6} className="bundleList">
              {bundles.availableSlabs}
            </Col>
            <Col span={!isPrintFeatureEnabled ? 5 : 6} className="bundleList">
              {bundles.slabsOnHold}
            </Col>
            <Col span={!isPrintFeatureEnabled ? 3 : 1}>
              <PictureFilled
                style={{
                  color: "#2c3fff",
                  cursor: "pointer",
                  fontSize:
                    viewing.bundle === bundles.bundleNumber &&
                    viewing.slab === "-1"
                      ? "x-large"
                      : "large",
                }}
                onClick={() => {
                  setImages(
                    bundles.images === undefined || bundles.images.length === 0
                      ? []
                      : bundles.images
                  );
                  setViewing({
                    bundle: bundles.bundleNumber,
                    slab: "-1",
                  });
                  setAddImageTo({
                    bundle: bundles.bundleNumber,
                    slab: "-1",
                    bundle: "-1",
                  });
                  setShowAddImages(
                    images.length === 0 && viewing.bundle !== "-1"
                      ? true
                      : false
                  );
                  setSelectedImagesSourceData(bundles);
                  props.setActiveStep(0);
                }}
              />
            </Col>
            {!isPrintFeatureEnabled && (
              <Col
                span={3}
                className="bundleList"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <PrintSlabDetails slabData={slabsList} />
              </Col>
            )}
          </Row>
        }
      >
        {props.isCart ? (
          <SlabTableCart
            productId={productId}
            bundleId={bundles.bundleNumber}
            onItemsSelected={onItemsSelected}
            selectedParentRowKeys={selectedParentRowKeys}
            setImagePopUp={props.setImagePopUp}
            setImagePopUpValue={props.setImagePopUpValue}
            setSelectedSlab={props.setSelectedSlab}
            selectedSlab={props.selectedSlab}
            setCartItemSelected={props.setCartItemSelected}
            cartItemSelected={props.cartItemSelected}
            productDetails={props.productDetails}
            bundleDetails={bundles}
            setImages={setImages}
            viewing={viewing}
            setViewing={setViewing}
            setActiveStep={props.setActiveStep}
            setSelectedImagesSourceData={setSelectedImagesSourceData}
            isPrintFeatureEnabled={isPrintFeatureEnabled}
          />
        ) : (
          <SlabTable
            productId={productId}
            bundleId={bundles.bundleNumber}
            onItemsSelected={onItemsSelected}
            selectedParentRowKeys={selectedParentRowKeys}
            setImagePopUp={props.setImagePopUp}
            setImagePopUpValue={props.setImagePopUpValue}
            setSelectedSlab={props.setSelectedSlab}
            selectedSlab={props.selectedSlab}
            setImages={setImages}
            viewing={viewing}
            setViewing={setViewing}
            setShowAddImages={setShowAddImages}
            setActiveStep={props.setActiveStep}
            filterObject={props.filterObject}
            setSelectedImagesSourceData={setSelectedImagesSourceData}
            isPrintFeatureEnabled={isPrintFeatureEnabled}
          />
        )}
      </Panel>
    </Collapse>
  );
};

export default BundleCard;
