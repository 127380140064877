import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { TOAST_TYPE, notify } from "../../utils/constants";
import {
  UpdateShipmentInfo,
  addNotes,
  addNotesSalesSlab,
  deleteReport,
  getNotesData,
  getPreSignedUrl,
  getSales,
  moveTo1,
  moveTo2,
  updateCustomer,
  updateInspector,
  updateSlabDetailsSales,
} from "../api/sales";

export const useGetSalesList = (queryParam) => {
  return useQuery({
    queryKey: queryParam,
    queryFn: getSales,
  });
};

export const useMoveTo1 = (queryParam, onSuccess) => {
  return useMutation({
    mutationKey: queryParam,
    mutationFn: moveTo1,
    onSuccess: onSuccess,
  });
};

export const useMoveTo2 = (queryParam, onSuccess) => {
  return useMutation({
    mutationKey: queryParam,
    mutationFn: moveTo2,
    onSuccess: onSuccess,
  });
};

export const useUpdateShipmentInfo = (queryParam, onSuccess) => {
  return useMutation({
    mutationKey: queryParam,
    mutationFn: UpdateShipmentInfo,
    onSuccess: onSuccess,
  });
};

export const useAddNotes = (queryParam, onSuccess) => {
  return useMutation({
    mutationKey: queryParam,
    mutationFn: addNotes,
    onSuccess: onSuccess,
  });
};

export const useUpdateInspector = (queryParam, onSuccess) => {
  return useMutation({
    mutationKey: queryParam,
    mutationFn: updateInspector,
    onSuccess: onSuccess,
  });
};

export const useUpdateCustomer = (queryParam, onSuccess) => {
  return useMutation({
    mutationKey: queryParam,
    mutationFn: updateCustomer,
    onSuccess: onSuccess,
  });
};

export const useGetPreSignedUrl = (queryParam) => {
  return useQuery({
    queryKey: queryParam,
    queryFn: getPreSignedUrl,
  });
};

export const useDeleteReport = (queryParam, onSuccess) => {
  return useMutation({
    mutationKey: queryParam,
    mutationFn: deleteReport,
    onSuccess: onSuccess,
  });
};

export const useFetchNotes = (queryKey) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: getNotesData,
  });
};

export const useAddNotesSalesSlab = (mutationKey, onSettled) => {
  const { t } = useTranslation();
  const notesMutation = useMutation({
    mutationKey: mutationKey,
    mutationFn: addNotesSalesSlab,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(
          t("toast.reachAdmin", { item: t("toast.inventory") }),
          TOAST_TYPE.FAIL
        );
      } else {
        return (
          <Snackbar open={true} autoHideDuration={6000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Error adding notes
            </Alert>
          </Snackbar>
        );
      }
    },
    onSettled: onSettled,
  });
  return notesMutation;
};

export const useUpdateSlabDetailsSales = (mutationKey, onSuccess) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: updateSlabDetailsSales,
    onSuccess: onSuccess,
  });
};
