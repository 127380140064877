import { PlusOutlined } from "@ant-design/icons";
import { Col, Empty, Layout, Row, Skeleton, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../styles/products.css";
import CollapsiblePanelHeader from "../CollapsiblePanelHeader";
import ProductCard from "../ProductCard";
const fields = ["Product", "Category", "Colour", "Location"];

export default function SearchProducts({ searchResult, ...props }) {
  const { t } = useTranslation();
  const [openModalForm, setopenModalForm] = useState(false);

  const showModalForm = () => {
    setopenModalForm(true);
  };

  // console.log(props.flag)

  const [product, setProduct] = useState({});
  const [updateMode, setUpdateMode] = useState(false);
  // const [flag,setFlag] = useState(false);

  const editProduct = (product) => {
    setProduct(product);
    showModalForm();
  };

  return (
    <Layout>
      <Content
        style={{
          width: "85%",
        }}
      >
        {searchResult.isLoading ? (
          <Skeleton active />
        ) : (
          <Space direction="vertical" style={{ width: "100%" }} size={24}>
            {/* {console.log(props.flag)} */}
            <CollapsiblePanelHeader
              panelProps={{
                header: (
                  <Row style={{ width: "100%" }} className="tableHeader2">
                    {_.map(fields, (field) => (
                      <Col
                        key={field}
                        style={
                          field.toLowerCase() === "category"
                            ? { paddingLeft: "2.75vw" }
                            : field.toLowerCase() === "colour"
                            ? { paddingLeft: "5vw" }
                            : field.toLowerCase() === "location"
                            ? { paddingLeft: "5vw" }
                            : {}
                        }
                        span={
                          field.toLowerCase() === "category"
                            ? 6
                            : field.toLowerCase() === "colour"
                            ? 7
                            : field.toLowerCase() === "location"
                            ? 5
                            : 6
                        }
                      >
                        {t(`productHeaders.${field.toLowerCase()}`)}
                      </Col>
                    ))}
                  </Row>
                ),
                key: "header",
                className: "collapable-header",
              }}
              collapseProps={{
                ghost: true,
                size: "large",
                collapsible: "disabled",
              }}
            />
            <Content
              className="ListViewProducts"
              style={{
                width: "83.75vw",
                height: "53vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {searchResult.data === undefined ? (
                <Empty
                  style={{
                    width: "100%",
                    marginTop: "2vh",
                    fontFamily: "Roboto",
                  }}
                  description={<span>No results found</span>}
                />
              ) : searchResult.data.productList.length === 0 ? (
                <Empty
                  style={{
                    width: "100%",
                    marginTop: "2vh",
                    fontFamily: "Roboto",
                  }}
                  description={<span>No results found</span>}
                />
              ) : (
                searchResult.data.productList.map((product) => (
                  <ProductCard
                    key={product.name}
                    product={product}
                    onEdit={editProduct}
                    setUpdateMode={setUpdateMode}
                    searchView={true}
                  />
                ))
              )}
            </Content>
          </Space>
        )}
      </Content>
    </Layout>
  );
}
