import { getCountries } from "country-state-picker";

const countryObj1 = getCountries();
const countryArr = [];
const countryRef = {};

for (let i = 0; i < countryObj1.length; ++i) {
  countryArr.push(countryObj1[i].name);
  countryRef[countryObj1[i].name] = countryObj1[i].code;
}

const FiltersCustomers = [
  {
    key: "Customer Type",
    type: "checkbox",
    value: ["Architect", "Builder", "Designer", "Distributor"],
  },
  {
    key: "Customer Country",
    type: "checkbox",
    value: countryArr,
  },
];

const columns = [
  {
    title: "Sales Order",
    dataIndex: "salesOrder",
    key: "salesOrder",
  },
  {
    title: "Sales date",
    dataIndex: "salesDate",
    key: "salesDate",
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
  },
  {
    title: "Product type",
    dataIndex: "productType",
    key: "productType",
  },
  {
    title: "Thickness",
    dataIndex: "thickness",
    key: "thickness",
  },
  {
    title: "Finish",
    dataIndex: "finish",
    key: "finish",
  },
  {
    title: "Unit price",
    dataIndex: "price",
    key: "price",
  },
];

const customerType = [
  {
    value: "Architect",
    label: "Architect",
  },
  {
    value: "Builder",
    label: "Builder",
  },
  {
    value: "Designer",
    label: "Designer",
  },
  {
    value: "Distributor",
    label: "Distributor",
  },
  {
    value: "Fabricator",
    label: "Fabricator",
  },
  {
    value: "Homeowner",
    label: "Homeowner",
  },
  {
    value: "Factory",
    label: "Factory",
  },
];

const customerZone = countryArr;

const phoneNoCode = [
  {
    value: "+55",
    label: "+55",
  },
  {
    value: "+1",
    label: "+1",
  },
];

const measurementSystem = [
  "Blocks > cm, m, kg and m3",
  "Slabs > cm, m and m2",
  "Blocks > Inches, feet, pounds, tons",
  "Slabs > Inches, Feet, Sq. Ft.",
];

const currency = [
  {
    value: "Australian Dollar",
    label: "Australian Dollar",
  },
  {
    value: "Brazilian Real",
    label: "Brazilian Real",
  },
  {
    value: "Euro",
    label: "Euro",
  },
  {
    value: "Indian Rupee",
    label: "Indian Rupee",
  },
  {
    value: "Pound",
    label: "Pound",
  },
  {
    value: "Saudi Riyal",
    label: "Saudi Riyal",
  },
  {
    value: "Turkish Lira",
    label: "Turkish Lira",
  },
  {
    value: "UAE Dirham",
    label: "UAE Dirham",
  },
  {
    value: "US Dollar",
    label: "US Dollar",
  },
];

const paymentTerms = [
  {
    value: "2% 10, NET 30",
    label: "2% 10, NET 30",
  },
  {
    value: "5% 10, NET 30",
    label: "5% 10, NET 30",
  },
  {
    value: "50% 120 Days, 50% 150 Days",
    label: "50% 120 Days, 50% 150 Days",
  },
  {
    value: "50% 120 Days, 50% 180 Days",
    label: "50% 120 Days, 50% 180 Days",
  },
  {
    value: "50% 150 Days, 50% 180 Days",
    label: "50% 150 Days, 50% 180 Days",
  },
  {
    value: "50% 90 Days, 50% 120 Days",
    label: "50% 90 Days, 50% 120 Days",
  },
  {
    value: "50% 90 Days, 50% 150 Days",
    label: "50% 90 Days, 50% 150 Days",
  },
  {
    value: "CAD - Cash Against Documents",
    label: "CAD - Cash Against Documents",
  },
  {
    value: "CASH",
    label: "CASH",
  },
  {
    value: "CIA - Cash in Advance",
    label: "CIA - Cash in Advance",
  },
  {
    value: "COD - Cash on Delivery",
    label: "COD - Cash on Delivery",
  },
  {
    value: "NET 10 Days",
    label: "NET 10 Days",
  },
  {
    value: "NET 120 Days",
    label: "NET 120 Days",
  },
  {
    value: "NET 14 Days",
    label: "NET 14 Days",
  },
  {
    value: "NET 150 Days",
    label: "NET 150 Days",
  },
  {
    value: "NET 180 Days",
    label: "NET 180 Days",
  },
  {
    value: "NET 30 Days",
    label: "NET 30 Days",
  },
  {
    value: "NET 60 Days",
    label: "NET 60 Days",
  },
  {
    value: "NET 7 Days",
    label: "NET 7 Days",
  },
  {
    value: "NET 90 Days",
    label: "NET 90 Days",
  },
];

const paymentMethod = [
  {
    value: "ACH",
    label: "ACH",
  },
  {
    value: "Auto Pay",
    label: "Auto Pay",
  },
  {
    value: "Cash",
    label: "Cash",
  },
  {
    value: "Check",
    label: "Check",
  },
  {
    value: "Credit Card - AMEX",
    label: "Credit Card - AMEX",
  },
  {
    value: "Credit Card - Master",
    label: "Credit Card - Master",
  },
  {
    value: "Credit Card - Other",
    label: "Credit Card - Other",
  },
  {
    value: "Credit Card - Visa",
    label: "Credit Card - Visa",
  },
  {
    value: "Debit Card",
    label: "Debit Card",
  },
  {
    value: "Other",
    label: "Other",
  },
  {
    value: "Wire",
    label: "Wire",
  },
];

const incoTerms = [
  {
    value: "CFR - Cost and Frieght",
    label: "CFR - Cost and Frieght",
  },
  {
    value: "CIF - Cost, Insurance & Freight",
    label: "CIF - Cost, Insurance & Freight",
  },
  {
    value: "CIP - Carriage & Insurance Paid",
    label: "CIP - Carriage & Insurance Paid",
  },
  {
    value: "CPT - Carriage Paid to",
    label: "CPT - Carriage Paid to",
  },
  {
    value: "DAP - Delivered at Place",
    label: "DAP - Delivered at Place",
  },
  {
    value: "DDP - Delivered Duty Paid",
    label: "DDP - Delivered Duty Paid",
  },
  {
    value: "DPU - Delivered at Place Unloaded",
    label: "DPU - Delivered at Place Unloaded",
  },
  {
    value: "EXW - Ex Works",
    label: "EXW - Ex Works",
  },
  {
    value: "FAS - Free Alongside Ship",
    label: "FAS - Free Alongside Ship",
  },
  {
    value: "FCA - Free Carrier",
    label: "FCA - Free Carrier",
  },
  {
    value: "FOB - Free on Board",
    label: "FOB - Free on Board",
  },
];

const shippingMode = [
  {
    value: "Air",
    label: "Air",
  },
  {
    value: "Road",
    label: "Road",
  },
  {
    value: "Ocean",
    label: "Ocean",
  },
  {
    value: "Rail",
    label: "Rail",
  },
  {
    value: "Road & Ocean",
    label: "Road & Ocean",
  },
  {
    value: "Road & Rail",
    label: "Road & Rail",
  },
  {
    value: "Ocean & Rail",
    label: "Ocean & Rail",
  },
  {
    value: "Air & Road",
    label: "Air & Road",
  },
  {
    value: "Air & Rail",
    label: "Air & Rail",
  },
  {
    value: "Ocean, Rail & Road",
    label: "Ocean, Rail & Road",
  },
];

const shippingPort = [
  "Bahia Blanca",
  "Buenos Aires",
  "Mar del Plata",
  "Puerto Deseado",
  "Puerto Madryn",
  "Rio Grande",
  "Rosario",
  "San Antonio Oeste",
  "San Pedro",
  "Ushuaia",
  "Zarate",
  "Puerto Quijarro",
  "Aratu",
  "Belem",
  "Fortaleza",
  "Imbituba",
  "Ipojuca",
  "Itaguai",
  "Itajai",
  "Manaus",
  "Navegantes",
  "Paranagua",
  "Pecem",
  "Rio de Janeiro",
  "Rio Grande",
  "Salvador",
  "Santos",
  "Sao Francisco do Sul",
  "Vila do Conde",
  "Vitoria",
  "Antofagasta",
  "Arica",
  "Coronel",
  "Easter Island",
  "Huasco",
  "Iquique",
  "Lirquen",
  "Mejillones",
  "Port Williams",
  "Puerto Chacabuco",
  "Puerto Ventanas",
  "Punta Arenas",
  "San Antonio",
  "San Vicente",
  "Talcahuano",
  "Valparaiso",
  "Barranquilla",
  "Buenaventura",
  "Cartagena",
  "San Andres Island",
  "Santa Marta",
  "Turbo",
  "Guayaquil",
  "Manta",
  "Georgetown",
  "Asuncion",
  "Callao",
  "Ilo",
  "Matarani",
  "Paita",
  "Paramaribo",
  "Montevideo",
  "Cumana",
  "El Guamache",
  "Guanta",
  "La Guaira",
  "Maracaibo",
  "Puerto Cabello",
  "Argentia",
  "Halifax",
  "Liverpool",
  "Montreal",
  "Pictou",
  "Port Hawkesbury",
  "Prince Rupert",
  "Saint John",
  "Sidney",
  "Sydney",
  "Vancouver",
  "Victoria",
  "Mobile",
  "Anchorage",
  "Angoon",
  "Cordova",
  "Craig",
  "Excursion Inlet",
  "Haines",
  "Hoonah",
  "Juneau",
  "Kake",
  "Kenai",
  "Ketchikan",
  "Klawock",
  "Petersburg",
  "Port MacKenzie",
  "Seward",
  "Sitka",
  "Skagway",
  "Tenakee Springs",
  "Thorne Bay",
  "Unalaska",
  "Valdez",
  "Whittier",
  "Wrangell",
  "Yakutat",
  "Long Beach",
  "Los Angeles",
  "Oakland",
  "Port Hueneme",
  "San Diego",
  "Wilmington",
  "Cape Canaveral",
  "Fernandina Beach",
  "Jacksonville",
  "Miami",
  "Palm Beach",
  "Port Everglades",
  "Tampa",
  "Savannah",
  "Hilo",
  "Honolulu",
  "Kahului",
  "Kaunakakai",
  "Kawaihae",
  "Nawiliwili",
  "New Orleans",
  "Eastport",
  "Portland",
  "Baltimore",
  "Boston",
  "Aberdeen",
  "Gulfport",
  "Portsmouth",
  "Newark",
  "Pennsauken",
  "New York",
  "Wilmington",
  "Portland",
  "Chester",
  "Philadelphia",
  "Charleston",
  "Brownsville",
  "Freeport",
  "Galveston",
  "Houston",
  "Newport News",
  "Norfolk",
  "Portsmouth",
  "Richmond",
  "Everett",
  "Longview",
  "Seattle",
  "Tacoma",
  "Almirante",
  "Balboa",
  "Colon",
  "Colon",
  "Cristobal",
  "Corinto",
  "El Bluff",
  "El Rama",
  "Altamira",
  "Ensenada",
  "Lazaro Cardenas",
  "Manzanillo",
  "Mazatlan",
  "Tampico",
  "Veracruz",
  "Puerto Castilla",
  "Puerto Cortes",
  "San Lorenzo",
  "Puerto Barrios",
  "Puerto Quetzal",
  "Santo Tomas de Castilla",
  "Acajutla",
  "Caldera",
  "Limon",
  "Moin",
  "Belize City",
  "Aden",
  "Hodeidah",
  "Abu Dhabi",
  "Dubai",
  "Fujairah",
  "Jebel Ali",
  "Khor Fakkan",
  "Sharjah",
  "Akdeniz",
  "Ambarli",
  "Derince",
  "Gemlik",
  "Haydarpasa",
  "Istanbul",
  "Izmir",
  "Mersin",
  "Lattakia",
  "Tartous",
  "Dammam",
  "Jeddah",
  "Jubail",
  "Doha",
  "Muscat",
  "Salalah",
  "Beirut",
  "Tripoli",
  "Doha",
  "Shuaiba",
  "Shuwaikh",
  "Aqaba",
  "Ashdod",
  "Eilat",
  "Haifa",
  "Umm Qasr",
  "Bandar Abbas",
  "Bushehr",
  "Chabahar",
  "Imam Khomeini",
  "Khorramshahr",
  "Batumi",
  "Poti",
  "Dhekelia",
  "Limassol",
  "Mina Salman",
  "Baku",
  "Chittagong",
  "Mongla",
  "Chennai",
  "Cochin (Kochi)",
  "Haldia",
  "Jawaharlal Nehru/ Nhava Sheva",
  "Kolkata (Calcutta)",
  "Mumbai",
  "Mundra",
  "Pipavav",
  "Tuticorin",
  "Visakhapatnam",
  "Male",
  "Yangon",
  "Karachi",
  "Muhammad Bin Qasim",
  "Colombo",
  "Cua Cam",
  "Da Nang",
  "Hai Phong",
  "Ho Chi Minh City",
  "Ho Chi Minh City",
  "Phu My",
  "Bangkok",
  "Laem Chabang",
  "Map Ta Phut",
  "Rayong",
  "Songkhla",
  "Sriracha",
  "Singapore",
  "Bugo",
  "Cagayan de Oro",
  "Cebu",
  "Davao",
  "General Santos",
  "Manila",
  "Subic Bay",
  "Bintulu",
  "Johor",
  "Kota Kinabalu",
  "Kuantan",
  "Kuching",
  "Labuan",
  "Penang",
  "Port Klang",
  "Sandakan",
  "Sibu",
  "Tanjung Pelepas",
  "Tawau",
  "Belawan",
  "Jakarta",
  "Palembang",
  "Panjang",
  "Selat Panjang",
  "Surabaya",
  "Tanjung Emas",
  "Tanjung Sekong",
  "Kaohsiung",
  "Keelung",
  "Taichung",
  "Taipei",
  "Macao",
  "Busan",
  "Gunsan",
  "Gwangyang",
  "Incheon",
  "Masan",
  "Okgye",
  "Pohang",
  "Pyeongtaek",
  "Ulsan",
  "Akita",
  "Chiba",
  "Fukuyama",
  "Hachinohe",
  "Hakata",
  "Hibiki",
  "Hiroshima",
  "Hitachinaka",
  "Hososhima",
  "Imari",
  "Ishigaki",
  "Ishikari",
  "Iwakuni",
  "Kainan",
  "Kawasaki",
  "Kitakyushu",
  "Kobe",
  "Mizushima",
  "Moji",
  "Nagoya",
  "Naha",
  "Naoshima",
  "Niigata",
  "Niihama",
  "Oita",
  "Omaezaki",
  "Onahama",
  "Osaka",
  "Saganoseki",
  "Sendai",
  "Shibushi",
  "Shimizu",
  "Tokachi",
  "Tokyo",
  "Tomakomai",
  "Toyama",
  "Toyohashi",
  "Ube",
  "Yokkaichi",
  "Yokohama",
  "Hong Kong",
  "Kwai Chung",
  "Chiwan",
  "Da Chan Bay",
  "Dalian",
  "Fuqing",
  "Fuzhou",
  "Guangzhou",
  "Haikou",
  "Huangpu",
  "Jinzhou",
  "Jiujiang",
  "Lianyungang",
  "Longkou",
  "Lufeng",
  "Nanjing",
  "Nantong",
  "Ningbo",
  "Qingdao",
  "Shanghai",
  "Shantou",
  "Shekou",
  "Tianjin",
  "Wuhan",
  "Wuhu",
  "Xiamen",
  "Xingang",
  "Yangzhou",
  "Yantai",
  "Yantian",
  "Yingkou",
  "Zhangjiagang",
  "Zhangzhou",
  "Zhanjiang",
  "Freetown",
  "Dakar",
  "Apapa",
  "Onne",
  "Takoradi",
  "Tema",
  "Jamestown",
  "Cape Town",
  "Durban",
  "East London",
  "Port Elizabeth",
  "Richards Bay",
  "Walvis Bay",
  "Beira",
  "Maputo",
  "Nacala",
  "Pemba",
  "Port Louis",
  "Majunga",
  "Toamasina",
  "Banana",
  "Boma",
  "Matadi",
  "Cabinda",
  "Lobito",
  "Luanda",
  "Namibe",
  "Soyo",
  "Agadir",
  "Casablanca",
  "Tanger (Tangier)",
  "Al Khoms",
  "Benghazi",
  "Misurata (Qasr Ahmed)",
  "Tripoli",
  "Ain Sukhna",
  "Alexandria",
  "Damietta",
  "East Port Said",
  "El-Dekheila",
  "El-Sokhna",
  "Port Said",
  "Suez",
  "Algiers",
  "Annaba",
  "Bejaia",
  "Oran",
  "Skikda",
  "Dar es Salaam",
  "Tanga",
  "Zanzibar",
  "Port Victoria",
  "Mombasa",
  "Port Vila",
  "Santo",
  "Apia",
  "Lae",
  "Madang",
  "Port Moresby",
  "Rabaul",
  "Auckland",
  "Bluff",
  "Lyttelton",
  "Napier",
  "Nelson",
  "New Plymouth",
  "Port Otago",
  "Tauranga",
  "Timaru",
  "Wellington",
  "Westport",
  "Noumea",
  "Papeete",
  "Lautoka",
  "Suva",
  "Adelaide",
  "Bell Bay",
  "Brisbane",
  "Fremantle",
  "Geraldton",
  "Gladstone",
  "Hay Point",
  "Melbourne",
  "Newcastle",
  "Port Hedland",
  "Port Kembla",
  "Sydney",
  "Townsville",
  "Pago-Pago",
  "Piti",
  "Basel",
  "Birsfelden",
  "Kleinhuningen",
  "Muttenz-Au",
  "St. Johann",
  "Amsterdam",
  "Moerdijk",
  "Rotterdam",
  "Terneuzen/Vlissingen",
  "Vlaardingen",
  "Hercules",
  "Fontvieille",
  "Bremen",
  "Bremerhaven",
  "Hamburg",
  "Travemunde",
  "Boulogne-sur-Mer",
  "Brest",
  "Dunkerque",
  "Fos-sur-Mer",
  "La Pallice",
  "Le Havre",
  "Le Verdon",
  "Marseille",
  "Nantes - Saint Nazaire",
  "Rouen",
  "Antwerp",
  "Ghent",
  "Zeebrugge",
  "Enns",
  "Krems",
  "Linz",
  "Vienna",
  "A Coruna",
  "Algeciras",
  "Alicante",
  "Almeria",
  "Barcelona",
  "Bilbao",
  "Cadiz",
  "Cartagena",
  "Gijon",
  "Las Palmas",
  "Malaga",
  "Sagunto",
  "Santa Cruz de Tenerife",
  "Santander",
  "Seville",
  "Tarragona",
  "Valencia",
  "Vigo",
  "Koper",
  "Belgrade",
  "Figueira Da Foz",
  "Leixoes",
  "Lisbon",
  "Praia Da Vitoria",
  "Setubal",
  "Sines",
  "Bar",
  "Marsaxlokk",
  "Ancona",
  "Bari",
  "Cagliari",
  "Civitavecchia",
  "Genoa",
  "Gioia Tauro",
  "La Spezia",
  "Livorno",
  "Napoli (Naples)",
  "Palermo",
  "Pozzallo",
  "Ravenna",
  "Salerno",
  "Taranto",
  "Trieste",
  "Vado Ligure",
  "Venezia (Venice)",
  "Patras",
  "Piraeus",
  "Thessaloniki",
  "Marina Bay",
  "Mid-Harbour Marina",
  "Gibraltar",
  "Ploce",
  "Rijeka",
  "Durres",
  "Goole",
  "Aberdeen",
  "Belfast",
  "Bristol",
  "Chatham",
  "Dover",
  "Felixstowe",
  "Grangemouth",
  "Greenock",
  "Harwich",
  "Heysham",
  "Hull",
  "Immingham",
  "Inverness",
  "Killingholme",
  "Liverpool",
  "Montrose",
  "Newport",
  "Plymouth",
  "Rochester",
  "Southampton",
  "Sunderland",
  "Teesport",
  "Tilbury",
  "Tyne",
  "Warrenpoint",
  "Gavle",
  "Goteborg",
  "Helsingborg",
  "Karlshamn",
  "Malmo",
  "Norrkoping",
  "Sodertalje",
  "Stockholm",
  "Uddevalla",
  "Barentsburg",
  "Longyearbyen",
  "Ny-Alesund",
  "Bergen",
  "Gamle Fredrikstad",
  "Kristiansand",
  "Larvik",
  "Moss",
  "Oslo",
  "Stavanger",
  "Klaipeda",
  "Riga",
  "Cork",
  "Dublin",
  "Wicklow",
  "Grundartangi",
  "Reydarfjordur",
  "Reykjavik",
  "Vestmannaeyjar",
  "Hamina",
  "Helsinki",
  "Kokkola",
  "Kotka",
  "Rauma",
  "Thorshavn",
  "Tallinn",
  "Fredericia",
  "Aarhus",
  "Copenhagen",
  "Esbjerg",
  "Ilyichevsk",
  "Odessa",
  "Bratislava",
  "Anadyr",
  "Kaliningrad",
  "Kholmsk",
  "Korsakov",
  "Magadan",
  "Nakhodka",
  "Novorossiysk",
  "Providenija",
  "St. Petersburg",
  "Vladivostok",
  "Vostochny",
  "Constantza",
  "Gdansk",
  "Gdynia",
  "Csepel/ Budapest",
  "Prague",
  "Usti nad Labem",
  "Varna",
  "Mazyr",
  "Boca Chica",
  "Puerto Plata",
  "Punta Caucedo",
  "Rio Haina",
  "Santo Domingo",
  "Roseau",
  "Woodbridge Bay",
  "Havana",
  "Santiago de Cuba",
  "George Town",
  "Freeport",
  "Hamilton",
  "Bridgetown",
  "Freeport",
  "Marsh Harbor",
  "Nassau",
  "Oranjestad",
  "Saint John's",
  "The Valley",
  "Cruz Bay",
  "Port Alucroix",
  "St. Thomas",
  "Port Purcell",
  "Virgin Gorda",
  "Grand Turk",
  "Providenciales",
  "Point Lisas",
  "Pointe-a-Pierre",
  "Port of Spain",
  "Canouan",
  "Campden Park Bay",
  "Kingstown",
  "Castries",
  "Vieux-Fort",
  "Basseterre",
  "Charlestown",
  "Ponce",
  "San Juan",
  "Puerto Nuevo",
  "Curacao",
  "Kralendijk",
  "Philipspburg",
  "Little Bay",
  "Fort-de-France",
  "Kingston",
  "Montego Bay",
  "Cap Haitien",
  "Corail",
  "Port-au-Prince",
  "Gustavia",
  "Pointe-a-Pitre",
  "Saint George's",
];

const country = countryArr;

const state = [
  // United States
  {
    value: "Alabama",
    label: "Alabama",
  },
  {
    value: "California",
    label: "California",
  },
  {
    value: "Florida",
    label: "Florida",
  },
  {
    value: "New York",
    label: "New York",
  },
  {
    value: "Texas",
    label: "Texas",
  },
  // United Kingdom
  {
    value: "England",
    label: "England",
  },
  {
    value: "Scotland",
    label: "Scotland",
  },
  {
    value: "Wales",
    label: "Wales",
  },
  {
    value: "Northern Ireland",
    label: "Northern Ireland",
  },
  // Canada
  {
    value: "Ontario",
    label: "Ontario",
  },
  {
    value: "Quebec",
    label: "Quebec",
  },
  {
    value: "British Columbia",
    label: "British Columbia",
  },
  {
    value: "Alberta",
    label: "Alberta",
  },
  // Australia
  {
    value: "New South Wales",
    label: "New South Wales",
  },
  {
    value: "Victoria",
    label: "Victoria",
  },
  {
    value: "Queensland",
    label: "Queensland",
  },
  {
    value: "Western Australia",
    label: "Western Australia",
  },
  // Germany
  {
    value: "Bavaria",
    label: "Bavaria",
  },
  {
    value: "North Rhine-Westphalia",
    label: "North Rhine-Westphalia",
  },
  {
    value: "Baden-Württemberg",
    label: "Baden-Württemberg",
  },
  {
    value: "Lower Saxony",
    label: "Lower Saxony",
  },
  // France
  {
    value: "Île-de-France",
    label: "Île-de-France",
  },
  {
    value: "Provence-Alpes-Côte d'Azur",
    label: "Provence-Alpes-Côte d'Azur",
  },
  {
    value: "Auvergne-Rhône-Alpes",
    label: "Auvergne-Rhône-Alpes",
  },
  {
    value: "Occitanie",
    label: "Occitanie",
  },
  // Italy
  {
    value: "Lombardy",
    label: "Lombardy",
  },
  {
    value: "Sicily",
    label: "Sicily",
  },
  {
    value: "Veneto",
    label: "Veneto",
  },
  {
    value: "Campania",
    label: "Campania",
  },
  // Spain
  {
    value: "Andalusia",
    label: "Andalusia",
  },
  {
    value: "Catalonia",
    label: "Catalonia",
  },
  {
    value: "Madrid",
    label: "Madrid",
  },
  {
    value: "Valencia",
    label: "Valencia",
  },
  // Japan
  {
    value: "Tokyo",
    label: "Tokyo",
  },
  {
    value: "Osaka",
    label: "Osaka",
  },
  {
    value: "Hokkaido",
    label: "Hokkaido",
  },
  {
    value: "Aichi",
    label: "Aichi",
  },
  // China
  {
    value: "Guangdong",
    label: "Guangdong",
  },
  {
    value: "Shandong",
    label: "Shandong",
  },
  {
    value: "Jiangsu",
    label: "Jiangsu",
  },
];

export const CustomerConstants = {
  FiltersCustomers: FiltersCustomers,
  Columns: columns,
  customerType: customerType,
  customerZone: customerZone,
  phoneNoCode: phoneNoCode,
  currency: currency,
  paymentTerms: paymentTerms,
  paymentMethod: paymentMethod,
  incoTerms: incoTerms,
  shippingMode: shippingMode,
  shippingPort: shippingPort,
  country: country,
  state: state,
  measurementSystem: measurementSystem,
  countryRef: countryRef,
};
