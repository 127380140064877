import { DeleteOutlined } from "@ant-design/icons";
import { propsToClassKey } from "@mui/styles";
import { Avatar, Button, Form, Input, Skeleton, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useAddNotes, useFetchNotes } from "../queries/react-query/inventory";
import { useAddNotesSalesSlab } from "../queries/react-query/sales";

const { TextArea } = Input;

const EmptyNotesForm = ({ onClosePopup, ...props }) => {
  const [currData, setCurrData] = useState(props.data);
  const [form] = Form.useForm();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [isPosting, setIsPosting] = useState(false);
  const [flag, setFlag] = useState(false);
  const { t } = useTranslation();
  const [notes, setNotes] = useState("");
  const queryClient = useQueryClient();
  const onSuccessAddNote = () => {
    setTimeout(() => {
      queryClient.refetchQueries(["Notes"]);
      setIsPosting(false);
      setFlag(flag ? false : true);
    }, [1000]);
  };
  const fetchNotes = useFetchNotes([
    "Notes",
    props.isSales
      ? {
          salesEntityId: props.entityId,
          slabEntityId: localStorage.getItem("slabId"),
        }
      : { slabEntityId: localStorage.getItem("slabId") },
    { isSales: props.isSales ? true : false },
  ]);
  const addNotes = useAddNotes(["Notes"], onSuccessAddNote);
  const addNotesSales = useAddNotesSalesSlab(["Notes"], onSuccessAddNote);
  const handlePost = () => {
    const requestBody = props.isSales
      ? {
          salesEntityId: props.entityId,
          slabEntityId: localStorage.getItem("slabId"),
          note: notes,
        }
      : {
          slabEntityId: localStorage.getItem("slabId"),
          note: notes,
        };

    setIsPosting(true);
    if (props.isSales) {
      addNotesSales.mutate(requestBody);
    } else {
      addNotes.mutate(requestBody);
    }
    setNotes("");
    form.submit();
  };

  const handleCancel = () => {
    setNotes("");
    form.resetFields();
    onClosePopup();
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
    form.setFieldValue({ notes: e.target.value });
  };

  const TimeAgo = (milliseconds) => {
    let d = new Date(milliseconds);
    let month = d.getMonth();
    let date = d.getDate();
    let year = d.getFullYear();
    let hr = d.getHours() / 10 >= 1 ? d.getHours() : `0${d.getHours()}`;
    let min = d.getMinutes() / 10 >= 1 ? d.getMinutes() : `0${d.getMinutes()}`;
    let time = hr + ":" + min;

    return (
      <span>
        {months[month]} {date}, {year} | {time}
      </span>
    );
  };

  useEffect(() => {}, [flag, notes]);
  return fetchNotes.isLoading ? (
    <Skeleton active />
  ) : (
    <Content style={{ width: "100%" }}>
      <TextArea
        rows={5}
        className="TextAreaNotes"
        placeholder="Type your notes here"
        value={notes}
        style={{
          backgroundColor: "white",
          resize: "none",
          marginBottom: "1vh",
        }}
        onChange={handleNotesChange}
      />
      <Space
        style={{
          display: "flex",
          gap: "12px",
          justifyContent: "right",
          marginBottom: "2vh",
        }}
      >
        <Button
          shape="round"
          onClick={handlePost}
          loading={isPosting}
          disabled={!notes && !isPosting}
          className="contactSupplierBtn"
        >
          Save
        </Button>
      </Space>
      {fetchNotes.data.notes === null || fetchNotes.data.notes === undefined ? (
        <></>
      ) : fetchNotes.data.notes.length !== 0 ? (
        <Content style={{ width: "100%" }}>
          <Content style={{ height: "35vh", overflowY: "auto", width: "100%" }}>
            {fetchNotes.data.notes.map((data, i) => {
              return (
                <Content
                  key={i}
                  style={{ width: "100%", padding: "0 0 1vh 0" }}
                >
                  <Content
                    className="notesSales"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      padding: "2vh 0",
                      width: "100%",
                    }}
                  >
                    <Avatar
                      id="Avatar"
                      style={{
                        backgroundColor: "#1055BB",
                        verticalAlign: "middle",
                        width: "40px",
                      }}
                      size="large"
                      gap={4}
                    >
                      {data.userFullName
                        ? data.userFullName.charAt(0)
                        : localStorage.getItem("userName").charAt(0)}
                    </Avatar>
                    <Content style={{ width: "100%" }}>
                      <h5 className="notesUsername">
                        {data.userFullName
                          ? data.userFullName
                          : localStorage.getItem("userName")}
                      </h5>
                      <p style={{ marginBottom: "0" }} className="notesData">
                        {data.message}
                      </p>
                      <h5 className="notesDateTime">
                        {data.postedTime
                          ? TimeAgo(data.postedTime)
                          : "Unknown time"}
                      </h5>
                    </Content>
                    {/* <DeleteOutlined
                      style={{
                        fontSize: "medium",
                        color: "red",
                        marginRight: "2vw",
                      }}
                      // onClick={deleteCustomer}
                    /> */}
                  </Content>
                </Content>
              );
            })}
          </Content>
        </Content>
      ) : (
        <></>
      )}
    </Content>
  );
};

export default EmptyNotesForm;
