import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";

countries.registerLocale(enLocale);

const countryObj = countries.getNames("en", { select: "official" });

const countryArr = Object.entries(countryObj).map(([key, value]) => {
  return value;
});
const flattenObject = (obj) => {
  const flattened = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};
const validate = (values) => {
  let valid = false;
  if (values.name) {
    valid = true;
  }
  if (valid && values.skus.length) {
    let total = values.skus.reduce((rCount, subP) => {
      const flattened = flattenObject(subP);
      let total = Object.keys(flattened).reduce((pCount, key) => {
        if (flattened[key]) {
          ++pCount;
        }
        return pCount;
      }, 0);
      return [7, 4].includes(total) ? ++rCount : rCount;
    }, 0);
    if (total === values.skus.length) {
      valid = true;
    } else {
      valid = false;
    }
  }
  return valid;
};

const materialType = ["Slab"];
const finishTypes = [
  { value: "Aged", label: "Aged" },
  { value: "Brushed", label: "Brushed" },
  { value: "Bush Hammered", label: "Bush Hammered" },
  { value: "Flamed/Thermal", label: "Flamed/Thermal" },
  { value: "Honed", label: "Honed" },
  { value: "Leathered", label: "Leathered" },
  { value: "Mushroom", label: "Mushroom" },
  { value: "Natural", label: "Natural" },
  { value: "Polished", label: "Polished" },
  { value: "Sandblasted", label: "Sandblasted" },
  { value: "Sawn", label: "Sawn" },
  { value: "Silk", label: "Silk" },
  { value: "Soft Leather", label: "Soft Leather" },
  { value: "Tumbled", label: "Tumbled" },
  { value: "Unfinished", label: "Unfinished" },
];

const qualityList = [
  { value: "Commercial", label: "Commercial" },
  { value: "High-Standard", label: "High-Standard" },
  { value: "Low-Standard", label: "Low-Standard" },
  { value: "Premium", label: "Premium" },
  { value: "Standard", label: "Standard" },
];
const currencyCode = [
  { value: "Australian Dollar", label: "Australian Dollar" },
  { value: "Brazilian Real", label: "Brazilian Real" },
  { value: "Euro", label: "Euro" },
  { value: "Indian Rupee", label: "Indian Rupee" },
  { value: "Pound", label: "Pound" },
  { value: "Saudi Riyal", label: "Saudi Riyal" },
  { value: "Turkish Lira", label: "Turkish Lira" },
  { value: "UAE Dirham", label: "UAE Dirham" },
  { value: "US Dollar", label: "US Dollar" },
];

const currencyTextToCode = () => {
  return currencyCode.reduce((prev, cur) => {
    prev[cur.value] = cur.code;
    return prev;
  }, {});
};

const units = [
  {
    value: "in",
    label: "in",
  },
  {
    value: "cm",
    label: "cm",
  },
];

const category = [
  {
    value: "Agate",
    label: "Agate",
  },
  {
    value: "Bluestone",
    label: "Bluestone",
  },
  {
    value: "Dolomitic Marble",
    label: "Dolomitic Marble",
  },
  {
    value: "Gneiss",
    label: "Gneiss",
  },
  {
    value: "Granite",
    label: "Granite",
  },
  {
    value: "Limestone",
    label: "Limestone",
  },
  {
    value: "Marble",
    label: "Marble",
  },
  {
    value: "Onyx",
    label: "Onyx",
  },
  {
    value: "Quartz",
    label: "Quartz",
  },
  {
    value: "Quartzite",
    label: "Quartzite",
  },
  {
    value: "Sandstone",
    label: "Sandstone",
  },
  {
    value: "Schist",
    label: "Schist",
  },
  {
    value: "Serpentine",
    label: "Serpentine",
  },
  {
    value: "Slate",
    label: "Slate",
  },
  {
    value: "Soapstone",
    label: "Soapstone",
  },
  {
    value: "Travertine",
    label: "Travertine",
  },
];

const colours = [
  {
    value: "Beige",
    label: "Beige",
  },
  {
    value: "Black",
    label: "Black",
  },
  {
    value: "Blue",
    label: "Blue",
  },
  {
    value: "Burgundy",
    label: "Burgundy",
  },
  {
    value: "Cream",
    label: "Cream",
  },
  {
    value: "Green",
    label: "Green",
  },
  {
    value: "Grey",
    label: "Grey",
  },
  {
    value: "White",
    label: "White",
  },
  {
    value: "Yellow",
    label: "Yellow",
  },
  {
    value: "Brown",
    label: "Brown",
  },
  {
    value: "Red",
    label: "Red",
  },
  {
    value: "Violet",
    label: "Violet",
  },
  {
    value: "Purple",
    label: "Purple",
  },
  {
    value: "Pink",
    label: "Pink",
  },
  {
    value: "Orange",
    label: "Orange",
  },
  {
    value: "Gold",
    label: "Gold",
  },
];

const skuSlabTemplate = {
  productType: "",
  thickness: "",
  thicknessUnit: "",
  finish: "",
  quality: "",
  price: "",
  currencyCode: "",
};

const skuBlockTemplate = {
  productType: "",
  quality: "",
  price: "",
  currencyCode: "",
};

const STATUS = {
  AVAILABLE: "AVAILABLE",
  HOLD: "HOLD",
  SOLD: "SOLD",
};

const Filters = [
  {
    key: "Category",
    type: "checkbox",
    value: [
      "Agate",
      "Bluestone",
      "Dolomitic Marble",
      "Gneiss",
      "Granite",
      "Limestone",
      "Marble",
      "Onyx",
      "Quartz",
      "Quartzite",
      "Sandstone",
      "Schist",
      "Serpentine",
      "Slate",
      "Soapstone",
      "Travertine",
    ],
  },
  {
    key: "Colour",
    type: "checkbox",
    value: [
      "Beige",
      "Black",
      "Blue",
      "Burgundy",
      "Cream",
      "Green",
      "Grey",
      "White",
      "Yellow",
      "Brown",
      "Red",
      "Violet",
      "Purple",
      "Pink",
      "Orange",
      "Gold",
    ],
  },
  {
    key: "Country",
    type: "checkbox",
    value: countryArr,
  },
];

export const ProductConstants = {
  Filters,
  validate,
  units,
  skuSlabTemplate,
  skuBlockTemplate,
  currencyCode,
  materialType,
  finishTypes,
  qualityList,
  currencyTextToCode,
  STATUS,
  colours,
  category,
};
