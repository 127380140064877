import {
  ArrowRightOutlined,
  AuditOutlined,
  BellOutlined,
  CheckOutlined,
  CloseOutlined,
  FileAddOutlined,
  FileTextOutlined,
  LoadingOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Edit, Person } from "@mui/icons-material";
import { Autocomplete, TextField } from "@mui/material";
import { Button, Collapse, Table, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import InspectionRow from "./InspectionRow";
const { Panel } = Collapse;

export default function InspectionCollapse(props) {
  const [inspectorName, setInspectorName] = useState(
    props.data.inspectorUserName ? props.data.inspectorUserName : ""
  );
  const [customerName, setCustomerName] = useState(
    props.data.customer ? props.data.customer : ""
  );

  useEffect(() => {}, [
    inspectorName,
    customerName,
    props.isLoadingCustomer,
    props.isLoadingInspector,
  ]);
  const handleNotesClick = () => {
    props.setNotesOf(props.data.notes ? props.data.notes : []);
    props.togglePopup();
    props.setIndex(props.itemIndex);
    props.setToggleClick(0);
  };

  const changeInspectorFn = () => {
    props.setInspectorLoading(props.itemIndex);
    // props.setInspectorLoading(-1);
    props.updateInspector.mutate({
      entityId: props.data.entityId,
      inspectorUserId: inspectorName,
    });
    props.setIsLoadingInspector(true);
  };

  const changeCustomerFn = async () => {
    props.setCustomerLoading(props.itemIndex);
    // props.setCustomerLoading(-1);
    props.updateCustomer.mutateAsync({
      entityId: props.data.entityId,
      customerId: customerName,
    });
    props.setIsLoadingCustomer(true);
  };
  const columns = [
    {
      title: "Product",
      dataIndex: "productName",
      width: "140",
    },
    {
      title: "Thickness",
      dataIndex: "thickness",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
  ];
  const handleInvoice = () => {
    props.setOpenDrawer(true);
    props.setIndex(props.itemIndex);
    // console.log(props.data.shipmentInfo)
    props.setShipmentInfo(props.data.shipmentInfo);
    props.setDrawerClick(2);
  };

  const handleMoveTo = () => {
    props.setOpenDrawer(true);
    props.setIndex(props.itemIndex);
    props.setDrawerClick(1);
  };

  const handleReport = () => {
    props.setOpenDrawer(true);
    props.setIndex(props.itemIndex);
    props.setDrawerClick(3);
  };

  const handleListClick = () => {
    props.setOpenDrawer(true);
    props.setIndex(props.itemIndex);
    props.setDrawerClick(0);
  };
  const flatProps = {
    options: props.customersList
      ? props.customersList.customerList.map((option) => option.customerName)
      : [],
  };

  const flatPropsInspector = {
    options: props.listUsers
      ? props.listUsers.userList.map((option) => option.fullName)
      : [],
  };

  return (
    <Collapse
      className="salesCollapse"
      style={{
        marginBottom: "24px",
        borderTop: "0",
        borderRight: "0",
        borderLeft: "0",
        borderRadius: "8px",
        borderBottom:
          "0.75px solid var(--neutral-background-text-white-300, #E6E6E6)",
        background: "var(--neutral-background-text-white-100, #F9F9F9)",
      }}
    >
      <Panel
        header={
          <InspectionRow
            listItemStyle={props.listItemStyle}
            data={props.data}
            setDrawerTitle={props.setDrawerTitle}
            setOpenDrawer={props.setOpenDrawer}
            itemIndex={props.itemIndex}
            setIndex={props.setIndex}
            setDrawerClick={props.setDrawerClick}
            searchView={props.searchView}
          />
        }
        showArrow={false}
        key="1"
      >
        <Content
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Table
            style={{ width: "50%" }}
            className="salesTableSales"
            columns={columns}
            dataSource={
              props.data.saleSummary
                ? props.data.saleSummary.productSummaryList !== undefined
                  ? props.data.saleSummary.productSummaryList
                  : []
                : []
            }
            pagination={false}
            rowKey="productName"
            scroll={{
              y: 240,
            }}
            summary={(pageData) => {
              return (
                <>
                  <Table.Summary.Row className="tableSummary">
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      {props.data.saleSummary
                        ? props.data.saleSummary.totalQuantity
                          ? props.data.saleSummary.totalQuantity
                          : 0
                        : 0}{" "}
                      slabs
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      {props.data.saleSummary
                        ? props.data.saleSummary.totalPrice
                          ? props.data.saleSummary.totalPrice
                          : 0
                        : 0}{" "}
                      USD
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />

          <Content
            style={{ marginLeft: "2vw", padding: "4vh 2vw", width: "30%" }}
          >
            <Content
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <Content
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingBottom: "1.5vh",
                  width: "50%",
                }}
              >
                <Person sx={{ fontSize: "xx-large" }} />
                <Content style={{ paddingLeft: "0.5vw", width: "100%" }}>
                  <p style={{ marginBottom: "0" }} className="smallHeader">
                    Sales Rep.
                  </p>
                  <p style={{ marginBottom: "0" }} className="mediumHeader">
                    {props.data.createdByUserName}
                  </p>
                </Content>
              </Content>
            </Content>
            <Content
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Content
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingBottom: "1.5vh",
                  width: "100%",
                }}
              >
                <Person sx={{ fontSize: "xx-large" }} />
                <Content style={{ paddingLeft: "0.5vw", width: "100%" }}>
                  <p style={{ marginBottom: "0" }} className="smallHeader">
                    Customer
                  </p>
                  {props.changeCustomer ? (
                    <Content
                      style={{
                        width: "100%",
                        paddingTop: "1vh",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Autocomplete
                        {...flatProps}
                        id="combo-box-demo"
                        onChange={(event, newValue) => {
                          setCustomerName(newValue ? newValue : "Select");
                        }}
                        sx={{ width: 200 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Customer name" />
                        )}
                      />
                      <Button
                        type="text"
                        style={{ marginLeft: "5px" }}
                        onClick={changeCustomerFn}
                        icon={
                          props.customerLoading === props.itemIndex ? (
                            <></>
                          ) : (
                            <CheckOutlined
                              style={{ color: "green", fontSize: "large" }}
                            />
                          )
                        }
                        disabled={
                          props.customerLoading === props.itemIndex
                            ? true
                            : false
                        }
                      >
                        {props.customerLoading === props.itemIndex ? (
                          <LoadingOutlined
                            style={{ color: "#0c25f3", fontSize: "large" }}
                          />
                        ) : (
                          <></>
                        )}
                      </Button>
                      <Button
                        type="text"
                        style={{ marginLeft: "5px" }}
                        onClick={() => {
                          props.setChangeCustomer(false);
                        }}
                        icon={
                          <CloseOutlined
                            style={{ color: "red", fontSize: "large" }}
                          />
                        }
                        disabled={
                          props.customerLoading === props.itemIndex
                            ? true
                            : false
                        }
                      ></Button>
                    </Content>
                  ) : (
                    <p style={{ marginBottom: "0" }} className="mediumHeader">
                      {props.data.customer ? props.data.customer : "--"}{" "}
                      {props.searchView ? (
                        <></>
                      ) : (
                        <Edit
                          style={{
                            color: "#0C25F3",
                            fontSize: "x-large",
                            marginBottom: "-0.5vh",
                          }}
                          onClick={() => {
                            props.setChangeCustomer(true);
                          }}
                        />
                      )}
                    </p>
                  )}
                </Content>
              </Content>
            </Content>
            <Content
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Content
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                <Person sx={{ fontSize: "xx-large" }} />
                <Content style={{ paddingLeft: "0.5vw", width: "100%" }}>
                  <p style={{ marginBottom: "0" }} className="smallHeader">
                    Inspector
                  </p>
                  {props.changeInspector ? (
                    <Content
                      style={{
                        width: "100%",
                        paddingTop: "1vh",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Autocomplete
                        {...flatPropsInspector}
                        id="combo-box-demo"
                        onChange={(event, newValue) => {
                          setInspectorName(newValue ? newValue : "Select");
                        }}
                        sx={{ width: 200 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Inspector name" />
                        )}
                      />
                      <Button
                        type="text"
                        style={{ marginLeft: "5px" }}
                        onClick={changeInspectorFn}
                        icon={
                          props.inspectorLoading === props.itemIndex ? (
                            <LoadingOutlined
                              style={{ color: "#0c25f3", fontSize: "large" }}
                            />
                          ) : (
                            <CheckOutlined
                              style={{ color: "green", fontSize: "large" }}
                            />
                          )
                        }
                        disabled={
                          props.inspectorLoading === props.itemIndex
                            ? true
                            : false
                        }
                      ></Button>
                      <Button
                        type="text"
                        style={{ marginLeft: "5px" }}
                        onClick={() => {
                          props.setChangeInspector(false);
                        }}
                        icon={
                          <CloseOutlined
                            style={{ color: "red", fontSize: "large" }}
                          />
                        }
                        disabled={
                          props.inspectorLoading === props.itemIndex
                            ? true
                            : false
                        }
                      ></Button>
                    </Content>
                  ) : (
                    <p style={{ marginBottom: "0" }} className="mediumHeader">
                      {props.data.inspectorUserName
                        ? props.data.inspectorUserName
                        : "--"}{" "}
                      {props.searchView ? (
                        <></>
                      ) : (
                        <Edit
                          style={{
                            color: "#0C25F3",
                            fontSize: "x-large",
                            marginBottom: "-0.5vh",
                          }}
                          onClick={() => {
                            props.setChangeInspector(true);
                          }}
                        />
                      )}
                    </p>
                  )}
                </Content>
              </Content>
            </Content>
          </Content>
          <Content style={{ width: "20%" }}>
            <Content style={{ width: "100%" }}>
              <Button
                type="text"
                className="SalesCollapseButton"
                icon={<TableOutlined />}
                style={{
                  width: "13.75vw",
                  textAlign: "left",
                  width: "50%",
                }}
                onClick={handleListClick}
              >
                View details
              </Button>
            </Content>
            {props.searchView ? (
              <></>
            ) : props.type === 1 && props.data.shipmentInfo === undefined ? (
              <Content style={{ width: "100%" }}>
                <Tooltip title="Update Shipment Information before proceeding">
                  <Button
                    type="text"
                    className="SalesCollapseButton"
                    icon={<ArrowRightOutlined />}
                    style={{
                      width: "13.75vw",
                      textAlign: "left",
                      width: "50%",
                    }}
                    onClick={handleMoveTo}
                    disabled={
                      (props.type === 1 &&
                        props.data.shipmentInfo === undefined) ||
                      props.type === 3
                        ? true
                        : false
                    }
                  >
                    Move to
                  </Button>
                </Tooltip>
              </Content>
            ) : (
              <Content style={{ width: "100%" }}>
                <Button
                  type="text"
                  className="SalesCollapseButton"
                  icon={<ArrowRightOutlined />}
                  style={{
                    width: "13.75vw",
                    textAlign: "left",
                    width: "50%",
                  }}
                  onClick={handleMoveTo}
                  disabled={
                    (props.type === 1 &&
                      props.data.shipmentInfo === undefined) ||
                    props.type === 3
                      ? true
                      : false
                  }
                >
                  Move to
                </Button>
              </Content>
            )}
            {props.type === 0 ? (
              <Content style={{ width: "100%" }}>
                <Button
                  type="text"
                  className="SalesCollapseButton"
                  icon={
                    props.searchView ? (
                      <FileTextOutlined />
                    ) : (
                      <FileAddOutlined />
                    )
                  }
                  style={{
                    minWidth: "13.75vw",
                    textAlign: "left",
                    overflowWrap: "break-word",
                    width: "50%",
                  }}
                  onClick={handleReport}
                >
                  {props.searchView ? "View Report" : "Add Report"}
                </Button>
              </Content>
            ) : props.type !== -1 ? (
              <Content style={{ width: "100%" }}>
                <Button
                  type="text"
                  className="SalesCollapseButton"
                  icon={<FileTextOutlined />}
                  style={{
                    minWidth: "13.75vw",
                    textAlign: "left",
                    overflowWrap: "break-word",
                    width: "50%",
                  }}
                  onClick={handleReport}
                >
                  View Report
                </Button>
              </Content>
            ) : (
              <></>
            )}
            <Content style={{ width: "100%" }}>
              <Button
                type="text"
                className="SalesCollapseButton"
                icon={<BellOutlined />}
                style={{
                  width: "13.75vw",
                  textAlign: "left",
                  width: "50%",
                }}
                onClick={handleNotesClick}
              >
                {props.searchView ? "View Notes" : "Add Notes"}
              </Button>
            </Content>
            <Content
              style={{
                width: "100%",
              }}
            >
              {props.setInvoice ? (
                <Button
                  type="text"
                  className="SalesCollapseButton"
                  icon={<AuditOutlined />}
                  style={{
                    minWidth: "13.75vw",
                    textAlign: "left",
                    overflowWrap: "break-word",
                    width: "50%",
                  }}
                  onClick={handleInvoice}
                >
                  {props.type == 2 || props.searchView
                    ? "View Invoice"
                    : "Add Shipment"}
                </Button>
              ) : (
                <></>
              )}
            </Content>
          </Content>
        </Content>
      </Panel>
    </Collapse>
  );
}
