const frTranslations={
  navbar: {
    title: "Stonovation",
    inventory: "Inventaire",
    products: "Produits",
    logout: "Déconnexion",
    sales: "Ventes",
    marketplace: "Place de marché",
    settings: "Réglages",
    myProfile: "Mon profil",
    cart: "Chariot",
    search: "Rechercher",
    mystore: "Ma boutique",
    home: "Accueil",
    supplier: "Fournisseur",
    suppliers: "Fournisseurs",
    help: "Aide",
    support: "soutien",
    premium: "Passez à la version premium",
  },
  subNav: {
    liveInventory: "Inventaire en direct",
    products: "Produits",
    hold: "Tenir",
    inspection: "Inspection",
    salesOrder: "Commande de vente",
    shippedOrder: "Commande expédiée",
    cancelledOrder: "Commande annulée",
    customers: "Clientèle",
    users: "Les utilisateurs",
    CompanyProfile: "Profil de l'entreprise",
    store: "Boutique",
    myStore: "Ma boutique",
    offers: "Offres",
  },
  bundleInfo: {
    collapse: "Effondrement",
    expand: "Élargir",
  },
  tableHeader: {
    slabNumber: "Numéro de dalle",
    quality: "Qualité",
    thickness: "Epaisseur",
    width: "Largeur",
    length: "Longueur",
    notes: "Remarques",
    customer: "Client",
    status: "État",
    finish: "Terminer",
    blockNo: "Numéro de bloc",
    type: "Tapez",
    price: "Prix",
  },
  createBundle: {
    removeBundleTooltip: "Supprimer ce lot",
    removeSlabTooltip: "Supprimer cette dalle",
    addASlab: "Ajouter une dalle",
    addSlabGrp: "Ajouter un groupe de dalles",
  },
  actions: {
    closeBtn: "Fermer",
    updateBtn: "Mettre à jour",
    removeBtn: "Supprimer",
    removeHoldBtn: "Supprimer la mise en attente",
    addBtn: "Ajouter",
    nextBtn: "Suivant",
    backBtn: "Retour",
    createBtn: "Créez",
    resetBtn: "Réinitialiser",
    cancelBtn: "Annuler",
    filterBtn: "Filtre",
  },
  aria: {
    labels: {
      openInFull: "ouvert dans son intégralité",
      reset: "réinitialiser le filtre",
      addInventory: "Ajouter un article d'inventaire",
      search: "chercher",
      viewDetails: "Afficher les détails",
      productList: "listes d'articles de produits",
      refresh: "actualiser",
      bundleTable: "Tableau détaillé des offres groupées",
    },
  },
  slab: {
    labels: {
      finish: "Terminer",
      price: "Prix",
      quality: "Qualité",
      thickness: "Epaisseur",
      width: "Largeur",
      length: "Longueur",
      qty: "Quantité",
      notes: "Remarques",
      customerName: "Nom du client",
      currency: "Devise",
      unit: "Unité",
      min: "Minimum",
      max: "Max",
      noOfSlabs: "Nombre de dalles",
    },
    status: {
      available: "DISPONIBLE",
      hold: "TENIR",
      sold: "VENDU",
      all: "TOUS",
    },
  },
  pageTitles: {
    PAGE_1: `Pick a product`,
    PAGE_2: `Block and Bundles`,
    PAGE_3: `Bundle Info`,
    PAGE_4: `Review`,
  },
  toast: {
    success: "Avec succès {{type}} {{item}}",
    fail: "Impossible de {{type}} {{item}}",
    warn: "{{item}} créé avec succès, veuillez ajouter plus de détails",
    reachAdmin: "Impossible de récupérer les listes {{item}}, veuillez contacter l'administrateur",
    created: "créé",
    create: "créer",
    updated: "mis à jour",
    update: "mise à jour",
    delete: "supprimer",
    deleted: "supprimé",
    inventory: "inventaire",
    product: "produit",
  },
  inventoryDetails: {
    changeStatus: "Modifier le statut",
    bundleNumber: "Numéro de lot",
    inStock: "En stock",
    available: "Disponible",
    hold: "Tenir",
    popUptitle: "Mettre à jour {{type}}",
    addInventory: "Ajouter un inventaire",
    product: "Produit",
    filterPopupTitle: "Filtre",
    searchLabel: "Rechercher dans l'inventaire",
    notes: "Remarques",
    customer: "Client",
    onHold: "en attente",
  },
  inventoryForm: {
    searchProduct: "Rechercher un produit",
    category: "Catégorie",
    colour: "Couleur",
    location: "Lieu",
    material: "Forme du matériau",
    addBundle: "Ajouter un bundle",
    blockNumber: "Numéro de bloc",
    noOfBundles: "Nombre de lots",
  },
  tooltip: {
    filter: "Filtre",
    editProduct: "Modifier ce produit",
    deleteProduct: "Supprimer ce produit",
    addSubproduct: "Ajouter un nouveau sous-produit",
    removeItem: "Supprimer cet article",
    addNotes: "Ajouter des notes",
    removeFromHold: "Supprimer de la mise en attente",
    moveToHold: "Déplacer pour conserver",
    changeStatus: "Cliquez pour modifier",
    language: "Changer de langue",
    viewCustomer: "Cliquez pour voir",
    viewProduct: "Afficher le produit",
    addCondition: "Ajouter des images à tous les lots",
  },
  productContainer: {
    createProduct: "Créer un produit",
    searchLabel: "Rechercher un produit",
    updateProduct: "Mettre à jour le produit",
    createProductBtn: "Créer un produit",
    name: "Nom du produit",
  },
  searchBar: {
    search: "Rechercher...",
  },
  inventoryHeaders: {
    products: "Produits",
    slabsInStock: "Dalles en stock",
    slabsAvailable: "Dalles disponibles",
    slabsOnHold: "Dalles en attente",
  },
  cart: {
    products: "Produits",
    slabsInStock: "Dalles en stock",
    slabsAvailable: "Dalles disponibles",
    slabsOnHold: "Dalles en attente",
    slabsUnavailable: "Dalles non disponibles",
  },
  productHeaders: {
    product: "Produit",
    category: "Catégorie",
    colour: "Couleur",
    location: "Lieu",
    type: "Tapez",
    thickness: "Epaisseur",
    finish: "Terminer",
    quality: "Qualité",
    price: "Prix",
    quantity: "Quantité",
  },
  salesHeaders: {
    orderNumber: "Numéro de commande",
    customer: "Client",
    products: "Produits",
    type: "Tapez",
    orderCreated: "Commande créée",
    status: "État",
  },
  usersHeaders: {
    fullname: "Nom complet",
    jobTitle: "Intitulé du poste",
    department: "Département",
    employeeNumber: "Numéro d'employé",
    joiningDate: "Date d'adhésion",
    userID: "ID utilisateur",
    accessLevel: "Niveau d'accès",
    phoneNo: "Numéro de téléphone",
    secondaryAddress: "Adresse secondaire",
  },
  companyName: {
    companyName: "Nom de l'entreprise",
    email: "Courrier électronique",
    contactNumber: "Numéro de contact",
    timezone: "Fuseau horaire",
    city: "Ville",
    country: "Pays",
    save: "Enregistrer",
    cancel: "Annuler",
    edit: "Modifier",
  },
  customers: {
    customerProfile: "Profil du client",
    companyName: "Nom de l'entreprise",
    customerType: "Type de client",
    customerZone: "Zone client",
    sinceDate: "Depuis la date",
    contactDetails: "Informations de contact",
    contactName: "Nom du contact",
    phoneNo: "Numéro de téléphone",
    email: "Courrier électronique",
    shareInventory: "Partager l'inventaire",
    purchasing: "Achats",
    currency: "Devise",
    paymentTerms: "Modalités de paiement",
    paymentMethod: "Mode de paiement",
    measurementSystem: "Système de mesure",
    incoTerms: "Incoterms",
    shippingDetails: "Détails d'expédition",
    shippingMode: "Mode d'expédition",
    shippingPort: "Port d'expédition",
    addressDetails: "Détails de l'adresse",
    addressLine1: "Ligne d'adresse 1",
    addressLine2: "Ligne d'adresse 2",
    zipCode: "Code postal",
    state: "État",
    country: "Pays",
    additionalContactDetails: "Informations de contact supplémentaires",
    businessDetails: "Informations sur l'entreprise",
    registeredBusinessName: "Nom de l'entreprise enregistrée",
    addAdditionalContact: "Ajouter un contact supplémentaire",
  },
  bundleHeader: {
    bundleNo: "Numéro de lot",
    slabInStock: "Dalles en stock",
    slabAvailable: "Dalles disponibles",
    slabOnHold: "Dalles en attente",
    image: "Image",
    bundle: "Forfait",
    block: "Bloquer",
    inStock: "En stock",
    onHold: "En attente",
    available: "Disponible",
    totalPrice: "Prix total",
    quality: "Qualité",
    finish: "Terminer",
    imageClickInstr: "Cliquez sur l'icône de l'image dans le tableau",
  },
  myProfile: {
    yourProfile: "Votre profil",
    fullname: "Nom complet",
    jobDetails: "Détails du poste",
    employeeNumber: "Numéro d'employé",
    joiningDate: "Date d'adhésion",
    department: "Département",
    jobTitle: "Intitulé du poste",
    accountDetails: "Détails du compte",
    userId: "ID utilisateur",
    contactDetails: "Informations de contact",
    businessPhNo: "Numéro de téléphone professionnel",
    secEmail: "Adresse e-mail secondaire",
    secAddr: "Adresse secondaire",
    generalPreference: "Préférences générales",
    preferedLanguage: "Langue préférée",
    timeZone: "Fuseau horaire",
    state: "État",
    country: "Pays",
  },
  companyProfile: {
    businessProfile: "Profil de l'entreprise",
    registeredBusinessName: "Nom de l'entreprise enregistrée",
    businessDetails: "Informations sur l'entreprise",
    businessPhNo: "Numéro de téléphone professionnel",
    registeredBusinessAddress: "Adresse commerciale enregistrée",
    contactDetails: "Informations de contact",
    administratorEmail: "E-mail de l'administrateur",
    administratorPhNo: "Numéro de téléphone de l'administrateur",
    timeNDate: "Heure et date",
    timeZone: "Fuseau horaire",
  },
  globalBtn: {
    edit: "Modifier",
    save: "Enregistrer",
    cancel: "Annuler",
    addToCart: "Ajouter au panier",
    removeFromCart: "Supprimer du panier",
    createOffer: "Créer une offre",
    createOfferOrSalesText: "Créer une offre/Créer une commande de vente",
    createSales: "Création d'une commande de vente",
    moveTo: "Déplacer vers",
    next: "Suivant",
    back: "Retour",
    close: "Fermer",
    yes: "Oui",
    no: "Non",
    confirmation: "Confirmation",
    success: "Succès",
    error: "Erreur",
    offerCreateFail:
      "Impossible de créer des ventes pour les articles en attente. Veuillez retirer les articles qui sont en attente.",
  },
  slabHeader: {
    slabNo: "Numéro de dalle",
    blockNo: "Numéro de bloc",
    grossHeight: "Hauteur brute",
    grossWidth: "Largeur brute",
    netHeight: "Hauteur nette",
    netWidth: "Largeur nette",
    finish: "Terminer",
    quality: "Qualité",
    price: "Prix",
    currencyCode: "Code de devise",
    thickness: "Epaisseur",
    thicknessUnit: "Unité d'épaisseur",
    images: "Des images",
    status: "État",
    notes: "Remarques",
    edit: "Modifier",
    netLength: "Longueur nette",
  },
  filters: {
    Thickness: "Epaisseur",
    Category: "Catégorie",
    Height: "Hauteur",
    Width: "Largeur",
    Finish: "Terminer",
    Price: "Prix",
    Quality: "Qualité",
    Colour: "Couleur",
    Country: "Pays",
    SalesPrice: "Prix de vente",
    Customer: "Client",
    Inspector: "Inspecteur",
    Department: "Département",
    JobTitle: "Intitulé du poste",
    Supplier: "Fournisseur",
    CustomerCountry: "Pays du client",
    CustomerType: "Type de client",
    City: "Ville",
    SupplierCountry: "Pays du fournisseur",
    SupplierCity: "Ville du fournisseur",
    OfferState: "État de l'offre",
  },
  homePage: {
    goodMorning: "Bonjour, ",
    goodEvening: "Bonsoir, ",
    goodAfternoon: "Bon après-midi, ",
    beYourself: "Sois toi-même !",
    inventoryAnalytics: "Analyse de l'inventaire",
    totalItemsInInventory: "Total des articles en stock",
  },
  help: {
    businessProfileAndUserManagement: "Profil professionnel et gestion des utilisateurs",
    addInventory: "Ajouter un inventaire",
    addAndEditProducts: "Ajouter et modifier des produits",
  },
  offers: {
    offerDeleteSuccess: "L'offre a été annulée avec succès",
    offerDeleteFailure: "Une erreur est survenue lors de la suppression de l'offre",
    viewDetails: "Afficher les détails de l'offre",
    copyLinkToClipboard: "Copier le lien de l'offre dans le presse-papiers",
    cancel: "Annuler l'offre",
    copiedLinkToClipboard: "Lien de l'offre copié dans le presse-papiers",
  },
}
export default frTranslations;
