import React, { useEffect, useState } from "react";
import InventoryImageFormStepOne from "./InventoryImageFormStepOne";
import InventoryImageFormStepThree from "./InventoryImageFormStepThree";
import InventoryImageFormStepTwo from "./InventoryImageFormStepTwo";

export default function InventoryImageForm({
  step,
  setStep,
  addImageTo,
  setAddImageTo,
  inventory,
  setBlockDisabled,
  updateFlag,
  images,
  setImages,
  setUpdateImages,
  ...props
}) {
  const [fileList, setFileList] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([]);
  useEffect(() => {}, [fileList, imageUrls]);
  // console.log(fileList)
  switch (step) {
    case 0:
      return (
        <InventoryImageFormStepOne
          step={step}
          setStep={setStep}
          addImageTo={addImageTo}
          fileList={fileList}
          setFileList={setFileList}
          imageUrls={imageUrls}
          setImageUrls={setImageUrls}
          uploadStatus={uploadStatus}
          setUploadStatus={setUploadStatus}
        />
      );
    case 1:
      return (
        <InventoryImageFormStepTwo
          step={step}
          setStep={setStep}
          addImageTo={addImageTo}
          fileList={fileList}
          setFileList={setFileList}
          imageUrls={imageUrls}
          setImageUrls={setImageUrls}
          uploadStatus={uploadStatus}
          setUploadStatus={setUploadStatus}
        />
      );
    default:
      return (
        <InventoryImageFormStepThree
          step={step}
          setStep={setStep}
          addImageTo={addImageTo}
          fileList={fileList}
          setFileList={setFileList}
          imageUrls={imageUrls}
          setImageUrls={setImageUrls}
          productId={inventory.product.name}
          setAddImageTo={setAddImageTo}
          setBlockDisabled={setBlockDisabled}
          uploadStatus={uploadStatus}
          setUploadStatus={setUploadStatus}
          updateFlag={updateFlag}
          images={images}
          setImages={setImages}
          setUpdateImages={setUpdateImages}
          setImageAddedCheck={props.setImageAddedCheck}
          imageAddedCheck={props.imageAddedCheck}
          blockDisabled={props.blockDisabled}
          addCartSuccess={props.addCartSuccess}
          setAddCartSuccess={props.setAddCartSuccess}
          mobileView={props.mobileView}
          togglePopup={props.togglePopup}
        />
      );
  }
}
