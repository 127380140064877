export default function SlabDetails({ slabDataList }) {
  return (
    <>
      {slabDataList.map((slabData) => (
        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: "10px",
            marginLeft: "10px",
            display: "inline-block",
            padding: "10px",
            width: "80%",
            marginTop: "20px",
          }}
          key={`${slabData.bundleNumber}_${slabData.slabNumber}`}
        >
          <div>
            <p>Product Name: {slabData.productId}</p>{" "}
            <p>Bundle Number: {slabData.bundleNumber}</p>{" "}
            <p>Slab Number: {slabData.slabNumber}</p>{" "}
            <p>
              Thickness: {slabData.thickness}
              {slabData.thicknessUnit}
            </p>{" "}
            <p>Net Height: {slabData.netHeight}cm</p>{" "}
            <p>Net Width: {slabData.netWidth}cm</p>
          </div>
        </div>
      ))}
    </>
  );
}
