import { getCountries } from "country-state-picker";

const countryObj1 = getCountries();
const countryArr = [];
const countryRef = {};

for (let i = 0; i < countryObj1.length; ++i) {
  countryArr.push(countryObj1[i].name);
  countryRef[countryObj1[i].name] = countryObj1[i].code;
}

const timeZone = [
  {
    value: "IST - Indian Standard Time",
    label: "IST - Indian Standard Time",
  },
  {
    value: "PDT - Pacific Daylight Time",
    label: "PDT - Pacific Daylight Time",
  },
];

const language = [
  {
    value: "English",
    label: "English",
  },
  {
    value: "Hindi",
    label: "Hindi",
  },
];

const state = [
  {
    value: "Delhi",
    label: "Delhi",
  },
  {
    value: "Karnataka",
    label: "Karnataka",
  },
  {
    value: "California",
    label: "California",
  },
];

const country = countryArr;

const languageCode = [
  {
    value: "en",
    label: "en - English",
  },
  {
    value: "es",
    label: "es - Spanish",
  },
  {
    value: "fr",
    label: "fr - French",
  },
  {
    value: "it",
    label: "it - Italian",
  },
  {
    value: "por",
    label: "por - Portuguese",
  },
];

const department = [
  { value: "Customer Service", label: "Customer Service" },
  { value: "Finance", label: "Finance" },
  { value: "Human Resources", label: "Human Resources" },
  { value: "Management", label: "Management" },
  { value: "Marketing", label: "Marketing" },
  { value: "Operations", label: "Operations" },
  { value: "Production", label: "Production" },
  { value: "Purchasing", label: "Purchasing" },
  { value: "Sales", label: "Sales" },
];

const jobTitle = [
  { value: "Owner", label: "Owner" },
  { value: "CEO", label: "CEO" },
  { value: "CFO", label: "CFO" },
  { value: "Sales Director", label: "Sales Director" },
  { value: "Sales Manager", label: "Sales Manager" },
  { value: "Sales Rep", label: "Sales Rep" },
  { value: "Sales Assistant", label: "Sales Assistant" },
  { value: "Production Manager", label: "Production Manager" },
  { value: "Production Assistant", label: "Production Assistant" },
  { value: "Assistant", label: "Assistant" },
  { value: "Purchasing Director", label: "Purchasing Director" },
  { value: "Purchasing Manager", label: "Purchasing Manager" },
  { value: "Purchasing Assistant", label: "Purchasing Assistant" },
  { value: "Inspector", label: "Inspector" },
  { value: "Operations Director", label: "Operations Director" },
  { value: "Operations Manager", label: "Operations Manager" },
  { value: "Operations Assistant", label: "Operations Assistant" },
  { value: "Customer Service Rep", label: "Customer Service Rep" },
  { value: "Customer Service Assistant", label: "Customer Service Assistant" },
  { value: "Finance Director", label: "Finance Director" },
];

const access = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "nonadmin",
    label: "Non-Admin",
  },
];

export const userProfileConstants = {
  timeZone,
  language,
  state,
  country,
  languageCode,
  department,
  jobTitle,
  access,
  countryRef,
};
