import { useTranslation } from "react-i18next";
import ManageMyProfile from "../../pages/ManageMyProfile/ManageMyProfile";

export const Widgets = () => {
  const { t } = useTranslation();
  return [
    {
      centered: true,
      tabItems: [
        {
          label: t("navbar.myProfile"),
          key: "MyProfile",
          children: <ManageMyProfile />,
        },
      ],
      type: "tab",
    },
  ];
};
