import { Card, Layout } from "antd";
import Meta from "antd/es/card/Meta";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { useTranslation } from "react-i18next";
import "./help.css";

export default function Help() {
  const { t } = useTranslation();
  return (
    <Layout
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Content>
        <h2 className="supportHeader" style={{ textAlign: "center" }}>
          {t("navbar.support")}
        </h2>
        <Content
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "2vh",
          }}
        >
          <Card
            hoverable
            style={{
              width: 370,
              height: "auto",
              marginRight: "2vw",
              backgroundColor: "#f8f9f3",
            }}
            cover={
              <iframe
                src="https://www.loom.com/embed/1d9e082108fe41a6b83f34ab29058eb5?sid=7922cf1f-e9e6-4ff3-b88c-afe7afabaeee"
                style={{ borderRadius: "7px" }}
                frameborder="0"
                allowfullscreen="true"
                height={250}
              ></iframe>
            }
          >
            <Meta title={t("help.businessProfileAndUserManagement")} />
          </Card>

          <Card
            hoverable
            style={{
              width: 370,
              height: "auto",
              marginRight: "2vw",
              backgroundColor: "#f8f9f3",
            }}
            cover={
              <iframe
                src="https://www.loom.com/embed/122b54c26d8a4394a7e0a57215306dbc?sid=636f8b42-07a2-410e-b289-bf099918780e"
                style={{ borderRadius: "7px" }}
                frameborder="0"
                allowfullscreen="true"
                height={250}
              ></iframe>
            }
          >
            <Meta title={t("help.addInventory")} />
          </Card>

          <Card
            hoverable
            style={{
              width: 370,
              height: "auto",
              backgroundColor: "#f8f9f3",
            }}
            cover={
              <iframe
                src="https://www.loom.com/embed/8b97c5824baf4a39952593391667e785?sid=cf5ee921-5dcf-43fa-9379-ce876a8f9481"
                style={{ borderRadius: "7px" }}
                frameborder="0"
                allowfullscreen="true"
                height={250}
              ></iframe>
            }
          >
            <Meta title={t("help.addAndEditProducts")} />
          </Card>
        </Content>
      </Content>
    </Layout>
  );
}
