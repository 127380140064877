import _ from "lodash";
import ProductInfo from "./ProductInfo";
import { removeSpaceFromProductName } from "./product.util";

export default function ProductInfoList({ productList }) {
  return (
    <>
      {/* Providing productName as key to each productInfo component
    and not using map index because there are chances that Array index can 
    change. This would cause React to re-render all the 
    elements instead of just updating the newly added item  */}

      {_.map(productList, (product) => (
        <ProductInfo
          info={product}
          key={removeSpaceFromProductName(product.productName)}
        />
      ))}
    </>
  );
}
