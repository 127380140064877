const esTranslations={
  navbar: {
    title: "Stonovación",
    inventory: "Inventario",
    products: "Productos",
    logout: "Cerrar sesión",
    sales: "Ventas",
    marketplace: "Marketplace",
    settings: "Ajustes",
    myProfile: "Mi perfil",
    cart: "Carrito",
    search: "Búsqueda",
    mystore: "Mi tienda",
    home: "Inicio",
    supplier: "Proveedor",
    suppliers: "Proveedores",
    help: "Ayuda",
    support: "Soporte",
    premium: "Actualiza a premium",
  },
  subNav: {
    liveInventory: "Inventario en vivo",
    products: "Productos",
    hold: "Sostenga",
    inspection: "Inspección",
    salesOrder: "Pedido de venta",
    shippedOrder: "Pedido enviado",
    cancelledOrder: "Pedido cancelado",
    customers: "Clientes",
    users: "Usuarios",
    CompanyProfile: "Perfil empresarial",
    store: "Tienda",
    myStore: "Mi tienda",
    offers: "Ofertas",
  },
  bundleInfo: {
    collapse: "Colapso",
    expand: "Expandir",
  },
  tableHeader: {
    slabNumber: "Número de losa",
    quality: "Calidad",
    thickness: "Grosor",
    width: "Anchura",
    length: "Longitud",
    notes: "Notas",
    customer: "Cliente",
    status: "Estado",
    finish: "Finalizar",
    blockNo: "Bloquear no",
    type: "Tipo",
    price: "Precio",
  },
  createBundle: {
    removeBundleTooltip: "Eliminar este paquete",
    removeSlabTooltip: "Eliminar esta losa",
    addASlab: "Añadir una losa",
    addSlabGrp: "Agregar grupo de losas",
  },
  actions: {
    closeBtn: "Cerrar",
    updateBtn: "Actualización",
    removeBtn: "Eliminar",
    removeHoldBtn: "Eliminar retención",
    addBtn: "Añadir",
    nextBtn: "Próxima",
    backBtn: "Atrás",
    createBtn: "Crear",
    resetBtn: "Reiniciar",
    cancelBtn: "Cancelar",
    filterBtn: "Filtrar",
  },
  aria: {
    labels: {
      openInFull: "abierto en su totalidad",
      reset: "restablecer filtro",
      addInventory: "Añadir artículo de inventario",
      search: "búsqueda",
      viewDetails: "Ver detalles",
      productList: "listas de artículos de productos",
      refresh: "refrescar",
      bundleTable: "Tabla de detalles del paquete",
    },
  },
  slab: {
    labels: {
      finish: "Finalizar",
      price: "Precio",
      quality: "Calidad",
      thickness: "Grosor",
      width: "Anchura",
      length: "Longitud",
      qty: "Cantidad",
      notes: "Notas",
      customerName: "Nombre del cliente",
      currency: "Moneda",
      unit: "Unidad",
      min: "Mín",
      max: "Máx",
      noOfSlabs: "Número de losas",
    },
    status: {
      available: "DISPONIBLE",
      hold: "MANTENER",
      sold: "VENDIDO",
      all: "TODOS",
    },
  },
  pageTitles: {
    PAGE_1: `Pick a product`,
    PAGE_2: `Block and Bundles`,
    PAGE_3: `Bundle Info`,
    PAGE_4: `Review`,
  },
  toast: {
    success: "{{type}} {{item}} correctamente",
    fail: "No se pudo {{type}} {{item}}",
    warn: "{{item}} creado correctamente. Añade más detalles",
    reachAdmin: "No se han podido obtener las listas de {{item}}. Ponte en contacto con el administrador",
    created: "creado",
    create: "crear",
    updated: "actualizado",
    update: "actualización",
    delete: "eliminar",
    deleted: "borrada",
    inventory: "inventario",
    product: "producto",
  },
  inventoryDetails: {
    changeStatus: "Cambiar estado",
    bundleNumber: "Número de paquete",
    inStock: "En stock",
    available: "Disponible",
    hold: "Sostenga",
    popUptitle: "Actualizar {{type}}",
    addInventory: "Añadir inventario",
    product: "Producto",
    filterPopupTitle: "Filtrar",
    searchLabel: "Buscar en el inventario",
    notes: "Notas",
    customer: "Cliente",
    onHold: "en espera",
  },
  inventoryForm: {
    searchProduct: "Busca un producto",
    category: "Categoría",
    colour: "Color",
    location: "Ubicación",
    material: "Forma material",
    addBundle: "Añadir un paquete",
    blockNumber: "Número de bloque",
    noOfBundles: "Cantidad de paquetes",
  },
  tooltip: {
    filter: "Filtrar",
    editProduct: "Editar este producto",
    deleteProduct: "Eliminar este producto",
    addSubproduct: "Añadir un nuevo subproducto",
    removeItem: "Eliminar este artículo",
    addNotes: "Añadir notas",
    removeFromHold: "Retirar de la retención",
    moveToHold: "Mover para retener",
    changeStatus: "Haga clic para cambiar",
    language: "Cambiar idioma",
    viewCustomer: "Haga clic para ver",
    viewProduct: "Ver producto",
    addCondition: "Añadir imágenes a todos los paquetes",
  },
  productContainer: {
    createProduct: "Crear producto",
    searchLabel: "Buscar producto",
    updateProduct: "Actualizar producto",
    createProductBtn: "Crear producto",
    name: "Nombre del producto",
  },
  searchBar: {
    search: "Buscar...",
  },
  inventoryHeaders: {
    products: "Productos",
    slabsInStock: "Losas en stock",
    slabsAvailable: "Losas disponibles",
    slabsOnHold: "Losas en espera",
  },
  cart: {
    products: "Productos",
    slabsInStock: "Losas en stock",
    slabsAvailable: "Losas disponibles",
    slabsOnHold: "Losas en espera",
    slabsUnavailable: "Losas no disponibles",
  },
  productHeaders: {
    product: "Producto",
    category: "Categoría",
    colour: "Color",
    location: "Ubicación",
    type: "Tipo",
    thickness: "Grosor",
    finish: "Finalizar",
    quality: "Calidad",
    price: "Precio",
    quantity: "Cantidad",
  },
  salesHeaders: {
    orderNumber: "Número de pedido",
    customer: "Cliente",
    products: "Productos",
    type: "Tipo",
    orderCreated: "Pedido creado",
    status: "Estado",
  },
  usersHeaders: {
    fullname: "Nombre completo",
    jobTitle: "Título del puesto",
    department: "Departamento",
    employeeNumber: "Número de empleado",
    joiningDate: "fecha de ingreso",
    userID: "ID de usuario",
    accessLevel: "Nivel de acceso",
    phoneNo: "Número de teléfono",
    secondaryAddress: "Dirección secundaria",
  },
  companyName: {
    companyName: "Nombre de la empresa",
    email: "Correo electrónico",
    contactNumber: "Número de contacto",
    timezone: "Zona horaria",
    city: "Ciudad",
    country: "País",
    save: "Guardar",
    cancel: "Cancelar",
    edit: "Editar",
  },
  customers: {
    customerProfile: "Perfil del cliente",
    companyName: "Nombre de la empresa",
    customerType: "Tipo de cliente",
    customerZone: "Zona de clientes",
    sinceDate: "Fecha desde",
    contactDetails: "Detalles de contacto",
    contactName: "Nombre de contacto",
    phoneNo: "Número de teléfono",
    email: "Correo electrónico",
    shareInventory: "Compartir inventario",
    purchasing: "Adquisición",
    currency: "Moneda",
    paymentTerms: "Condiciones de pago",
    paymentMethod: "Método de pago",
    measurementSystem: "Sistema de medición",
    incoTerms: "Incoterms",
    shippingDetails: "Detalles de envío",
    shippingMode: "Modo de envío",
    shippingPort: "Puerto de embarque",
    addressDetails: "Detalles de la dirección",
    addressLine1: "Línea de dirección 1",
    addressLine2: "Línea de dirección 2",
    zipCode: "Código postal",
    state: "Estado",
    country: "País",
    additionalContactDetails: "Detalles de contacto adicionales",
    businessDetails: "Detalles de la empresa",
    registeredBusinessName: "Nombre comercial registrado",
    addAdditionalContact: "Agregar contacto adicional",
  },
  bundleHeader: {
    bundleNo: "Número de paquete",
    slabInStock: "Losas en stock",
    slabAvailable: "Losas disponibles",
    slabOnHold: "Losas en espera",
    image: "Imagen",
    bundle: "Paquete",
    block: "Bloquear",
    inStock: "En stock",
    onHold: "En espera",
    available: "Disponible",
    totalPrice: "Precio total",
    quality: "Calidad",
    finish: "Finalizar",
    imageClickInstr: "Haga clic en el icono de la imagen en la tabla",
  },
  myProfile: {
    yourProfile: "Tu perfil",
    fullname: "Nombre completo",
    jobDetails: "Detalles del trabajo",
    employeeNumber: "Número de empleado",
    joiningDate: "Fecha de incorporación",
    department: "Departamento",
    jobTitle: "Título del puesto",
    accountDetails: "Detalles de la cuenta",
    userId: "ID de usuario",
    contactDetails: "Detalles de contacto",
    businessPhNo: "Número de teléfono de la empresa",
    secEmail: "Dirección de correo electrónico secundaria",
    secAddr: "Dirección secundaria",
    generalPreference: "Preferencias generales",
    preferedLanguage: "Idioma preferido",
    timeZone: "Zona horaria",
    state: "Estado",
    country: "País",
  },
  companyProfile: {
    businessProfile: "Perfil empresarial",
    registeredBusinessName: "Nombre comercial registrado",
    businessDetails: "Detalles de la empresa",
    businessPhNo: "Número de teléfono de la empresa",
    registeredBusinessAddress: "Dirección comercial registrada",
    contactDetails: "Detalles de contacto",
    administratorEmail: "Correo electrónico del administrador",
    administratorPhNo: "Número de teléfono del administrador",
    timeNDate: "Fecha y hora",
    timeZone: "Zona horaria",
  },
  globalBtn: {
    edit: "Editar",
    save: "Guardar",
    cancel: "Cancelar",
    addToCart: "Añadir al carrito",
    removeFromCart: "Eliminar del carrito",
    createOffer: "Crear oferta",
    createOfferOrSalesText: "Crear oferta/ Crear pedido de venta",
    createSales: "Crear pedido de venta",
    moveTo: "Trasladarse a",
    next: "Próxima",
    back: "Atrás",
    close: "Cerrar",
    yes: "Sí",
    no: "No",
    confirmation: "Confirmación",
    success: "Éxito",
    error: "Error",
    offerCreateFail:
      "No se pueden crear ventas de los artículos que están en espera. Elimine los artículos que están en espera.",
  },
  slabHeader: {
    slabNo: "Losa No.",
    blockNo: "Bloque No.",
    grossHeight: "Altura bruta",
    grossWidth: "Anchura bruta",
    netHeight: "Altura neta",
    netWidth: "Ancho neto",
    finish: "Finalizar",
    quality: "Calidad",
    price: "Precio",
    currencyCode: "Código de moneda",
    thickness: "Grosor",
    thicknessUnit: "Unidad de espesor",
    images: "Imágenes",
    status: "Estado",
    notes: "Notas",
    edit: "Editar",
    netLength: "Longitud neta",
  },
  filters: {
    Thickness: "Grosor",
    Category: "Categoría",
    Height: "Altura",
    Width: "Anchura",
    Finish: "Finalizar",
    Price: "Precio",
    Quality: "Calidad",
    Colour: "Color",
    Country: "País",
    SalesPrice: "Precio de venta",
    Customer: "Cliente",
    Inspector: "Inspector",
    Department: "Departamento",
    JobTitle: "Título del puesto",
    Supplier: "Proveedor",
    CustomerCountry: "País del cliente",
    CustomerType: "Tipo de cliente",
    City: "Ciudad",
    SupplierCountry: "País del proveedor",
    SupplierCity: "Ciudad del proveedor",
    OfferState: "Estado de la oferta",
  },
  homePage: {
    goodMorning: "Buenos días, ",
    goodEvening: "Buenas noches, ",
    goodAfternoon: "Buenas tardes, ",
    beYourself: "¡Sé tú mismo!",
    inventoryAnalytics: "Análisis de inventario",
    totalItemsInInventory: "Total de artículos en inventario",
  },
  help: {
    businessProfileAndUserManagement: "Perfil empresarial y gestión de usuarios",
    addInventory: "Añadir inventario",
    addAndEditProducts: "Añadir y editar productos",
  },
  offers: {
    offerDeleteSuccess: "La oferta se canceló correctamente",
    offerDeleteFailure: "Se ha producido un error al eliminar la oferta",
    viewDetails: "Ver detalles de la oferta",
    copyLinkToClipboard: "Copiar el enlace de la oferta al portapapeles",
    cancel: "Cancelar oferta",
    copiedLinkToClipboard: "Enlace de oferta copiado al portapapeles",
  },
}
export default esTranslations;
