const porTranslations = {
  navbar: {
    title: "Renovação de pedras",
    inventory: "Inventário",
    products: "Produtos",
    logout: "Sair",
    sales: "Vendas",
    marketplace: "Marketplace",
    settings: "Configurações",
    myProfile: "Meu perfil",
    cart: "Carrinho",
    search: "Pesquisar",
    mystore: "Minha loja",
    home: "Início",
    supplier: "Fornecedor",
    suppliers: "Fornecedores",
    help: "Socorro",
    support: "Suporte",
    premium: "Faça o upgrade para premium",
  },
  subNav: {
    liveInventory: "Inventário ao vivo",
    products: "Produtos",
    hold: "Espere",
    inspection: "Inspeção",
    salesOrder: "Pedido de venda",
    shippedOrder: "Pedido enviado",
    cancelledOrder: "Pedido cancelado",
    customers: "Clientes",
    users: "Usuários",
    CompanyProfile: "Perfil da empresa",
    store: "Armazenar",
    myStore: "Minha loja",
    offers: "Ofertas",
  },
  bundleInfo: {
    collapse: "Colapso",
    expand: "Expandir",
  },
  tableHeader: {
    slabNumber: "Número da laje",
    quality: "Qualidade",
    thickness: "Espessura",
    width: "Largura",
    length: "Comprimento",
    notes: "Notas",
    customer: "Cliente",
    status: "Status",
    finish: "Concluir",
    blockNo: "Bloquear Não",
    type: "Tipo",
    price: "Preço",
  },
  createBundle: {
    removeBundleTooltip: "Remover este pacote",
    removeSlabTooltip: "Remova esta placa",
    addASlab: "Adicionar uma laje",
    addSlabGrp: "Adicionar grupo de placas",
  },
  actions: {
    closeBtn: "Fechar",
    updateBtn: "Atualizar",
    removeBtn: "Remover",
    removeHoldBtn: "Remover retenção",
    addBtn: "Adicionar",
    nextBtn: "Próximo",
    backBtn: "Voltar",
    createBtn: "Criar",
    resetBtn: "Redefinir",
    cancelBtn: "Cancelar",
    filterBtn: "Filtro",
  },
  aria: {
    labels: {
      openInFull: "aberto na íntegra",
      reset: "filtro de reinicialização",
      addInventory: "Adicionar item de inventário",
      search: "procurar",
      viewDetails: "Exibir detalhes",
      productList: "listas de itens de produtos",
      refresh: "atualizar",
      bundleTable: "Tabela de detalhes do pacote",
    },
  },
  slab: {
    labels: {
      finish: "Concluir",
      price: "Preço",
      quality: "Qualidade",
      thickness: "Espessura",
      width: "Largura",
      length: "Comprimento",
      qty: "Quantidade",
      notes: "Notas",
      customerName: "Nome do cliente",
      currency: "Moeda",
      unit: "Unidade",
      min: "Min",
      max: "máximo",
      noOfSlabs: "Número de placas",
    },
    status: {
      available: "DISPONÍVEL",
      hold: "SEGURE",
      sold: "VENDIDA",
      all: "TUDO",
    },
  },
  pageTitles: {
    PAGE_1: `Pick a product`,
    PAGE_2: `Block and Bundles`,
    PAGE_3: `Bundle Info`,
    PAGE_4: `Review`,
  },
  toast: {
    success: "Com sucesso {{type}} {{item}}",
    fail: "Falha ao {{type}} {{item}}",
    warn: "{{item}} criado com sucesso. Adicione mais detalhes",
    reachAdmin:
      "Falha ao obter listas de {{item}}. Entre em contato com o administrador",
    created: "criada",
    create: "criar",
    updated: "atualizado",
    update: "atualização",
    delete: "deletar",
    deleted: "excluído",
    inventory: "inventário",
    product: "produto",
  },
  inventoryDetails: {
    changeStatus: "Alterar status",
    bundleNumber: "Número do pacote",
    inStock: "Em estoque",
    available: "Disponível",
    hold: "Espere",
    popUptitle: "Atualizar {{type}}",
    addInventory: "Adicionar inventário",
    product: "Produto",
    filterPopupTitle: "Filtro",
    searchLabel: "Pesquisar inventário",
    notes: "Notas",
    customer: "Cliente",
    onHold: "em espera",
  },
  inventoryForm: {
    searchProduct: "Pesquisar um produto",
    category: "Categoria",
    colour: "Cor",
    location: "Localização",
    material: "Formulário de material",
    addBundle: "Adicionar um pacote",
    blockNumber: "Número do bloco",
    noOfBundles: "Número de pacotes",
  },
  tooltip: {
    filter: "Filtro",
    editProduct: "Editar este produto",
    deleteProduct: "Excluir este produto",
    addSubproduct: "Adicionar um novo subproduto",
    removeItem: "Remover este item",
    addNotes: "Adicionar notas",
    removeFromHold: "Remover da espera",
    moveToHold: "Mover para segurar",
    changeStatus: "Clique para alterar",
    language: "Alterar idioma",
    viewCustomer: "Clique para ver",
    viewProduct: "Exibir produto",
    addCondition: "Adicione imagens a todos os pacotes",
  },
  productContainer: {
    createProduct: "Criar produto",
    searchLabel: "Pesquisar produto",
    updateProduct: "Atualizar produto",
    createProductBtn: "Criar produto",
    name: "Nome do produto",
  },
  searchBar: {
    search: "Pesquisar...",
  },
  inventoryHeaders: {
    products: "Produtos",
    slabsInStock: "Lajes em estoque",
    slabsAvailable: "Lajes disponíveis",
    slabsOnHold: "Lajes em espera",
  },
  cart: {
    products: "Produtos",
    slabsInStock: "Lajes em estoque",
    slabsAvailable: "Lajes disponíveis",
    slabsOnHold: "Lajes em espera",
    slabsUnavailable: "Lajes indisponíveis",
  },
  productHeaders: {
    product: "Produto",
    category: "Categoria",
    colour: "Cor",
    location: "Localização",
    type: "Tipo",
    thickness: "Espessura",
    finish: "Concluir",
    quality: "Qualidade",
    price: "Preço",
    quantity: "Quantidade",
  },
  salesHeaders: {
    orderNumber: "Número do pedido",
    customer: "Cliente",
    products: "Produtos",
    type: "Tipo",
    orderCreated: "Pedido criado",
    status: "Status",
  },
  usersHeaders: {
    fullname: "Nome completo",
    jobTitle: "Título do trabalho",
    department: "Departamento",
    employeeNumber: "Número do funcionário",
    joiningDate: "Data de adesão",
    userID: "ID de usuário",
    accessLevel: "Nível de acesso",
    phoneNo: "Número de telefone",
    secondaryAddress: "Endereço secundário",
  },
  companyName: {
    companyName: "Nome da empresa",
    email: "E-mail",
    contactNumber: "Número de contato",
    timezone: "Fuso horário",
    city: "Cidade",
    country: "País",
    save: "Salvar",
    cancel: "Cancelar",
    edit: "Editar",
  },
  customers: {
    customerProfile: "Perfil do cliente",
    companyName: "Nome da empresa",
    customerType: "Tipo de cliente",
    customerZone: "Zona do cliente",
    sinceDate: "Desde a data",
    contactDetails: "Detalhes de contato",
    contactName: "Nome do contato",
    phoneNo: "Número de telefone",
    email: "E-mail",
    shareInventory: "Compartilhar inventário",
    purchasing: "Comprando",
    currency: "Moeda",
    paymentTerms: "Condições de pagamento",
    paymentMethod: "Método de pagamento",
    measurementSystem: "Sistema de medição",
    incoTerms: "Incoterms",
    shippingDetails: "Detalhes de envio",
    shippingMode: "Modo de envio",
    shippingPort: "Porto de embarque",
    addressDetails: "Detalhes do endereço",
    addressLine1: "Linha de endereço 1",
    addressLine2: "Linha de endereço 2",
    zipCode: "Código postal",
    state: "Estado",
    country: "País",
    additionalContactDetails: "Detalhes adicionais de contato",
    businessDetails: "Detalhes do negócio",
    registeredBusinessName: "Nome comercial registrado",
    addAdditionalContact: "Adicionar contato adicional",
  },
  bundleHeader: {
    bundleNo: "Número do pacote",
    slabInStock: "Lajes em estoque",
    slabAvailable: "Lajes disponíveis",
    slabOnHold: "Lajes em espera",
    image: "Imagem",
    bundle: "Pacote",
    block: "Bloquear",
    inStock: "Em estoque",
    onHold: "Em espera",
    available: "Disponível",
    totalPrice: "Preço total",
    quality: "Qualidade",
    finish: "Concluir",
    imageClickInstr: "Clique no ícone da imagem na tabela",
  },
  myProfile: {
    yourProfile: "Seu perfil",
    fullname: "Nome completo",
    jobDetails: "Detalhes do trabalho",
    employeeNumber: "Número do funcionário",
    joiningDate: "Data de adesão",
    department: "Departamento",
    jobTitle: "Título do trabalho",
    accountDetails: "Detalhes da conta",
    userId: "ID de usuário",
    contactDetails: "Detalhes de contato",
    businessPhNo: "Número de telefone comercial",
    secEmail: "Endereço de e-mail secundário",
    secAddr: "Endereço secundário",
    generalPreference: "Preferências gerais",
    preferedLanguage: "Idioma preferido",
    timeZone: "Fuso horário",
    state: "Estado",
    country: "País",
  },
  companyProfile: {
    businessProfile: "Perfil de negócios",
    registeredBusinessName: "Nome comercial registrado",
    businessDetails: "Detalhes do negócio",
    businessPhNo: "Número de telefone comercial",
    registeredBusinessAddress: "Endereço comercial registrado",
    contactDetails: "Detalhes de contato",
    administratorEmail: "E-mail do administrador",
    administratorPhNo: "Número de telefone do administrador",
    timeNDate: "Hora e data",
    timeZone: "Fuso horário",
  },
  globalBtn: {
    edit: "Editar",
    save: "Salvar",
    cancel: "Cancelar",
    addToCart: "Adicionar ao carrinho",
    removeFromCart: "Remover do carrinho",
    createOffer: "Criar oferta",
    createOfferOrSalesText: "Criar oferta/Criar pedido de venda",
    createSales: "Criar pedido de venda",
    moveTo: "Mover para",
    next: "Próximo",
    back: "Voltar",
    close: "Fechar",
    yes: "sim",
    no: "Não",
    confirmation: "Confirmação",
    success: "Sucesso",
    error: "Erro",
    offerCreateFail:
      "Não é possível criar vendas dos itens que estão em espera. Remova os itens que estão em espera.",
  },
  slabHeader: {
    slabNo: "Laje No.",
    blockNo: "Bloco No.",
    grossHeight: "Altura bruta",
    grossWidth: "Largura bruta",
    netHeight: "Altura da rede",
    netWidth: "Largura líquida",
    finish: "Concluir",
    quality: "Qualidade",
    price: "Preço",
    currencyCode: "Código da moeda",
    thickness: "Espessura",
    thicknessUnit: "Unidade de espessura",
    images: "Imagens",
    status: "Status",
    notes: "Notas",
    edit: "Editar",
    netLength: "Comprimento da rede",
  },
  filters: {
    Thickness: "Espessura",
    Category: "Categoria",
    Height: "Altura",
    Width: "Largura",
    Finish: "Concluir",
    Price: "Preço",
    Quality: "Qualidade",
    Colour: "Cor",
    Country: "País",
    SalesPrice: "Preço de venda",
    Customer: "Cliente",
    Inspector: "Inspetor",
    Department: "Departamento",
    JobTitle: "Título do trabalho",
    Supplier: "Fornecedor",
    CustomerCountry: "País do cliente",
    CustomerType: "Tipo de cliente",
    City: "Cidade",
    SupplierCountry: "País do fornecedor",
    SupplierCity: "Cidade do fornecedor",
    OfferState: "Status da oferta",
  },
  homePage: {
    goodMorning: "Bom dia, ",
    goodEvening: "Boa noite, ",
    goodAfternoon: "Boa tarde, ",
    beYourself: "Seja você mesmo!",
    inventoryAnalytics: "Análise de inventário",
    totalItemsInInventory: "Total de itens no inventário",
  },
  help: {
    businessProfileAndUserManagement:
      "Perfil comercial e gerenciamento de usuários",
    addInventory: "Adicionar inventário",
    addAndEditProducts: "Adicionar e editar produtos",
  },
  offers: {
    offerDeleteSuccess: "Oferta cancelada com sucesso",
    offerDeleteFailure: "Ocorreu um erro ao excluir a oferta",
    viewDetails: "Exibir detalhes da oferta",
    copyLinkToClipboard: "Copie o link da oferta para a prancheta",
    cancel: "Cancelar oferta",
    copiedLinkToClipboard: "Link da oferta copiado para a prancheta",
  },
};

export default porTranslations;
