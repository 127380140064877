const subDomains = [
  "localhost",
  "release-test.stonovation.com",
  "release-test-seller.stonovation.com",
];

const isProd = () => {
  const hostname = window.location.hostname;
  return !subDomains.includes(hostname);
};

const Flags = {
  enablePrint: () => isProd(),
};

export { Flags };
