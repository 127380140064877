const enTranslations = {
  navbar: {
    title: "Stonovation",
    inventory: "Inventory",
    products: "Products",
    logout: "Logout",
    sales: "Sales",
    marketplace: "Marketplace",
    settings: "Settings",
    myProfile: "My Profile",
    cart: "Cart",
    search: "Search",
    mystore: "My Store",
    home: "Home",
    supplier: "Supplier",
    suppliers: "Suppliers",
    help: "Help",
    support: "Support",
    premium: "Upgrade to premium",
  },
  subNav: {
    liveInventory: "Live Inventory",
    products: "Products",
    hold: "Hold",
    inspection: "Inspection",
    salesOrder: "Sales Order",
    shippedOrder: "Shipped Order",
    cancelledOrder: "Cancelled Order",
    customers: "Customers",
    users: "Users",
    CompanyProfile: "Business Profile",
    store: "Store",
    myStore: "My Store",
    offers: "Offers",
    productInfo: "Product Info",
  },
  bundleInfo: {
    collapse: "Collapse",
    expand: "Expand",
  },
  tableHeader: {
    slabNumber: "Slab Number",
    quality: "Quality",
    thickness: "Thickness",
    width: "Width",
    length: "Length",
    notes: "Notes",
    customer: "Customer",
    status: "Status",
    finish: "Finish",
    blockNo: "Block No",
    type: "Type",
    price: "Price",
  },
  createBundle: {
    removeBundleTooltip: "Remove this Bundle",
    removeSlabTooltip: "Remove this Slab",
    addASlab: "Add a Slab",
    addSlabGrp: "Add Slab Group",
  },
  actions: {
    closeBtn: "Close",
    updateBtn: "Update",
    removeBtn: "Remove",
    removeHoldBtn: "Remove Hold",
    addBtn: "Add",
    nextBtn: "Next",
    backBtn: "Back",
    createBtn: "Create",
    resetBtn: "Reset",
    cancelBtn: "Cancel",
    filterBtn: "Filter",
  },
  aria: {
    labels: {
      openInFull: "open in full",
      reset: "reset filter",
      addInventory: "Add inventory item",
      search: "search",
      viewDetails: "View Details",
      productList: "product item lists",
      refresh: "refresh",
      bundleTable: "Bundle Details Table",
    },
  },
  slab: {
    labels: {
      finish: "Finish",
      price: "Price",
      quality: "Quality",
      thickness: "Thickness",
      width: "Width",
      length: "Length",
      qty: "Quantity",
      notes: "Notes",
      customerName: "Customer Name",
      currency: "Currency",
      unit: "Unit",
      min: "Min",
      max: "Max",
      noOfSlabs: "Number of Slabs",
    },
    status: {
      available: "AVAILABLE",
      hold: "HOLD",
      sold: "SOLD",
      all: "ALL",
    },
  },
  pageTitles: {
    PAGE_1: `Pick a product`,
    PAGE_2: `Block and Bundles`,
    PAGE_3: `Bundle Info`,
    PAGE_4: `Review`,
  },
  toast: {
    success: "Sucessfully {{type}} {{item}}",
    fail: "Failed to {{type}} {{item}}",
    warn: "Sucessfully created {{item}}, Please add more details",
    reachAdmin: "Failed to fetch {{item}} lists, please reach out to the Admin",
    created: "created",
    create: "create",
    updated: "updated",
    update: "update",
    delete: "delete",
    deleted: "deleted",
    inventory: "inventory",
    product: "product",
  },
  inventoryDetails: {
    changeStatus: "Change Status",
    bundleNumber: "Bundle Number",
    inStock: "In Stock",
    available: "Available",
    hold: "Hold",
    popUptitle: "Update {{type}}",
    addInventory: "Add inventory",
    product: "Product",
    filterPopupTitle: "Filter",
    searchLabel: "Search inventory",
    notes: "Notes",
    customer: "Customer",
    onHold: "on Hold",
  },
  inventoryForm: {
    searchProduct: "Search a product",
    category: "Category",
    colour: "Colour",
    location: "Location",
    material: "Material Form",
    addBundle: "Add a Bundle",
    blockNumber: "Block Number",
    noOfBundles: "Number of Bundles",
  },
  tooltip: {
    filter: "Filter",
    editProduct: "Edit this Product",
    deleteProduct: "Delete this Product",
    addSubproduct: "Add a new sub product",
    removeItem: "Remove this item",
    addNotes: "Add notes",
    removeFromHold: "Remove from Hold",
    moveToHold: "Move to Hold",
    changeStatus: "Click to change",
    language: "Change Language",
    viewCustomer: "Click to view",
    viewProduct: "View Product",
    addCondition: "Add images to all bundles",
  },
  productContainer: {
    createProduct: "Create Product",
    searchLabel: "Search Product",
    updateProduct: "Update Product",
    createProductBtn: "Create Product",
    name: "Product Name",
  },
  searchBar: {
    search: "Search...",
  },
  inventoryHeaders: {
    products: "Products",
    slabsInStock: "Slabs in Stock",
    slabsAvailable: "Slabs Available",
    slabsOnHold: "Slabs on hold",
  },
  cart: {
    products: "Products",
    slabsInStock: "Slabs in Stock",
    slabsAvailable: "Slabs Available",
    slabsOnHold: "Slabs on hold",
    slabsUnavailable: "Slabs Unavailable",
  },
  productHeaders: {
    product: "Product",
    category: "Category",
    colour: "Colour",
    location: "Location",
    type: "Type",
    thickness: "Thickness",
    finish: "Finish",
    quality: "Quality",
    price: "Price",
    quantity: "Quantity",
  },
  salesHeaders: {
    orderNumber: "Order Number",
    customer: "Customer",
    products: "Products",
    type: "Type",
    orderCreated: "Order Created",
    status: "Status",
  },
  usersHeaders: {
    fullname: "Fullname",
    jobTitle: "Job Title",
    department: "Department",
    employeeNumber: "Employee Number",
    joiningDate: "joining Date",
    userID: "User ID",
    accessLevel: "Access Level",
    phoneNo: "Phone Number",
    secondaryAddress: "Secondary Address",
  },
  companyName: {
    companyName: "Company Name",
    email: "Email",
    contactNumber: "Contact Number",
    timezone: "Timezone",
    city: "City",
    country: "Country",
    save: "Save",
    cancel: "Cancel",
    edit: "Edit",
  },
  customers: {
    customerProfile: "Customer Profile",
    companyName: "Company Name",
    customerType: "Customer Type",
    customerZone: "Customer Zone",
    sinceDate: "Since Date",
    contactDetails: "Contact Details",
    contactName: "Contact Name",
    phoneNo: "Phone Number",
    email: "Email",
    shareInventory: "Share Inventory",
    purchasing: "Purchasing",
    currency: "Currency",
    paymentTerms: "Payment Terms",
    paymentMethod: "Payment Method",
    measurementSystem: "Measurement System",
    incoTerms: "Incoterms",
    shippingDetails: "Shipping Details",
    shippingMode: "Shipping Mode",
    shippingPort: "Shipping Port",
    addressDetails: "Address Details",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    zipCode: "Zip Code",
    state: "State",
    country: "Country",
    additionalContactDetails: "Additional Contact Details",
    businessDetails: "Business Details",
    registeredBusinessName: "Registered Business Name",
    addAdditionalContact: "Add Additional Contact",
  },
  bundleHeader: {
    bundleNo1: "Bundle No.",
    bundleNo: "Bundle Number",
    slabInStock: "Slabs In Stock",
    slabAvailable: "Slabs Available",
    slabOnHold: "Slabs On Hold",
    image: "Image",
    bundle: "Bundle",
    block: "Block",
    inStock: "In Stock",
    onHold: "OnHold",
    available: "Available",
    totalPrice: "Total Price",
    quality: "Quality",
    finish: "Finish",
    imageClickInstr: "Click on image icon in table",
    addImage: "Add Image",
    printSlabDetails: "Print Slab Details",
  },
  myProfile: {
    yourProfile: "Your Profile",
    fullname: "Full Name",
    jobDetails: "Job Details",
    employeeNumber: "Employee Number",
    joiningDate: "Joining Date",
    department: "Department",
    jobTitle: "Job Title",
    accountDetails: "Account Details",
    userId: "User ID",
    contactDetails: "Contact Details",
    businessPhNo: "Bussiness Phone Number",
    secEmail: "Secondary Email Address",
    secAddr: "Secondary Address",
    generalPreference: "General Preferences",
    preferedLanguage: "Preferred Language",
    timeZone: "Time Zone",
    state: "State",
    country: "Country",
  },
  companyProfile: {
    businessProfile: "Bussiness Profile",
    registeredBusinessName: "Registered Business Name",
    businessDetails: "Business Details",
    businessPhNo: "Business Phone Number",
    registeredBusinessAddress: "Registered Business Address",
    contactDetails: "Contact Details",
    administratorEmail: "Administrator Email",
    administratorPhNo: "Administrator Phone Number",
    timeNDate: "Time & Date",
    timeZone: "Time Zone",
  },
  globalBtn: {
    edit: "Edit",
    save: "Save",
    cancel: "Cancel",
    addToCart: "Add to Cart",
    removeFromCart: "Remove from Cart",
    createOffer: "Create offer",
    createOfferOrSalesText: "Create offer/ Create sales order",
    createSales: "Create sales order",
    moveTo: "Move to",
    next: "Next",
    back: "Back",
    close: "Close",
    yes: "Yes",
    no: "No",
    confirmation: "Confirmation",
    success: "Success",
    error: "Error",
    offerCreateFail:
      "Cannot create sales of the items which are on hold. Please remove the items that are on hold.",
    details: "Details",
    noResults: "No results found",
    loading: "Loading",
  },
  slabHeader: {
    slabNo: "Slab No.",
    blockNo: "Block No.",
    grossHeight: "Gross Height",
    grossWidth: "Gross Width",
    netHeight: "Net Height",
    netWidth: "Net Width",
    finish: "Finish",
    quality: "Quality",
    price: "Price/Sqft",
    currencyCode: "Currency Code",
    thickness: "Thickness",
    thicknessUnit: "Thickness Unit",
    images: "Images",
    status: "Status",
    notes: "Notes",
    edit: "Edit",
    netLength: "Net Length",
    more: "More",
    slab: "Slab",
    currency: "Currency",
    unit: "Unit",
    print: "Print",
    totalPrice: "Total Price",
  },
  filters: {
    filters: "Filters",
    Thickness: "Thickness",
    Category: "Category",
    Height: "Height",
    Width: "Width",
    Finish: "Finish",
    Price: "Price",
    Quality: "Quality",
    Colour: "Colour",
    Country: "Country",
    SalesPrice: "Sales Price",
    Customer: "Customer",
    Inspector: "Inspector",
    Department: "Department",
    JobTitle: "Job Title",
    Supplier: "Supplier",
    CustomerCountry: "Customer Country",
    CustomerType: "Customer Type",
    City: "City",
    SupplierCountry: "Supplier Country",
    SupplierCity: "Supplier City",
    OfferState: "Offer status",
  },
  homePage: {
    goodMorning: "Good Morning, ",
    goodEvening: "Good Evening, ",
    goodAfternoon: "Good Afternoon, ",
    beYourself: "Be Yourself!",
    inventoryAnalytics: "Inventory Analytics",
    totalItemsInInventory: "Total Items In Inventory",
  },
  help: {
    businessProfileAndUserManagement: "Business Profile and User Management",
    addInventory: "Add Inventory",
    addAndEditProducts: "Add and Edit Products",
  },
  offers: {
    offerDeleteSuccess: "Offer cancelled successfully",
    offerDeleteFailure: "Error occurred while deleting offer",
    viewDetails: "View offer details",
    copyLinkToClipboard: "Copy offer link to clipboard",
    cancel: "Cancel offer",
    copiedLinkToClipboard: "Copied offer link to clipboard",
  },
};

export default enTranslations;
