import axios from "axios";
import {
  USER_ROUTE,
  getEndPointFor,
  getHeaders,
} from "../../utils/api_endpoints";

// let profile = [
//   {
//     id: "234567890",
//     fullname: "Praneeth",
//     organization: "Stonovation",
//     jobtitle: "Frontend Engineer Intern",
//     dept: "Software",
//     userID: "praneeth@stonovation.com",
//     phoneNo: "+91 7435024089",
//     timeZone: "IST - Indian Standard Time",
//     secEmail: "praneeth@gmail.com",
//     lang: "English",
//     employeeNumber: "1234567",
//     addr1: "vsygcsjd",
//     addr2: "fasxasnxa",
//     joiningDate: "2015-01-11",
//     zip: "23456",
//     state: "Delhi",
//     country: "India",
//   },
//   {
//     id: "234567891",
//     fullname: "Praneeth",
//     organization: "Stonovation",
//     jobtitle: "Frontend Engineer Intern",
//     dept: "Software",
//     userID: "praneeth@stonovation.com",
//     phoneNo: "+91 7435024089",
//     timeZone: "IST - Indian Standard Time",
//     secEmail: "praneeth@gmail.com",
//     lang: "English",
//     employeeNumber: "1234567",
//     addr1: "vsygcsjd",
//     addr2: "fasxasnxa",
//     joiningDate: "2015-01-11",
//     zip: "23456",
//     state: "Delhi",
//     country: "India",
//   },
//   {
//     id: "234567892",
//     fullname: "Praneeth",
//     organization: "Stonovation",
//     jobtitle: "Frontend Engineer Intern",
//     dept: "Software",
//     userID: "praneeth@stonovation.com",
//     phoneNo: "+91 7435024089",
//     timeZone: "IST - Indian Standard Time",
//     secEmail: "praneeth@gmail.com",
//     lang: "English",
//     employeeNumber: "1234567",
//     addr1: "vsygcsjd",
//     addr2: "fasxasnxa",
//     joiningDate: "2015-01-11",
//     zip: "23456",
//     state: "Delhi",
//     country: "India",
//   },
//   {
//     id: "234567893",
//     fullname: "Praneeth",
//     organization: "Stonovation",
//     jobtitle: "Frontend Engineer Intern",
//     dept: "Software",
//     userID: "praneeth@stonovation.com",
//     phoneNo: "+91 7435024089",
//     timeZone: "IST - Indian Standard Time",
//     secEmail: "praneeth@gmail.com",
//     lang: "English",
//     employeeNumber: "1234567",
//     addr1: "vsygcsjd",
//     addr2: "fasxasnxa",
//     joiningDate: "2015-01-11",
//     zip: "23456",
//     state: "Delhi",
//     country: "India",
//   },
//   {
//     id: "234567894",
//     fullname: "Praneeth",
//     organization: "Stonovation",
//     jobtitle: "Frontend Engineer Intern",
//     dept: "Software",
//     userID: "praneeth@stonovation.com",
//     phoneNo: "+91 7435024089",
//     timeZone: "IST - Indian Standard Time",
//     secEmail: "praneeth@gmail.com",
//     lang: "English",
//     employeeNumber: "1234567",
//     addr1: "vsygcsjd",
//     addr2: "fasxasnxa",
//     joiningDate: "2015-01-11",
//     zip: "23456",
//     state: "Delhi",
//     country: "India",
//   },
//   {
//     id: "234567895",
//     fullname: "Praneeth",
//     organization: "Stonovation",
//     jobtitle: "Frontend Engineer Intern",
//     dept: "Software",
//     userID: "praneeth@stonovation.com",
//     phoneNo: "+91 7435024089",
//     timeZone: "IST - Indian Standard Time",
//     secEmail: "praneeth@gmail.com",
//     lang: "English",
//     employeeNumber: "1234567",
//     addr1: "vsygcsjd",
//     addr2: "fasxasnxa",
//     joiningDate: "2015-01-11",
//     zip: "23456",
//     state: "Delhi",
//     country: "India",
//   },
// ];

export const getUserProfile = async ({ queryKey }) => {
  const template = queryKey[1];
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(USER_ROUTE.list)}`, template, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const addUser = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(USER_ROUTE.base)}`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const deleteUser = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .delete(`${getEndPointFor(USER_ROUTE.base)}`, {
      headers: headers,
      data: object,
    })
    .then((response) => response.data);
  return response;
};

export const editProfile = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .put(`${getEndPointFor(USER_ROUTE.base)}`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};
