import { Button, ButtonGroup, Grid, TextField } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { inventoryConstants } from "../utils/inventory";
const { getEmptyBundle } = inventoryConstants;

// const useStyle = makeStyles((theme) => ({
//   buttonSeparate: {
//     margin: theme.spacing(2),
//   },
// }));

function InventoryFormStepTwo({ goToPage, inventory, setInventory }) {
  const [bundleNumber, setBundleNumber] = useState(inventory.bundleNumber);
  const [blockNumber, setBlockNumber] = useState(inventory.blockNumber);
  // const classes = useStyle();
  const [errorTracker, setErrorTracker] = useState({});
  const { t } = useTranslation();
  // console.log(inventory)
  const isValidForm = () => {
    const errors = {
      blockNumber: !!blockNumber,
      bundleNumber: !!bundleNumber,
    };
    setErrorTracker(errors);
    return Object.values(errors).every((b) => !!b === true);
  };

  const updateError = (name, value) => {
    let ref = { ...errorTracker };
    ref[name] = !!value;
    setErrorTracker(ref);
  };

  return (
    <>
      <Grid
        container
        sx={{
          "& .MuiTextField-root": { m: 1, width: "80%" },
          marginTop: "2vh",
          marginBottom: "2vh",
        }}
      >
        <Grid item xs={6}>
          <TextField
            label={t("inventoryForm.blockNumber")}
            value={blockNumber}
            name="BlockNumber"
            error={!!errorTracker.blockNumber === false}
            onChange={({ target }) => {
              updateError("blockNumber", target.value);
              setBlockNumber(target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t("inventoryForm.noOfBundles")}
            value={bundleNumber}
            name="bundleNumber"
            error={!!errorTracker.bundleNumber === false}
            type="number"
            onChange={({ target }) => {
              updateError("bundleNumber", target.value);
              setBundleNumber(target.value);
            }}
          />
        </Grid>
      </Grid>
      <Content
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          // className={classes.buttonSeparate}
          className="nextButton"
          sx={{
            marginTop: "1vh",
            padding: "1vh 3vw",
            borderRadius: "100px",
            background: "var(--primary-blue-500, #0C25F3)",
          }}
          type="Back"
          onClick={() => {
            goToPage(0);
          }}
        >
          {t("actions.backBtn")}
        </Button>
        {bundleNumber.length !== 0 ? (
          <Button
            variant="contained"
            // className={classes.buttonSeparate}
            className="nextButton"
            sx={{
              marginTop: "1vh",
              padding: "1vh 3vw",
              borderRadius: "100px",
              background: "var(--primary-blue-500, #0C25F3)",
            }}
            type="Next"
            onClick={() => {
              if (!isValidForm()) {
                return;
              }
              const createBundles = (bundleNumber) => {
                bundleNumber = +bundleNumber;
                let tempArray = [];
                for (let i = 0; i < bundleNumber; i++) {
                  tempArray.push(getEmptyBundle(inventory));
                }
                return tempArray;
              };
              let currentBundles = inventory.bundles
                ? [...inventory.bundles]
                : [];
              let newBundleNumber = currentBundles.length
                ? bundleNumber - currentBundles.length
                : bundleNumber;
              let emptyBundles = createBundles(newBundleNumber);
              let bundles =
                currentBundles.length > bundleNumber
                  ? currentBundles.slice(0, bundleNumber)
                  : [...currentBundles, ...emptyBundles];
              setInventory({
                ...inventory,
                bundleNumber,
                blockNumber,
                bundles,
              });
              goToPage(2);
            }}
          >
            {t("actions.nextBtn")}
          </Button>
        ) : (
          <Button
            variant="contained"
            // className={classes.buttonSeparate}
            className="nextButton"
            sx={{
              marginTop: "1vh",
              padding: "1vh 3vw",
              borderRadius: "100px",
              background: "var(--primary-blue-500, #0C25F3)",
            }}
            type="Next"
            onClick={() => {
              if (!isValidForm()) {
                return;
              }
              const createBundles = (bundleNumber) => {
                bundleNumber = +bundleNumber;
                let tempArray = [];
                for (let i = 0; i < bundleNumber; i++) {
                  tempArray.push(getEmptyBundle(inventory));
                }
                return tempArray;
              };
              let currentBundles = inventory.bundles
                ? [...inventory.bundles]
                : [];
              let newBundleNumber = currentBundles.length
                ? bundleNumber - currentBundles.length
                : bundleNumber;
              let emptyBundles = createBundles(newBundleNumber);
              let bundles =
                currentBundles.length > bundleNumber
                  ? currentBundles.slice(0, bundleNumber)
                  : [...currentBundles, ...emptyBundles];
              setInventory({
                ...inventory,
                bundleNumber,
                blockNumber,
                bundles,
              });
              goToPage(2);
            }}
            disabled
          >
            {t("actions.nextBtn")}
          </Button>
        )}
      </Content>
    </>
  );
}

export default InventoryFormStepTwo;
