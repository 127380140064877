import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box, Drawer, IconButton } from "@mui/material";
import { Layout, Skeleton } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFetchResults } from "../../../queries/react-query/search";
import MobileFilters from "../MobileFilters";
import SearchInventory from "./SearchInventory";
import "./search.css";
const drawerWidth = 240;

export default function MobileMainLayout() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const [filterObject, setFilterObject] = useState({});
  const [presentColorIndex, setPresentColorIndex] = useState(0);
  const searchQueryParam = searchParams.get("searchText");
  const searchModuleParam = searchParams.get("module");
  const [mobileOpen, setMobileOpen] = useState(false);
  const colors = [
    "#008018",
    "#F59E0B",
    "#d10067",
    "#db2a12",
    "#0078ed",
    "#dede02",
  ];
  useEffect(() => {}, [
    searchQueryParam,
    searchModuleParam,
    filterObject,
    presentColorIndex,
  ]);
  const searchResults = useFetchResults([
    "ISearch",
    {
      module: searchModuleParam,
      searchText: searchQueryParam,
      filterContext: filterObject,
    },
  ]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const dynamicFilters = (inputJSON) => {
    const dFilters = Object.keys(inputJSON).map((key) => ({
      key,
      type: "checkbox",
      value: Object.keys(inputJSON[key]).map((paramName) => ({
        paramName,
        cnt: inputJSON[key][paramName],
      })),
    }));
    return dFilters;
  };
  const drawer = searchResults.isLoading ? (
    <div style={{ width: "100%", margin: "2vh 2vw" }}>
      <Skeleton active />
    </div>
  ) : (
    <div>
      <MobileFilters
        dynamic={true}
        queryClient={queryClient}
        Filters={dynamicFilters(searchResults.data.aggregations)}
        setFilterObject={setFilterObject}
        filterObject={filterObject}
      />
    </div>
  );
  return (
    <Layout style={{ paddingTop: "2vh" }}>
      <Content
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "4vw",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <FilterAltIcon style={{ marginBottom: "2vh", color: "#0c25f3" }} />
          </IconButton>
          <div
            style={{
              width: "100%",
              paddingBottom: "2vh",
              paddingRight: "2vw",
              paddingLeft: "1vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ margin: 0, width: "10%" }}>in : </p>
            <div
              className="searchNav"
              style={{
                width: "97%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div
                onClick={() => {
                  searchParams.set("module", "INVENTORY");
                  searchParams.set("searchText", searchQueryParam);
                  const searchString = searchParams.toString();
                  setFilterObject({});
                  navigate(`/search?${searchString}`);
                  // queryClient.refetchQueries(['search'])
                }}
                className={
                  searchModuleParam === "INVENTORY"
                    ? "searchNavTextActive"
                    : "searchNavText"
                }
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textAlign: "center",
                  padding: "2vh 0",
                }}
              >
                {`${t(`navbar.inventory`)}`}
              </div>
            </div>
          </div>
        </div>
        {searchResults.isLoading ? (
          <Skeleton active />
        ) : searchModuleParam === "INVENTORY" &&
          localStorage.getItem("isInventoryEnabled") !== "false" ? (
          <SearchInventory
            searchResult={searchResults}
            filterObject={filterObject}
          />
        ) : (
          <></>
        )}
      </Content>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Layout>
  );
}
