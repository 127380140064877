import { UserOutlined } from "@ant-design/icons";
import { Autocomplete, TextField } from "@mui/material";
import { Breadcrumb, Button, Skeleton, Space, Table } from "antd";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  useCreateSales,
  useGetSummaryForOffer,
} from "../../queries/react-query/cart";
import { useGetCustomers } from "../../queries/react-query/customers";
import { useGetProfile } from "../../queries/react-query/userProfile";
import "./offer.css";

export default function CreateSalesOrder(props) {
  const [customerName, setCustomerName] = useState("");
  const queryClient = useQueryClient();
  const [inspectorName, setInspectorName] = useState("");
  const navigate = useNavigate();
  const summaryList = useGetSummaryForOffer(["summary"]);
  const [createLoading, setCreateLoading] = useState(false);
  const onSuccessMutation = () => {
    setTimeout(() => {
      queryClient.refetchQueries("cart");
      queryClient.refetchQueries("HSales");
      setCreateLoading(false);
      navigate({
        pathname: "/sales",
        search: createSearchParams({ tab: "hold" }).toString(),
      });
    }, 4000);
  };

  const createSalesError = (err) => {
    props.setErrMsg(err);
    props.setSalesClicked(false);
    props.setPopup(true);
    setTimeout(() => {
      props.setPopup(false);
      props.setErrMsg("");
    }, [4000]);
  };

  const createSales = useCreateSales(
    ["createSales"],
    onSuccessMutation,
    createSalesError
  );
  const listCustomers = useGetCustomers([
    "customers",
    {
      from: 0,
      size: 100,
    },
  ]);

  const listUsers = useGetProfile([
    "users",
    {
      from: 0,
      size: 100,
    },
  ]);
  const createSalesFn = () => {
    if (customerName === "" || inspectorName === "") {
      alert("Please fill the required fields!!");
    } else {
      setCreateLoading(true);
      createSales.mutate({
        customerId: customerName,
        inspector: inspectorName,
      });
    }
  };

  const flatProps = {
    options: listCustomers.data
      ? listCustomers.data.customerList.map((option) => option.customerName)
      : [],
  };

  const flatPropsInspector = {
    options: listUsers.data
      ? listUsers.data.userList.map((option) => option.fullName)
      : [],
  };
  const items = [
    {
      title: (
        <p style={{ margin: "0" }}>
          Products{" "}
          {summaryList.isLoading
            ? 0
            : summaryList.data.summary.productSummaryList.length}
        </p>
      ),
    },
    {
      title: (
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <UserOutlined style={{ color: "black", margin: 0 }} />
          <p style={{ margin: 0 }}>Customer</p>
          <Autocomplete
            {...flatProps}
            id="combo-box-demo"
            onChange={(event, newValue) => {
              setCustomerName(newValue ? newValue : "Select");
            }}
            sx={{ width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <p style={customerName === "" ? { color: "red" } : {}}>
                    Customer
                  </p>
                }
              />
            )}
          />
        </Space>
      ),
    },
    {
      title: (
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <UserOutlined style={{ color: "black", margin: 0 }} />
          <p style={{ margin: 0 }}>Inspector</p>
          <Autocomplete
            {...flatPropsInspector}
            id="combo-box-demo"
            onChange={(event, newValue) => {
              setInspectorName(newValue ? newValue : "Select");
            }}
            sx={{ width: 200, padding: "0" }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ padding: "0" }}
                label={
                  <p style={inspectorName === "" ? { color: "red" } : {}}>
                    Inspector
                  </p>
                }
              />
            )}
          />
        </Space>
      ),
    },
  ];

  const columns = [
    {
      title: "Product",
      dataIndex: "productName",
    },
    {
      title: "Thickness",
      dataIndex: "thickness",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
  ];

  return summaryList.isLoading ||
    listCustomers.isLoading ||
    listUsers.isLoading ? (
    <Skeleton active />
  ) : (
    <div style={{ width: "100%", padding: "2vh 2vw" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "70vw" }}>
          <h3 className="drawerTitle">Summary</h3>
          <br />
          <Breadcrumb items={items} className="drawerTitle1" />
        </div>
      </div>

      <Table
        style={{ width: "100%", marginTop: "2vh", borderRadius: "7px" }}
        className="offerTable"
        columns={columns}
        dataSource={
          summaryList.data.summary
            ? summaryList.data.summary.productSummaryList !== undefined
              ? summaryList.data.summary.productSummaryList
              : []
            : []
        }
        pagination={false}
        rowKey="productName"
        scroll={{
          y: 240,
        }}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row
                style={{ color: "black", backgroundColor: "#D6E7FF" }}
              >
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  {summaryList.data.summary
                    ? summaryList.data.summary.totalQuantity
                      ? summaryList.data.summary.totalQuantity
                      : 0
                    : 0}{" "}
                  slabs
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {summaryList.data.summary
                    ? summaryList.data.summary.totalPrice
                      ? summaryList.data.summary.totalPrice
                      : 0
                    : 0}{" "}
                  {summaryList.data.summary
                    ? summaryList.data.summary.totalPriceCurrencyCode
                      ? summaryList.data.summary.totalPriceCurrencyCode
                      : "USD"
                    : "USD"}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <div
        style={{
          width: "100%",
          textAlign: "center",
          paddingTop: "4vh",
        }}
      >
        <Button
          type="primary"
          className="nextButton"
          shape="round"
          style={{
            backgroundColor: "#D6E7FF",
            fontFamily: "Open Sans",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "19px",
            letterSpacing: "0em",
            color: "#0066FF",
          }}
          onClick={createSalesFn}
          loading={createLoading}
        >
          Create sales order
        </Button>
        <Button
          type="primary"
          shape="round"
          className="nextButton"
          style={{
            backgroundColor: "#ffc4c4",
            fontFamily: "Open Sans",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "19px",
            letterSpacing: "0em",
            color: "red",
            marginLeft: "2vw",
          }}
          onClick={() => {
            props.setSalesClicked(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
