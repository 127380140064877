import { Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./navbar.module.scss";

const { Title } = Typography;

export default function Navbar() {
  const navigateTo = useNavigate();

  return (
    <div
      style={{
        padding: 0,
        backgroundColor: "white",
      }}
    >
      <Row align="left" className={styles.navBar}>
        <Title
          level={3}
          className="mainHeading"
          style={{ cursor: "pointer" }}
          onClick={() => navigateTo("/")}
        >
          STONOVATION
        </Title>
      </Row>
    </div>
  );
}
