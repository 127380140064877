const itTranslations={
  navbar: {
    title: "Stonovation",
    inventory: "Inventario",
    products: "Prodotti",
    logout: "Esci",
    sales: "Vendite",
    marketplace: "Marketplace",
    settings: "Impostazioni",
    myProfile: "Il mio profilo",
    cart: "Carrello",
    search: "Cerca",
    mystore: "Il mio negozio",
    home: "Home",
    supplier: "Fornitore",
    suppliers: "Fornitori",
    help: "Aiuto",
    support: "Supporto",
    premium: "Esegui l'upgrade a premium",
  },
  subNav: {
    liveInventory: "Inventario live",
    products: "Prodotti",
    hold: "Tieni",
    inspection: "Ispezione",
    salesOrder: "Ordine di vendita",
    shippedOrder: "Ordine spedito",
    cancelledOrder: "Ordine annullato",
    customers: "Clienti",
    users: "Utenti",
    CompanyProfile: "Profilo aziendale",
    store: "Negozio",
    myStore: "Il mio negozio",
    offers: "Offerte",
  },
  bundleInfo: {
    collapse: "Comprimi",
    expand: "Espandi",
  },
  tableHeader: {
    slabNumber: "Numero lastra",
    quality: "Qualità",
    thickness: "Spessore",
    width: "Larghezza",
    length: "Lunghezza",
    notes: "Note",
    customer: "Cliente",
    status: "Status",
    finish: "Finitura",
    blockNo: "Blocco n.",
    type: "Tipo",
    price: "Prezzo",
  },
  createBundle: {
    removeBundleTooltip: "Rimuovi questo pacchetto",
    removeSlabTooltip: "Rimuovi questa lastra",
    addASlab: "Aggiungi una lastra",
    addSlabGrp: "Aggiungi Slab Group",
  },
  actions: {
    closeBtn: "Chiudi",
    updateBtn: "Aggiornamento",
    removeBtn: "Rimuovi",
    removeHoldBtn: "Rimuovi blocco",
    addBtn: "Inserisci",
    nextBtn: "Prossimo",
    backBtn: "Indietro",
    createBtn: "Crea",
    resetBtn: "Reimposta",
    cancelBtn: "Annulla",
    filterBtn: "Filtro",
  },
  aria: {
    labels: {
      openInFull: "apri per intero",
      reset: "reimposta filtro",
      addInventory: "Aggiungi articolo di inventario",
      search: "ricerca",
      viewDetails: "Visualizza dettagli",
      productList: "elenchi di articoli di prodotto",
      refresh: "aggiorna",
      bundleTable: "Tabella dei dettagli del pacchetto",
    },
  },
  slab: {
    labels: {
      finish: "Finitura",
      price: "Prezzo",
      quality: "Qualità",
      thickness: "Spessore",
      width: "Larghezza",
      length: "Lunghezza",
      qty: "Quantità",
      notes: "Note",
      customerName: "Nome cliente",
      currency: "Valuta",
      unit: "Unità",
      min: "Min",
      max: "Max",
      noOfSlabs: "Numero di lastre",
    },
    status: {
      available: "DISPONIBILE",
      hold: "TENERE",
      sold: "VENDUTO",
      all: "TUTTI",
    },
  },
  pageTitles: {
    PAGE_1: `Pick a product`,
    PAGE_2: `Block and Bundles`,
    PAGE_3: `Bundle Info`,
    PAGE_4: `Review`,
  },
  toast: {
    success: "{{type}} {{item}} con successo",
    fail: "Impossibile eseguire {{type}} {{item}}",
    warn: "{{item}} creato con successo, aggiungi ulteriori dettagli",
    reachAdmin: "Impossibile recuperare gli elenchi {{item}}, contatta l'amministratore",
    created: "creato",
    create: "creare",
    updated: "aggiornato",
    update: "aggiornare",
    delete: "eliminare",
    deleted: "cancellato",
    inventory: "inventario",
    product: "prodotto",
  },
  inventoryDetails: {
    changeStatus: "Cambia stato",
    bundleNumber: "Numero del pacchetto",
    inStock: "In magazzino",
    available: "Disponibile",
    hold: "Tieni",
    popUptitle: "Aggiorna {{type}}",
    addInventory: "Aggiungi inventario",
    product: "Prodotto",
    filterPopupTitle: "Filtro",
    searchLabel: "Cerca nell'inventario",
    notes: "Note",
    customer: "Cliente",
    onHold: "in attesa",
  },
  inventoryForm: {
    searchProduct: "Cerca un prodotto",
    category: "Categoria",
    colour: "Colore",
    location: "Posizione",
    material: "Forma del materiale",
    addBundle: "Aggiungi un pacchetto",
    blockNumber: "Numero di blocco",
    noOfBundles: "Numero di pacchetti",
  },
  tooltip: {
    filter: "Filtro",
    editProduct: "Modifica questo prodotto",
    deleteProduct: "Elimina questo prodotto",
    addSubproduct: "Aggiungi un nuovo sottoprodotto",
    removeItem: "Rimuovi questo articolo",
    addNotes: "Aggiungere note",
    removeFromHold: "Rimuovi dalla sospensione",
    moveToHold: "Sposta in attesa",
    changeStatus: "Fai clic per modificare",
    language: "Cambia lingua",
    viewCustomer: "Clicca per visualizzare",
    viewProduct: "Visualizza prodotto",
    addCondition: "Aggiungi immagini a tutti i pacchetti",
  },
  productContainer: {
    createProduct: "Crea prodotto",
    searchLabel: "Cerca prodotto",
    updateProduct: "Aggiorna prodotto",
    createProductBtn: "Crea prodotto",
    name: "Nome del prodotto",
  },
  searchBar: {
    search: "Cerca...",
  },
  inventoryHeaders: {
    products: "Prodotti",
    slabsInStock: "Lastre in magazzino",
    slabsAvailable: "Lastre disponibili",
    slabsOnHold: "Lastre in attesa",
  },
  cart: {
    products: "Prodotti",
    slabsInStock: "Lastre in magazzino",
    slabsAvailable: "Lastre disponibili",
    slabsOnHold: "Lastre in attesa",
    slabsUnavailable: "Lastre non disponibili",
  },
  productHeaders: {
    product: "Prodotto",
    category: "Categoria",
    colour: "Colore",
    location: "Posizione",
    type: "Tipo",
    thickness: "Spessore",
    finish: "Finitura",
    quality: "Qualità",
    price: "Prezzo",
    quantity: "Quantità",
  },
  salesHeaders: {
    orderNumber: "Numero dell'ordine",
    customer: "Cliente",
    products: "Prodotti",
    type: "Tipo",
    orderCreated: "Ordine creato",
    status: "Status",
  },
  usersHeaders: {
    fullname: "Nome completo",
    jobTitle: "Titolo del lavoro",
    department: "Dipartimento",
    employeeNumber: "Numero di dipendenti",
    joiningDate: "data di adesione",
    userID: "ID utente",
    accessLevel: "Livello di accesso",
    phoneNo: "Numero di telefono",
    secondaryAddress: "Indirizzo secondario",
  },
  companyName: {
    companyName: "Nome dell'azienda",
    email: "E-mail",
    contactNumber: "Numero di contatto",
    timezone: "Fuso orario",
    city: "Città",
    country: "Paese",
    save: "Salva",
    cancel: "Annulla",
    edit: "Modifica",
  },
  customers: {
    customerProfile: "Profilo del cliente",
    companyName: "Nome dell'azienda",
    customerType: "Tipo di cliente",
    customerZone: "Zona clienti",
    sinceDate: "Dalla data",
    contactDetails: "Dettagli di contatto",
    contactName: "Nome del contatto",
    phoneNo: "Numero di telefono",
    email: "E-mail",
    shareInventory: "Condividi l'inventario",
    purchasing: "Acquisti",
    currency: "Valuta",
    paymentTerms: "Termini di pagamento",
    paymentMethod: "Metodo di pagamento",
    measurementSystem: "Sistema di misurazione",
    incoTerms: "Incoterms",
    shippingDetails: "Dettagli di spedizione",
    shippingMode: "Modalità di spedizione",
    shippingPort: "Porto di spedizione",
    addressDetails: "Dettagli dell'indirizzo",
    addressLine1: "Indirizzo riga 1",
    addressLine2: "Indirizzo riga 2",
    zipCode: "Codice postale",
    state: "Stato",
    country: "Paese",
    additionalContactDetails: "Dettagli di contatto aggiuntivi",
    businessDetails: "Dettagli aziendali",
    registeredBusinessName: "Nome commerciale registrato",
    addAdditionalContact: "Aggiungi contatto aggiuntivo",
  },
  bundleHeader: {
    bundleNo: "Numero del pacchetto",
    slabInStock: "Lastre in magazzino",
    slabAvailable: "Lastre disponibili",
    slabOnHold: "Lastre in attesa",
    image: "Immagine",
    bundle: "Pacchetto",
    block: "Blocca",
    inStock: "In magazzino",
    onHold: "In attesa",
    available: "Disponibile",
    totalPrice: "Prezzo totale",
    quality: "Qualità",
    finish: "Finitura",
    imageClickInstr: "Clicca sull'icona dell'immagine nella tabella",
  },
  myProfile: {
    yourProfile: "Il tuo profilo",
    fullname: "Nome completo",
    jobDetails: "Dettagli del lavoro",
    employeeNumber: "Numero di dipendenti",
    joiningDate: "Data di adesione",
    department: "Dipartimento",
    jobTitle: "Titolo del lavoro",
    accountDetails: "Dettagli dell'account",
    userId: "ID utente",
    contactDetails: "Dettagli di contatto",
    businessPhNo: "Numero di telefono aziendale",
    secEmail: "Indirizzo email secondario",
    secAddr: "Indirizzo secondario",
    generalPreference: "Preferenze generali",
    preferedLanguage: "Lingua preferita",
    timeZone: "Fuso orario",
    state: "Stato",
    country: "Paese",
  },
  companyProfile: {
    businessProfile: "Profilo aziendale",
    registeredBusinessName: "Nome commerciale registrato",
    businessDetails: "Dettagli aziendali",
    businessPhNo: "Numero di telefono aziendale",
    registeredBusinessAddress: "Indirizzo aziendale registrato",
    contactDetails: "Dettagli di contatto",
    administratorEmail: "Email dell'amministratore",
    administratorPhNo: "Numero di telefono dell'amministratore",
    timeNDate: "Ora e data",
    timeZone: "Fuso orario",
  },
  globalBtn: {
    edit: "Modifica",
    save: "Salva",
    cancel: "Annulla",
    addToCart: "Aggiungi al carrello",
    removeFromCart: "Rimuovi dal carrello",
    createOffer: "Crea offerta",
    createOfferOrSalesText: "Crea offerta/ Crea ordine di vendita",
    createSales: "Crea ordine di vendita",
    moveTo: "Passa a",
    next: "Prossimo",
    back: "Indietro",
    close: "Chiudi",
    yes: "sì",
    no: "No",
    confirmation: "Conferma",
    success: "Successo",
    error: "Errore",
    offerCreateFail:
      "Non è possibile creare vendite degli articoli in sospeso. Rimuovi gli articoli in attesa.",
  },
  slabHeader: {
    slabNo: "Lastra n.",
    blockNo: "Blocco n.",
    grossHeight: "Altezza lorda",
    grossWidth: "Larghezza lorda",
    netHeight: "Altezza netta",
    netWidth: "Larghezza netta",
    finish: "Finitura",
    quality: "Qualità",
    price: "Prezzo",
    currencyCode: "Codice valuta",
    thickness: "Spessore",
    thicknessUnit: "Unità di spessore",
    images: "Immagini",
    status: "Status",
    notes: "Note",
    edit: "Modifica",
    netLength: "Lunghezza netta",
  },
  filters: {
    Thickness: "Spessore",
    Category: "Categoria",
    Height: "Altezza",
    Width: "Larghezza",
    Finish: "Finitura",
    Price: "Prezzo",
    Quality: "Qualità",
    Colour: "Colore",
    Country: "Paese",
    SalesPrice: "Prezzo di vendita",
    Customer: "Cliente",
    Inspector: "Ispettore",
    Department: "Dipartimento",
    JobTitle: "Titolo del lavoro",
    Supplier: "Fornitore",
    CustomerCountry: "Paese del cliente",
    CustomerType: "Tipo di cliente",
    City: "Città",
    SupplierCountry: "Paese fornitore",
    SupplierCity: "Città del fornitore",
    OfferState: "Stato dell'offerta",
  },
  homePage: {
    goodMorning: "Buongiorno ",
    goodEvening: "Buona sera, ",
    goodAfternoon: "Buon pomeriggio, ",
    beYourself: "Sii te stesso!",
    inventoryAnalytics: "Analisi dell'inventario",
    totalItemsInInventory: "Totale articoli nell'inventario",
  },
  help: {
    businessProfileAndUserManagement: "Profilo aziendale e gestione degli utenti",
    addInventory: "Aggiungi inventario",
    addAndEditProducts: "Aggiungere e modificare prodotti",
  },
  offers: {
    offerDeleteSuccess: "Offerta annullata con successo",
    offerDeleteFailure: "Si è verificato un errore durante l'eliminazione dell'offerta",
    viewDetails: "Visualizza i dettagli dell'offerta",
    copyLinkToClipboard: "Copia il link dell'offerta negli appunti",
    cancel: "Annulla l'offerta",
    copiedLinkToClipboard: "Link dell'offerta copiato negli appunti",
  },
}
export default itTranslations;
