import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "@mui/material";
import { Image, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";

export default function InventoryImageFormStepTwo({
  step,
  setStep,
  addImageTo,
  fileList,
  setFileList,
  imageUrls,
  setImageUrls,
  uploadStatus,
  setUploadStatus,
}) {
  const removeImage = (index) => {
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    const newUrlList = imageUrls.slice();
    newUrlList.splice(index, 1);
    const newUploadList = uploadStatus.slice();
    newUploadList.splice(index, 1);
    setUploadStatus(newUploadList);
    setImageUrls(newUrlList);
    setFileList(newFileList);
  };
  return (
    <>
      <Content style={{ textAlign: "center", width: "100%" }}>
        <p
          className="imageUploadHeading"
          style={{
            fontFamily: "Roboto",
          }}
        >
          Image(s) for{" "}
          {addImageTo.slab !== "-1" ? `Slab ${addImageTo.slab} -` : ""}{" "}
          {addImageTo.bundle !== "-1" ? `Bundle ${addImageTo.bundle}` : ""}{" "}
          {addImageTo.block ? `- Block ${addImageTo.block}` : ""}
        </p>
      </Content>
      <Space
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        wrap="wrap"
      >
        {imageUrls.map((data, i) => {
          const isVideo = data.fileType.includes("video");
          return (
            <Content key={i} style={{ width: "100%" }}>
              {!isVideo ? (
                <Image width={200} height={200} src={data.preSignedUrl} />
              ) : (
                <video
                  src={data.preSignedUrl}
                  width={200}
                  height={200}
                  controls
                />
              )}
              <Content style={{ textAlign: "right", width: "100%" }}>
                <DeleteOutlined
                  className="deleteIconCls"
                  onClick={() => {
                    removeImage(i);
                  }}
                />
              </Content>
            </Content>
          );
        })}
      </Space>
      <Content
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          type="Back"
          // className={classes.buttonSeparate}
          className="nextButton"
          sx={{
            marginTop: "1vh",
            padding: "1vh 3vw",
            borderRadius: "100px",
            background: "var(--primary-blue-500, #0C25F3)",
          }}
          onClick={() => {
            setStep(0);
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          type="Next"
          className="nextButton"
          // className={classes.buttonSeparate}
          sx={{
            marginTop: "1vh",
            padding: "1vh 3vw",
            borderRadius: "100px",
            background: "var(--primary-blue-500, #0C25F3)",
          }}
          onClick={() => {
            setStep(2);
          }}
          disabled={fileList.length === 0 ? true : false}
        >
          Next
        </Button>
      </Content>
    </>
  );
}
