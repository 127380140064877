import { Card, Col, Empty, Row, Skeleton } from "antd";
import Meta from "antd/es/card/Meta";
import { Content } from "antd/es/layout/layout";
import React from "react";

export default function SearchSuppliers({
  colors,
  setPresentColorIndex,
  supplierName,
  setSupplierName,
  supplierId,
  setSupplierId,
  sellerPreview,
  setSellerPreview,
  searchResult,
  ...props
}) {
  return searchResult.isLoading || !searchResult.data ? (
    <Skeleton active />
  ) : searchResult.data.tenantList.length === 0 ? (
    <Empty
      style={{ width: "100%", marginTop: "2vh", fontFamily: "Roboto" }}
      description={<span>No results found</span>}
    />
  ) : (
    <Content
      className="ListViewSearch"
      style={{ marginTop: "2vh", width: "100%" }}
    >
      <Row style={{ justifyContent: "center" }}>
        {searchResult.data.tenantList.map((data, i) => {
          return (
            <Col style={{ margin: "2vh 1vw" }} key={i}>
              <Card
                hoverable
                style={{
                  width: 342,
                  height: 270,
                  backgroundColor: "#f9f9f6",
                }}
                cover={
                  <div
                    style={{
                      backgroundColor: colors[i % colors.length],
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "173px", // Adjust the height as needed
                      width: "342px",
                      fontSize: "96px",
                      color: "white",
                    }}
                  >
                    {data.name.split(" ")[0][0]}
                    {data.name.split(" ")[1] ? data.name.split(" ")[1][0] : ""}
                  </div>
                }
                onClick={() => {
                  setSupplierName(data.name);
                  setSupplierId(data.tenantId);
                  setSellerPreview(true);
                  setPresentColorIndex(i % colors.length);
                }}
              >
                <Meta
                  className="cardHeader"
                  title={data.name}
                  description={`${data.city}, ${data.country}`}
                  style={{ marginBottom: "0.5vh" }}
                />
              </Card>
            </Col>
          );
        })}
      </Row>
    </Content>
  );
}
