import { useState } from "react";
import { addInventoryFn } from "../queries/api/inventory";
import InventoryFormStepFour from "./InventoryFormStepFour";
import InventoryFromStepOne from "./InventoryFormStepOne";
import InventoryFormStepThree from "./InventoryFormStepThree";
import InventoryFormStepTwo from "./InventoryFormStepTwo";

function InventoryForm({
  step,
  goToPage,
  addInventory,
  inventory,
  setInventory,
  nextVal,
  setNextVal,
  blockDisabled,
  setAddClicked,
  setBlockDisabled,
  ...props
}) {
  // console.log(inventory)

  const processObject = (requestBody, inventory) => {
    for (let i = 0; i < inventory.bundles.length; ++i) {
      let template = {
        bundleNumber: inventory.bundles[i].bundleNumber,
        slabGroupList: [],
      };
      // console.log(inventory.bundles[i]);
      for (let j = 0; j < inventory.bundles[i].slabs.length; ++j) {
        // console.log(inventory.bundles[i].slabs[j])
        let obj = {
          numberOfSlabs: parseInt(inventory.bundles[i].slabs[j].qty),
          slabInfo: {
            price: "",
            currencyCode: "",
            thickness: "",
            thicknessUnit: "",
            netHeight: "",
            netWidth: "",
            grossHeight: "",
            grossWidth: "",
            finish: "",
            quality: "",
          },
        };
        let currentRawSlab = inventory.bundles[i].slabs[j];
        let currentSlab = template;
        obj.slabInfo.price = currentRawSlab.price;
        obj.slabInfo.currencyCode = inventory.currencyCode;
        obj.slabInfo.thickness = parseInt(currentRawSlab.thickness);
        obj.slabInfo.thicknessUnit = inventory.thicknessUnit;
        obj.slabInfo.netHeight = parseFloat(currentRawSlab.netLength);
        obj.slabInfo.netWidth = parseFloat(currentRawSlab.netWidth);
        obj.slabInfo.grossHeight = parseFloat(currentRawSlab.grossLength);
        obj.slabInfo.grossWidth = parseFloat(currentRawSlab.grossWidth);
        obj.slabInfo.finish = currentRawSlab.finish;
        obj.slabInfo.quality = currentRawSlab.quality;
        currentSlab.slabGroupList.push(obj);
        requestBody.bundles.push(currentSlab);
      }
    }
    return requestBody;
  };

  const submitForm = async () => {
    let requestBody = {
      productId: inventory.product.name,
      blockNumber: inventory.blockNumber,
      bundles: [],
    };
    // console.log("Here 1")
    requestBody = await processObject(requestBody, inventory);
    // console.log(requestBody)
    // setNextVal(requestBody);
    const data = await addInventoryFn(requestBody);
    setNextVal(data);
    goToPage(3);
    // console.log(data);
  };
  switch (step) {
    case 0:
      return (
        <InventoryFromStepOne
          goToPage={goToPage}
          inventory={inventory}
          setInventory={setInventory}
        />
      );
    case 1:
      return (
        <InventoryFormStepTwo
          goToPage={goToPage}
          inventory={inventory}
          setInventory={setInventory}
        />
      );
    case 2:
      return (
        <>
          <InventoryFormStepThree
            goToPage={goToPage}
            inventory={inventory}
            setInventory={setInventory}
            addInventory={addInventory}
            submitForm={submitForm}
          />
        </>
      );
    default:
      return (
        <>
          <InventoryFormStepFour
            goToPage={goToPage}
            inventory={inventory}
            setInventory={setInventory}
            addInventory={addInventory}
            nextVal={nextVal}
            setAddImageTo={props.setAddImageTo}
            submitForm={submitForm}
            blockDisabled={blockDisabled}
            setAddClicked={setAddClicked}
            setBlockDisabled={setBlockDisabled}
            queryClient={props.queryClient}
            addToInventory={props.addToInventory}
            imageAddedCheck={props.imageAddedCheck}
          />
        </>
      );
  }
}

export default InventoryForm;
