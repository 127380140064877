import {
  Authenticator,
  Heading,
  Text,
  View,
  useTheme,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DefaultLayout from "./containers/DefaultLayout";
import PublicLayout from "./containers/PublicLayout";
import { getFirstURLPathName, publicPageUrls } from "./utils/publicpages";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const config = window?.AMPLIFYCONFIG
  ? window.AMPLIFYCONFIG
  : {
      region: "us-west-2",
      userPoolId: "us-west-2_xkll7pxzT",
      userPoolWebClientId: "1qvl8qu0pgh7p02oabib8r4mj4",
      userAttributes: ["locale", "custom:chat_auth_token", "custom:guest_user"],
    };

Amplify.configure({
  Auth: config,
});

const formFields = {
  confirmVerifyUser: {
    confirmation_code: {
      labelHidden: false,
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text
          color={`${tokens.colors.black["100"]}`}
          fontSize={`${tokens.fontSizes.large}`}
        >
          Stonovation
        </Text>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={`${tokens.colors.neutral["80"]}`}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  VerifyUser: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },

  ConfirmVerifyUser: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};

const App = () => {
  const [loggedInUser, setLoggedInUser] = useState(null);

  const [currentLocation, setCurrentLocation] = useState("");

  const [location, setLocation] = useState(
    getFirstURLPathName(window.location.pathname)
  );

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => setLoggedInUser(user.username))
      .catch((err) => setLoggedInUser(null));
  }, []);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      localStorage.clear();
      setLoggedInUser(null);
    } catch (error) {
      console.log("Error signing out:", error);
    }
  };

  useEffect(() => {
    setLocation(getFirstURLPathName(currentLocation));
  }, [currentLocation]);

  if (publicPageUrls.includes(location)) {
    return (
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route
              path="*"
              element={<PublicLayout setCurrentPath={setCurrentLocation} />}
            />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    );
  }

  return (
    <Authenticator
      loginMechanisms={["email"]}
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {({ user }) => {
        if (loggedInUser !== user.username) {
          setLoggedInUser(user.username);
        }

        // console.log(localStorage.getItem('language'))
        if (localStorage.getItem("language") === null) {
          localStorage.setItem("language", user.attributes.locale);
        }

        return (
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Routes>
                <Route
                  path="*"
                  element={
                    <DefaultLayout signOut={handleSignOut} user={user} />
                  }
                />
              </Routes>
            </BrowserRouter>
          </QueryClientProvider>
        );
      }}
    </Authenticator>
  );
};

export default App;
