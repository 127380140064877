import { Button, ButtonGroup, Grid } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { CameraOutlined } from "@ant-design/icons";
import { CheckCircle } from "@mui/icons-material";
import { Col, Collapse, Row, Skeleton, Table, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import _ from "lodash";
import { useTranslation } from "react-i18next";
// import { useAddInventory } from "../queries/react-query/inventory";
// import { inventoryConstants } from "../utils/inventory";

const { Panel } = Collapse;

// const useStyle = makeStyles((theme) => ({
//   buttonSeparate: {
//     margin: theme.spacing(2),
//   },
// }));

function InventoryFormStepFour({
  goToPage,
  inventory,
  setInventory,
  addInventory,
  submitForm,
  nextVal,
  blockDisabled,
  setAddClicked,
  setBlockDisabled,
  ...props
}) {
  const { t } = useTranslation();

  const bundleFound = (bundle) => {
    let bundleFlag = false;
    for (let i = 0; i < blockDisabled.length; ++i) {
      if (blockDisabled[i] == bundle) {
        bundleFlag = true;
      }
    }
    return bundleFlag;
  };

  const columns = [
    {
      title: "Slab No.",
      dataIndex: "slabNumber",
      className: "values",
      render: (text, record, index) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: "Block No.",
      dataIndex: "blockNumber",
      className: "values",
      render: (text, record, index) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: "Thickness",
      dataIndex: "thickness",
      className: "values",
      render: (text, record) =>
        !_.isUndefined(text)
          ? inventory.thicknessUnit
            ? `${text} ${inventory.thicknessUnit}`
            : text
          : "-",
    },
    {
      title: "Gross Height",
      dataIndex: "grossHeight",
      className: "values",
      render: (text, record) =>
        !_.isUndefined(text)
          ? inventory.thicknessUnit
            ? `${text} ${inventory.thicknessUnit}`
            : text
          : "-",
    },
    {
      title: "Gross Width",
      dataIndex: "grossWidth",
      className: "values",
      render: (text, record) =>
        !_.isUndefined(text)
          ? inventory.thicknessUnit
            ? `${text} ${inventory.thicknessUnit}`
            : text
          : "-",
    },
    {
      title: "Net Height",
      dataIndex: "netHeight",
      className: "values",
      render: (text, record) =>
        !_.isUndefined(text)
          ? inventory.thicknessUnit
            ? `${text} ${inventory.thicknessUnit}`
            : text
          : "-",
    },
    {
      title: "Net Width",
      dataIndex: "netWidth",
      className: "values",
      render: (text, record) =>
        !_.isUndefined(text)
          ? inventory.thicknessUnit
            ? `${text} ${inventory.thicknessUnit}`
            : text
          : "-",
    },
    {
      title: "Finish",
      dataIndex: "finish",
      className: "values",
      render: (text, record) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: "Quality",
      dataIndex: "quality",
      className: "values",
      render: (text, record) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: "Price",
      dataIndex: "price",
      className: "values",
      render: (text, record) =>
        !_.isUndefined(text)
          ? inventory.currencyCode
            ? `${text} ${inventory.currencyCode}`
            : ""
          : "-",
    },
    {
      title: "Add image",
      dataIndex: "isValid",
      className: "values",
      render: (text, record, index) => {
        var booleanCheck = false;
        for (let i = 0; i < props.imageAddedCheck.length; ++i) {
          if (
            props.imageAddedCheck[i].slab === `${record.slabNumber}` &&
            props.imageAddedCheck[i].bundle === record.bundleNumber &&
            props.imageAddedCheck[i].block === record.blockNumber
          ) {
            booleanCheck = true;
          }
        }
        return (
          <Button
            type="text"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginLeft: booleanCheck ? "2vw" : "",
            }}
            onClick={() => {
              props.setAddImageTo({
                bundle: `${record.bundleNumber}`,
                slab: `${record.slabNumber}`,
                block: `${record.blockNumber}`,
              });
            }}
          >
            <CameraOutlined style={{ color: "#0066FF" }} />
            {booleanCheck ? (
              <CheckCircle
                sx={{ color: "green", fontSize: "small", marginLeft: "1vw" }}
              />
            ) : (
              <></>
            )}
          </Button>
        );
      },
    },
  ];
  return (
    <>
      <Grid
        container
        sx={{
          "& .MuiTextField-root": { m: 1, width: "80%" },
          "& .MuiAccordion-root": { marginBottom: "10px" },
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            maxHeight: "48vh",
            overflowY: "auto",
            padding: "2vh 1vw 1vh 1vw",
            marginBottom: "1vh",
            border: "1px solid #babab8",
            borderRadius: "7px",
            paddingLeft: "1vw",
          }}
        >
          <Row
            style={{
              width: "100%",
              padding: "2vh 0 2vh 0",
              border: "1px solid rgba(57, 57, 57, 0.66)",
            }}
          >
            <Col
              span={6}
              className="form4Header"
              style={{
                color: "rgb(101, 101, 101)",
                fontWeight: 700,
                fontSize: "14px",
                paddingLeft: "3vw",
              }}
            >
              Bundle
            </Col>
            <Col
              className="form4Header"
              span={6}
              style={{
                color: "rgb(101, 101, 101)",
                fontWeight: 700,
                fontSize: "14px",
                paddingLeft: "1vw",
              }}
            >
              In Stock
            </Col>
            <Col
              className="form4Header"
              span={6}
              style={{
                color: "rgb(101, 101, 101)",
                fontWeight: 700,
                fontSize: "14px",
              }}
            >
              On Hold
            </Col>
            <Col
              className="form4Header"
              span={5}
              style={{
                color: "rgb(101, 101, 101)",
                fontWeight: 700,
                fontSize: "14px",
                marginLeft: "-1vw",
              }}
            >
              Available
            </Col>
            <Col
              className="form4Header"
              span={1}
              style={{
                color: "rgb(101, 101, 101)",
                fontWeight: 700,
                fontSize: "14px",
                marginLeft: "-1.75vw",
              }}
            >
              Image
            </Col>
          </Row>
          {nextVal.map((data, i) => {
            return (
              <Collapse
                key={i}
                style={{
                  borderRadius: "0",
                  border: "0px",
                  marginBottom: "1vh",
                }}
              >
                <Panel
                  className="addImageTable"
                  header={
                    <Row
                      style={{ width: "calc(100%-3vw)", marginLeft: "0.5vw" }}
                    >
                      <Col
                        span={6}
                        className="form4Header"
                        style={{
                          color: "rgb(101, 101, 101)",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        {data[0].bundleNumber}
                      </Col>
                      <Col
                        className="form4Header"
                        span={6}
                        style={{
                          color: "rgb(101, 101, 101)",
                          fontWeight: 700,
                          fontSize: "14px",
                          paddingLeft: "0.3vw",
                        }}
                      >
                        {data.length}
                      </Col>
                      <Col
                        className="form4Header"
                        span={6}
                        style={{
                          color: "rgb(101, 101, 101)",
                          fontWeight: 700,
                          fontSize: "14px",
                          paddingLeft: "0.5vw",
                        }}
                      >
                        0
                      </Col>
                      <Col
                        className="form4Header"
                        span={4}
                        style={{
                          color: "rgb(101, 101, 101)",
                          fontWeight: 700,
                          fontSize: "14px",
                          paddingLeft: "0.7vw",
                        }}
                      >
                        {data.length}
                      </Col>
                      <Col
                        className="form4Header"
                        span={2}
                        style={{
                          color: "rgb(101, 101, 101)",
                          fontWeight: 700,
                          fontSize: "14px",
                          paddingLeft: "0.7vw",
                        }}
                      >
                        <Button
                          type="text"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            props.setAddImageTo({
                              bundle: `${data[0].bundleNumber}`,
                              slab: "-1",
                              block: "-1",
                            });
                          }}
                        >
                          <CameraOutlined style={{ color: "#0066FF" }} />
                          {bundleFound(data[0].bundleNumber) ? (
                            <CheckCircle
                              sx={{
                                color: "green",
                                fontSize: "small",
                                marginLeft: "1vw",
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  }
                >
                  <Table
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    className="slab-table inventory-table"
                  />
                </Panel>
              </Collapse>
            );
          })}
        </Grid>
      </Grid>
      <Content
        style={{
          textAlign: "right",
          width: "100%",
        }}
      >
        {blockDisabled.length !== nextVal.length ? (
          <Tooltip
            style={{ textAlign: "right" }}
            title={t("tooltip.addCondition")}
          >
            <span>
              <Button
                variant="contained"
                type="Back"
                // className={classes.buttonSeparate}
                className="nextButton"
                sx={{
                  marginTop: "1vh",
                  padding: "1vh 3vw",
                  borderRadius: "100px",
                  background: "var(--primary-blue-500, #0C25F3)",
                }}
                onClick={() => {
                  setAddClicked(false);
                  setBlockDisabled([]);
                  goToPage(0);
                  if (props.addToInventory === true) {
                    props.queryClient.refetchQueries(["inventory"]);
                  }
                }}
                disabled={
                  blockDisabled.length !== nextVal.length ? true : false
                }
              >
                {t("actions.createBtn")}
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button
            variant="contained"
            type="Back"
            // className={classes.buttonSeparate}
            className="nextButton"
            sx={{
              marginTop: "1vh",
              padding: "1vh 3vw",
              borderRadius: "100px",
              background: "var(--primary-blue-500, #0C25F3)",
            }}
            onClick={() => {
              setAddClicked(false);
              setBlockDisabled([]);
              goToPage(0);
              if (props.addToInventory === true) {
                props.queryClient.refetchQueries(["inventory"]);
              }
            }}
            disabled={blockDisabled.length !== nextVal.length ? true : false}
          >
            {t("actions.createBtn")}
          </Button>
        )}
      </Content>
    </>
  );
}

export default InventoryFormStepFour;
