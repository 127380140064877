import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { useTranslation } from "react-i18next";

export default function ConfirmationComponent(props) {
  const { t } = useTranslation();
  return (
    <Content style={{ textAlign: "center", width: "100%" }}>
      <ExclamationCircleFilled
        style={{ fontSize: "400%", color: "red", marginBottom: "2vh" }}
      />
      <p
        style={{
          fontSize: "large",
          fontWeight: "bold",
          marginBottom: "1vh",
        }}
      >
        {t("globalBtn.confirmation")}
      </p>
      <p>{props.detail}</p>
      <Content style={{ width: "100%" }}>
        <Button
          type="primary"
          danger
          style={{
            marginRight: "1vw",
          }}
          onClick={() => {
            props.setClose(false);
          }}
        >
          {t("globalBtn.no")}
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: "green",
          }}
          onClick={props.onConfirm}
          loading={props.loading ? true : false}
        >
          {t("globalBtn.yes")}
        </Button>
      </Content>
    </Content>
  );
}
