import "antd/dist/reset.css";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./i18n";
import "./index.scss";
import { makeServer } from "./mirage/server";

if (process.env.RUN_MIRAGE) {
  makeServer({ environment: process.env.NODE_ENV });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
