import { useTranslation } from "react-i18next";
import HomePage from "../../pages/Home/HomePage";

export const Widgets = () => {
  const { t } = useTranslation();
  return [
    {
      centered: true,
      tabItems: [
        {
          key: "Home",
          children: <HomePage />,
        },
      ],
      type: "tab",
    },
  ];
};
