import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { Space } from "antd";
import { Content } from "antd/es/layout/layout";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { CustomerConstants } from "../../utils/customers";
import { salesConstants } from "../../utils/sales";

export default function ShipmentForm(props) {
  const [sec2Mode, setSec2Mode] = useState("Road");
  useEffect(() => {}, [sec2Mode]);
  useEffect(() => {
    props.setShipmentInfo((prev) => ({
      ...prev,
      invoiceDate: new Date().getTime(),
    }));
  }, []);
  const convertToNormal = (d) => {
    const date = new Date(d);
    return date.toLocaleString().split(",")[0];
  };
  // console.log(props.shipmentInfo)
  const phoneNoCode = CustomerConstants.phoneNoCode;

  const paymentTerms = salesConstants.paymentTerms;
  const incoterms = salesConstants.incoterms;
  const insurance = salesConstants.insurance;
  const freightHandler = salesConstants.freightHandler;
  const vessel = salesConstants.vessel;
  const containerType = salesConstants.containerType;
  const shippingLine = salesConstants.shippingLine;
  const destinationPort = salesConstants.destinationPort;
  const portOfLoading = salesConstants.portOfLoading;
  const destinationCntry = salesConstants.destinationCntry;
  const countryOfOrigin = salesConstants.countryOfOrigin;
  const unloadingAddress = salesConstants.unloadingAddress;
  const shippingMode = salesConstants.shippingMode;
  const paymentMethods = salesConstants.paymentMethods;

  return (
    <Content
      style={{
        maxHeight: "100%",
        overflowY: "auto",
        width: "100%",
      }}
    >
      <Content
        style={{
          borderBottom:
            "1px solid var(--neutral-background-text-white-500, #C6C6C6)",
          width: "100%",
          paddingBottom: "2vh",
        }}
      >
        <p className="shipmentFormHeader" style={{ margin: 0 }}>
          Billing Details
        </p>
        <Content style={{ paddingTop: "3vh", width: "100%" }}>
          <Content style={{ width: "100%" }}>
            <p className="shipmentFormInputHeader">Supplier invoice number</p>
            <TextField
              sx={{ width: "100%" }}
              id="outlined-basic"
              label="Supplier invoice number"
              variant="outlined"
              defaultValue={
                props.shipmentInfo
                  ? props.shipmentInfo.supplierInvoiceNumber
                    ? props.shipmentInfo.supplierInvoiceNumber
                    : ""
                  : ""
              }
              onChange={(e) => {
                e.preventDefault();
                props.setShipmentInfo((prev) => ({
                  ...prev,
                  supplierInvoiceNumber: e.target.value,
                }));
              }}
            />
          </Content>
        </Content>
        <Content
          style={{
            paddingTop: "3vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Content style={{ width: "100%", paddingRight: "1vw" }}>
            <p className="shipmentFormInputHeader">Invoice date</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                sx={{ width: "100%" }}
                defaultValue={
                  props.shipmentInfo
                    ? props.shipmentInfo.invoiceDate
                      ? dayjs(props.shipmentInfo.invoiceDate)
                      : dayjs()
                    : dayjs()
                }
                onChange={(date) => {
                  const milliseconds = date.valueOf();
                  props.setShipmentInfo((prevState) => ({
                    ...prevState,
                    invoiceDate: milliseconds,
                  }));
                }}
              />
            </LocalizationProvider>
          </Content>
          <Content style={{ width: "100%" }}>
            <p className="shipmentFormInputHeader">Payment terms</p>
            <Autocomplete
              options={paymentTerms.map((option) => ({ label: option }))}
              getOptionLabel={(option) => option.label}
              defaultValue={{
                label: props.shipmentInfo
                  ? props.shipmentInfo.paymentTerms
                    ? props.shipmentInfo.paymentTerms
                    : "Select"
                  : "Select",
              }}
              id="combo-box-demo"
              onChange={(event, newValue) => {
                props.setShipmentInfo((prev) => ({
                  ...prev,
                  paymentTerms: newValue ? newValue.label : "Select",
                }));
              }}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Payment terms" />
              )}
            />
          </Content>
        </Content>
        <Content
          style={{
            paddingTop: "3vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Content style={{ width: "100%", paddingRight: "1vw" }}>
            <p className="shipmentFormInputHeader">Incoterms</p>
            <Autocomplete
              options={incoterms.map((option) => ({ label: option }))}
              getOptionLabel={(option) => option.label}
              defaultValue={{
                label: props.shipmentInfo
                  ? props.shipmentInfo.incoTerms
                    ? props.shipmentInfo.incoTerms
                    : "Select"
                  : "Select",
              }}
              id="combo-box-demo"
              onChange={(event, newValue) => {
                props.setShipmentInfo((prev) => ({
                  ...prev,
                  incoTerms: newValue ? newValue.label : "Select",
                }));
              }}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Incoterms" />
              )}
            />
          </Content>
          <Content style={{ width: "100%" }}>
            <p className="shipmentFormInputHeader">Invoice due date</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                sx={{ width: "100%" }}
                defaultValue={
                  props.shipmentInfo
                    ? props.shipmentInfo.invoiceDueDate
                      ? dayjs(props.shipmentInfo.invoiceDueDate)
                      : dayjs()
                    : dayjs()
                }
                onChange={(date) => {
                  const milliseconds = date.valueOf();
                  props.setShipmentInfo((prevState) => ({
                    ...prevState,
                    invoiceDueDate: milliseconds,
                  }));
                }}
              />
            </LocalizationProvider>
          </Content>
        </Content>
        <Content style={{ paddingTop: "3vh", width: "100%" }}>
          <Content style={{ width: "100%" }}>
            <p className="shipmentFormInputHeader">Payment method</p>
            <Autocomplete
              options={paymentMethods.map((option) => ({ label: option }))}
              getOptionLabel={(option) => option.label}
              defaultValue={{
                label: props.shipmentInfo
                  ? props.shipmentInfo.paymentMethod
                    ? props.shipmentInfo.paymentMethod
                    : "Select"
                  : "Select",
              }}
              id="combo-box-demo"
              onChange={(event, newValue) => {
                props.setShipmentInfo((prev) => ({
                  ...prev,
                  paymentMethod: newValue ? newValue.label : "Select",
                }));
              }}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Payment method" />
              )}
            />
          </Content>
        </Content>
      </Content>
      <Content
        style={{ width: "100%", paddingBottom: "2vh", paddingTop: "2vh" }}
      >
        <p className="shipmentFormHeader" style={{ margin: 0 }}>
          Delivery Details
        </p>
        <Content style={{ paddingTop: "3vh", width: "100%" }}>
          <Content style={{ width: "100%" }}>
            <p className="shipmentFormInputHeader">Shipping method</p>
            <Autocomplete
              options={shippingMode.map((option) => ({ label: option }))}
              getOptionLabel={(option) => option.label}
              defaultValue={{
                label: props.shipmentInfo
                  ? props.shipmentInfo.shippingMethod
                    ? props.shipmentInfo.shippingMethod
                    : "Select"
                  : "Select",
              }}
              id="combo-box-demo"
              onChange={(event, newValue) => {
                props.setShipmentInfo((prev) => ({
                  ...prev,
                  shippingMethod: newValue ? newValue.label : "Select",
                }));
                setSec2Mode(newValue ? newValue.label : "Select");
              }}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Shipping Method" />
              )}
            />
          </Content>
        </Content>
        {sec2Mode === "Road" ? (
          <>
            <Content
              style={{
                paddingTop: "3vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Content style={{ width: "100%", paddingRight: "1vw" }}>
                <p className="shipmentFormInputHeader">Unloading Address</p>
                <Autocomplete
                  options={unloadingAddress.map((option) => ({
                    label: option,
                  }))}
                  getOptionLabel={(option) => option.label}
                  defaultValue={{
                    label: props.shipmentInfo
                      ? props.shipmentInfo.unloadingAddress
                        ? props.shipmentInfo.unloadingAddress
                        : "Select"
                      : "Select",
                  }}
                  id="combo-box-demo"
                  onChange={(event, newValue) => {
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      unloadingAddress: newValue ? newValue.label : "Select",
                    }));
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Unloading Address" />
                  )}
                />
              </Content>
              <Content style={{ width: "100%" }}>
                <p className="shipmentFormInputHeader">Contact number</p>
                <Content style={{ width: "100%" }}>
                  <FormControl sx={{ width: "21%", marginRight: "10px" }}>
                    <InputLabel id="demo-simple-select-label">Code</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Code"
                      value={
                        props.shipmentInfo
                          ? props.shipmentInfo.contactNumber
                            ? props.shipmentInfo.contactNumber.split(" ") ===
                              "--"
                              ? "Select"
                              : props.shipmentInfo.contactNumber.split(" ")[0]
                            : "Select"
                          : "Select"
                      }
                      onChange={(e) => {
                        if (props.shipmentInfo.contactNumber === undefined) {
                          props.setShipmentInfo((prevState) => ({
                            ...prevState,
                            contactNumber: e.target.value,
                          }));
                        } else {
                          const firstWord =
                            props.shipmentInfo.contactNumber.split(" ");
                          if (firstWord.length === 1)
                            props.setShipmentInfo((prevState) => ({
                              ...prevState,
                              contactNumber: e.target.value,
                            }));
                          else
                            props.setShipmentInfo((prevState) => ({
                              ...prevState,
                              contactNumber: `${e.target.value} ${firstWord[1]}`,
                            }));
                        }
                      }}
                    >
                      {phoneNoCode.map((phoneNumber, i) => {
                        return (
                          <MenuItem key={i} value={phoneNumber.value}>
                            {phoneNumber.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Phone number"
                    variant="outlined"
                    sx={{ width: "75%" }}
                    defaultValue={
                      props.shipmentInfo
                        ? props.shipmentInfo.contactNumber
                          ? props.shipmentInfo.contactNumber.split(" ") === "--"
                            ? ""
                            : props.shipmentInfo.contactNumber.split(" ")
                                .length === 1
                            ? ""
                            : props.shipmentInfo.contactNumber.split(" ")[1]
                          : ""
                        : ""
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      const firstWord =
                        props.shipmentInfo.contactNumber.split(" ");

                      props.setShipmentInfo((prevState) => ({
                        ...prevState,
                        contactNumber: `${firstWord[0]} ${e.target.value}`,
                      }));
                    }}
                  />
                </Content>
              </Content>
            </Content>
            <Content
              style={{
                paddingTop: "3vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Content style={{ width: "100%", paddingRight: "1vw" }}>
                <p className="shipmentFormInputHeader">Gross weight</p>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Gross weight"
                  variant="outlined"
                  defaultValue={
                    props.shipmentInfo
                      ? props.shipmentInfo.grossWeight
                        ? props.shipmentInfo.grossWeight
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      grossWeight: e.target.value,
                    }));
                  }}
                />
              </Content>
              <Content style={{ width: "100%" }}>
                <p className="shipmentFormInputHeader">Net weight</p>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Net weight"
                  variant="outlined"
                  defaultValue={
                    props.shipmentInfo
                      ? props.shipmentInfo.netWeight
                        ? props.shipmentInfo.netWeight
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      netWeight: e.target.value,
                    }));
                  }}
                />
              </Content>
            </Content>
            <Content
              style={{
                paddingTop: "3vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Content style={{ width: "100%", paddingRight: "1vw" }}>
                <p className="shipmentFormInputHeader">Freight handler</p>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Freight handler"
                  variant="outlined"
                  defaultValue={
                    props.shipmentInfo
                      ? props.shipmentInfo.freightHandler
                        ? props.shipmentInfo.freightHandler
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      freightHandler: e.target.value,
                    }));
                  }}
                />
              </Content>
              <Content style={{ width: "100%" }}>
                <p className="shipmentFormInputHeader">Booking Number</p>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Booking Number"
                  variant="outlined"
                  defaultValue={
                    props.shipmentInfo
                      ? props.shipmentInfo.bookingNumber
                        ? props.shipmentInfo.bookingNumber
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      bookingNumber: e.target.value,
                    }));
                  }}
                />
              </Content>
            </Content>
            <Content
              style={{
                paddingTop: "3vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Content style={{ width: "100%", paddingRight: "1vw" }}>
                <p className="shipmentFormInputHeader">Loading date</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    sx={{ width: "100%" }}
                    defaultValue={
                      props.shipmentInfo
                        ? props.shipmentInfo.loadingDate
                          ? dayjs(props.shipmentInfo.loadingDate)
                          : dayjs()
                        : dayjs()
                    }
                    onChange={(date) => {
                      const milliseconds = date.valueOf();
                      props.setShipmentInfo((prevState) => ({
                        ...prevState,
                        loadingDate: milliseconds,
                      }));
                    }}
                  />
                </LocalizationProvider>
              </Content>
              <Content style={{ width: "100%" }}>
                <p className="shipmentFormInputHeader">Estimate arrival date</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    sx={{ width: "100%" }}
                    defaultValue={
                      props.shipmentInfo
                        ? props.shipmentInfo.estimatedArrivalDate
                          ? dayjs(props.shipmentInfo.estimatedArrivalDate)
                          : dayjs()
                        : dayjs()
                    }
                    onChange={(date) => {
                      const milliseconds = date.valueOf();
                      props.setShipmentInfo((prevState) => ({
                        ...prevState,
                        estimatedArrivalDate: milliseconds,
                      }));
                    }}
                  />
                </LocalizationProvider>
              </Content>
            </Content>
          </>
        ) : (
          <>
            <Content
              style={{
                paddingTop: "3vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Content style={{ width: "100%", paddingRight: "1vw" }}>
                <p className="shipmentFormInputHeader">Country of origin</p>
                <Autocomplete
                  options={countryOfOrigin.map((option) => ({ label: option }))}
                  getOptionLabel={(option) => option.label}
                  defaultValue={{
                    label: props.shipmentInfo
                      ? props.shipmentInfo.countryOfOrigin
                        ? props.shipmentInfo.countryOfOrigin
                        : "Select"
                      : "Select",
                  }}
                  id="combo-box-demo"
                  onChange={(event, newValue) => {
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      countryOfOrigin: newValue ? newValue.label : "Select",
                    }));
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Country of origin" />
                  )}
                />
              </Content>
              <Content style={{ width: "100%" }}>
                <p className="shipmentFormInputHeader">Port of loading</p>
                <Autocomplete
                  options={portOfLoading.map((option) => ({ label: option }))}
                  getOptionLabel={(option) => option.label}
                  defaultValue={{
                    label: props.shipmentInfo
                      ? props.shipmentInfo.portOfLoading
                        ? props.shipmentInfo.portOfLoading
                        : "Select"
                      : "Select",
                  }}
                  id="combo-box-demo"
                  onChange={(event, newValue) => {
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      portOfLoading: newValue ? newValue.label : "Select",
                    }));
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Port of loading" />
                  )}
                />
              </Content>
            </Content>
            <Content
              style={{
                paddingTop: "3vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Content style={{ width: "100%", paddingRight: "1vw" }}>
                <p className="shipmentFormInputHeader">Destination country</p>
                <Autocomplete
                  options={destinationCntry.map((option) => ({
                    label: option,
                  }))}
                  getOptionLabel={(option) => option.label}
                  defaultValue={{
                    label: props.shipmentInfo
                      ? props.shipmentInfo.destinationCountry
                        ? props.shipmentInfo.destinationCountry
                        : "Select"
                      : "Select",
                  }}
                  id="combo-box-demo"
                  onChange={(event, newValue) => {
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      destinationCountry: newValue ? newValue.label : "Select",
                    }));
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Destination country" />
                  )}
                />
              </Content>
              <Content style={{ width: "100%" }}>
                <p className="shipmentFormInputHeader">Port at destination</p>
                <Autocomplete
                  options={destinationPort.map((option) => ({ label: option }))}
                  getOptionLabel={(option) => option.label}
                  defaultValue={{
                    label: props.shipmentInfo
                      ? props.shipmentInfo.destinationPort
                        ? props.shipmentInfo.destinationPort
                        : "Select"
                      : "Select",
                  }}
                  id="combo-box-demo"
                  onChange={(event, newValue) => {
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      destinationPort: newValue ? newValue.label : "Select",
                    }));
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Port of destination" />
                  )}
                />
              </Content>
            </Content>
            <Content
              style={{
                paddingTop: "3vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Content style={{ width: "100%", paddingRight: "1vw" }}>
                <p className="shipmentFormInputHeader">Shipping line</p>
                <Autocomplete
                  options={shippingLine.map((option) => ({ label: option }))}
                  getOptionLabel={(option) => option.label}
                  defaultValue={{
                    label: props.shipmentInfo
                      ? props.shipmentInfo.shippingLine
                        ? props.shipmentInfo.shippingLine
                        : "Select"
                      : "Select",
                  }}
                  id="combo-box-demo"
                  onChange={(event, newValue) => {
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      shippingLine: newValue ? newValue.label : "Select",
                    }));
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Shipping line" />
                  )}
                />
              </Content>
              <Content style={{ width: "100%" }}>
                <p className="shipmentFormInputHeader">Container type</p>
                <Autocomplete
                  options={containerType.map((option) => ({ label: option }))}
                  getOptionLabel={(option) => option.label}
                  defaultValue={{
                    label: props.shipmentInfo
                      ? props.shipmentInfo.containerType
                        ? props.shipmentInfo.containerType
                        : "Select"
                      : "Select",
                  }}
                  id="combo-box-demo"
                  onChange={(event, newValue) => {
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      containerType: newValue ? newValue.label : "Select",
                    }));
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Container type" />
                  )}
                />
              </Content>
            </Content>
            <Content
              style={{
                paddingTop: "3vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Content style={{ width: "100%", paddingRight: "1vw" }}>
                <p className="shipmentFormInputHeader">Container number</p>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Container number"
                  variant="outlined"
                  defaultValue={
                    props.shipmentInfo
                      ? props.shipmentInfo.containerNumber
                        ? props.shipmentInfo.containerNumber
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      containerNumber: e.target.value,
                    }));
                  }}
                />
              </Content>
              <Content style={{ width: "100%" }}>
                <p className="shipmentFormInputHeader">Seal number</p>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Seal number"
                  variant="outlined"
                  defaultValue={
                    props.shipmentInfo
                      ? props.shipmentInfo.sealNumber
                        ? props.shipmentInfo.sealNumber
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      sealNumber: e.target.value,
                    }));
                  }}
                />
              </Content>
            </Content>
            <Content
              style={{
                paddingTop: "3vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Content style={{ width: "100%", paddingRight: "1vw" }}>
                <p className="shipmentFormInputHeader">Vessel</p>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Vessel"
                  variant="outlined"
                  defaultValue={
                    props.shipmentInfo
                      ? props.shipmentInfo.vessel
                        ? props.shipmentInfo.vessel
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      vessel: e.target.value,
                    }));
                  }}
                />
              </Content>
              <Content style={{ width: "100%" }}>
                <p className="shipmentFormInputHeader">Insurance</p>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Insurance"
                  variant="outlined"
                  defaultValue={
                    props.shipmentInfo
                      ? props.shipmentInfo.insurance
                        ? props.shipmentInfo.insurance
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      insurance: e.target.value,
                    }));
                  }}
                />
              </Content>
            </Content>
            <Content
              style={{
                paddingTop: "3vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Content style={{ width: "100%", paddingRight: "1vw" }}>
                <p className="shipmentFormInputHeader">Gross weight</p>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Gross weight"
                  variant="outlined"
                  defaultValue={
                    props.shipmentInfo
                      ? props.shipmentInfo.grossWeight
                        ? props.shipmentInfo.grossWeight
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      grossWeight: e.target.value,
                    }));
                  }}
                />
              </Content>
              <Content style={{ width: "100%" }}>
                <p className="shipmentFormInputHeader">Net weight</p>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Net weight"
                  variant="outlined"
                  defaultValue={
                    props.shipmentInfo
                      ? props.shipmentInfo.netWeight
                        ? props.shipmentInfo.netWeight
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      netWeight: e.target.value,
                    }));
                  }}
                />
              </Content>
            </Content>
            <Content
              style={{
                paddingTop: "3vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Content style={{ width: "100%", paddingRight: "1vw" }}>
                <p className="shipmentFormInputHeader">Freight handler</p>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Freight handler"
                  variant="outlined"
                  defaultValue={
                    props.shipmentInfo
                      ? props.shipmentInfo.freightHandler
                        ? props.shipmentInfo.freightHandler
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      freightHandler: e.target.value,
                    }));
                  }}
                />
              </Content>
              <Content style={{ width: "100%" }}>
                <p className="shipmentFormInputHeader">Booking Number</p>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Booking Number"
                  variant="outlined"
                  defaultValue={
                    props.shipmentInfo
                      ? props.shipmentInfo.bookingNumber
                        ? props.shipmentInfo.bookingNumber
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    props.setShipmentInfo((prev) => ({
                      ...prev,
                      bookingNumber: e.target.value,
                    }));
                  }}
                />
              </Content>
            </Content>
            <Content
              style={{
                paddingTop: "3vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Content style={{ width: "100%", paddingRight: "1vw" }}>
                <p className="shipmentFormInputHeader">Loading date</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    sx={{ width: "100%" }}
                    defaultValue={
                      props.shipmentInfo
                        ? props.shipmentInfo.loadingDate
                          ? dayjs(props.shipmentInfo.loadingDate)
                          : dayjs()
                        : dayjs()
                    }
                    onChange={(date) => {
                      const milliseconds = date.valueOf();
                      props.setShipmentInfo((prevState) => ({
                        ...prevState,
                        loadingDate: milliseconds,
                      }));
                    }}
                  />
                </LocalizationProvider>
              </Content>
              <Content style={{ width: "100%" }}>
                <p className="shipmentFormInputHeader">Estimate arrival date</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    sx={{ width: "100%" }}
                    defaultValue={
                      props.shipmentInfo
                        ? props.shipmentInfo.estimatedArrivalDate
                          ? dayjs(props.shipmentInfo.estimatedArrivalDate)
                          : dayjs()
                        : dayjs()
                    }
                    onChange={(date) => {
                      const milliseconds = date.valueOf();
                      props.setShipmentInfo((prevState) => ({
                        ...prevState,
                        estimatedArrivalDate: milliseconds,
                      }));
                    }}
                  />
                </LocalizationProvider>
              </Content>
            </Content>
          </>
        )}
      </Content>
    </Content>
  );
}
