import axios from "axios";
import {
  CART_ROUTE,
  CRUD_OPS,
  getEndPointFor,
  getHeaders,
  INVENTORY_ROUTE,
  SALES_ROUTE,
} from "../../utils/api_endpoints";

export const fetchBundles = async (queryKey, page) => {
  const headers = await getHeaders();
  const response = await axios
    .post(
      `${getEndPointFor(INVENTORY_ROUTE.base)}/allBundlesWithImages`,
      {
        from: (page - 1) * 24,
        size: 24,
        filterContext: queryKey[1].filterContext,
        apiVersion: 2,
      },
      { headers: headers }
    )
    .then((response) => {
      let responseArr = [];
      for (let i = 0; i < response.data.inventoryBundles.length; ++i) {
        let tempObj = response.data.inventoryBundles[i];
        tempObj.aggregations = response.data.aggregations;
        responseArr.push(tempObj);
      }
      return responseArr;
    });
  return response;
};

export const getInventoryList = async ({ queryKey }) => {
  // TODO: need to add filter to the inventory list API
  const [, filter] = queryKey;
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(INVENTORY_ROUTE.base)}/list`, queryKey[1], {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const getBundleList = async ({ queryKey }) => {
  const [, productId] = queryKey;
  const headers = await getHeaders();
  const response = await axios
    .post(
      `${getEndPointFor(INVENTORY_ROUTE.base)}/bundles`,
      {
        productId: productId,
        filterContext: queryKey[2].filterContext,
      },
      {
        headers: headers,
      }
    )
    .then((response) => response.data);
  return response;
};

export const getBundleImages = async ({ queryKey }) => {
  const [, productId] = queryKey;
  const headers = await getHeaders();
  const response = await axios
    .post(
      `${getEndPointFor(INVENTORY_ROUTE.base)}/bundles/get`,
      {
        product: productId,
        bundleNumber: queryKey[2].bundleNumber,
      },
      {
        headers: headers,
      }
    )
    .then((response) => response.data);
  return response;
};

export const deleteBundleImages = async (payload) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(INVENTORY_ROUTE.deleteFile)}`, payload, {
      headers: headers,
    })
    .then((response) => {
      return { data: response.data, deletePayload: payload };
    }); //Sending payload back to make some manipulation through onsuccess
  return response;
};

export const getSlabsByBundleId = async ({ queryKey }) => {
  const [, productId, bundleNumber] = queryKey;
  const headers = await getHeaders();
  const response = await axios
    .post(
      `${getEndPointFor(INVENTORY_ROUTE.base)}/slabs`,
      {
        productId: productId,
        bundleNumber: bundleNumber,
        filterContext: queryKey[3].filterContext,
      },
      {
        headers: headers,
      }
    )
    .then((response) => {
      let totalSlab = response.data.length;
      let slabsOnHold = 0;
      let slabsAvailable = 0;
      let editedData = response.data;
      for (let i = 0; i < totalSlab; i++) {
        if (response.data[i].status === "AVAILABLE") {
          slabsAvailable += 1;
        } else if (response.data[i].status === "HOLD") {
          slabsOnHold += 1;
        }
      }
      for (let i = 0; i < totalSlab; i++) {
        editedData[i].totalSlabs = totalSlab;
        editedData[i].availableSlabs = slabsAvailable;
        editedData[i].slabsOnHold = slabsOnHold;
      }
      return editedData;
    });
  return response;
};

export const getNotesData = async ({ queryKey }) => {
  const headers = await getHeaders();
  const endPoint = queryKey[2].isSales
    ? `${getEndPointFor(SALES_ROUTE.base)}/slab/getNotes`
    : `${getEndPointFor(INVENTORY_ROUTE.base)}/slabs/get`;
  const response = await axios
    .post(endPoint, queryKey[1], {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const putSlabsOnHold = async (body) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(INVENTORY_ROUTE.base)}/putSlabsOnHold`, body, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const addNotes = async (body) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(INVENTORY_ROUTE.base)}/addNoteToSlab`, body, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const addInventoryFn = async (obj) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(INVENTORY_ROUTE.base)}`, obj, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const fetchSlabs = async ({ queryKey }) => {
  const headers = await getHeaders();
  const response = await axios
    .post(
      `${getEndPointFor(INVENTORY_ROUTE.base)}/slabs`,
      {
        bundleNumber: queryKey[1].bundleNumber,
        productId: queryKey[1].productName,
        filterContext: queryKey[2].filterContext,
      },
      {
        headers: headers,
      }
    )
    .then((response) => response.data);
  return response;
};

export const addToCart = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(CART_ROUTE.base)}`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const updateSlabDetails = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(INVENTORY_ROUTE.base)}/updateSlab`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const getBundleListTileView = async (page) => {
  const headers = await getHeaders();
  const response = await axios
    .post(
      `${getEndPointFor(INVENTORY_ROUTE.base)}/allBundlesWithImages`,
      { from: (page - 1) * 10, size: 10 },
      {
        headers: headers,
      }
    )
    .then((response) => response.data);
  return response;
};
