import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Content } from "antd/es/layout/layout";
import { inventoryConstants } from "../utils/inventory";
import AutoCompleteProduct from "./AutoCompleteProduct";

const InventoryFromStepOne = ({ goToPage, inventory, setInventory }) => {
  const [skus, setSkus] = useState([]);

  const [materialTypeList, setMaterialTypeList] = useState([]);
  const [thicknessList, setThicknessList] = useState([]);
  const [finishList, setFinishList] = useState([]);
  const [uniqueFinishList, setUniqueFinishList] = useState([]);
  const [uniqueThicknessList, setUniqueThicknessList] = useState([]);
  const [product, setProduct] = useState({});
  const [thickness, setThickness] = useState(
    inventory.thickness
      ? `${inventory.thickness}-${inventory.thicknessUnit}`
      : ""
  );
  const [type, setType] = useState("");
  const [quality, setQuality] = useState(inventory.quality);
  const [finish, setFinish] = useState(inventory.finish);
  const [price, setPrice] = useState(inventory.price);
  const [currencyCode, setCurrencyCode] = useState(inventory.currencyCode);

  const [errorTracker, setErrorTracker] = useState({});
  const { t } = useTranslation();

  let uniqueItems = {};
  if (inventory.product) {
    inventory.product.skus.forEach(({ productType }) => {
      if (!uniqueItems[productType]) {
        uniqueItems[productType] = {
          productType,
        };
      }
    });
  }

  const [uniqueMaterialTypeList, setUniqueMaterialTypeList] = useState(
    Object.values(uniqueItems)
  );

  const [options, setOptions] = useState([]);
  // const classes = useStyle();

  const handleProductSelection = (product) => {
    setOptions(product ? [product, ...options] : options);
    setProduct(product);
    let uniqueItems = {};
    if (product) {
      setSkus(product.skus ? product.skus : []);
      if (product.skus)
        product.skus.forEach(({ productType }) => {
          if (!uniqueItems[productType]) {
            uniqueItems[productType] = {
              productType,
            };
          }
        });
    } else {
      setSkus([]);
    }
    setUniqueMaterialTypeList(Object.values(uniqueItems));
    setMaterialTypeList([]);
    setUniqueThicknessList([]);
    setThicknessList([]);
    setFinishList([]);
    setUniqueFinishList([]);
    setType("");
    setThickness("");
    setFinish("");
    setQuality("");
    setPrice("");
    setCurrencyCode("");
  };

  const handleTypeSelection = (newValue) => {
    setType(newValue);
    let typePickedSkus = skus.filter(
      ({ productType }) => productType === newValue
    );
    let uniqueItems = {};
    if (newValue === "SLAB") {
      typePickedSkus.forEach(({ thickness, thicknessUnit }) => {
        if (!uniqueItems[thickness]) {
          uniqueItems[thickness] = {
            size: thickness,
            unit: thicknessUnit,
          };
        }
      });
      setMaterialTypeList(typePickedSkus);
      setUniqueThicknessList(Object.values(uniqueItems));
      setThicknessList([]);
      setUniqueFinishList([]);
      setFinishList([]);
    } else {
      setFinishList(typePickedSkus);
    }
    setThickness("");
    setFinish("");
    setQuality("");
    setCurrencyCode("");
    setPrice("");
  };

  const handleThicknessSelection = (value) => {
    setThickness(value);
    let thicknessPickedSkus = materialTypeList.filter(
      ({ thickness, thicknessUnit }) =>
        `${thickness}-${thicknessUnit}` === value
    );
    let uniqueItems = {};
    thicknessPickedSkus.forEach(({ finish }) => {
      if (!uniqueItems[finish]) {
        uniqueItems[finish] = { finish };
      }
    });
    setThicknessList(thicknessPickedSkus);
    setUniqueFinishList(Object.values(uniqueItems));
    setFinishList([]);
    setFinish("");
    setQuality("");
    setCurrencyCode("");
    setPrice("");
  };

  const handleFinishSelection = (value) => {
    setFinish(value);
    setFinishList(thicknessList.filter(({ finish }) => finish === value));
    setQuality("");
    setCurrencyCode("");
    setPrice("");
  };

  const handleQualitySelection = (value) => {
    setQuality(value);
    const qualityList = finishList.filter(({ quality }) => quality === value);
    const { price, currencyCode } = qualityList[0];
    setPrice(price);
    setCurrencyCode(currencyCode);
  };

  const isValidateForm = () => {
    const errors = {
      type: !!type,
      quality: !!quality,
    };

    if (type === "SLAB") {
      errors.thickness = !!thickness;
      errors.finish = !!finish;
    }
    setErrorTracker(errors);
    return Object.values(errors).every((b) => b === true);
  };

  const updateError = (name, value) => {
    let ref = { ...errorTracker };
    ref[name] = !!value;
    setErrorTracker(ref);
  };

  return (
    <>
      <Grid
        container
        sx={{
          "& .MuiTextField-root": { m: 1, width: "80%" },
          marginTop: "2vh",
          marginBottom: "2vh",
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            marginBottom: "2vh",
          }}
        >
          <AutoCompleteProduct
            handleProductSelection={handleProductSelection}
            product={product}
            searchLabel={t("inventoryForm.searchProduct")}
            options={options}
            setOptions={setOptions}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            marginBottom: "2vh",
          }}
        ></Grid>
        <Grid item xs={2}>
          <TextField
            label={t("inventoryForm.material")}
            value={type ? type : ""}
            select
            name="materialType"
            error={errorTracker.type === false}
            onChange={({ target }) => {
              updateError("type", target.value);
              handleTypeSelection(target.value);
            }}
            disabled={uniqueMaterialTypeList.length < 1}
          >
            {uniqueMaterialTypeList.map(({ productType }, i) => (
              <MenuItem key={i} value={productType}>
                {productType}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {type === "SLAB" && (
          <>
            <Grid item xs={2}>
              <TextField
                label={t("slab.labels.thickness")}
                value={thickness}
                select
                error={errorTracker.thickness === false}
                name="thickness"
                onChange={({ target }) => {
                  updateError("thickness", target.value);
                  handleThicknessSelection(target.value);
                }}
                disabled={uniqueThicknessList.length < 1}
              >
                {uniqueThicknessList.map(({ size, unit }, i) => (
                  <MenuItem key={i} value={`${size}-${unit}`}>
                    {`${size}-${unit}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("slab.labels.finish")}
                value={finish}
                select
                name="finish"
                error={errorTracker.finish === false}
                onChange={({ target }) => {
                  updateError("finish", target.value);
                  handleFinishSelection(target.value);
                }}
                disabled={uniqueFinishList.length < 1}
              >
                {uniqueFinishList.map(({ finish }, i) => (
                  <MenuItem key={i} value={finish}>
                    {finish}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </>
        )}
        <Grid item xs={3}>
          <TextField
            label={t("slab.labels.quality")}
            value={quality}
            select
            name="quality"
            error={errorTracker.quality === false}
            onChange={({ target }) => {
              updateError("quality", target.value);
              handleQualitySelection(target.value);
            }}
            disabled={finishList.length < 1}
          >
            {finishList.map(({ quality }, i) => (
              <MenuItem key={i} value={quality}>
                {quality}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            label={t("slab.labels.price")}
            value={price}
            name="price"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {inventoryConstants.currencies[currencyCode]}
                </InputAdornment>
              ),
            }}
            disabled
          />
        </Grid>
      </Grid>
      <Content style={{ width: "100%", textAlign: "right" }}>
        {price.length === 0 ? (
          <Button
            variant="contained"
            className="nextButton"
            // className={classes.buttonSeparate}
            sx={{
              marginTop: "1vh",
              padding: "1vh 3vw",
              borderRadius: "100px",
              background: "var(--primary-blue-500, #0C25F3)",
            }}
            type="Next"
            onClick={() => {
              if (!isValidateForm()) {
                return;
              }
              let inventoryRef = { ...inventory };
              delete inventoryRef["bundles"];
              setInventory({
                ...inventoryRef,
                id: product.id,
                product,
                price,
                finish,
                quality,
                type,
                thickness: thickness.split("-")[0],
                currencyCode,
                thicknessUnit: thickness.split("-")[1],
              });

              goToPage(1);
            }}
            disabled
          >
            {t("actions.nextBtn")}
          </Button>
        ) : (
          <Button
            variant="contained"
            // className={classes.buttonSeparate}
            className="nextButton"
            sx={{
              marginTop: "1vh",
              padding: "1vh 3vw",
              borderRadius: "100px",
              background: "var(--primary-blue-500, #0C25F3)",
            }}
            type="Next"
            onClick={() => {
              if (!isValidateForm()) {
                return;
              }
              let inventoryRef = { ...inventory };
              delete inventoryRef["bundles"];
              setInventory({
                ...inventoryRef,
                id: product.id,
                product,
                price,
                finish,
                quality,
                type,
                thickness: thickness.split("-")[0],
                currencyCode,
                thicknessUnit: thickness.split("-")[1],
              });

              goToPage(1);
            }}
          >
            {t("actions.nextBtn")}
          </Button>
        )}
      </Content>
    </>
  );
};

export default InventoryFromStepOne;
