import { CometChat } from "@cometchat-pro/chat";
import React from "react";
// import { CometChatUsersWithMessages } from '@cometchat/chat-uikit-react';
import { Content } from "antd/es/layout/layout";
import { CometChatUI } from "../../CometChat/components";

export default function Messages() {
  // const authToken = "uma_16988562826c426fa36a40be57195c9f703fbdf6";//Replace with your auth token
  // console.log( window.location.port)
  // const authToken = window.location.port === "3000"?"sachin_1698907655615fdf3137a8a4a7ec9ad1cbf68fc7":"uma_16988562826c426fa36a40be57195c9f703fbdf6";
  // CometChat.login(authToken).then(user => {
  //   console.log("Login Successful:", { user });
  // }).catch(err=>console.log(err));

  return (
    // <></>
    // <>
    // Done
    // </>
    <Content style={{ height: "78vh" }}>
      <CometChatUI />
    </Content>
  );
}
