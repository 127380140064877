import { Button, Skeleton, Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useGetSummaryForOffer } from "../../queries/react-query/cart";
import { useGetCustomers } from "../../queries/react-query/customers";
import { useCreateOffers } from "../../queries/react-query/offers";
import { useGetProfile } from "../../queries/react-query/userProfile";
import "./offer.css";

export default function CreateOffer(props) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const summaryList = useGetSummaryForOffer(["summary"]);
  const [createLoading, setCreateLoading] = useState(false);
  const onSuccessMutation = () => {
    setTimeout(() => {
      queryClient.refetchQueries("cart");
      queryClient.refetchQueries("HOffers");
      setCreateLoading(false);
      navigate({
        pathname: "/sales",
        search: createSearchParams({ tab: "offers" }).toString(),
      });
    }, 4000);
  };

  const createOfferError = (err) => {
    props.setErrMsg(err);
    props.setOfferClicked(false);
    props.setPopup(true);
    setTimeout(() => {
      props.setPopup(false);
      props.setErrMsg("");
    }, [4000]);
  };

  const createOffer = useCreateOffers(
    ["createOffer"],
    onSuccessMutation,
    createOfferError
  );

  const listCustomers = useGetCustomers([
    "customers",
    {
      from: 0,
      size: 100,
    },
  ]);

  const listUsers = useGetProfile([
    "users",
    {
      from: 0,
      size: 100,
    },
  ]);

  const createOfferFn = () => {
    setCreateLoading(true);
    createOffer.mutate({});
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "productName",
    },
    {
      title: "Thickness",
      dataIndex: "thickness",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
  ];

  return summaryList.isLoading ||
    listCustomers.isLoading ||
    listUsers.isLoading ? (
    <Skeleton active />
  ) : (
    <div style={{ width: "100%", padding: "2vh 2vw" }}>
      <Table
        style={{ width: "100%", marginTop: "2vh", borderRadius: "7px" }}
        className="offerTable"
        columns={columns}
        dataSource={
          summaryList.data.summary
            ? summaryList.data.summary.productSummaryList !== undefined
              ? summaryList.data.summary.productSummaryList
              : []
            : []
        }
        pagination={false}
        rowKey="productName"
        scroll={{
          y: 240,
        }}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row
                style={{ color: "black", backgroundColor: "#D6E7FF" }}
              >
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  {summaryList.data.summary
                    ? summaryList.data.summary.totalQuantity
                      ? summaryList.data.summary.totalQuantity
                      : 0
                    : 0}{" "}
                  slabs
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {summaryList.data.summary
                    ? summaryList.data.summary.totalPrice
                      ? summaryList.data.summary.totalPrice
                      : 0
                    : 0}{" "}
                  {summaryList.data.summary
                    ? summaryList.data.summary.totalPriceCurrencyCode
                      ? summaryList.data.summary.totalPriceCurrencyCode
                      : "USD"
                    : "USD"}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <div
        style={{
          width: "100%",
          textAlign: "center",
          paddingTop: "4vh",
        }}
      >
        <Button
          type="primary"
          className="nextButton"
          shape="round"
          style={{
            backgroundColor: "#D6E7FF",
            fontFamily: "Open Sans",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "19px",
            letterSpacing: "0em",
            color: "#0066FF",
          }}
          onClick={createOfferFn}
          loading={createLoading}
        >
          {t("globalBtn.createOffer")}
        </Button>
        <Button
          type="primary"
          shape="round"
          className="nextButton"
          style={{
            backgroundColor: "#ffc4c4",
            fontFamily: "Open Sans",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "19px",
            letterSpacing: "0em",
            color: "red",
            marginLeft: "2vw",
          }}
          onClick={() => {
            props.setOfferClicked(false);
          }}
        >
          {t("globalBtn.cancel")}
        </Button>
      </div>
    </div>
  );
}
