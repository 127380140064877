import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import CustomerSearch from "../../components/Customers/CustomerSearch";
import "../../pages/Customers/customersStyle.css";

export default function SearchCustomers({ searchResult, ...props }) {
  const [stateClick, stateClickChange] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [popUp, setPopUp] = useState(0);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  useEffect(() => {}, [
    stateClick,
    userDetails,
    popUp,
    deleteClicked,
    deleteData,
  ]);

  return (
    <Layout
      style={{
        backgroundColor: "transparent",
        zIndex: -1,
        height: "auto",
        marginBottom: "0px",
      }}
    >
      <Layout
        style={{
          padding: "0",
        }}
      >
        <CustomerSearch
          setUserDetails={setUserDetails}
          customerData={searchResult}
          stateClickChange={stateClickChange}
          popUp={popUp}
          setPopUp={setPopUp}
          setDeleteClicked={setDeleteClicked}
          setDeleteData={setDeleteData}
          searchView={true}
        />
      </Layout>
    </Layout>
  );
}
