import axios from "axios";
import {
  OFFERS_ROUTE,
  getEndPointFor,
  getHeaders,
} from "../../utils/api_endpoints";

export const createOffer = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(OFFERS_ROUTE.create)}`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const getOffer = async ({ queryKey }) => {
  const response = await axios
    .post(`${getEndPointFor(OFFERS_ROUTE.get)}`, queryKey[1])
    .then((response) => response.data);
  return response;
};

export const cancelOffer = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(OFFERS_ROUTE.update)}`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const getListOfOffers = async ({ queryKey }) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(OFFERS_ROUTE.base)}`, queryKey[1], {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const updateSlabDetailsOffers = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(OFFERS_ROUTE.base)}/slab/edit`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};
