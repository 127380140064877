import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Layout,
  Pagination,
  Row,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import ConfirmationComponent from "../../components/ConfirmationComponent";
import SuccessComponent from "../../components/SuccessComponent";
import ProfileEdit from "../../components/UserProfile/ProfileEdit";
import ProfileView from "../../components/UserProfile/ProfileView";
import UserAdd from "../../components/UserProfile/UserAdd";
import {
  useAddUser,
  useDeleteUser,
  useEditProfile,
  useGetProfile,
} from "../../queries/react-query/userProfile";
import "./UserProfile.css";
const { Header } = Layout;

export default function UserProfile() {
  const { t } = useTranslation();
  const [editClicked, setEditClicked] = useState(false);
  const [editData, setEditData] = useState([]);
  const [addClicked, setAddClicked] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [popUp, setPopUp] = useState(0);
  const [sort, setSort] = useState("");
  const [deleteData, setDeleteData] = useState(null);
  const [loadingIndex, setLoadingIndex] = useState(-1);
  const [errMsg, setErrMsg] = useState("");
  const profileDetails = useGetProfile([
    "userProfile",
    {
      from: localStorage.getItem("pageStartUser")
        ? localStorage.getItem("pageStartUser")
        : 0,
      size: localStorage.getItem("pageSizeUser")
        ? localStorage.getItem("pageSizeUser")
        : 10,
    },
  ]);

  const queryClient = useQueryClient();

  const onSuccessEdit = () => {
    setEditClicked(false);
    setPopUp(2);
    setTimeout(() => {
      queryClient.invalidateQueries(["userProfile"]);
      setPopUp(0);
    }, [4000]);
  };
  const onSuccessAdd = () => {
    setAddClicked(false);
    setPopUp(1);
    setTimeout(() => {
      queryClient.invalidateQueries(["userProfile"]);
      setPopUp(0);
    }, [4000]);
  };

  const onSuccessDelete = () => {
    setLoadingIndex(-1);
    setConfirmDelete(false);
    setPopUp(3);
    setTimeout(() => {
      queryClient.invalidateQueries(["userProfile"]);
      setPopUp(0);
    }, [4000]);
  };

  const addUserError = (ErrMsg) => {
    setErrMsg(ErrMsg);
    setAddClicked(false);
    setLoadingIndex(-1);
    setPopUp(4);
    setTimeout(() => {
      setPopUp(0);
      setErrMsg("");
    }, [4000]);
  };

  const profileAddMutate = useAddUser(
    ["userProfile"],
    onSuccessAdd,
    addUserError
  );
  const profileEditMutate = useEditProfile(["userProfile"], onSuccessEdit);
  const userDeleteMutate = useDeleteUser(["userProfile"], onSuccessDelete);

  const pageChange = (page, pageSize) => {
    // console.log(val)
    localStorage.setItem("pageStartUser", (page - 1) * 10);
    localStorage.setItem("pageSizeUser", 10);
    profileDetails.refetch();
  };
  const handleChangeSort = (event) => {
    setSort(event.target.value);
  };
  const confirmDeleteFn = () => {
    userDeleteMutate.mutate({
      userEmail: deleteData.email,
    });
    setConfirmDelete(false);
  };

  const addUserFn = () => {
    setAddClicked(true);
  };
  return (
    <Layout>
      <Content style={{ marginTop: "2vh", width: "98%", paddingLeft: "1vw" }}>
        <Layout
          style={{
            padding: "0",
          }}
        >
          {addClicked ? (
            <UserAdd
              setAddClicked={setAddClicked}
              profileMutate={profileAddMutate}
            />
          ) : profileAddMutate.isLoading ? (
            <Skeleton active />
          ) : editClicked ? (
            profileEditMutate.isLoading ? (
              <Skeleton active />
            ) : (
              <ProfileEdit
                data={editData}
                profileMutate={profileEditMutate}
                setEditClicked={setEditClicked}
              />
            )
          ) : (
            <>
              <Row className="userTableHeader">
                <Col
                  className="userTableHeader"
                  span={11}
                  style={{ paddingLeft: "90px" }}
                >
                  {t("usersHeaders.fullname")}
                </Col>
                {/* <Col span={6}>Organization</Col> */}
                <Col
                  className="userTableHeader"
                  span={7}
                  style={{ marginLeft: "-0.35vw" }}
                >
                  {t("usersHeaders.jobTitle")}
                </Col>
                <Col
                  className="userTableHeader"
                  span={6}
                  style={{ marginLeft: "-10px" }}
                >
                  {t("usersHeaders.department")}
                </Col>
                {/* <Col span = {6}> </Col> */}
              </Row>
              {profileDetails.isLoading ? (
                <Skeleton active />
              ) : (
                <Content
                  className="userBody"
                  style={{ width: "100%", height: "56vh", overflowY: "auto" }}
                >
                  {profileDetails.data === undefined ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  ) : profileDetails.data.userList.length === 0 ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  ) : (
                    profileDetails.data.userList.map((data, i) => {
                      return (
                        <ProfileView
                          setConfirmDelete={setConfirmDelete}
                          key={i}
                          data={data}
                          setEditClicked={setEditClicked}
                          setEditData={setEditData}
                          setDeleteData={setDeleteData}
                          deleteMutate={userDeleteMutate}
                          confirmDelete={confirmDelete}
                          index={i}
                          loadingIndex={loadingIndex}
                          setLoadingIndex={setLoadingIndex}
                        />
                      );
                    })
                  )}
                </Content>
              )}
              <div
                style={{
                  marginTop: "2vh",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Pagination
                  current={localStorage.getItem("pageStartUser") / 10 + 1}
                  total={
                    profileDetails.data !== undefined
                      ? profileDetails.data.totalItems
                      : 0
                  }
                  // total={50}
                  onChange={pageChange}
                />
              </div>
            </>
          )}
        </Layout>
        {confirmDelete ? (
          <Content
            style={{
              backgroundColor: "rgba(128, 128, 128, 0.5)",
              width: "100%",
              height: "75.75vh",
              zIndex: 2,
              position: "absolute",
              top: "-2vh",
              left: "0",
              borderRadius: "5px",
            }}
          >
            <Card
              style={{
                width: "30%",
                height: "auto",
                backgroundColor: "white",
                margin: "20vh 0 0 34vw",
              }}
              extra={
                <CloseOutlined
                  style={{ color: "#0066FF" }}
                  onClick={() => {
                    setConfirmDelete(false);
                  }}
                />
              }
            >
              <ConfirmationComponent
                setClose={setConfirmDelete}
                detail={`Do you want to delete user - ${deleteData.fullName}`}
                onConfirm={() => {
                  confirmDeleteFn();
                }}
              />
            </Card>
          </Content>
        ) : (
          <></>
        )}
        {popUp !== 0 ? (
          <Content
            style={{
              backgroundColor: "rgba(128, 128, 128, 0.5)",
              width: "100%",
              height: "75.75vh",
              zIndex: 2,
              position: "absolute",
              top: "-2vh",
              left: "0",
              borderRadius: "5px",
            }}
          >
            <Card
              style={{
                width: "30%",
                height: "auto",
                backgroundColor: "white",
                margin: "20vh 0 0 34vw",
              }}
              // extra={
              //   <CloseOutlined
              //     style={{ color: "#0066FF" }}
              //     onClick={() => {
              //       setPopUp(0);
              //     }}
              //   />
              // }
            >
              <SuccessComponent
                isError={popUp === 4 ? true : false}
                detail={
                  popUp === 1
                    ? "Successfully added user!!"
                    : popUp === 2
                    ? "Successfully edited user!!"
                    : popUp === 3
                    ? "Successfully deleted user!!"
                    : errMsg
                }
              />
            </Card>
          </Content>
        ) : (
          <></>
        )}
        {addClicked || editClicked ? (
          <></>
        ) : (
          <Tooltip>
            <Button
              style={{
                position: "fixed",
                right: "1vw",
                bottom: "3vh",
                width: "50px",
                height: "50px",
                fontSize: "18px",
                textAlign: "center",
              }}
              type="primary"
              className="addButton"
              icon={<PlusOutlined />}
              onClick={addUserFn}
            ></Button>
          </Tooltip>
        )}
      </Content>
    </Layout>
  );
}
