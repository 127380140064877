import { faker } from "@faker-js/faker";
import {
  belongsTo,
  createServer,
  Factory,
  hasMany,
  Model,
  RestSerializer,
} from "miragejs";
import * as API_ENPOINTS from "../utils/api_endpoints";

export function makeServer({ environment = "test" } = {}) {
  let server = createServer({
    environment,

    serializers: {
      application: RestSerializer,
      // inventory: RestSerializer.extend({
      //   include: ["slabs"],
      //   embed: true,
      // }),
    },

    models: {
      product: Model,
      inventory: Model.extend({
        bundles: hasMany(),
      }),
      bundle: Model.extend({
        slabs: hasMany(),
        inventory: belongsTo(),
      }),
      slab: Model.extend({
        bundle: belongsTo(),
      }),
    },
    factories: {
      inventory: Factory.extend({
        name() {
          return faker.commerce.productName();
        },
        total() {
          return faker.datatype.number({ min: 4, max: 30 });
        },
        available() {
          return faker.datatype.number({ max: this.total });
        },
        afterCreate(inventory, server) {
          const bundles = server.createList(
            "bundle",
            Math.floor(inventory.total / 4),
            {
              inventory,
            }
          );
          for (let i = 0; i < Math.floor(inventory.total / 4); i++) {
            bundles[i].totalSlabs = faker.datatype.number({
              max: Math.floor(inventory.total / 4),
            });
            bundles[i].avaliableSlabs = faker.datatype.number({
              min: 0,
              max: bundles[i].totalSlabs,
            });
            if (bundles[i].avaliableSlabs) {
              bundles[i].customer = "";
              bundles[i].status = "AVAILABLE";
            }
          }
          inventory.update({
            bundles,
          });
        },
      }),
      bundle: Factory.extend({
        status() {
          return "HOLD";
        },
        totalSlabs() {
          return faker.datatype.number({ max: 30 });
        },
        avaliableSlabs() {
          return faker.datatype.number({ max: this.totalSlabs });
        },
        notes() {
          return faker.lorem.sentence(5);
        },
        customer() {
          return faker.company.companyName();
        },
        afterCreate(bundle, server) {
          const slabs = server.createList("slab", bundle.totalSlabs, {
            bundle,
          });
          for (let i = 0; i < bundle.avaliableSlabs; i++) {
            slabs[i].status = "AVAILABLE";
            slabs[i].customer = "";
          }
          bundle.update({
            slabs,
          });
        },
      }),
      slab: Factory.extend({
        length() {
          return faker.datatype.number({ min: 1, max: 10 });
        },
        height() {
          return faker.datatype.number({ min: 1, max: 10 });
        },
        thickness() {
          return faker.datatype.number({ min: 1, max: 10 });
        },
        thickenssUnit() {
          return "in";
        },
        price() {
          return faker.datatype.number({ min: 1, max: 10 });
        },
        notes() {
          return faker.lorem.sentence(5);
        },
        currencyCode() {
          return "USD";
        },
        images() {
          return [
            faker.image.nature(),
            faker.image.nature(),
            faker.image.nature(),
          ];
        },
        status() {
          return "HOLD";
        },
        customer() {
          return faker.company.companyName();
        },
      }),
    },

    seeds(server) {
      server.create("product", {
        name: "Dallas White",
        skus: [
          {
            type: "slab",
            thickness: "2",
            thicknessUnit: "in",
            finish: "Mat",
            quality: "Premium",
            price: "100",
            currencyCode: "USD",
          },
          {
            type: "slab",
            thickness: "3",
            thicknessUnit: "in",
            finish: "Shiny",
            quality: "Medium",
            price: "50",
            currencyCode: "USD",
          },
          {
            type: "slab",
            thickness: "3",
            thicknessUnit: "in",
            finish: "Mat",
            quality: "Medium",
            price: "60",
            currencyCode: "USD",
          },
          {
            type: "slab",
            thickness: "3",
            thicknessUnit: "in",
            finish: "Mat",
            quality: "premium",
            price: "90",
            currencyCode: "USD",
          },
          {
            type: "block",
            quality: "Premium",
            price: "70",
            currencyCode: "USD",
          },
        ],
      });
      server.create("product", {
        name: "Absolute Black",
        skus: [
          {
            type: "slab",
            thickness: "3",
            thicknessUnit: "in",
            finish: "Shiny",
            quality: "Medium",
            price: "50",
            currencyCode: "USD",
          },
          {
            type: "block",
            quality: "Medium",
            price: "50",
            currencyCode: "USD",
          },
        ],
      });
      server.createList("inventory", 20);
    },

    routes() {
      this.get(
        `${API_ENPOINTS.PRODUCT_ROUTE.base}${API_ENPOINTS.CRUD_OPS.ALL}`,
        (schema) => {
          return schema.products.all();
        }
      );

      this.post(
        `${API_ENPOINTS.PRODUCT_ROUTE.base}${API_ENPOINTS.CRUD_OPS.ADD}`,
        (schema, request) => {
          let body = JSON.parse(request.requestBody);
          return schema.products.create(body);
        }
      );

      this.delete(
        `${API_ENPOINTS.PRODUCT_ROUTE.base}${API_ENPOINTS.CRUD_OPS.DELETE}/:id`,
        (schema, request) => {
          let id = request.params.id;
          return schema.products.find(id).destroy();
        }
      );

      this.get(
        `${API_ENPOINTS.PRODUCT_ROUTE.base}${API_ENPOINTS.CRUD_OPS.FIND}/:id`,
        (schema, request) => {
          let id = request.params.id;
          return schema.products.find(id);
        }
      );
      this.patch(
        `${API_ENPOINTS.PRODUCT_ROUTE.base}${API_ENPOINTS.CRUD_OPS.EDIT}/:id`,
        (schema, request) => {
          let newAttrs = JSON.parse(request.requestBody);
          let id = request.params.id;
          let product = schema.products.find(id);
          return product.update(newAttrs);
        }
      );
      this.patch(
        `${API_ENPOINTS.SLAB_ROUTE.base}${API_ENPOINTS.CRUD_OPS.EDIT}/:id`,
        (schema, request) => {
          let newAttrs = JSON.parse(request.requestBody);
          let id = request.params.id;
          let slab = schema.slabs.find(id);
          if (newAttrs.isOnHold !== slab.isOnHold) {
            let available = newAttrs.isOnHold
              ? slab.inventory.stock.available - 1
              : slab.inventory.stock.available + 1;
            slab.inventory.update({
              stock: {
                ...slab.inventory.stock,
                available,
              },
            });
          }

          return slab.update(newAttrs);
        }
      );
      this.get(
        `${API_ENPOINTS.INVENTORY_ROUTE.base}${API_ENPOINTS.CRUD_OPS.ALL}`,
        (schema) => {
          return schema.inventories.all();
        }
      );
      this.post(
        `${API_ENPOINTS.INVENTORY_ROUTE.base}${API_ENPOINTS.CRUD_OPS.ADD}`,
        (schema, request) => {
          let body = JSON.parse(request.requestBody);
          return schema.inventories.create(body);
        }
      );
      this.get(`${API_ENPOINTS.INVENTORY_ROUTE.bundles}`, (schema, request) => {
        let newAttrs = JSON.parse(request.requestBody);
        let id = request.params.id;
        const bundles = schema.bundles.where({ inventoryId: id });
        return bundles;
      });
      this.get(`${API_ENPOINTS.INVENTORY_ROUTE.slabs}`, (schema, request) => {
        let newAttrs = JSON.parse(request.requestBody);
        let id = request.params.id;
        let bundle_id = request.params.bundle_id;
        return schema.slabs.all();
      });
    },
  });

  return server;
}
