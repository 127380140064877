import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Descriptions, Input, Select, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

export default function ShipmentView(props) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const TimeAgo = (milliseconds) => {
    let d = new Date(milliseconds);
    let month = d.getMonth();
    let date = d.getDate();
    if (date < 10) {
      date = `0${date}`;
    }
    let year = d.getFullYear();

    return `${months[month]} ${date}, ${year}`;
  };
  return (
    <Content
      style={{
        maxHeight: "100%",
        overflowY: "auto",
        width: "100%",
      }}
    >
      {props.searchView ? (
        <></>
      ) : (
        <p style={{ textAlign: "right" }}>
          <Button
            type="text"
            style={{ color: "#0C25F3", fontSize: "large" }}
            onClick={() => {
              props.setEditClicked(true);
            }}
            icon={<EditOutlined />}
          ></Button>
        </p>
      )}
      <Content
        style={{
          borderBottom:
            "1px solid var(--neutral-background-text-white-500, #C6C6C6)",
          width: "100%",
          paddingBottom: "2vh",
          textAlign: "center",
        }}
      >
        <p className="shipmentFormHeader" style={{ margin: 0 }}>
          Billing Details
        </p>
        <Content
          style={{ paddingTop: "3vh", width: "100%", textAlign: "center" }}
        >
          <Content style={{ width: "100%" }}>
            <p className="shipmentFormInputHeader">Supplier invoice number</p>
            <p className="summaryValue">
              {props.shipmentInfo
                ? props.shipmentInfo.supplierInvoiceNumber
                  ? props.shipmentInfo.supplierInvoiceNumber
                  : "--"
                : "--"}
            </p>
          </Content>
        </Content>
        <Content
          style={{
            paddingTop: "3vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Content
            style={{ width: "100%", paddingRight: "1vw", textAlign: "center" }}
          >
            <p className="shipmentFormInputHeader">Invoice date</p>
            <p className="summaryValue">
              {props.shipmentInfo
                ? props.shipmentInfo.invoiceDate
                  ? TimeAgo(props.shipmentInfo.invoiceDate)
                  : "--"
                : "--"}
            </p>
          </Content>
          <Content style={{ width: "100%", textAlign: "center" }}>
            <p className="shipmentFormInputHeader">Payment terms</p>
            <p className="summaryValue">
              {props.shipmentInfo
                ? props.shipmentInfo.paymentTerms
                  ? props.shipmentInfo.paymentTerms
                  : "--"
                : "--"}
            </p>
          </Content>
        </Content>
        <Content
          style={{
            paddingTop: "3vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Content
            style={{ width: "100%", paddingRight: "1vw", textAlign: "center" }}
          >
            <p className="shipmentFormInputHeader">Incoterms</p>
            <p className="summaryValue">
              {props.shipmentInfo
                ? props.shipmentInfo.incoTerms
                  ? props.shipmentInfo.incoTerms
                  : "--"
                : "--"}
            </p>
          </Content>
          <Content style={{ width: "100%", textAlign: "center" }}>
            <p className="shipmentFormInputHeader">Invoice due date</p>
            <p className="summaryValue">
              {props.shipmentInfo
                ? props.shipmentInfo.invoiceDueDate
                  ? TimeAgo(props.shipmentInfo.invoiceDueDate)
                  : "--"
                : "--"}
            </p>
          </Content>
        </Content>
        <Content style={{ paddingTop: "3vh", width: "100%" }}>
          <Content style={{ width: "100%", textAlign: "center" }}>
            <p className="shipmentFormInputHeader">Payment method</p>
            <p className="summaryValue">
              {props.shipmentInfo
                ? props.shipmentInfo.paymentMethod
                  ? props.shipmentInfo.paymentMethod
                  : "--"
                : "--"}
            </p>
          </Content>
        </Content>
      </Content>
      <Content
        style={{ width: "100%", paddingBottom: "2vh", paddingTop: "2vh" }}
      >
        <p
          className="shipmentFormHeader"
          style={{ margin: 0, textAlign: "center" }}
        >
          Delivery Details
        </p>
        <Content style={{ paddingTop: "3vh", width: "100%" }}>
          <Content style={{ width: "100%", textAlign: "center" }}>
            <p className="shipmentFormInputHeader">Shipping method</p>
            <p className="summaryValue">
              {props.shipmentInfo
                ? props.shipmentInfo.shippingMethod
                  ? props.shipmentInfo.shippingMethod
                  : "--"
                : "--"}
            </p>
          </Content>
        </Content>
        {props.shipmentInfo ? (
          props.shipmentInfo.shippingMethod ? (
            props.shipmentInfo.shippingMethod === "Road" ? (
              <>
                <Content
                  style={{
                    paddingTop: "3vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Content
                    style={{
                      width: "100%",
                      paddingRight: "1vw",
                      textAlign: "center",
                    }}
                  >
                    <p className="shipmentFormInputHeader">Unloading Address</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.unloadingAddress
                          ? props.shipmentInfo.unloadingAddress
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="shipmentFormInputHeader">Contact number</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.contactNumber
                          ? props.shipmentInfo.contactNumber
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                </Content>
                <Content
                  style={{
                    paddingTop: "3vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Content
                    style={{
                      width: "100%",
                      paddingRight: "1vw",
                      textAlign: "center",
                    }}
                  >
                    <p className="shipmentFormInputHeader">Gross weight</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.grossWeight
                          ? props.shipmentInfo.grossWeight
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="shipmentFormInputHeader">Net weight</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.netWeight
                          ? props.shipmentInfo.netWeight
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                </Content>
                <Content
                  style={{
                    paddingTop: "3vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Content
                    style={{
                      width: "100%",
                      paddingRight: "1vw",
                      textAlign: "center",
                    }}
                  >
                    <p className="shipmentFormInputHeader">Freight handler</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.freightHandler
                          ? props.shipmentInfo.freightHandler
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="shipmentFormInputHeader">Booking Number</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.bookingNumber
                          ? props.shipmentInfo.bookingNumber
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                </Content>
                <Content
                  style={{
                    paddingTop: "3vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Content
                    style={{
                      width: "100%",
                      paddingRight: "1vw",
                      textAlign: "center",
                    }}
                  >
                    <p className="shipmentFormInputHeader">Loading date</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.loadingDate
                          ? TimeAgo(props.shipmentInfo.loadingDate)
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="shipmentFormInputHeader">
                      Estimate arrival date
                    </p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.estimatedArrivalDate
                          ? TimeAgo(props.shipmentInfo.estimatedArrivalDate)
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                </Content>
              </>
            ) : (
              <>
                <Content
                  style={{
                    paddingTop: "3vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Content
                    style={{
                      width: "100%",
                      paddingRight: "1vw",
                      textAlign: "center",
                    }}
                  >
                    <p className="shipmentFormInputHeader">Country of origin</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.countryOfOrigin
                          ? props.shipmentInfo.countryOfOrigin
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="shipmentFormInputHeader">Port of loading</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.portOfLoading
                          ? props.shipmentInfo.portOfLoading
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                </Content>
                <Content
                  style={{
                    paddingTop: "3vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Content
                    style={{
                      width: "100%",
                      paddingRight: "1vw",
                      textAlign: "center",
                    }}
                  >
                    <p className="shipmentFormInputHeader">
                      Destination country
                    </p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.destinationCountry
                          ? props.shipmentInfo.destinationCountry
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="shipmentFormInputHeader">
                      Port at destination
                    </p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.destinationPort
                          ? props.shipmentInfo.destinationPort
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                </Content>
                <Content
                  style={{
                    paddingTop: "3vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Content
                    style={{
                      width: "100%",
                      paddingRight: "1vw",
                      textAlign: "center",
                    }}
                  >
                    <p className="shipmentFormInputHeader">Shipping line</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.shippingLine
                          ? props.shipmentInfo.shippingLine
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="shipmentFormInputHeader">Container type</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.containerType
                          ? props.shipmentInfo.containerType
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                </Content>
                <Content
                  style={{
                    paddingTop: "3vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Content
                    style={{
                      width: "100%",
                      paddingRight: "1vw",
                      textAlign: "center",
                    }}
                  >
                    <p className="shipmentFormInputHeader">Container number</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.containerNumber
                          ? props.shipmentInfo.containerNumber
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="shipmentFormInputHeader">Seal number</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.sealNumber
                          ? props.shipmentInfo.sealNumber
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                </Content>
                <Content
                  style={{
                    paddingTop: "3vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Content
                    style={{
                      width: "100%",
                      paddingRight: "1vw",
                      textAlign: "center",
                    }}
                  >
                    <p className="shipmentFormInputHeader">Vessel</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.vessel
                          ? props.shipmentInfo.vessel
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="shipmentFormInputHeader">Insurance</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.insurance
                          ? props.shipmentInfo.insurance
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                </Content>
                <Content
                  style={{
                    paddingTop: "3vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Content
                    style={{
                      width: "100%",
                      paddingRight: "1vw",
                      textAlign: "center",
                    }}
                  >
                    <p className="shipmentFormInputHeader">Gross weight</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.grossWeight
                          ? props.shipmentInfo.grossWeight
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="shipmentFormInputHeader">Net weight</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.netWeight
                          ? props.shipmentInfo.netWeight
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                </Content>
                <Content
                  style={{
                    paddingTop: "3vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Content
                    style={{
                      width: "100%",
                      paddingRight: "1vw",
                      textAlign: "center",
                    }}
                  >
                    <p className="shipmentFormInputHeader">Freight handler</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.freightHandler
                          ? props.shipmentInfo.freightHandler
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="shipmentFormInputHeader">Booking Number</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.bookingNumber
                          ? props.shipmentInfo.bookingNumber
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                </Content>
                <Content
                  style={{
                    paddingTop: "3vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Content
                    style={{
                      width: "100%",
                      paddingRight: "1vw",
                      textAlign: "center",
                    }}
                  >
                    <p className="shipmentFormInputHeader">Loading date</p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.loadingDate
                          ? TimeAgo(props.shipmentInfo.loadingDate)
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                  <Content style={{ width: "100%", textAlign: "center" }}>
                    <p className="shipmentFormInputHeader">
                      Estimate arrival date
                    </p>
                    <p className="summaryValue">
                      {props.shipmentInfo
                        ? props.shipmentInfo.estimatedArrivalDate
                          ? TimeAgo(props.shipmentInfo.estimatedArrivalDate)
                          : "--"
                        : "--"}
                    </p>
                  </Content>
                </Content>
              </>
            )
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Content>
    </Content>
  );
}
