import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Layout, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import ConfirmationComponent from "../../components/ConfirmationComponent";
import CustomerAdd from "../../components/Customers/CustomerAdd";
import CustomerPopup from "../../components/Customers/CustomerPopup";
import CustomerSearch from "../../components/Customers/CustomerSearch";
import EditCustomer from "../../components/Customers/EditCustomer";
import Filter from "../../components/Filter";
import SuccessComponent from "../../components/SuccessComponent";
import SuccessLoading from "../../components/SuccessLoading";
import {
  useDeleteCustomer,
  useEditCustomer,
  useGetCustomers,
  useInsertCustomer,
} from "../../queries/react-query/customers";
import { CustomerConstants } from "../../utils/customers";
import "./customersStyle.css";

export default function Customers(props) {
  const queryClient = useQueryClient();
  const [stateClick, stateClickChange] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [addClicked, setAddClicked] = useState(false);
  const [popUp, setPopUp] = useState(0);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [filterObject, setFilterObject] = useState({});
  const [customerLoading, setCustomerLoading] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);

  const onSuccessAdd = () => {
    setCustomerLoading(false);
    setLoadingSuccess(true);
    setPopUp(1);
    setTimeout(() => {
      queryClient.refetchQueries(["customers"]);
    }, [1000]);
    setTimeout(() => {
      setLoadingSuccess(false);
    }, [2000]);
    setTimeout(() => {
      setPopUp(0);
    }, [3000]);
  };

  const onSuccessEdit = () => {
    setCustomerLoading(false);
    setLoadingSuccess(true);
    setPopUp(2);
    setTimeout(() => {
      queryClient.refetchQueries(["customers"]);
    }, [1000]);
    setTimeout(() => {
      setLoadingSuccess(false);
    }, [2000]);
    setTimeout(() => {
      setPopUp(0);
    }, [3000]);
  };

  const onSuccessDelete = () => {
    setLoadingSuccess(true);
    setPopUp(3);
    setTimeout(() => {
      queryClient.refetchQueries(["customers"]);
    }, [1000]);
    setTimeout(() => {
      setLoadingSuccess(false);
    }, [2000]);
    setTimeout(() => {
      setPopUp(0);
    }, [3000]);
  };
  const customerMutation = useInsertCustomer(["customers"], onSuccessAdd);
  const deleteCustomerMutation = useDeleteCustomer(
    ["customers"],
    onSuccessDelete
  );
  const customerData = useGetCustomers([
    "customers",
    {
      from: localStorage.getItem("pageStart")
        ? localStorage.getItem("pageStart")
        : 0,
      size: localStorage.getItem("pageSize")
        ? localStorage.getItem("pageSize")
        : 10,
      filterContext: filterObject,
    },
  ]);

  const confirmDelete = () => {
    deleteCustomerMutation.mutate({
      customerName: deleteData.customerName,
    });
    setDeleteClicked(false);
  };
  const editCustomerMutation = useEditCustomer(["customers"], onSuccessEdit);

  const addCustomer = () => {
    setAddClicked(true);
  };

  useEffect(() => {}, [
    stateClick,
    userDetails,
    addClicked,
    popUp,
    deleteClicked,
    deleteData,
  ]);

  const Filters = CustomerConstants.FiltersCustomers;
  return (
    <>
      <Layout
        style={{
          backgroundColor: "transparent",
          zIndex: -1,
          height: "auto",
          marginBottom: "0px",
        }}
      >
        <Layout
          style={{
            padding: "0",
          }}
        >
          {addClicked ? (
            <CustomerAdd
              setAddClicked={setAddClicked}
              customerMutation={customerMutation}
              setPopUp={setPopUp}
              customerLoading={customerLoading}
              setCustomerLoading={setCustomerLoading}
            />
          ) : // <></>
          editClicked ? (
            <EditCustomer
              data={editValue}
              setEditClicked={setEditClicked}
              editCustomerMutation={editCustomerMutation}
              customerLoading={customerLoading}
              setCustomerLoading={setCustomerLoading}
            />
          ) : (
            <>
              <Filter
                queryClient={queryClient}
                setFilterObject={setFilterObject}
                filterObject={filterObject}
                queryKey={"customers"}
                Filters={Filters}
              />
              <CustomerSearch
                setUserDetails={setUserDetails}
                setEditValue={setEditValue}
                setEditClicked={setEditClicked}
                customerData={customerData}
                stateClickChange={stateClickChange}
                popUp={popUp}
                setPopUp={setPopUp}
                deleteCustomerMutation={deleteCustomerMutation}
                customerMutation={customerMutation}
                editCustomerMutation={editCustomerMutation}
                setDeleteClicked={setDeleteClicked}
                setDeleteData={setDeleteData}
              />
            </>
          )}
        </Layout>
        {addClicked || editClicked ? (
          <></>
        ) : (
          <Tooltip>
            <Button
              style={{
                position: "fixed",
                right: "1vw",
                bottom: "3vh",
                width: "50px",
                height: "50px",
                fontSize: "18px",
                textAlign: "center",
              }}
              type="primary"
              className="addButton"
              icon={<PlusOutlined />}
              onClick={addCustomer}
            ></Button>
          </Tooltip>
        )}
      </Layout>
      {deleteClicked ? (
        <Content
          style={{
            backgroundColor: "rgba(128, 128, 128, 0.5)",
            width: "100%",
            height: "76vh",
            zIndex: 2,
            position: "absolute",
            top: "0",
            left: "0",
            borderRadius: "5px",
          }}
        >
          <Card
            style={{
              width: "30%",
              height: "auto",
              backgroundColor: "white",
              margin: "20vh 0 0 35vw",
            }}
            extra={
              <CloseOutlined
                style={{ color: "#0066FF" }}
                onClick={() => {
                  setDeleteClicked(false);
                }}
              />
            }
          >
            <ConfirmationComponent
              setClose={setDeleteClicked}
              detail={`Do you want to delete customer - ${deleteData.customerName}`}
              onConfirm={() => {
                confirmDelete();
              }}
            />
          </Card>
        </Content>
      ) : (
        <></>
      )}
      {popUp !== 0 || loadingSuccess ? (
        <Content
          style={{
            backgroundColor: "rgba(128, 128, 128, 0.5)",
            width: "100%",
            height: "76vh",
            zIndex: 2,
            position: "absolute",
            top: "-2vh",
            left: "0",
            borderRadius: "5px",
          }}
        >
          <Card
            style={{
              width: "30%",
              height: "auto",
              backgroundColor: "white",
              margin: "20vh 0 0 35vw",
            }}
            // extra={
            //   <CloseOutlined
            //     style={{ color: "#0066FF" }}
            //     onClick={() => {
            //       setPopUp(0);
            //     }}
            //   />
            // }
          >
            {loadingSuccess ? (
              <SuccessLoading
                detail={"Please wait while we update your data!!"}
              />
            ) : (
              <SuccessComponent
                detail={
                  popUp === 1
                    ? "Successfully added user!!"
                    : popUp === 2
                    ? "Successfully edited user!!"
                    : "Successfully deleted user!!"
                }
              />
            )}
          </Card>
        </Content>
      ) : (
        <></>
      )}

      {stateClick ? (
        <CustomerPopup
          stateClickChange={stateClickChange}
          userDetails={userDetails}
        />
      ) : (
        <></>
      )}
    </>
  );
}
