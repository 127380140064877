import axios from "axios";
import _ from "lodash";
import {
  CRUD_OPS,
  getEndPointFor,
  getHeaders,
  PRODUCT_ROUTE,
} from "../../utils/api_endpoints";

export const createProduct = async (values) => {
  values.skus.map((sku) => (sku.productType = "SLAB"));
  _.forEach(values, (value, key) => {
    if (_.isUndefined(value)) {
      delete values[key];
    }
  });
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(PRODUCT_ROUTE.base)}`, values, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const deleteProduct = async (id) => {
  console.log(id);
  const headers = await getHeaders();
  const response = await axios
    .delete(`${getEndPointFor(PRODUCT_ROUTE.base)}/${id}`, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const getProduct = async ({ queryKey }) => {
  const [, product] = queryKey;
  const formattedName = product.name.toLowerCase().replace(/\W+/g, "");
  const headers = await getHeaders();
  const response = await axios
    .get(`${getEndPointFor(PRODUCT_ROUTE.base)}/${formattedName}`, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const getProducts = async ({ queryKey }) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(PRODUCT_ROUTE.products)}`, queryKey[1], {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const searchProducts = async (value) => {
  const headers = await getHeaders();
  const response = axios
    .post(`${getEndPointFor(PRODUCT_ROUTE.base, "/search")}`, {
      headers: headers,
      body: JSON.stringify(value),
    })
    .then((response) => response.data);
  return response;
};

export const suggestProducts = async (value) => {
  const headers = await getHeaders();
  const response = axios
    .post(`${getEndPointFor(PRODUCT_ROUTE.base, "/suggest")}`, {
      headers: headers,
      body: JSON.stringify({
        productNamePrefix: value,
      }),
    })
    .then((response) => response.data);
  return response;
};

export const updateProduct = async (values) => {
  values.skus.map((sku) => (sku.productType = "SLAB"));
  _.forEach(values, (value, key) => {
    if (_.isUndefined(value)) {
      delete values[key];
    }
  });
  const headers = await getHeaders();
  const response = await axios
    .put(`${getEndPointFor(PRODUCT_ROUTE.base)}/${values.name}`, values, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};
