import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Layout, Space } from "antd";
import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getStates } from "country-state-picker";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { CustomerConstants } from "../../utils/customers";
import AdditionalContact from "./AdditionalContact";
const { Content } = Layout;

export default function EditCustomer(props) {
  const { t } = useTranslation();
  const [additionalDetails, setAdditionalDetails] = useState([]);
  const [editValue, setEditValue] = useState(props.data);
  const [flag, setFlag] = useState(false);
  useEffect(() => {}, [
    editValue,
    additionalDetails,
    flag,
    editValue.additionalContactList,
  ]);
  const customerType = CustomerConstants.customerType;

  const measurementSystem = CustomerConstants.measurementSystem;

  const customerZone = CustomerConstants.customerZone;

  const phoneNoCode = CustomerConstants.phoneNoCode;

  const currency = CustomerConstants.currency;

  const paymentTerms = CustomerConstants.paymentTerms;

  const paymentMethods = CustomerConstants.paymentMethod;

  const incoTerms = CustomerConstants.incoTerms;

  const shippingMode = CustomerConstants.shippingMode;

  const shippingPort = CustomerConstants.shippingPort;

  const country = CustomerConstants.country;

  const state = getStates(CustomerConstants.countryRef[editValue.country])
    ? getStates(CustomerConstants.countryRef[editValue.country])
    : [];

  const dateChange = (date, dateString) => {
    setEditValue((prevState) => ({
      ...prevState,
      sinceDate: dateString,
    }));
  };

  const goBack = () => {
    props.setEditClicked(false);
  };

  const submitClicked = () => {
    if (editValue.contactName === "" || editValue.email === "") {
      alert("Please fill the required fields!!");
    } else {
      props.setCustomerLoading(true);
      setTimeout(() => {
        props.editCustomerMutation.mutate(editValue);
        props.setEditClicked(false);
      }, [1000]);
    }
  };

  const handleDelete = (key) => {
    // console.log("2")
    const updatedComponents = additionalDetails.filter(
      (component) => component.key !== key
    );
    setAdditionalDetails(updatedComponents);
  };

  const handleExistingDelete = (key) => {
    // console.log("1")
    editValue.additionalContactList = editValue.additionalContactList.filter(
      (component) => component.key !== key
    );
    setFlag(flag ? false : true);
  };

  const addContact = () => {
    const key = Date.now().toString();
    setAdditionalDetails([
      ...additionalDetails,
      <AdditionalContact
        key={key}
        key1={key}
        value={null}
        setEditValue={setEditValue}
        data={editValue}
        phoneNoCode={phoneNoCode}
        onDelete={() => handleDelete(key)}
      />,
    ]);
  };
  return (
    <Content
      style={{
        width: "100%",
        padding: "0 24px 0 24px",
        margin: 0,
        minHeight: 280,
      }}
    >
      <Content
        style={{
          width: "100%",
          borderBottom:
            "0.5px solid var(--neutral-background-text-white-900, #797979)",
          paddingBottom: "4vh",
        }}
      >
        <h1 className="addEmployeeProfile">{t("customers.customerProfile")}</h1>
        <h1 className="subHeaderProfile">{t("customers.businessDetails")}</h1>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p
            className="inputHeaderBussinessName"
            style={{ textAlign: "center" }}
          >
            {t("customers.registeredBusinessName")}
          </p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <p className="profileValues">
                {editValue.customerName === "--" ? "" : editValue.customerName}
              </p>
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.customerType")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={customerType.map((option) => ({
                  label: option.label,
                }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                defaultValue={{
                  label: editValue.customerType
                    ? editValue.customerType
                    : "Select",
                }}
                onChange={(event, newValue) => {
                  setEditValue((prev) => ({
                    ...prev,
                    customerType: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.customerType")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.customerZone")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={customerZone.map((option) => ({ label: option }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                defaultValue={{
                  label: editValue.customerZone
                    ? editValue.customerZone
                    : "Select",
                }}
                onChange={(event, newValue) => {
                  setEditValue((prev) => ({
                    ...prev,
                    customerZone: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.customerZone")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.sinceDate")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  sx={{ width: "30vw" }}
                  defaultValue={dayjs()}
                  onChange={(date) => {
                    if (date) {
                      setEditValue((prevState) => ({
                        ...prevState,
                        sinceDate: `${date.date()}-${
                          date.month() + 1
                        }-${date.year()}`,
                      }));
                    }
                  }}
                />
              </LocalizationProvider>
            </Content>
          </Content>
        </Content>
      </Content>
      <Content
        style={{
          width: "100%",
          paddingTop: "2vh",
          borderBottom:
            "0.5px solid var(--neutral-background-text-white-900, #797979)",
          paddingBottom: "4vh",
        }}
      >
        <h1 className="subHeaderProfile">{t("customers.contactDetails")}</h1>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.contactName")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ width: "30vw" }}
                id="outlined-basic"
                label={
                  <p
                    style={editValue.contactName === "" ? { color: "red" } : {}}
                  >
                    {t("customers.contactName")}
                  </p>
                }
                variant="outlined"
                defaultValue={
                  editValue.contactName === "--" ? "" : editValue.contactName
                }
                onChange={(e) => {
                  e.preventDefault();
                  setEditValue((prevState) => ({
                    ...prevState,
                    contactName: e.target.value,
                  }));
                }}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.phoneNo")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <Space>
                <FormControl sx={{ width: "5vw" }}>
                  <InputLabel id="demo-simple-select-label">Code</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Code"
                    value={
                      editValue.phoneNumber.split(" ") === "--"
                        ? "Select"
                        : editValue.phoneNumber.split(" ")[0]
                    }
                    onChange={(e) => {
                      if (editValue.phoneNumber === undefined) {
                        setEditValue((prevState) => ({
                          ...prevState,
                          phoneNumber: e.target.value,
                        }));
                      } else {
                        const firstWord = editValue.phoneNumber.split(" ");
                        if (firstWord.length === 1)
                          setEditValue((prevState) => ({
                            ...prevState,
                            phoneNumber: e.target.value,
                          }));
                        else
                          setEditValue((prevState) => ({
                            ...prevState,
                            phoneNumber: `${e.target.value} ${firstWord[1]}`,
                          }));
                      }
                    }}
                  >
                    {phoneNoCode.map((phoneNumber, i) => {
                      return (
                        <MenuItem key={i} value={phoneNumber.value}>
                          {phoneNumber.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label={t("customers.phoneNo")}
                  variant="outlined"
                  sx={{ width: "25vw" }}
                  defaultValue={
                    editValue.phoneNumber.split(" ") === "--"
                      ? ""
                      : editValue.phoneNumber.split(" ").length === 1
                      ? ""
                      : editValue.phoneNumber.split(" ")[1]
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    const firstWord = editValue.phoneNumber.split(" ");

                    setEditValue((prevState) => ({
                      ...prevState,
                      phoneNumber: `${firstWord[0]} ${e.target.value}`,
                    }));
                  }}
                />
              </Space>
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.email")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ width: "30vw" }}
                id="outlined-basic"
                label={
                  <p style={editValue.email === "" ? { color: "red" } : {}}>
                    {t("customers.email")}
                  </p>
                }
                variant="outlined"
                defaultValue={editValue.email === "--" ? "" : editValue.email}
                onChange={(e) => {
                  e.preventDefault();
                  setEditValue((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }));
                }}
              />
            </Content>
          </Content>
        </Content>
        <Content
          style={{
            width: "100%",
            paddingBottom: "2vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            className="inputHeader"
            style={{ width: "15vw", marginBottom: "0" }}
          >
            {t("customers.shareInventory")}
          </p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <FormControl style={{ marginLeft: "-23vw" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={
                    editValue.shareInventory
                      ? `${editValue.shareInventory}`
                      : "false"
                  }
                  name="radio-buttons-group"
                  onChange={(event) => {
                    const newValue = event.target.value === "true"; // Convert string to boolean
                    setEditValue((prevState) => ({
                      ...prevState,
                      shareInventory: newValue,
                    }));
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Content>
          </Content>
        </Content>
      </Content>
      <Content
        style={{
          width: "100%",
          borderBottom:
            "0.5px solid var(--neutral-background-text-white-900, #797979)",
          paddingBottom: "4vh",
        }}
      >
        <h1 className="subHeaderProfile">
          {t("customers.additionalContactDetails")}
        </h1>
        {editValue.additionalContactList ? (
          editValue.additionalContactList.map((value) => {
            return (
              <AdditionalContact
                key={value.id}
                key1={value.id}
                value={value}
                setEditValue={setEditValue}
                data={editValue}
                phoneNoCode={phoneNoCode}
                onDelete={() => handleExistingDelete(value.id)}
              />
            );
          })
        ) : (
          <></>
        )}
        {additionalDetails.map((component) => component)}
        <Content
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            paddingRight: "2vw",
          }}
        >
          <Button
            type="link"
            className="additionalContactBtn"
            onClick={addContact}
            icon={<PlusCircleOutlined />}
          >
            {t("customers.addAdditionalContact")}
          </Button>
        </Content>
      </Content>
      <Content
        style={{
          width: "100%",
          borderBottom:
            "0.5px solid var(--neutral-background-text-white-900, #797979)",
          paddingBottom: "4vh",
        }}
      >
        <h1 className="subHeaderProfile">{t("customers.purchasing")}</h1>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.currency")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={currency.map((option) => ({ label: option.label }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                defaultValue={{
                  label: editValue.currency ? editValue.currency : "Select",
                }}
                onChange={(event, newValue) => {
                  setEditValue((prev) => ({
                    ...prev,
                    currency: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.currency")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.paymentTerms")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={paymentTerms.map((option) => ({
                  label: option.label,
                }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                defaultValue={{
                  label: editValue.paymentTerms
                    ? editValue.paymentTerms
                    : "Select",
                }}
                onChange={(event, newValue) => {
                  setEditValue((prev) => ({
                    ...prev,
                    paymentTerms: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.paymentTerms")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.paymentMethod")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={paymentMethods.map((option) => ({
                  label: option.label,
                }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                defaultValue={{
                  label: editValue.paymentMethod
                    ? editValue.paymentMethod
                    : "Select",
                }}
                onChange={(event, newValue) => {
                  setEditValue((prev) => ({
                    ...prev,
                    paymentMethod: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.paymentMethod")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.measurementSystem")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={measurementSystem.map((option) => ({ label: option }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                defaultValue={{
                  label: editValue.measurementSystem
                    ? editValue.measurementSystem
                    : "Select",
                }}
                onChange={(event, newValue) => {
                  setEditValue((prev) => ({
                    ...prev,
                    measurementSystem: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("customers.measurementSystem")}
                  />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.incoTerms")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={incoTerms.map((option) => ({ label: option.label }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                defaultValue={{
                  label: editValue.incoTerms ? editValue.incoTerms : "Select",
                }}
                onChange={(event, newValue) => {
                  setEditValue((prev) => ({
                    ...prev,
                    incoTerms: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.incoTerms")} />
                )}
              />
            </Content>
          </Content>
        </Content>
      </Content>
      <Content
        style={{
          width: "100%",
          borderBottom:
            "0.5px solid var(--neutral-background-text-white-900, #797979)",
          paddingBottom: "4vh",
        }}
      >
        <h1 className="subHeaderProfile">{t("customers.shippingDetails")}</h1>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.shippingMode")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={shippingMode.map((option) => ({
                  label: option.label,
                }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                defaultValue={{
                  label: editValue.shippingMode
                    ? editValue.shippingMode
                    : "Select",
                }}
                onChange={(event, newValue) => {
                  setEditValue((prev) => ({
                    ...prev,
                    shippingMode: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.shippingMode")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.shippingPort")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={shippingPort.map((option) => ({ label: option }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                defaultValue={{
                  label: editValue.shippingPort
                    ? editValue.shippingPort
                    : "Select",
                }}
                onChange={(event, newValue) => {
                  setEditValue((prev) => ({
                    ...prev,
                    shippingPort: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.shippingPort")} />
                )}
              />
            </Content>
          </Content>
        </Content>
      </Content>
      <Content style={{ width: "100%", paddingBottom: "4vh" }}>
        <h1 className="subHeaderProfile">{t("customers.addressDetails")}</h1>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.addressLine1")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ width: "30vw" }}
                id="outlined-basic"
                label={t("customers.addressLine1")}
                variant="outlined"
                defaultValue={
                  editValue.address1 === "--" ? "" : editValue.address1
                }
                onChange={(e) => {
                  e.preventDefault();
                  setEditValue((prevState) => ({
                    ...prevState,
                    address1: e.target.value,
                  }));
                }}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.addressLine2")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ width: "30vw" }}
                id="outlined-basic"
                label={t("customers.addressLine2")}
                variant="outlined"
                defaultValue={
                  editValue.address2 === "--" ? "" : editValue.address2
                }
                onChange={(e) => {
                  e.preventDefault();
                  setEditValue((prevState) => ({
                    ...prevState,
                    address2: e.target.value,
                  }));
                }}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.zipCode")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ width: "30vw" }}
                id="outlined-basic"
                label={t("customers.zipCode")}
                variant="outlined"
                defaultValue={
                  editValue.zipCode === "--" ? "" : editValue.zipCode
                }
                onChange={(e) => {
                  e.preventDefault();
                  setEditValue((prevState) => ({
                    ...prevState,
                    zipCode: e.target.value,
                  }));
                }}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.country")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={country.map((option) => ({ label: option }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                defaultValue={{
                  label: editValue.country ? editValue.country : "Select",
                }}
                onChange={(event, newValue) => {
                  setEditValue((prev) => ({
                    ...prev,
                    country: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.country")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeaderEdit">{t("customers.state")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={state.map((option) => ({ label: option }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                defaultValue={{
                  label: editValue.state ? editValue.state : "Select",
                }}
                onChange={(event, newValue) => {
                  setEditValue((prev) => ({
                    ...prev,
                    state: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("customers.state")}
                    disabled={editValue.country === "" ? true : false}
                  />
                )}
              />
            </Content>
          </Content>
        </Content>
      </Content>
      <Content
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "1vh 0 0 0",
        }}
      >
        <Button
          className="companyEditButton"
          shape="round"
          size="large"
          style={{ borderRadius: "100px", marginLeft: "2vw" }}
          onClick={goBack}
        >
          {t("globalBtn.cancel")}
        </Button>
        <Button
          className="contactSupplierBtn"
          shape="round"
          size="large"
          style={{
            borderRadius: "100px",
            marginRight: "2vw",
          }}
          onClick={submitClicked}
          loading={props.customerLoading}
          disabled={props.customerLoading}
        >
          {t("globalBtn.save")}
        </Button>
      </Content>
      {/* <Content className="searchHeader">
        <p
          style={{
            margin: "0 0 1.5vh 0",
          }}
        >
          {editValue.customerName}
        </p>
      </Content>

      <Content className="customerBlueDiv">
        <Content className="addCustomerHeader">
          <Avatar
            id="Avatar"
            style={{
              backgroundColor: "#1055BB",
              verticalAlign: "middle",
            }}
            size="large"
            gap={4}
          >
            {editValue.customerName ? editValue.customerName.charAt(0) : "P"}
          </Avatar>
          <Descriptions
            layout="vertical"
            style={{ width: "90%" }}
            column={4}
            labelStyle={{
              fontFamily: "'Open Sans', sans-serif",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "100%",
              letterSpacing: "0.005em",
              color: "#A2A3A5",
            }}
          >
            <Descriptions.Item label="Name">
              {editValue.customerName === "--" ? "" : editValue.customerName}
            </Descriptions.Item>
            <Descriptions.Item label="Customer type">
              <Select
                className="my-select-container"
                defaultValue={
                  editValue.customerType === "--"
                    ? "Select"
                    : editValue.customerType
                }
                style={{
                  width: 120,
                }}
                onChange={(e) => {
                  setEditValue((prevState) => ({
                    ...prevState,
                    customerType: e,
                  }));
                }}
                options={customerType}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Customer zone">
              <Select
                className="my-select-container"
                defaultValue={
                  editValue.customerZone === "--"
                    ? "Select"
                    : editValue.customerZone
                }
                style={{
                  width: 120,
                }}
                onChange={(e) => {
                  setEditValue((prevState) => ({
                    ...prevState,
                    customerZone: e,
                  }));
                }}
                options={customerZone}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Since date">
              <DatePicker
                onChange={dateChange}
                placeholder={
                  editValue.sinceDate === "--"
                    ? "DD-MM-YYYY"
                    : editValue.sinceDate
                }
              />
            </Descriptions.Item>
          </Descriptions>
        </Content>
        <hr style={{ margin: "2px 0 10px 0" }} />

        <Descriptions
          className="Header"
          title="Contact details"
          style={{ paddingLeft: "2.5vw" }}
          layout="vertical"
          labelStyle={{
            fontFamily: "'Open Sans', sans-serif",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "100%",
            letterSpacing: "0.005em",
            color: "#A2A3A5",
          }}
        >
          <Descriptions.Item className="Body" label="Contact name">
            <Input
              placeholder="Enter Contact name"
              className="inputFieldAddCustomer"
              style={{
                width: 200,
              }}
              defaultValue={
                editValue.contactName === "--" ? "" : editValue.contactName
              }
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  contactName: e.target.value,
                }));
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item className="Body" label="Phone no.">
            <Select
              className="my-select-container"
              defaultValue={
                editValue.phoneNumber.split(" ") === "--"
                  ? "Select"
                  : editValue.phoneNumber.split(" ")[0]
              }
              style={{
                width: 70,
              }}
              onChange={(e) => {
                const firstWord = editValue.phoneNumber.split(" ");
                if (firstWord.length === 1)
                  setEditValue((prevState) => ({
                    ...prevState,
                    phoneNumber: e,
                  }));
                else
                  setEditValue((prevState) => ({
                    ...prevState,
                    phoneNumber: `${e} ${firstWord[1]}`,
                  }));
              }}
              options={phoneNoCode}
            />
            <Input
              style={{ padding: "5px 0 6px 0" }}
              className="inputFieldAddCustomer"
              defaultValue={
                editValue.phoneNumber.split(" ") === "--"
                  ? ""
                  : editValue.phoneNumber.split(" ").length === 1
                  ? ""
                  : editValue.phoneNumber.split(" ")[1]
              }
              onChange={(e) => {
                e.preventDefault();
                const firstWord = editValue.phoneNumber.split(" ");

                setEditValue((prevState) => ({
                  ...prevState,
                  phoneNumber: `${firstWord[0]} ${e.target.value}`,
                }));
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item className="Body" label="Email">
            <Input
              placeholder="Enter Email"
              className="inputFieldAddCustomer"
              defaultValue={editValue.email === "--" ? "" : editValue.email}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }));
              }}
            />
          </Descriptions.Item>
        </Descriptions>
        <Content>
          {editValue.additionalContactList.map((value) => {
            return (
              <AdditionalContact
                key={value.id}
                key1={value.id}
                value={value}
                setEditValue={setEditValue}
                data={editValue}
                phoneNoCode={phoneNoCode}
                onDelete={() => handleExistingDelete(value.id)}
              />
            );
          })}
        </Content>
        <Content>{additionalDetails.map((component) => component)}</Content>
        <Content
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            paddingLeft: "1.5vw",
          }}
        >
          <Button
            type="link"
            onClick={addContact}
            icon={<PlusCircleOutlined />}
          >
            Add Additional Contact
          </Button>
        </Content>

        <Descriptions
          className="Header"
          title="Purchasing"
          style={{
            margin: "1vh 0 0 2.5vw",
          }}
          layout="vertical"
          labelStyle={{
            fontFamily: "'Open Sans', sans-serif",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "100%",
            letterSpacing: "0.005em",
            color: "#A2A3A5",
          }}
        >
          <Descriptions.Item className="Body" label="Currency">
            <Select
              className="my-select-container"
              defaultValue={
                editValue.currency === "--" ? "Select" : editValue.currency
              }
              style={{
                width: 120,
              }}
              onChange={(e) => {
                setEditValue((prevState) => ({
                  ...prevState,
                  currency: e,
                }));
              }}
              options={currency}
            />
          </Descriptions.Item>
          <Descriptions.Item className="Body" label="Payment terms">
            <Select
              className="my-select-container"
              defaultValue={
                editValue.paymentTerms === "--"
                  ? "Select"
                  : editValue.paymentTerms
              }
              style={{
                width: 250,
              }}
              onChange={(e) => {
                setEditValue((prevState) => ({
                  ...prevState,
                  paymentTerms: e,
                }));
              }}
              options={paymentTerms}
            />
          </Descriptions.Item>
          <Descriptions.Item className="Body" label="Payment Method" span={2}>
            <Select
              className="my-select-container"
              defaultValue={
                editValue.paymentMethod === "--"
                  ? "Select"
                  : editValue.paymentMethod
              }
              style={{
                width: 220,
              }}
              onChange={(e) => {
                setEditValue((prevState) => ({
                  ...prevState,
                  paymentMethod: e,
                }));
              }}
              options={paymentMethods}
            />
          </Descriptions.Item>
          <Descriptions.Item className="Body" label="Measurement System">
            <Select
              className="my-select-container"
              defaultValue={
                editValue.measurementSystem === "--"
                  ? "Select"
                  : editValue.measurementSystem
              }
              style={{
                width: 120,
              }}
              onChange={(e) => {
                setEditValue((prevState) => ({
                  ...prevState,
                  measurementSystem: e,
                }));
              }}
              options={customerZone}
            />
          </Descriptions.Item>
          <Descriptions.Item className="Body" label="Incoterms">
            <Select
              className="my-select-container"
              defaultValue={
                editValue.incoTerms === "--" ? "Select" : editValue.incoTerms
              }
              style={{
                width: 250,
              }}
              onChange={(e) => {
                setEditValue((prevState) => ({
                  ...prevState,
                  incoTerms: e,
                }));
              }}
              options={incoTerms}
            />
          </Descriptions.Item>
        </Descriptions>

        <Row style={{ margin: "1vh 0 0 0" }}>
          <Col span={8}>
            <Descriptions
              className="Header"
              title="Shipping details"
              style={{ paddingLeft: "2.5vw" }}
              layout="vertical"
              labelStyle={{
                fontFamily: "'Open Sans', sans-serif",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "100%",
                letterSpacing: "0.005em",
                color: "#A2A3A5",
              }}
            >
              <Descriptions.Item
                className="Body"
                label="Shipping mode"
                span={3}
              >
                <Select
                  className="my-select-container"
                  defaultValue={
                    editValue.shippingMode === "--"
                      ? "Select"
                      : editValue.shippingMode
                  }
                  style={{
                    width: 120,
                  }}
                  onChange={(e) => {
                    setEditValue((prevState) => ({
                      ...prevState,
                      shippingMode: e,
                    }));
                  }}
                  options={shippingMode}
                />
              </Descriptions.Item>
              <Descriptions.Item
                className="Body"
                label="Shipping port"
                span={3}
              >
                <Select
                  className="my-select-container"
                  defaultValue={
                    editValue.shippingPort === "--"
                      ? "Select"
                      : editValue.shippingPort
                  }
                  style={{
                    width: 120,
                  }}
                  onChange={(e) => {
                    setEditValue((prevState) => ({
                      ...prevState,
                      shippingPort: e,
                    }));
                  }}
                  options={shippingPort}
                />
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={16}>
            <Descriptions
              className="Header"
              title="Address Details"
              layout="vertical"
              labelStyle={{
                fontFamily: "'Open Sans', sans-serif",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "100%",
                letterSpacing: "0.005em",
                color: "#A2A3A5",
              }}
            >
              <Descriptions.Item className="Body" label="Address line 1">
                <Input
                  placeholder="Enter flat no., Street"
                  style={{ width: "200px" }}
                  className="inputFieldAddCustomer"
                  defaultValue={
                    editValue.address1 === "--" ? "" : editValue.address1
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    setEditValue((prevState) => ({
                      ...prevState,
                      address1: e.target.value,
                    }));
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item
                className="Body"
                label="Address line 2"
                span={3}
              >
                <Input
                  placeholder="Enter landmark"
                  style={{ width: "200px" }}
                  className="inputFieldAddCustomer"
                  defaultValue={
                    editValue.address2 === "--" ? "" : editValue.address2
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    setEditValue((prevState) => ({
                      ...prevState,
                      address2: e.target.value,
                    }));
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item className="Body" label="Zip code">
                <Input
                  placeholder="01234"
                  className="inputFieldAddCustomer"
                  defaultValue={
                    editValue.zipCode === "--" ? "" : editValue.zipCode
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    setEditValue((prevState) => ({
                      ...prevState,
                      zipCode: e.target.value,
                    }));
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item className="Body" label="State">
                <Select
                  className="my-select-container"
                  defaultValue={
                    editValue.state === "--" ? "Select" : editValue.state
                  }
                  style={{
                    width: 120,
                  }}
                  onChange={(e) => {
                    setEditValue((prevState) => ({
                      ...prevState,
                      state: e,
                    }));
                  }}
                  options={state}
                />
              </Descriptions.Item>
              <Descriptions.Item className="Body" label="Country">
                <Select
                  className="my-select-container"
                  defaultValue={
                    editValue.country === "--" ? "Select" : editValue.country
                  }
                  style={{
                    width: 120,
                  }}
                  onChange={(e) => {
                    setEditValue((prevState) => ({
                      ...prevState,
                      country: e,
                    }));
                  }}
                  options={country}
                />
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Content>
      <Content style={{ textAlign: "center", margin: "1vh 0 0 0" }}>
        <Button
          type="primary"
          size="large"
          style={{
            backgroundColor: "white",
            color: "#0677ff",
            borderColor: "#0677ff",
            marginRight: "20px",
            padding: "0 50px",
          }}
          shape="round"
          onClick={goBack}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          shape="round"
          style={{ padding: "0 50px" }}
          onClick={submitClicked}
        >
          Submit
        </Button>
      </Content> */}
    </Content>
  );
}
