import { StopOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import { useParams } from "react-router-dom";
import Heroheader from "../../components/Common/Heroheader/Heroheader";
import ProductInfoList from "../../components/Common/ProductInfoList/ProductInfoList";
import { useGetOffer } from "../../queries/react-query/offers";
import { apiQueryKeys } from "../../utils/offers";
import styles from "./offer.module.scss";

export default function Offer() {
  const params = useParams();

  const { data: offer, isLoading } = useGetOffer([
    apiQueryKeys.getOffer,
    {
      offerId: params.id,
    },
  ]);

  if (isLoading) return <Skeleton active />;

  if (offer.status === "CANCELLED") {
    return (
      <div
        className={`${styles.offerPageContainer} ${styles.offerPageContainerCancelled}`}
      >
        <div>
          <div className={styles.iconContainer}>
            <StopOutlined style={{ fontSize: "80px" }} />
          </div>
          <div className={styles.userMessage}>
            <h1>Offer is not active anymore</h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.offerPageContainer}>
      <Heroheader
        headingText={offer.tenantName}
        subHeadingText={`${offer.tenantCity}, ${offer.tenantCountry}`}
      />

      <div className={styles.productInfoList}>
        <ProductInfoList productList={offer.productInformationList} />
      </div>
    </div>
  );
}
