import { Divider, Modal } from "antd";
import { useState } from "react";

export const usePopup = (initValue) => {
  const [openPopup, setOpenPopup] = useState(initValue);
  const togglePopup = () => setOpenPopup(!openPopup);

  return {
    openPopup,
    setOpenPopup,
    togglePopup,
  };
};

export const Popup = (props) => {
  const { title, children, openPopup, togglePopup, closePopupCallback } = props;

  return (
    <Modal
      width={props.flag ? 1400 : 550}
      title={title}
      open={openPopup}
      onCancel={() => {
        if (props.isCancelFn) {
          props.cancelFn();
        }
        closePopupCallback && closePopupCallback();
        togglePopup();
      }}
      closable={props.notClosable ? false : true}
      footer={null}
    >
      <Divider />
      {children}
    </Modal>
  );
};
