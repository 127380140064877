import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { TOAST_TYPE, notify } from "../../utils/constants";
import {
  createProduct,
  deleteProduct,
  getProduct,
  getProducts,
  searchProducts,
  suggestProducts,
  updateProduct,
} from "../api/products";

export const useCreateProduct = (queryName, onError, onSuccess) =>
  useMutation({
    mutationKey: queryName,
    mutationFn: createProduct,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(TOAST_TYPE.FAIL);
      } else {
        if (
          error.response.status / 100 >= 4 &&
          error.response.status / 100 < 5
        ) {
          onError(error.response.data);
        }
      }
    },
    onSuccess: onSuccess,
  });

export const useDeleteProduct = (queryName, onSuccess, onError) => {
  const { t } = useTranslation();
  return useMutation({
    mutationKey: queryName,
    mutationFn: deleteProduct,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(TOAST_TYPE.FAIL);
      } else {
        if (
          error.response.status / 100 >= 4 &&
          error.response.status / 100 < 5
        ) {
          onError(error.response.data);
        }
      }
    },
    onSuccess: onSuccess,
  });
};

export const useGetProduct = (queryName, onSuccess, enabled) =>
  useQuery({
    queryKey: queryName,
    queryFn: getProduct,
    onSuccess: onSuccess,
    onError: () => (
      <Snackbar open={true} autoHideDuration={6000}>
        <Alert severity="error" sx={{ width: "100%" }}>
          Product not found
        </Alert>
      </Snackbar>
    ),
    enabled: enabled,
  });

export const useGetProducts = (queryName) => {
  const { t } = useTranslation();
  return useQuery({
    queryKey: queryName,
    queryFn: getProducts,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(
          t("toast.reachAdmin", { item: t("toast.product") }),
          TOAST_TYPE.FAIL
        );
      } else {
        return (
          <Snackbar open={true} autoHideDuration={6000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Error fetching products
            </Alert>
          </Snackbar>
        );
      }
    },
  });
};

export const usePostSearchProduct = (queryName) => {
  const { t } = useTranslation();
  return useMutation({
    mutationKey: queryName,
    mutationFn: searchProducts,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(
          t("toast.reachAdmin", { item: t("toast.product") }),
          TOAST_TYPE.FAIL
        );
      } else {
        return (
          <Snackbar open={true} autoHideDuration={6000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Error searching products
            </Alert>
          </Snackbar>
        );
      }
    },
  });
};

export const usePostSuggestProduct = (queryName) => {
  const { t } = useTranslation();
  return useMutation({
    mutationKey: queryName,
    mutationFn: suggestProducts,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(
          t("toast.reachAdmin", { item: t("toast.product") }),
          TOAST_TYPE.FAIL
        );
      } else {
        return (
          <Snackbar open={true} autoHideDuration={6000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Error suggesting products
            </Alert>
          </Snackbar>
        );
      }
    },
  });
};

export const useUpdateProduct = (queryName, onError, onSuccess) =>
  useMutation({
    mutationKey: queryName,
    mutationFn: updateProduct,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(TOAST_TYPE.FAIL);
      } else {
        if (
          error.response.status / 100 >= 4 &&
          error.response.status / 100 < 5
        ) {
          onError(error.response.data);
        }
      }
    },
    onSuccess: onSuccess,
  });
