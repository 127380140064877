import { Grid, InputAdornment, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { inventoryConstants } from "../utils/inventory";
import { ProductConstants } from "../utils/product";

const { qualityList, finishTypes } = ProductConstants;
const { currencies } = inventoryConstants;

function CreateSlabs({
  slab,
  bundleIndex,
  slabIndex,
  handleSlabsChange,
  addSlabValidationRegistry,
  currencyCode,
  thicknessUnit,
}) {
  const handleValueChange = (value, name) => {
    updateError(name, value);
    handleSlabsChange(value, name, bundleIndex, slabIndex);
  };

  const [imageUrl, setImageUrl] = useState("");
  const [imageSize, setImageSize] = useState(false);
  const [errorTracker, setErrorTracker] = useState({});
  const { t } = useTranslation();

  const isValidForm = () => {
    const errors = {};
    for (const property in slab) {
      if (!["picture", "isValid"].includes(property)) {
        errors[property] = !!slab[property];
      }
    }
    setErrorTracker(errors);
    let isValid = Object.values(errors).every((b) => b === true);
    handleSlabsChange(isValid, "isValid", bundleIndex, slabIndex);
    return isValid;
  };

  addSlabValidationRegistry(slabIndex, isValidForm);

  const updateError = (name, value) => {
    let ref = { ...errorTracker };
    ref[name] = !!value;
    let isValid = Object.values(ref).every((b) => b === true);
    if (slab.isValid !== isValid) {
      handleSlabsChange(isValid, "isValid", bundleIndex, slabIndex);
    }
    setErrorTracker(ref);
  };
  useEffect(() => {
    if (slab.picture) {
      setImageUrl(URL.createObjectURL(slab.picture));
    }
  }, [slab.picture]);
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={3}>
        <TextField
          label={t("slab.labels.noOfSlabs")}
          value={slab.qty}
          error={errorTracker.qty === false}
          type="number"
          name="numberOfSlabs"
          onChange={({ target }) => {
            handleValueChange(target.value, "qty");
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label={"Gross length"}
          value={slab.grossLength}
          error={errorTracker.grossLength === false}
          type="number"
          name="slabgrosslength"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{thicknessUnit}</InputAdornment>
            ),
          }}
          onChange={({ target }) => {
            handleValueChange(target.value, "grossLength");
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label={"Gross Width"}
          value={slab.grossWidth}
          error={errorTracker.grossWidth === false}
          type="number"
          name="slabgrosswidth"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{thicknessUnit}</InputAdornment>
            ),
          }}
          onChange={({ target }) => {
            handleValueChange(target.value, "grossWidth");
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label={"Net length"}
          value={slab.netLength}
          error={errorTracker.netLength === false}
          type="number"
          name="slabnetlength"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{thicknessUnit}</InputAdornment>
            ),
          }}
          onChange={({ target }) => {
            handleValueChange(target.value, "netLength");
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label={"Net Width"}
          value={slab.netWidth}
          error={errorTracker.netWidth === false}
          type="number"
          name="slabnetwidth"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{thicknessUnit}</InputAdornment>
            ),
          }}
          onChange={({ target }) => {
            handleValueChange(target.value, "netWidth");
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label={t("slab.labels.thickness")}
          value={slab.thickness}
          error={errorTracker.thickness === false}
          type="number"
          name="thickness"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{thicknessUnit}</InputAdornment>
            ),
          }}
          onChange={({ target }) => {
            handleValueChange(target.value, "thickness");
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={t("slab.labels.quality")}
          error={errorTracker.quality === false}
          value={slab.quality}
          name="quality"
          select
          onChange={({ target }) => {
            handleValueChange(target.value, "quality");
          }}
        >
          {qualityList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={t("slab.labels.finish")}
          error={errorTracker.finish === false}
          value={slab.finish}
          name="finish"
          select
          onChange={({ target }) => {
            handleValueChange(target.value, "finish");
          }}
        >
          {finishTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={t("slab.labels.price")}
          error={errorTracker.price === false}
          value={slab.price}
          type="number"
          name="price"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currencies[currencyCode]}
              </InputAdornment>
            ),
          }}
          onChange={({ target }) => {
            handleValueChange(target.value, "price");
          }}
        />
      </Grid>
      <Grid item xs={9} />
      {/*<Grid item xs={4}>
        <input
          accept="image/*"
          type="file"
          id="select-image"
          style={{ display: "none" }}
          onChange={({ target }) => {
            handleValueChange(target.files[0], "picture");
          }}
        />
        <label htmlFor="select-image">
          <Button variant="contained" color="primary" component="span">
            Upload Image
          </Button>
        </label>
      </Grid>
       <Grid item xs={8}>
        {imageUrl && slab.picture && (
          <Box
            mt={2}
            textAlign="center"
            onMouseOver={() => {
              setImageSize(true);
            }}
            onClick={() => {
              setImageSize(true);
            }}
            onMouseOut={() => {
              setImageSize(false);
            }}
          >
            <div>Image Preview:</div>
            <img
              src={imageUrl}
              alt={slab.picture.name}
              height="50px"
              width="50px"
            />
            <Zoom
              in={imageSize}
              style={{ transitionDelay: imageSize ? "500ms" : "0ms" }}
            >
              <img
                src={imageUrl}
                alt={slab.picture.name}
                height="300px"
                width="300px"
                style={{
                  top: "20%",
                  position: "absolute",
                  left: "20%",
                  zIndex: "1",
                }}
              />
            </Zoom>
          </Box>
        )}
      </Grid> */}
    </Grid>
  );
}

export default CreateSlabs;
