const routes = [
  {
    path: "/",
    name: "Home",
    exact: "true",
  },
  {
    path: "/inventory",
    name: "Inventory",
    exact: true,
  },
  {
    path: "/sales",
    name: "Sales",
    exact: true,
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    exact: true,
  },
  {
    path: "/myStore",
    name: "MyStore",
    exact: true,
  },
  {
    path: "/settings",
    name: "Settings",
    exact: true,
  },
  {
    path: "/cart",
    name: "Cart",
    exact: true,
  },
  {
    path: "/myProfile",
    name: "MyProfile",
    exact: "true",
  },
  {
    path: "/search",
    name: "Search",
    exact: true,
  },
  {
    path: "/messages",
    name: "Messages",
    exact: true,
  },
  {
    path: "/help",
    name: "Help",
    exact: true,
  },
  {
    path: "/offer/:id",
    name: "Offer",
    exact: true,
  },
];

export default routes;
