import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Col, Divider, Row, Space, Table, Tooltip } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import "../../styles/products.css";
import CollapsiblePanel from "../CollapsiblePanel";
import "./style.scss";

const fields = ["name", "category", "colour", "location"];

const ProductCard = ({ product, onEdit, setUpdateMode, ...props }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("productHeaders.thickness"),
      dataIndex: "thickness",
      key: "thickness",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("productHeaders.finish"),
      dataIndex: "finish",
      key: "finish",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("productHeaders.quality"),
      dataIndex: "quality",
      key: "quality",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("productHeaders.price"),
      dataIndex: "price",
      key: "price",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
  ];

  return (
    <CollapsiblePanel
      className="salesCollapse"
      panelStyle={{
        style: {
          marginBottom: "24px",
          padding: "6px 0",
          borderTop: "0",
          borderRight: "0",
          borderLeft: "0",
          borderRadius: "8px",
          borderBottom:
            "0.75px solid var(--neutral-background-text-white-300, #E6E6E6)",
          background: "var(--neutral-background-text-white-100, #F9F9F9)",
        },
      }}
      panelProps={{
        header: (
          <Row>
            {_.map(fields, (field) =>
              field === "name" ? (
                <Col
                  id="productName1"
                  span={6}
                  key={`${product.name}-${field}`}
                >
                  {!_.isUndefined(product[field]) ? product[field] : "-"}
                </Col>
              ) : (
                <Col
                  id="otherDetails1"
                  span={6}
                  key={`${product.name}-${field}`}
                >
                  {!_.isUndefined(product[field]) ? product[field] : "-"}
                </Col>
              )
            )}
          </Row>
        ),
        key: "1",

        extra: props.searchView ? (
          <></>
        ) : (
          <Space
            split={<Divider style={{ borderColor: "#000" }} type="vertical" />}
          >
            <Tooltip
              arrow={{ pointAtCenter: true }}
              placement="topRight"
              title={t("tooltip.editProduct")}
            >
              <EditOutlined
                style={{ color: "#0066FF" }}
                onClick={(event) => {
                  event.stopPropagation();
                  setUpdateMode(true);
                  onEdit(product);
                }}
              />
            </Tooltip>
            <Tooltip
              arrow={{ pointAtCenter: true }}
              placement="topRight"
              title={t("tooltip.deleteProduct")}
            >
              <DeleteOutline
                style={{ color: "red" }}
                onClick={(event) => {
                  event.stopPropagation();
                  // setUpdateMode(true);
                  // onEdit(product);
                  props.setDeleteData({ name: product.name });
                  props.setConfirmDelete(true);
                }}
              />
            </Tooltip>
          </Space>
        ),
      }}
      collapseProps={{
        ghost: true,
        size: "large",
        style: { backgroundColor: "#FAFAFA" },
        key: product.name,
      }}
    >
      <Table
        style={{ background: "none" }}
        className="productTable"
        columns={columns}
        dataSource={product?.skus}
        pagination={{
          hideOnSinglePage: true,
        }}
        rowKey="name"
      />
    </CollapsiblePanel>
  );
};

export default ProductCard;
