import axios from "axios";
import {
  SEARCH_ROUTE,
  getEndPointFor,
  getHeaders,
} from "../../utils/api_endpoints";

export const fetchResults = async ({ queryKey }) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SEARCH_ROUTE.base)}`, queryKey[1], {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};
