import { Delete } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { inventoryConstants } from "../utils/inventory";
import CreateSlabs from "./CreateSlabs";

const { getEmptySlab } = inventoryConstants;

function CreateBundle({
  inventory,
  setInventory,
  bundle,
  bundleIndex,
  addToBundleValidationRegistry,
  removeFromBundleValidationRegistry,
}) {
  let slabValidationRegistry = [];
  const { t } = useTranslation();
  const bundleValidation = () => {
    return slabValidationRegistry.reduce(
      (currentValue, slabFn) => !!slabFn() && currentValue,
      true
    );
  };
  addToBundleValidationRegistry({
    bundleIndex,
    bundleFn: bundleValidation,
  });

  const addSlabValidationRegistry = (slabIndex, fn) => {
    slabValidationRegistry[slabIndex] = fn;
  };

  const createASlab = (bundleIndex) => {
    let inventoryRef = { ...inventory };
    let emptySlab = getEmptySlab(inventory);
    inventoryRef.bundles[bundleIndex].slabs.push(emptySlab);
    setInventory(inventoryRef);
  };

  const handleRemoveBundle = (bundleIndex) => {
    let inventoryRef = { ...inventory };
    let bundles = inventoryRef.bundles;
    bundles = [
      ...bundles.slice(0, bundleIndex),
      ...bundles.slice(bundleIndex + 1),
    ];
    --inventoryRef.bundleNumber;
    setInventory({ ...inventoryRef, bundles });
    removeFromBundleValidationRegistry(bundleIndex);
  };

  const handleRemoveSlab = (bundleIndex, slabIndex) => {
    let inventoryRef = { ...inventory };
    let slabs = inventoryRef.bundles[bundleIndex].slabs;
    slabs = [...slabs.slice(0, slabIndex), ...slabs.slice(slabIndex + 1)];
    inventoryRef.bundles[bundleIndex].slabs = slabs;
    setInventory({ ...inventoryRef });
    slabValidationRegistry = [
      ...slabValidationRegistry.slice(0, slabIndex),
      ...slabValidationRegistry.slice(slabIndex + 1),
    ];
  };
  const isBundleValid = (bundle) => {
    bundle.isValid = bundle.slabs.every(({ isValid }) => isValid);
  };
  const handleSlabsChange = (value, name, bundleIndex, slabIndex) => {
    let inventoryNew = { ...inventory };
    inventoryNew.bundles[bundleIndex]["slabs"][slabIndex][name] = value;
    isBundleValid(inventoryNew.bundles[bundleIndex]);
    setInventory(inventoryNew);
  };

  const handleBundleChange = (value, bundleIndex) => {
    let inventoryNew = { ...inventory };
    inventoryNew.bundles[bundleIndex]["bundleNumber"] = value;
    setInventory(inventoryNew);
  };

  return (
    <Accordion
      key={bundleIndex}
      sx={{
        border: `${bundle.isValid ? "" : "1px solid red"}`,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="Bundle-information"
        className="HeaderBundleSlab"
        sx={{
          "& .MuiAccordionSummary-content": {
            alignItems: "center",
            justifyContent: "space-between",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "7vw" }}>{`Bundle ${bundleIndex + 1}`} :</div>
          <TextField
            placeholder="Bundle number"
            onChange={(e) => {
              e.preventDefault();
              handleBundleChange(e.target.value, bundleIndex);
            }}
          />
        </div>
        {inventory.bundles.length > 1 && (
          <Tooltip title={t("createBundle.removeBundleTooltip")}>
            <IconButton onClick={(ev) => handleRemoveBundle(bundleIndex)}>
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {bundle.slabs.map((slab, slabIndex) => (
          <Accordion
            key={`${bundleIndex} - ${slabIndex}`}
            sx={{
              border: `${slab.isValid ? "" : "1px solid red"}`,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="slab-information"
              className="HeaderBundleSlab"
              sx={{
                "& .MuiAccordionSummary-content": {
                  alignItems: "center",
                  justifyContent: "space-between",
                },
              }}
            >
              {`Slab Group ${slabIndex + 1}`}
              {bundle.slabs.length > 1 && (
                <Tooltip title={t("createBundle.removeSlabTooltip")}>
                  <IconButton
                    onClick={(ev) => handleRemoveSlab(bundleIndex, slabIndex)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <CreateSlabs
                slab={slab}
                bundleIndex={bundleIndex}
                slabIndex={slabIndex}
                handleSlabsChange={handleSlabsChange}
                addSlabValidationRegistry={addSlabValidationRegistry}
                currencyCode={inventory.currencyCode}
                thicknessUnit={inventory.thicknessUnit}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
      <Button
        variant="outlined"
        className="AddSlabBundle"
        sx={{
          margin: "15px",
          borderRadius: "100px",
        }}
        onClick={() => {
          createASlab(bundleIndex);
        }}
      >
        {t("createBundle.addSlabGrp")}
      </Button>
    </Accordion>
  );
}

export default CreateBundle;
