import { PrinterOutlined } from "@ant-design/icons";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import SlabDetails from "./SlabDetails";

export default function PrintSlabDetails({ slabData }) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <PrinterOutlined
        style={{ color: "rgb(44, 63, 255)" }}
        onClick={handlePrint}
      />

      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <SlabDetails slabDataList={slabData} />
        </div>
      </div>
    </>
  );
}
