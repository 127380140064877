import { useTranslation } from "react-i18next";
import Customers from "../../pages/Customers/Customers.js";
import Sales from "../../pages/Sales";

export const Widgets = () => {
  const { t } = useTranslation();
  return [
    {
      centered: true,
      tabItems: [
        {
          label: t("subNav.offers"),
          key: "Offers",
          children: <Sales route={"OFFERS"} />,
        },
        {
          label: t("subNav.hold"),
          key: "Hold",
          children: <Sales route={"HOLD"} />,
        },
        {
          label: t("subNav.inspection"),
          key: "Inspection",
          children: <Sales route={"INSPECTION"} />,
        },
        {
          label: t("subNav.salesOrder"),
          key: "Sales Order",
          children: <Sales route={"ORDER"} />,
        },
        {
          label: t("subNav.shippedOrder"),
          key: "Shipped Order",
          children: <Sales route={"SHIPPED"} />,
        },
        {
          label: t("subNav.cancelledOrder"),
          key: "Cancelled Order",
          children: <Sales route={"CANCELLED"} />,
        },
        {
          label: t("subNav.customers"),
          key: "Customers",
          children: <Customers />,
        },
      ],
      type: "tab",
    },
  ];
};
