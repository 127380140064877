import { useTranslation } from "react-i18next";
import MarketplaceSeller from "../../components/Marketplace/MarketplaceSeller";

export const Widgets = () => {
  const { t } = useTranslation();
  return [
    {
      centered: true,
      tabItems: [
        {
          key: "My store",
          children: (
            <MarketplaceSeller supplierId={localStorage.getItem("tenantId")} />
          ),
        },
      ],
      type: "tab",
    },
  ];
};
