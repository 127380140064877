import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { TOAST_TYPE, notify } from "../../utils/constants";
import {
  addNotes,
  addToCart,
  deleteBundleImages,
  fetchBundles,
  fetchSlabs,
  getBundleImages,
  getBundleList,
  getBundleListTileView,
  getInventoryList,
  getNotesData,
  getSlabsByBundleId,
  updateSlabDetails,
} from "../api/inventory";

export const useFetchBundles = (queryKey) => {
  return useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => fetchBundles(queryKey, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return nextPage;
      },
    }
  );
};

export const useGetInventoryList = (queryKey) => {
  // TODO: Add query filter based on slab or block
  const { t } = useTranslation();
  return useQuery({
    queryKey: queryKey,
    queryFn: getInventoryList,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(
          t("toast.reachAdmin", { item: t("toast.inventory") }),
          TOAST_TYPE.FAIL
        );
      } else {
        return (
          <Snackbar open={true} autoHideDuration={6000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Error fetching products
            </Alert>
          </Snackbar>
        );
      }
    },
  });
};

export const useGetBundleList = (queryKey, onSuccess, enabled) => {
  // TODO: Add query filter based on slab or block
  const { t } = useTranslation();
  return useQuery({
    queryKey: queryKey,
    queryFn: getBundleList,
    onSuccess: onSuccess,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(
          t("toast.reachAdmin", { item: t("toast.inventory") }),
          TOAST_TYPE.FAIL
        );
      } else {
        return (
          <Snackbar open={true} autoHideDuration={6000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Error fetching products
            </Alert>
          </Snackbar>
        );
      }
    },
    enabled: enabled,
  });
};

export const useGetBundleImage = (queryKey) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: getBundleImages,
  });
};

export const useDeleteBundleOrSlabImages = (mutationKey, onSuccess) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: deleteBundleImages,
    onSuccess: (data) => onSuccess(data),
  });
};

export const useFetchNotes = (queryKey) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: getNotesData,
  });
};

export const useGetSlabsByBundleId = (queryKey, onSuccess, enabled) => {
  // TODO: Add query filter based on slab or block
  const { t } = useTranslation();
  return useQuery({
    queryKey: queryKey,
    queryFn: getSlabsByBundleId,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(
          t("toast.reachAdmin", { item: t("toast.inventory") }),
          TOAST_TYPE.FAIL
        );
      } else {
        return (
          <Snackbar open={true} autoHideDuration={6000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Error fetching products
            </Alert>
          </Snackbar>
        );
      }
    },
    enabled: enabled,
  });
};

export const useFetchSlabsInventory = (queryKey) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: fetchSlabs,
  });
};

// export const useAddInventory = (queryKey) => {
//   return useMutation({
//     mutationKey: queryKey,
//     mutationFn: addInventory,
//   });
// };

// export const usePutSlabsOnHold = () => {
//     const { t } = useTranslation();
//     return useMutation({
//         queryFn: putSlabsOnHold,
//         onError: (error) => {
//             if (error.response.status === 500) {
//                 notify(
//                     t("toast.reachAdmin", { item: t("toast.inventory") }),
//                     TOAST_TYPE.FAIL
//                 );
//             } else {
//                 return (
//                     <Snackbar open={true} autoHideDuration={6000}>
//                         <Alert severity="error" sx={{ width: "100%" }}>
//                             Error fetching products
//                         </Alert>
//                     </Snackbar>
//                 );
//             }
//         },
//         enabled: enabled,
//     });
// };

export const useAddNotes = (mutationKey, onSettled) => {
  const { t } = useTranslation();
  const notesMutation = useMutation({
    mutationKey: mutationKey,
    mutationFn: addNotes,
    onError: (error) => {
      if (error.response.status === 500) {
        notify(
          t("toast.reachAdmin", { item: t("toast.inventory") }),
          TOAST_TYPE.FAIL
        );
      } else {
        return (
          <Snackbar open={true} autoHideDuration={6000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Error adding notes
            </Alert>
          </Snackbar>
        );
      }
    },
    onSettled: onSettled,
  });
  return notesMutation;
};

export const useAddToCart = (mutationKey, onSuccess) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: addToCart,
    onSuccess: onSuccess,
  });
};

export const useUpdateSlabDetails = (mutationKey, onSuccess) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: updateSlabDetails,
    onSuccess: onSuccess,
  });
};

export const useGetBundleListTileView = (queryKey) => {
  return useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => getBundleListTileView(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return nextPage;
      },
    }
  );
};
