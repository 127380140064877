import { Button, Grid } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { Content } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";
import { inventoryConstants } from "../utils/inventory";
import CreateBundle from "./CreateBundle";
const { getEmptyBundle } = inventoryConstants;

// const useStyle = makeStyles((theme) => ({
//   buttonSeparate: {
//     margin: theme.spacing(2),
//   },
// }));

function InventoryFormStepThree({
  goToPage,
  inventory,
  setInventory,
  addInventory,
  submitForm,
}) {
  // const classes = useStyle();
  let bundleValidationRegistry = [];

  const addToBundleValidationRegistry = ({ bundleIndex, bundleFn }) => {
    bundleValidationRegistry[bundleIndex] = bundleFn;
  };

  const removeFromBundleValidationRegistry = (bundleIndex) => {
    bundleValidationRegistry = [
      ...bundleValidationRegistry.slice(0, bundleIndex),
      ...bundleValidationRegistry.slice(bundleIndex + 1),
    ];
  };
  // console.log(inventory)
  const isValidForm = () => {
    return bundleValidationRegistry.reduce(
      (currentValue, bundleFn) => !!bundleFn() && currentValue,
      true
    );
  };
  const createABundle = () => {
    let inventoryRef = { ...inventory };
    let emptyBundle = getEmptyBundle(inventory);
    inventoryRef.bundles = inventoryRef.bundles
      ? [...inventoryRef.bundles, emptyBundle]
      : [emptyBundle];
    ++inventoryRef.bundleNumber;
    setInventory(inventoryRef);
  };
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        sx={{
          "& .MuiTextField-root": { m: 1, width: "80%" },
          "& .MuiAccordion-root": { marginBottom: "10px" },
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            maxHeight: "48vh",
            overflowY: "auto",
            padding: "2vh 1vw 1vh 1vw",
            marginBottom: "1vh",
            border: "1px solid #babab8",
            borderRadius: "7px",
          }}
        >
          {inventory.bundles.map((bundle, bundleIndex) => (
            <CreateBundle
              key={bundleIndex}
              inventory={inventory}
              setInventory={setInventory}
              bundle={bundle}
              bundleIndex={bundleIndex}
              addToBundleValidationRegistry={addToBundleValidationRegistry}
              removeFromBundleValidationRegistry={
                removeFromBundleValidationRegistry
              }
            />
          ))}
        </Grid>
      </Grid>
      <Content style={{ width: "100%" }}>
        <Content style={{ width: "100%" }}>
          <Button
            variant="outlined"
            className="AddSlabBundle"
            sx={{
              padding: "1vh 1vw",
              borderRadius: "100px",
            }}
            onClick={() => {
              createABundle();
            }}
          >
            {t("inventoryForm.addBundle")}
          </Button>
        </Content>
        <Content
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "2vh",
          }}
        >
          <Button
            variant="contained"
            type="Back"
            className="nextButton"
            // className={classes.buttonSeparate}
            sx={{
              marginTop: "1vh",
              padding: "1vh 3vw",
              borderRadius: "100px",
              background: "var(--primary-blue-500, #0C25F3)",
            }}
            onClick={() => {
              goToPage(1);
            }}
          >
            {t("actions.backBtn")}
          </Button>
          <Button
            variant="contained"
            type="Next"
            className="nextButton"
            // className={classes.buttonSeparate}
            sx={{
              marginTop: "1vh",
              padding: "1vh 3vw",
              borderRadius: "100px",
              background: "var(--primary-blue-500, #0C25F3)",
            }}
            onClick={() => {
              if (isValidForm()) {
                addInventory(inventory);
                submitForm();
                // console.log(inventory);
                return;
              }
            }}
          >
            Save & Next
          </Button>
        </Content>
      </Content>
    </>
  );
}

export default InventoryFormStepThree;
