import axios from "axios";
import {
  SALES_ROUTE,
  getEndPointFor,
  getHeaders,
} from "../../utils/api_endpoints";

export const getSales = async ({ queryKey }) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SALES_ROUTE.base)}`, queryKey[1], {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const moveTo1 = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SALES_ROUTE.base)}/updateStatus`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const moveTo2 = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SALES_ROUTE.base)}/cancel`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const UpdateShipmentInfo = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SALES_ROUTE.base)}/updateShipment`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const addNotes = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SALES_ROUTE.base)}/addNote`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const updateCustomer = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SALES_ROUTE.base)}/assignCustomer`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const updateInspector = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SALES_ROUTE.base)}/assignInspector`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const getPreSignedUrl = async ({ queryKey }) => {
  const headers = await getHeaders();
  const response = await axios
    .post(
      `${getEndPointFor(SALES_ROUTE.base)}/uploadNewInspectionFile`,
      queryKey[1],
      {
        headers: headers,
      }
    )
    .then((response) => response.data);
  return response;
};

export const deleteReport = async (obj) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SALES_ROUTE.base)}/deleteInspectionFile`, obj, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const getNotesData = async ({ queryKey }) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SALES_ROUTE.base)}/get`, queryKey[1], {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const addNotesSalesSlab = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SALES_ROUTE.base)}/slab/addNote`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const updateSlabDetailsSales = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(SALES_ROUTE.base)}/slab/edit`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};
