import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import {
  contactSupplier,
  fetchBundles,
  fetchBundlesSeller,
  fetchSlabs,
  getSellerInfo,
} from "../api/marketplace";

// export const useFetchBundles = (queryKey) =>{
//     return useQuery({
//         queryKey: queryKey,
//         queryFn: fetchBundles
//     })
// }
export const useFetchBundles = (queryKey) => {
  return useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => fetchBundles(queryKey, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return nextPage;
      },
    }
  );
};

export const useFetchBundlesSeller = (queryKey) => {
  return useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => fetchBundlesSeller(pageParam, queryKey[1]),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return nextPage;
      },
    }
  );
};

export const useFetchSlabs = (queryKey) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: fetchSlabs,
  });
};

export const useSellerInfo = (queryKey) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: getSellerInfo,
  });
};

export const useContactSupplier = (queryKey, onSuccess) => {
  return useMutation({
    mutationKey: queryKey,
    mutationFn: contactSupplier,
    onSuccess: onSuccess,
  });
};
