import { Skeleton, Space } from "antd";
import Layout from "antd/es/layout/layout";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import React from "react";
import { Pie } from "react-chartjs-2";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useFetchInventoryAnalytics } from "../../queries/react-query/home";
import { QuotesConstants } from "../../utils/quotes";
import "./home.css";

ChartJS.register(
  ArcElement,
  CategoryScale,
  Tooltip,
  Legend,
  LinearScale,
  BarElement
);
// ChartJS.defaults.set('plugins.datalabels', {
//   color: 'white',
// });

export default function HomePage() {
  // const [Weekly,setWeekly] = useState(true);
  // const [Monthly,setMonthly] = useState(false);
  // const [Yearly,setYearly] = useState(false);
  // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November","December"];
  // const weeks = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  // const years = [2019,2020,2021,2022,2023];
  // const weekData1 = [10,15,8,40,30,25,38];
  // const weekData2 = [38,25,30,45,8,15,10];
  // const monthData1 = [12, 19, 3, 5, 2,11,10,11,36,20,0,9];
  // const monthData2 = [8, 10, 6, 12, 8, 0,0,9,20,0,11,36];
  // const yearData1 = [22, 23, 24, 10, 40];
  // const yearData2 = [40,24,10,23,22];
  const { t } = useTranslation();
  const quotes = QuotesConstants.quotes;
  const currentDate = new Date();
  const inventoryAnalytics = useFetchInventoryAnalytics(["InventoryAnalytics"]);
  const getText = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    let timeOfDay;

    if (currentHour >= 5 && currentHour < 12) {
      timeOfDay = "Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      timeOfDay = "Afternoon";
    } else {
      timeOfDay = "Evening";
    }
    return t(`homePage.good${timeOfDay}`);
  };

  // const data = {
  //   labels: Weekly? weeks: Monthly? months: years,
  //   datasets: [
  //     {
  //       label: "Items Bought",
  //       data: Weekly? weekData1 : Monthly? monthData1: yearData1, // First data set values
  //       backgroundColor: "rgba(75, 192, 192, 0.9)", // Color for bars in the first dataset
  //     },
  //     {
  //       label: "Items Sold",
  //       data: Weekly? weekData2 : Monthly ? monthData2 : yearData2, // Second data set values
  //       backgroundColor: "rgba(255, 99, 132, 0.9)", // Color for bars in the second dataset
  //     },
  //   ],
  // };

  // const weeklyClicked = () =>{
  //   setWeekly(true);
  //   setMonthly(false);
  //   setYearly(false);
  // };

  // const monthlyClicked = () =>{
  //   setWeekly(false);
  //   setMonthly(true);
  //   setYearly(false);
  // };

  // const yearlyClicked = () =>{
  //   setWeekly(false);
  //   setMonthly(false);
  //   setYearly(true);
  // };

  return (
    <Layout>
      <div className="HomeLayout">
        <div style={{ width: "100%", height: "100%" }} className="HomeContent">
          <div
            className="greetContent"
            style={{ width: "100%", height: "86%" }}
          >
            <div style={{ textAlign: "center" }}>
              <h1 className="greetText">
                {getText() + localStorage.getItem("userName")}
              </h1>
              <h3 className="quoteTxt">{t("homePage.beYourself")}</h3>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <p className="subQuote">
                {quotes[currentDate.getDate() % 32].quote}
              </p>
              <p className="author">
                -{quotes[currentDate.getDate() % 32].author}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", padding: "2vh 0" }}>
        <div style={{ width: "100%" }} className="AnalyticsMainHeader">
          <h2>{t("homePage.inventoryAnalytics")}</h2>
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className="subHeaderAnalytics">
            {t("homePage.totalItemsInInventory")}
          </p>
          {inventoryAnalytics.isLoading ? (
            <Skeleton active />
          ) : (
            <CountUp
              end={inventoryAnalytics.data.inventoryTotalSize}
              duration={6}
              className="counterClass"
            />
          )}
        </div>
        {inventoryAnalytics.isLoading ? (
          <Skeleton active />
        ) : (
          <div style={{ padding: "2vh 0" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
              }}
            >
              {inventoryAnalytics.data.aggregations.map((pieValues) => {
                return (
                  <div
                    style={{ padding: "2vh 2vw", width: "33.33%" }}
                    key={pieValues.name}
                  >
                    <div
                      className="chartBody"
                      style={{ textAlign: "center", width: "100%" }}
                    >
                      <p
                        className="subHeaderAnalytics"
                        style={{ marginBottom: "2vh" }}
                      >
                        {t(`filters.${pieValues.name}`)}
                      </p>
                      <Space>
                        <Pie
                          data={{
                            labels: pieValues.labels,
                            datasets: [
                              {
                                data: pieValues.values,
                                backgroundColor: [
                                  "#3498db",
                                  "#2ecc71",
                                  "#e74c3c",
                                  "#f1c40f",
                                  "#9b59b6",
                                  "#1abc9c",
                                  "#f39c12",
                                  "#e74c3c",
                                  "#2c3e50",
                                  "#d35400",
                                  "#27ae60",
                                  "#2980b9",
                                  "#c0392b",
                                  "#8e44ad",
                                  "#d35400",
                                  "#f39c12",
                                  "#34495e",
                                  "#16a085",
                                  "#8e44ad",
                                  "#c0392b",
                                  "#f1c40f",
                                  "#7f8c8d",
                                  "#3498db",
                                  "#2ecc71",
                                  "#f1c40f",
                                  "#e74c3c",
                                  "#34495e",
                                  "#16a085",
                                  "#9b59b6",
                                  "#e67e22",
                                  "#f39c12",
                                  "#c0392b",
                                  "#2980b9",
                                  "#3498db",
                                  "#2ecc71",
                                  "#e74c3c",
                                  "#f1c40f",
                                  "#9b59b6",
                                  "#1abc9c",
                                  "#f39c12",
                                  "#e74c3c",
                                  "#2c3e50",
                                  "#d35400",
                                  "#27ae60",
                                  "#2980b9",
                                  "#c0392b",
                                  "#8e44ad",
                                  "#d35400",
                                  "#f39c12",
                                  "#34495e",
                                  "#16a085",
                                ],
                                hoverBackgroundColor: [
                                  "#3498db",
                                  "#2ecc71",
                                  "#e74c3c",
                                  "#f1c40f",
                                  "#9b59b6",
                                  "#1abc9c",
                                  "#f39c12",
                                  "#e74c3c",
                                  "#2c3e50",
                                  "#d35400",
                                  "#27ae60",
                                  "#2980b9",
                                  "#c0392b",
                                  "#8e44ad",
                                  "#d35400",
                                  "#f39c12",
                                  "#34495e",
                                  "#16a085",
                                  "#8e44ad",
                                  "#c0392b",
                                  "#f1c40f",
                                  "#7f8c8d",
                                  "#3498db",
                                  "#2ecc71",
                                  "#f1c40f",
                                  "#e74c3c",
                                  "#34495e",
                                  "#16a085",
                                  "#9b59b6",
                                  "#e67e22",
                                  "#f39c12",
                                  "#c0392b",
                                  "#2980b9",
                                  "#3498db",
                                  "#2ecc71",
                                  "#e74c3c",
                                  "#f1c40f",
                                  "#9b59b6",
                                  "#1abc9c",
                                  "#f39c12",
                                  "#e74c3c",
                                  "#2c3e50",
                                  "#d35400",
                                  "#27ae60",
                                  "#2980b9",
                                  "#c0392b",
                                  "#8e44ad",
                                  "#d35400",
                                  "#f39c12",
                                  "#34495e",
                                  "#16a085",
                                ],
                              },
                            ],
                          }}
                          width={350}
                          height={400}
                          options={{
                            responsive: false,
                            maintainAspectRatio: false,
                            defaultFontSize: "20px",
                            plugins: {
                              tooltip: {
                                callbacks: {
                                  label: (tooltipItem) => {
                                    return `${tooltipItem.parsed} (${(
                                      (tooltipItem.parsed /
                                        inventoryAnalytics.data
                                          .inventoryTotalSize) *
                                      100
                                    ).toFixed(2)}%)`;
                                  },
                                },
                              },
                              legend: {
                                display: true,
                                responsive: false,
                                position: "bottom",
                                labels: {
                                  boxWidth: 15,
                                },
                                align: "center",
                              },
                            },
                          }}
                        />
                      </Space>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      {/* <Content style={{width:"100%"}}>
          <Content style={{width:"100%",height:"1px",boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.5)"}}>

          </Content>
      </Content>
      <Content style={{width:"100%", padding:"4vh 0 2vh 0"}}>
        <Content style={{width:"100%"}} className="AnalyticsMainHeader">
          <h2>Marketplace Analytics</h2>
        </Content>
        <Content style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
          <Content style={{width:"100%", textAlign:"center"}}>
            <p className="subHeaderAnalytics">Total Items Sold</p>
            <CountUp end={10000} duration={6} className="counterClass"/>
          </Content>
          <Content style={{width:"100%", textAlign:"center"}}>
            <p className="subHeaderAnalytics">Total Items Bought</p>
            <CountUp end={1000} duration={6} className="counterClass"/>
          </Content>
        </Content>
        <Content style={{padding:"2vh 0"}}>
          <Content style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"space-evenly"}}>
            <Bar data = {data} style={{
              padding: "0 2vw"
            }} height={500} options={{
              responsive: true,
              maintainAspectRatio:false,
              scales: {
                x: {
                  beginAtZero: true,
                  grid: {display: false},
                },
                y: {
                  display: false,
                },
              },
            }}/>
          </Content>
        </Content>
        <ButtonGroup style={{"margin":"2vh 0", display: "flex" , alignItems:"center", justifyContent : "center", color:"#0c25f3"}}  aria-label="medium secondary button group">
          {Weekly === true?<Button variant="contained" style={{backgroundColor:"#0c25f3"}} onClick = {weeklyClicked}>Weekly</Button>: <Button onClick = {weeklyClicked}>Weekly</Button>}
          {Monthly === true?<Button variant="contained" onClick={monthlyClicked}>Monthly</Button>:<Button onClick={monthlyClicked}>Monthly</Button>}
          {Yearly === true ? <Button variant="contained" onClick = {yearlyClicked}>Yearly</Button>:<Button onClick = {yearlyClicked}>Yearly</Button>}
          </ButtonGroup>
      </Content> */}
    </Layout>
  );
}
