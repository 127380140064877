import axios from "axios";
import {
  CUSTOMER_ROUTE,
  getEndPointFor,
  getHeaders,
} from "../../utils/api_endpoints";

const saleHistory = [
  {
    salesOrder: "1111",
    salesDate: "5 May, 23",
    product: "Absolute Black",
    productType: "Slab",
    thickness: "2 inches",
    finish: "Premium",
    price: "60 USD",
  },
  {
    salesOrder: "1112",
    salesDate: "5 May, 23",
    product: "Absolute Black",
    productType: "Slab",
    thickness: "2 inches",
    finish: "Premium",
    price: "60 USD",
  },
  {
    salesOrder: "1113",
    salesDate: "5 May, 23",
    product: "Absolute Black",
    productType: "Slab",
    thickness: "2 inches",
    finish: "Premium",
    price: "60 USD",
  },
  {
    salesOrder: "1114",
    salesDate: "5 May, 23",
    product: "Absolute Black",
    productType: "Slab",
    thickness: "2 inches",
    finish: "Premium",
    price: "60 USD",
  },
  {
    salesOrder: "1115",
    salesDate: "5 May, 23",
    product: "Absolute Black",
    productType: "Slab",
    thickness: "2 inches",
    finish: "Premium",
    price: "60 USD",
  },
  {
    salesOrder: "1116",
    salesDate: "5 May, 23",
    product: "Absolute Black",
    productType: "Slab",
    thickness: "2 inches",
    finish: "Premium",
    price: "60 USD",
  },
  {
    salesOrder: "1117",
    salesDate: "5 May, 23",
    product: "Absolute Black",
    productType: "Slab",
    thickness: "2 inches",
    finish: "Premium",
    price: "60 USD",
  },
  {
    salesOrder: "1118",
    salesDate: "5 May, 23",
    product: "Absolute Black",
    productType: "Slab",
    thickness: "2 inches",
    finish: "Premium",
    price: "60 USD",
  },
];

export const getCustomers = async ({ queryKey }) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(CUSTOMER_ROUTE.list)}`, queryKey[1], {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const getSalesHistory = ({ queryKey }) => {
  return saleHistory;
};

export const insertCustomer = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .post(`${getEndPointFor(CUSTOMER_ROUTE.base)}`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};

export const deleteCustomer = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .delete(`${getEndPointFor(CUSTOMER_ROUTE.base)}`, {
      headers: headers,
      data: object,
    })
    .then((response) => response.data);
  return response;
};

export const editCustomer = async (object) => {
  const headers = await getHeaders();
  const response = await axios
    .put(`${getEndPointFor(CUSTOMER_ROUTE.base)}`, object, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};
