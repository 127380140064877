import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { Box } from "@mui/material";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Image,
  Layout,
  List,
  Pagination,
  Radio,
  Row,
  Skeleton,
  Space,
} from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import {
  useAddNotes,
  useMoveTo1,
  useUpdateCustomer,
  useUpdateInspector,
} from "../../queries/react-query/sales";
import "../../styles/inventory.css";
import { salesConstants } from "../../utils/sales";
import CollapsiblePanel from "../CollapsiblePanel";
import Slider from "../Common/MediaSlider/MediaSlider";
import { Popup, usePopup } from "../CreatePopup";
import EmptyNotesFormGeneral from "../EmptyNotesFormGeneral";
import { ExpandableDrawer, useDrawer } from "../ExpandableDrawer";
import Filter from "../Filter";
import ImagePopUp from "../ImagePopUp";
import SuccessComponent from "../SuccessComponent";
import BundleCardSales from "./BundleCardSales";
import InspectionCollapse from "./InspectionCollapse";
import "./sales.css";

export default function Cancelled(props) {
  const { t } = useTranslation();
  const {
    openDrawer,
    setOpenDrawer,
    toggleDrawer,
    drawerTitle,
    setDrawerTitle,
    expand,
    setExpand,
  } = useDrawer(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [imagePopUp, setImagePopUp] = useState(false);
  const [imagePopUpValue, setImagePopUpValue] = useState("");
  const queryClient = useQueryClient();
  const [selectedSlab, setSelectedSlab] = useState([]);
  const [moveSuccess, setMoveSuccess] = useState(false);
  const [index, setIndex] = useState(0);
  const [drawerClick, setDrawerClick] = useState(0);
  const [value, setValue] = useState(2);
  const [changeInspector, setChangeInspector] = useState(false);
  const [changeCustomer, setChangeCustomer] = useState(false);
  const { openPopup, togglePopup } = usePopup(false);
  const [notesOf, setNotesOf] = useState([]);
  const [isPosting, setIsPosting] = useState(false);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const [isLoadingInspector, setIsLoadingInspector] = useState(false);
  const [popUpURL, setPopUpURL] = useState("");
  const [toggleClick, setToggleClick] = useState(0);
  const [images, setImages] = useState([]);
  const [viewing, setViewing] = useState({ bundle: "-1", slab: "-1" });
  const [customerLoading, setCustomerLoading] = useState(false);
  const [inspectorLoading, setInspectorLoading] = useState(false);
  const listItemStyle = {
    padding: "10px",
    paddingRight: "35px",
    background: "var(--neutral-background-text-white-100, #F9F9F9)",
  };

  const handleCloseDrawerCallBack = () => {};

  const handleItemsSelected = (data) => {
    setSelectedItems(data);
  };

  const handleDrawerExpand = (expanded) => {
    setExpand(expanded);
  };

  const pageChange = (page, pageSize) => {
    // console.log(val)
    localStorage.setItem("pageStartInspection", (page - 1) * 10);
    localStorage.setItem("pageSizeInspection", 10);
    props.listSales.refetch();
  };

  const onSuccessMutation = () => {
    queryClient.refetchQueries(["CSales"]);

    setMoveSuccess(true);
    setTimeout(() => {
      setMoveSuccess(false);
    }, [2000]);
  };

  const moveTo1 = useMoveTo1(["moveTo1"], onSuccessMutation);

  const moveClicked = () => {
    // console.log(value);
    if (value <= 3) {
      if (value == 2) {
        handleCloseDrawerCallBack && handleCloseDrawerCallBack();
        handleDrawerExpand(false);
        toggleDrawer();
        moveTo1.mutate({
          status: "INSPECTION",
          entityId: props.listSales.data.saleList[index].entityId,
        });
      } else if (value == 3) {
        handleCloseDrawerCallBack && handleCloseDrawerCallBack();
        handleDrawerExpand(false);
        toggleDrawer();
        moveTo1.mutate({
          status: "ORDER",
          entityId: props.listSales.data.saleList[index].entityId,
        });
      } else if (value == 4) {
        handleCloseDrawerCallBack && handleCloseDrawerCallBack();
        handleDrawerExpand(false);
        toggleDrawer();
        moveTo1.mutate({
          status: "SHIPPED",
          entityId: props.listSales.data.saleList[index].entityId,
        });
      }
    }
  };

  const updateInspectorSuccess = () => {
    setTimeout(() => {
      queryClient.refetchQueries(["CSales"]);
      setChangeInspector(false);
      setIsLoadingInspector(false);
      setInspectorLoading(-1);
    }, [1000]);
  };

  const updateCustomerSuccess = () => {
    setTimeout(() => {
      queryClient.refetchQueries(["CSales"]);
      setChangeCustomer(false);
      setIsLoadingCustomer(false);
      setCustomerLoading(-1);
    }, [1000]);
  };
  const updateInspector = useUpdateInspector(
    ["CSales"],
    updateInspectorSuccess
  );
  const updateCustomer = useUpdateCustomer(["CSales"], updateCustomerSuccess);
  const addNotesMutationSuccess = () => {
    queryClient.refetchQueries(["CSales"]);
    setIsPosting(false);
  };
  const addNotesMutation = useAddNotes(["CSales"], addNotesMutationSuccess);

  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {}, [
    index,
    drawerClick,
    value,
    changeInspector,
    changeCustomer,
    isPosting,
  ]);
  const Filters = salesConstants.Filters;
  const salesFilters = () => {
    const flatProps = {
      options: props.listCustomers
        ? props.listCustomers.data.customerList.map(
            (option) => option.customerName
          )
        : [],
    };
    Filters[0].value = flatProps.options;

    const flatPropsInspector = {
      options: props.listUsers
        ? props.listUsers.data.userList.map((option) => option.fullName)
        : [],
    };
    Filters[1].value = flatPropsInspector.options;
    return Filters;
  };
  return (
    <Layout>
      {props.listCustomers.isLoading || props.listUsers.isLoading ? (
        <Sider
          className="SiderNav"
          width="15%"
          style={{
            background: "var(--neutral-background-text-white-000, #FFF)",
            height: "67vh",
            borderRadius: "10px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Skeleton active />
        </Sider>
      ) : (
        <Filter
          queryClient={queryClient}
          setFilterObject={props.setFilterObject}
          filterObject={props.filterObject}
          queryKey={props.queryKey}
          Filters={Filters}
          isSales={true}
          salesFilters={salesFilters}
        />
      )}
      <Content
        style={{
          width: "85%",
          paddingRight: "3vw",
        }}
      >
        <List
          itemLayout="horizontal"
          style={{
            marginTop: "40px",
          }}
        >
          <Row
            style={{
              padding: "0 35px 0 20px",
              paddingRight: "35px",
            }}
            className="tableHeader"
          >
            <Col span={5} className="salesHeaderList">
              {t("salesHeaders.orderNumber")}
            </Col>
            <Col span={4} className="salesHeaderList">
              {t("salesHeaders.customer")}
            </Col>
            <Col
              span={6}
              style={{ marginLeft: "-1.75vw" }}
              className="salesHeaderList"
            >
              {t("salesHeaders.products")}
            </Col>
            <Col
              span={4}
              style={{ marginLeft: "-2.25vw" }}
              className="salesHeaderList"
            >
              {t("salesHeaders.type")}
            </Col>
            <Col
              span={5}
              style={{ marginLeft: "-0.25vw" }}
              className="salesHeaderList"
            >
              {t("salesHeaders.orderCreated")}
            </Col>
          </Row>
        </List>
        {props.listSales.isLoading ||
        props.listCustomers.isLoading ||
        props.listUsers.isLoading ||
        moveTo1.isLoading ? (
          <Skeleton active />
        ) : (
          <>
            <Content
              style={{ width: "100%", height: "53vh", overflowX: "hidden" }}
              className="ListViewSales"
            >
              {props.listSales.data.saleList.length === 0 ? (
                <Empty
                  style={{
                    width: "100%",
                    marginTop: "2vh",
                    fontFamily: "Roboto",
                  }}
                  description={<span>No results found</span>}
                />
              ) : (
                props.listSales.data.saleList.map((item, i) => {
                  return (
                    <InspectionCollapse
                      key={item.entityId}
                      itemIndex={i}
                      setIndex={setIndex}
                      listItemStyle={listItemStyle}
                      data={item}
                      setDrawerTitle={setDrawerTitle}
                      setOpenDrawer={setOpenDrawer}
                      setDrawerClick={setDrawerClick}
                      changeInspector={changeInspector}
                      setChangeInspector={setChangeInspector}
                      changeCustomer={changeCustomer}
                      setChangeCustomer={setChangeCustomer}
                      updateInspector={updateInspector}
                      updateCustomer={updateCustomer}
                      setNotesOf={setNotesOf}
                      togglePopup={togglePopup}
                      isLoadingCustomer={isLoadingCustomer}
                      setIsLoadingCustomer={setIsLoadingCustomer}
                      isLoadingInspector={isLoadingInspector}
                      setIsLoadingInspector={setIsLoadingInspector}
                      type={3}
                      setToggleClick={setToggleClick}
                      customersList={props.listCustomers.data}
                      listUsers={props.listUsers.data}
                      customerLoading={customerLoading}
                      inspectorLoading={inspectorLoading}
                      setCustomerLoading={setCustomerLoading}
                      setInspectorLoading={setInspectorLoading}
                    />
                  );
                })
              )}
            </Content>
            {drawerClick === 0 ? (
              <ExpandableDrawer
                toggleDrawer={toggleDrawer}
                openDrawer={openDrawer}
                closeDrawerCallback={handleCloseDrawerCallBack}
                title={""}
                expand={true}
                onDrawerExpand={handleDrawerExpand}
              >
                <Content
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    height: "100%",
                    alignItems: " flex-start",
                    width: "100%",
                  }}
                >
                  {props.listSales.isLoading ? (
                    <Skeleton active />
                  ) : (
                    <Content style={{ width: "40%" }}>
                      <p
                        className="imageHeadingInventory"
                        style={{ marginTop: "10vh" }}
                      >
                        {viewing.bundle == "-1"
                          ? `Bundle ${
                              props.listSales &&
                              props.listSales.data.saleList[0] &&
                              props.listSales.data.saleList[0]
                                .productInformationList[0] &&
                              props.listSales.data.saleList[0]
                                .productInformationList[0].bundles[0]
                                ? props.listSales.data.saleList[0]
                                    .productInformationList[0].bundles[0]
                                    .bundleNumber
                                : ""
                            }`
                          : viewing.slab === "-1"
                          ? `Bundle ${viewing.bundle}`
                          : `Bundle ${viewing.bundle} : Slab ${viewing.slab}`}
                      </p>
                      <Box
                        sx={{ maxWidth: "100%", flexGrow: 1, marginTop: "3vh" }}
                      >
                        <Slider slides={images} />
                      </Box>
                    </Content>
                  )}
                  <Divider
                    type="vertical"
                    style={{
                      height: "100%",
                      backgroundColor: "#D3D3D3",
                      marginLeft: "2vw",
                      marginRight: "2vw",
                    }}
                  />
                  <Content style={{ width: "55%" }}>
                    <Space
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      <h2 style={{ margin: 0, color: "#545454" }}>View List</h2>
                    </Space>
                    <Content style={{ marginTop: "30px", width: "100%" }}>
                      <Content
                        direction="vertical"
                        style={{ width: "100%" }}
                        size={24}
                      >
                        <Content className="ListViewInner">
                          {props.listSales.isLoading ? (
                            <Skeleton active />
                          ) : props.listSales.data.saleList[index] ? (
                            props.listSales.data.saleList[
                              index
                            ].productInformationList.map((data, i) => {
                              return (
                                <CollapsiblePanel
                                  key={i}
                                  panelProps={{
                                    header: data.productName,
                                    key: "1",
                                  }}
                                  collapseProps={{
                                    ghost: true,
                                    size: "large",
                                    style: { marginBottom: "2vh" },
                                    key: data.productName,
                                    className: "SalesProductCollapse",
                                  }}
                                  title={data.productName}
                                >
                                  <List
                                    itemLayout="horizontal"
                                    style={{
                                      marginTop: "10px",
                                      width: "100%",
                                    }}
                                  >
                                    <List.Item
                                      style={{
                                        width: "96%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        paddingLeft: "18px",
                                        marginBottom: "0",
                                      }}
                                    >
                                      <Space className="tableHeader1">
                                        {t("bundleHeader.bundleNo")}
                                      </Space>
                                      <Space
                                        style={{ marginLeft: "-1vw" }}
                                        className="tableHeader1"
                                      >
                                        {t("bundleHeader.slabInStock")}
                                      </Space>
                                      <Space
                                        style={{ marginLeft: "-1.5vw" }}
                                        className="tableHeader1"
                                      >
                                        {t("bundleHeader.totalPrice")}
                                      </Space>
                                      <Space
                                        style={{ marginLeft: "-1vw" }}
                                        className="tableHeader1"
                                      >
                                        {t("bundleHeader.quality")}
                                      </Space>
                                      <Space
                                        style={{ marginLeft: "-0.5vw" }}
                                        className="tableHeader1"
                                      >
                                        {t("bundleHeader.finish")}
                                      </Space>
                                      <Space className="tableHeader1">
                                        {t("bundleHeader.image")}
                                      </Space>
                                    </List.Item>
                                  </List>
                                  {data.bundles.map((bundle, j) => {
                                    return (
                                      <BundleCardSales
                                        key={bundle}
                                        bundles={bundle}
                                        bundleIndex={j}
                                        productId={data.productName}
                                        expand={expand}
                                        onDrawerExpand={handleDrawerExpand}
                                        selectedItems={selectedItems}
                                        onItemsSelected={handleItemsSelected}
                                        setImagePopUp={setImagePopUp}
                                        setImagePopUpValue={setImagePopUpValue}
                                        setSelectedSlab={setSelectedSlab}
                                        selectedSlab={selectedSlab}
                                        setImages={setImages}
                                        images={images}
                                        viewing={viewing}
                                        setViewing={setViewing}
                                        setActiveStep={setActiveStep}
                                        entityId={
                                          props.listSales.data.saleList[index]
                                            .entityId
                                        }
                                        value={3}
                                      />
                                    );
                                  })}
                                </CollapsiblePanel>
                              );
                            })
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )}
                        </Content>
                      </Content>
                    </Content>
                  </Content>
                </Content>
                {imagePopUp ? (
                  <>
                    <ImagePopUp
                      setImagePopUp={setImagePopUp}
                      imagePopUpValue={imagePopUpValue}
                    />
                  </>
                ) : (
                  <></>
                )}
              </ExpandableDrawer>
            ) : drawerClick === 1 ? (
              <ExpandableDrawer
                toggleDrawer={toggleDrawer}
                openDrawer={openDrawer}
                closeDrawerCallback={handleCloseDrawerCallBack}
                title={
                  props.listSales.data.saleList[index]
                    ? props.listSales.data.saleList[index].id
                    : ""
                }
                expand={expand}
                onDrawerExpand={handleDrawerExpand}
              >
                <Space
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <h2 className="moveToHeader" style={{ margin: 0 }}>
                    Move to
                  </h2>
                </Space>
                <Content style={{ marginTop: "30px", width: "100%" }}>
                  <Content
                    direction="vertical"
                    style={{ width: "100%" }}
                    size={24}
                  >
                    <CollapsiblePanel
                      panelProps={{
                        header: "Folders",
                        key: "1",
                      }}
                      collapseProps={{
                        ghost: true,
                        size: "large",
                        key: "Folders",
                        className: "MoveToCard",
                      }}
                      title={"Folders"}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          setValue(e.target.value);
                        }}
                        defaultChecked={value}
                        value={value}
                      >
                        <Space direction="vertical">
                          {/* <Radio value={1} style={{padding:"1vh 0vw"}}><p style={{marginBottom:"0",color:"#545454",paddingLeft:"1vw"}} >Inspection</p></Radio> */}
                          <Radio
                            value={2}
                            style={{ padding: "1vh 0vw", width: "100%" }}
                            className="radioButtonSales"
                          >
                            <p
                              style={{
                                marginBottom: "0",
                                paddingLeft: "1vw",
                              }}
                            >
                              Inspection
                            </p>
                          </Radio>
                          <Radio
                            value={3}
                            style={{ padding: "1vh 0vw" }}
                            className="radioButtonSales"
                          >
                            <p
                              style={{
                                marginBottom: "0",
                                paddingLeft: "1vw",
                              }}
                            >
                              Sales Order
                            </p>
                          </Radio>
                          <Radio
                            value={4}
                            style={{ padding: "1vh 0vw" }}
                            className="radioButtonSales"
                          >
                            <p
                              style={{
                                marginBottom: "0",
                                paddingLeft: "1vw",
                              }}
                            >
                              Shipped Order
                            </p>
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </CollapsiblePanel>
                    <Button
                      shape="round"
                      className="contactSupplierBtn"
                      style={{ width: "100%", marginTop: "1vh", height: "6vh" }}
                      size="large"
                      onClick={moveClicked}
                    >
                      Move from Cancelled order
                    </Button>
                  </Content>
                </Content>
              </ExpandableDrawer>
            ) : (
              <ExpandableDrawer
                toggleDrawer={toggleDrawer}
                openDrawer={openDrawer}
                closeDrawerCallback={handleCloseDrawerCallBack}
                title={
                  props.listSales.data.saleList[index]
                    ? props.listSales.data.saleList[index].id
                    : ""
                }
                expand={expand}
                onDrawerExpand={handleDrawerExpand}
              >
                <Space
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <h2 style={{ margin: 0, color: "#545454" }}>View Report</h2>
                </Space>
                <Content style={{ marginTop: "30px", width: "100%" }}>
                  <Content
                    direction="vertical"
                    style={{ width: "100%" }}
                    size={24}
                  >
                    <CollapsiblePanel
                      panelProps={{
                        header: (
                          <Content
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingLeft: "1vw",
                              paddingRight: "1vw",
                              width: "100%",
                            }}
                          >
                            <h3 style={{ margin: 0 }}>Report</h3>
                            <DownOutlined style={{ color: "#0677ff" }} />
                          </Content>
                        ),
                        defaultActiveKey: "1",
                      }}
                      collapseProps={{
                        ghost: true,
                        size: "large",
                        key: "Folders",
                        className: "MoveToCard",
                      }}
                      title={"Report"}
                    >
                      {props.listSales.data.saleList[index]
                        .inspectionPreSignedUrls ? (
                        props.listSales.data.saleList[index]
                          .inspectionPreSignedUrls.length !== 0 ? (
                          <Content
                            style={{
                              backgroundColor: "white",
                              padding: "1vh 1vw",
                              width: "100%",
                            }}
                          >
                            <h3 style={{ marginLeft: "1vw" }}>View Reports</h3>
                            <Row
                              style={{
                                maxHeight: "50vh",
                                overflowY: "auto",
                                textAlign: "center",
                              }}
                            >
                              {props.listSales.data.saleList[
                                index
                              ].inspectionPreSignedUrls.map((data) => {
                                return (
                                  <Col
                                    key={data.fileId}
                                    style={{ margin: "0.5vw" }}
                                  >
                                    <Image
                                      preview={{
                                        visible: false,
                                      }}
                                      width={160}
                                      style={{ marginTop: "1vh" }}
                                      src="https://cdn-icons-png.flaticon.com/512/2235/2235790.png"
                                      onClick={() => {
                                        togglePopup();
                                        setToggleClick(1);
                                        setPopUpURL(data.preSignedUrl);
                                      }}
                                    />
                                    {/* <PreviewImage fileUrl = {data.preSignedUrl}/> */}
                                  </Col>
                                );
                              })}
                            </Row>
                          </Content>
                        ) : (
                          <Empty
                            style={{
                              width: "100%",
                              marginTop: "2vh",
                              fontFamily: "Roboto",
                            }}
                            description={<span>No reports found</span>}
                          />
                        )
                      ) : (
                        <Empty
                          style={{
                            width: "100%",
                            marginTop: "2vh",
                            fontFamily: "Roboto",
                          }}
                          description={<span>No reports found</span>}
                        />
                      )}
                    </CollapsiblePanel>
                  </Content>
                </Content>
              </ExpandableDrawer>
            )}
          </>
        )}
        <Content
          style={{
            paddingTop: "2vh",
            paddingBottom: "2vh",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Pagination
            current={localStorage.getItem("pageStartInspection") / 10 + 1}
            total={
              props.listSales.data !== undefined
                ? props.listSales.data.totalItems
                : 0
            }
            onChange={pageChange}
          />
        </Content>
        {moveSuccess ? (
          <Content
            style={{
              backgroundColor: "rgba(128, 128, 128, 0.5)",
              width: "100%",
              height: "76vh",
              zIndex: 2,
              position: "absolute",
              top: "-2vh",
              left: "0",
              borderRadius: "5px",
            }}
          >
            <Card
              style={{
                width: "30%",
                height: "auto",
                backgroundColor: "white",
                margin: "20vh 0 0 35vw",
              }}
              extra={
                <CloseOutlined
                  style={{ color: "#0066FF" }}
                  onClick={() => {
                    setMoveSuccess(false);
                  }}
                />
              }
            >
              <SuccessComponent detail={"Success!!"} />
            </Card>
          </Content>
        ) : (
          <></>
        )}
        <Popup
          title={
            toggleClick === 0 ? (
              <p style={{ margin: "0" }} className="notesHeader">
                Add notes
              </p>
            ) : (
              <Space>Preview report</Space>
            )
          }
          openPopup={openPopup}
          togglePopup={togglePopup}
          flag={toggleClick === 1 ? true : false}
        >
          {toggleClick === 0 ? (
            <EmptyNotesFormGeneral
              onClosePopup={togglePopup}
              data={notesOf}
              addNotesMutation={addNotesMutation}
              isPosting={isPosting}
              setIsPosting={setIsPosting}
              saleListValue={props.listSales.data}
              index={index}
            />
          ) : (
            <iframe
              src={popUpURL}
              style={{
                width: "100%",
                height: "64vh",
                resize: "both",
                overflow: "auto",
              }}
            />
          )}
        </Popup>
      </Content>
    </Layout>
  );
}
