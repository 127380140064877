import { Card } from "antd";
import _ from "lodash";
import BundleInfo from "./BundleInfo";
import styles from "./product.module.scss";

export default function ProductInfo({ info }) {
  return (
    <Card
      title={info.productName}
      className={`${styles.cardBorder} ${styles.productCard}`}
    >
      {_.map(info.bundles, (bundle) => (
        <BundleInfo key={bundle.bundleNumber} bundle={bundle} />
      ))}
    </Card>
  );
}
