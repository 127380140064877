import { LoadingOutlined } from "@ant-design/icons";
import "@aws-amplify/ui-react/styles.css";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ConfigProvider, Layout, Spin } from "antd";
import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import MobileNavbar from "../MobileView/Components/MobileNavbar";
import { Popup, usePopup } from "../components/CreatePopup";
import NavBar from "../components/NavBar";
import SuccessComponent from "../components/SuccessComponent";
import SuccessLoading from "../components/SuccessLoading";
import { useUpdateGuest } from "../queries/react-query/home";
import { useGetTenant } from "../queries/react-query/widget";
import { CustomerConstants } from "../utils/customers";
import { globalConstants } from "../utils/global";
import routes from "../utils/routes";
import Widgets from "./Widgets";
import "./defaultLayout.css";

const { Content, Header } = Layout;

const DefaultLayout = ({ signOut, user }) => {
  const [flag, setFlag] = useState(false);
  const { openPopup, setOpenPopup, togglePopup } = usePopup(false);
  const [popUpMain, setPopUpMain] = useState(true);
  const [popUpLoading, setPopUpLoading] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const phoneNoCode = CustomerConstants.phoneNoCode;
  const [formData, setFromData] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const onSuccess = () => {
    setTimeout(() => {
      setPopUpLoading(false);
      setSuccessPopUp(true);
    }, [2000]);
    setTimeout(() => {
      setSuccessPopUp(false);
      togglePopup();
    }, [4000]);
  };
  const updateGuest = useUpdateGuest(["guestDetails"], onSuccess);
  const tenantDetails = useGetTenant(["tenant"]);
  localStorage.setItem("userName", user.attributes.given_name);
  useEffect(() => {}, [flag]);
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(
      localStorage.getItem("language")
        ? localStorage.getItem("language")
        : user.attributes.locale
    );
  }, [localStorage.getItem("language")]);

  const isMobile = globalConstants.isMobile();
  return tenantDetails.isLoading ? (
    <Content
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large"></Spin>
    </Content>
  ) : (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: `"Open Sans", sans-serif`,
        },
      }}
    >
      <Layout style={{ backgroundColor: "white", minHeight: "100vh" }}>
        <Header style={{ backgroundColor: "white", padding: 0 }}>
          {isMobile ? (
            <MobileNavbar
              style={{ "margin-bottom": 50 }}
              signOut={signOut}
              user={user}
              flag={flag}
              setFlag={setFlag}
              togglePopup={togglePopup}
              tenantDetails={tenantDetails.data}
            />
          ) : (
            <NavBar
              style={{ "margin-bottom": 50 }}
              signOut={signOut}
              user={user}
              flag={flag}
              setFlag={setFlag}
              togglePopup={togglePopup}
              tenantDetails={tenantDetails.data}
            />
          )}
        </Header>
        {true ? (
          <Content style={{ width: "100%", margin: "0 auto 24px" }}>
            <Suspense fallback={<LoadingOutlined />}>
              <Routes>
                {routes.map((route) => {
                  return (
                    <Route
                      key={route.name}
                      path={route.path}
                      exact={route.exact}
                      element={
                        <Widgets
                          flag={flag}
                          setFlag={setFlag}
                          section={route.name}
                        />
                      }
                    />
                  );
                })}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Suspense>
          </Content>
        ) : (
          <>Content</>
        )}
        <Popup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          togglePopup={togglePopup}
          cancelFn={() => {
            setPopUpMain(true);
          }}
          isCancelFn={true}
          title={
            popUpMain ? "Upgrade to get following features" : "Guest Details"
          }
          isFabIconNeeded={false}
        >
          {popUpLoading ? (
            <SuccessLoading
              detail={"Please wait while we update your data!!"}
            />
          ) : successPopUp ? (
            <SuccessComponent detail={"Success!!"} />
          ) : popUpMain ? (
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Content style={{ width: "100%" }}>
                <Content style={{ width: "100%", paddingLeft: "2vw" }}>
                  <ul>
                    <li className="tNC">
                      To get access to live inventory of multiple suppliers
                      across the globe
                    </li>
                    <li className="tNC">
                      Chat with suppliers and get instant replies to fasten your
                      sales
                    </li>
                  </ul>
                </Content>
              </Content>
              <Button
                className="upgradeBtn"
                style={{
                  width: "15vw",
                  textTransform: "capitalize",
                  backgroundColor: "#2c3fff",
                  color: "white",
                }}
                shape="round"
                size="large"
                onClick={() => {
                  setPopUpMain(false);
                }}
              >
                Upgrade for free
              </Button>
            </Content>
          ) : (
            <Content style={{ width: "100%" }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  updateGuest.mutate(formData);
                  setPopUpMain(true);
                  setPopUpLoading(true);
                }}
              >
                <TextField
                  onChange={(e) => {
                    setFromData((prev) => ({
                      ...prev,
                      companyName: e.target.value,
                    }));
                  }}
                  id="outlined-basic"
                  label="Company Name"
                  variant="outlined"
                  required
                  style={{ width: "100%", marginBottom: "2vh" }}
                />
                <TextField
                  onChange={(e) => {
                    setFromData((prev) => ({
                      ...prev,
                      firstName: e.target.value,
                    }));
                  }}
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  required
                  style={{ width: "100%", marginBottom: "2vh" }}
                />
                <TextField
                  onChange={(e) => {
                    setFromData((prev) => ({
                      ...prev,
                      lastName: e.target.value,
                    }));
                  }}
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  required
                  style={{ width: "100%", marginBottom: "2vh" }}
                />
                <TextField
                  onChange={(e) => {
                    setFromData((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                  id="outlined-basic"
                  type="email"
                  label="Email"
                  variant="outlined"
                  required
                  style={{ width: "100%", marginBottom: "2vh" }}
                />
                <Content
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FormControl style={{ width: "30%", marginBottom: "2vh" }}>
                    <InputLabel id="demo-simple-select-label">Code</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Code"
                      onChange={(e) => {
                        if (formData.phoneNumber === undefined) {
                          setFromData((prevState) => ({
                            ...prevState,
                            phoneNumber: e.target.value,
                          }));
                        } else {
                          const firstWord = formData.phoneNumber.split(" ");
                          if (firstWord.length === 1)
                            setFromData((prevState) => ({
                              ...prevState,
                              phoneNumber: e.target.value,
                            }));
                          else
                            setFromData((prevState) => ({
                              ...prevState,
                              phoneNumber: `${e.target.value} ${firstWord[1]}`,
                            }));
                        }
                      }}
                    >
                      {phoneNoCode.map((data, i) => {
                        return (
                          <MenuItem key={i} value={data.value}>
                            {data.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    onChange={(e) => {
                      e.preventDefault();
                      const firstWord = formData.phoneNumber.split(" ");

                      setFromData((prevState) => ({
                        ...prevState,
                        phoneNumber: `${firstWord[0]} ${e.target.value}`,
                      }));
                    }}
                    id="outlined-basic"
                    type="phone"
                    label="Contact Number"
                    variant="outlined"
                    style={{
                      marginLeft: "1vw",
                      width: "80%",
                      marginBottom: "2vh",
                    }}
                  />
                </Content>
                <Content style={{ width: "100%", textAlign: "center" }}>
                  <Button
                    type="submit"
                    className="upgradeBtn"
                    style={{ backgroundColor: "#2c3fff", color: "white" }}
                    size="large"
                  >
                    Save
                  </Button>
                </Content>
              </form>
            </Content>
          )}
        </Popup>
      </Layout>
    </ConfigProvider>
  );
};

export default DefaultLayout;
