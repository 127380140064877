import {
  CheckOutlined,
  EditOutlined,
  LoadingOutlined,
  PictureFilled,
} from "@ant-design/icons";
import { Autocomplete, TextField } from "@mui/material";
import { Button, Table } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsListOl } from "react-icons/bs";
import { useQueryClient } from "react-query";
import {
  useGetSlabsByBundleId,
  useUpdateSlabDetails,
} from "../../queries/react-query/inventory";
import { globalConstants } from "../../utils/global";
import { inventoryConstants } from "../../utils/inventory";
import { Popup, usePopup } from "../CreatePopup";
import EmptyNotesForm from "../EmptyNotesForm";
import { Lock } from "../Lock";
import PrintSlabDetails from "../PrintComponents/PrintSlabDetails";
import SuccessComponent from "../SuccessComponent";
import styles from "./slabtable.module.scss";

const SlabTable = ({
  productId,
  bundleId,
  onItemsSelected,
  selectedParentRowKeys,
  setSelectedSlab,
  selectedSlab,
  setImages,
  viewing,
  setViewing,
  setShowAddImages,
  setSelectedImagesSourceData,
  isPrintFeatureEnabled,
  ...props
}) => {
  const { openPopup, togglePopup } = usePopup(false);
  const [editId, setEditId] = useState({ slabNumber: -1, id: "-1" });
  const [editValue, setEditValue] = useState({});
  const [notesOf, setNotesOf] = useState([]);
  const [popUpContent, setPopUpContent] = useState(1);
  const [loadingState, setLoadingState] = useState(false);
  const queryClient = useQueryClient();
  const finish = inventoryConstants.finishTypes;
  const quality = inventoryConstants.qualityLevels;
  const thicknessMetric = inventoryConstants.thicknessUnit;
  const currency = Object.keys(inventoryConstants.currencies);
  const updateSlabSuccess = () => {
    setLoadingState(false);
    setEditId({ slabNumber: -1, id: "-1" });
    togglePopup();
    setPopUpContent(1);
    setTimeout(() => {
      queryClient.refetchQueries(["bundles", productId, bundleId]);
    }, [1000]);
  };
  const updateSlabDetails = useUpdateSlabDetails(
    ["bundles", productId, bundleId],
    updateSlabSuccess
  );
  const handleNotesClick = (record) => {
    localStorage.setItem("slabId", record.id);
    setNotesOf(record.notes ? record.notes : []);
    setPopUpContent(2);
    togglePopup();
  };

  const { t } = useTranslation();
  const columns = [
    {
      title: t("slabHeader.slabNo"),
      dataIndex: "slabNumber",
      key: "slabNumber",
      className: "values",
      render: (text) => (!_.isUndefined(text) ? text : "-"),
    },
    {
      title: t("slabHeader.blockNo"),
      dataIndex: "blockNumber",
      key: "blockNumber",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <TextField
            sx={{ width: "5vw" }}
            id="outlined-basic"
            variant="outlined"
            defaultValue={!_.isUndefined(text) ? text : ""}
            onChange={(e) => {
              e.preventDefault();
              setEditValue((prevState) => ({
                ...prevState,
                blockNumber: e.target.value,
              }));
            }}
            className="slabTextInput"
          />
        ) : !_.isUndefined(text) ? (
          text
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.grossHeight"),
      dataIndex: "grossHeight",
      key: "grossHeight",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <TextField
            sx={{ width: "5vw" }}
            id="outlined-basic"
            variant="outlined"
            defaultValue={!_.isUndefined(text) ? text : ""}
            onChange={(e) => {
              e.preventDefault();
              setEditValue((prevState) => ({
                ...prevState,
                grossHeight: e.target.value,
              }));
            }}
            className="slabTextInput"
          />
        ) : !_.isUndefined(text) ? (
          `${text} cm`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.grossWidth"),
      dataIndex: "grossWidth",
      key: "grossWidth",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <TextField
            sx={{ width: "5vw" }}
            id="outlined-basic"
            variant="outlined"
            defaultValue={!_.isUndefined(text) ? text : ""}
            onChange={(e) => {
              e.preventDefault();
              setEditValue((prevState) => ({
                ...prevState,
                grossWidth: e.target.value,
              }));
            }}
            className="slabTextInput"
          />
        ) : !_.isUndefined(text) ? (
          `${text} cm`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.netHeight"),
      dataIndex: "netHeight",
      key: "netHeight",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <TextField
            sx={{ width: "5vw" }}
            id="outlined-basic"
            variant="outlined"
            defaultValue={!_.isUndefined(text) ? text : ""}
            onChange={(e) => {
              e.preventDefault();
              setEditValue((prevState) => ({
                ...prevState,
                netHeight: e.target.value,
              }));
            }}
            className="slabTextInput"
          />
        ) : !_.isUndefined(text) ? (
          `${text} cm`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.netWidth"),
      dataIndex: "netWidth",
      key: "netWidth",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <TextField
            sx={{ width: "5vw" }}
            id="outlined-basic"
            variant="outlined"
            defaultValue={!_.isUndefined(text) ? text : ""}
            onChange={(e) => {
              e.preventDefault();
              setEditValue((prevState) => ({
                ...prevState,
                netWidth: e.target.value,
              }));
            }}
            className="slabTextInput"
          />
        ) : !_.isUndefined(text) ? (
          `${text} cm`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.finish"),
      dataIndex: "finish",
      key: "finish",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <Autocomplete
            className="slabAutocomplete"
            options={finish.map((option) => ({ label: option }))}
            getOptionLabel={(option) => option.label}
            defaultValue={{
              label: !_.isUndefined(text) ? text : "",
            }}
            id="combo-box-demo"
            onChange={(event, newValue) => {
              setEditValue((prev) => ({
                ...prev,
                finish: newValue ? newValue.label : "Select",
              }));
            }}
            renderInput={(params) => (
              <TextField
                className={styles.textField}
                {...params}
                sx={{ width: "6vw" }}
              />
            )}
          />
        ) : !_.isUndefined(text) ? (
          text
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.quality"),
      dataIndex: "quality",
      key: "quality",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <Autocomplete
            className="slabAutocomplete"
            options={quality.map((option) => ({ label: option }))}
            getOptionLabel={(option) => option.label}
            defaultValue={{
              label: !_.isUndefined(text) ? text : "",
            }}
            id="combo-box-demo"
            onChange={(event, newValue) => {
              setEditValue((prev) => ({
                ...prev,
                quality: newValue ? newValue.label : "Select",
              }));
            }}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: "6vw" }} />
            )}
          />
        ) : !_.isUndefined(text) ? (
          text
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.price"),
      dataIndex: "price",
      key: "price",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <div style={{ display: "flex" }}>
            <TextField
              sx={{ width: "6vw" }}
              id="outlined-basic"
              variant="outlined"
              defaultValue={!_.isUndefined(text) ? text : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  price: e.target.value,
                }));
              }}
              className="slabTextInput"
            />
            <Autocomplete
              className="slabAutocomplete"
              options={currency.map((option) => ({ label: option }))}
              getOptionLabel={(option) => option.label}
              defaultValue={{
                label: !_.isUndefined(record.currencyCode)
                  ? record.currencyCode
                  : "",
              }}
              id="combo-box-demo"
              onChange={(event, newValue) => {
                setEditValue((prev) => ({
                  ...prev,
                  priceMetric: newValue ? newValue.label : "Select",
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "6vw" }} />
              )}
            />
          </div>
        ) : !_.isUndefined(text) ? (
          `${text} ${inventoryConstants.currencies[record.currencyCode]}`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.totalPrice"),
      dataIndex: "totalPrice",
      key: "totalPrice",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <TextField
            sx={{ width: "5vw" }}
            variant="outlined"
            defaultValue={!_.isUndefined(text) ? text : ""}
            onChange={(e) => {
              e.preventDefault();
              setEditValue((prevState) => ({
                ...prevState,
                totalPrice: e.target.value,
              }));
            }}
            className="slabTextInput"
          />
        ) : !_.isUndefined(text) ? (
          `${globalConstants.set2placeToDecimal(text)} ${
            inventoryConstants.currencies[record.currencyCode]
          }`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.thickness"),
      dataIndex: "thickness",
      key: "thickness",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <div style={{ display: "flex" }}>
            <TextField
              sx={{ width: "6vw" }}
              id="outlined-basic"
              variant="outlined"
              defaultValue={!_.isUndefined(text) ? text : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  thickness: e.target.value,
                }));
              }}
              className="slabTextInput"
            />
            <Autocomplete
              className="slabAutocomplete"
              options={thicknessMetric.map((option) => ({ label: option }))}
              getOptionLabel={(option) => option.label}
              defaultValue={{
                label: !_.isUndefined(record.thicknessUnit)
                  ? record.thicknessUnit
                  : "",
              }}
              id="combo-box-demo"
              onChange={(event, newValue) => {
                setEditValue((prev) => ({
                  ...prev,
                  thicknessUnit: newValue ? newValue.label : "Select",
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "6vw" }} />
              )}
            />
          </div>
        ) : !_.isUndefined(text) ? (
          `${text} ${record.thicknessUnit}`
        ) : (
          "-"
        ),
    },
    {
      title: t("slabHeader.images"),
      dataIndex: "images",
      key: "images",
      className: "values",
      render: (text, record) => {
        return (
          <PictureFilled
            style={{
              color: "#2c3fff",
              cursor: "pointer",
              fontSize:
                viewing.bundle === bundleId &&
                viewing.slab === record.slabNumber
                  ? "x-large"
                  : "large",
            }}
            onClick={() => {
              setImages(
                record.images === undefined || record.images.length === 0
                  ? []
                  : record.images
              );
              setViewing({ bundle: bundleId, slab: record.slabNumber });
              setSelectedImagesSourceData(record);
              setShowAddImages(true);
              props.setActiveStep(0);
            }}
          />
        );
      },
    },
    {
      title: t("slabHeader.status"),
      dataIndex: "status",
      key: "status",
      className: "values",
      render: (text, record) => {
        if (record.status === "HOLD") {
          return <Lock state={false} text={"On Hold"} />;
        } else {
          return <Lock state={true} text={"Available"} />;
        }
      },
    },

    {
      title: t("slabHeader.notes"),
      dataIndex: "notes",
      key: "notes",
      className: "values",
      render: (text, record) => {
        return (
          <Button
            onClick={() => handleNotesClick(record)}
            type="text"
            disabled={localStorage.getItem("guest") === "true" ? true : false}
            icon={
              <BsListOl
                style={{
                  color:
                    localStorage.getItem("guest") === "true"
                      ? "grey"
                      : "#2c3fff",
                }}
              />
            }
          />
        );
      },
    },
    {
      title: t("slabHeader.print"),
      dataIndex: "slabPrint",
      key: "slabPrint",
      className: "values",
      hidden: isPrintFeatureEnabled,
      render: (text, record) => {
        const slabData = [];
        slabData.push(record);
        return <PrintSlabDetails slabData={slabData} />;
      },
    },
    {
      title: t("slabHeader.edit"),
      dataIndex: "slabNumber",
      key: "slabNumber",
      className: "values",
      render: (text, record) =>
        editId.slabNumber === record.slabNumber && editId.id === record.id ? (
          <Button
            type="text"
            icon={
              loadingState ? (
                <></>
              ) : (
                <CheckOutlined style={{ color: "green" }} />
              )
            }
            onClick={() => {
              setLoadingState(true);
              updateSlabDetails.mutate(editValue);
            }}
          >
            {loadingState ? <LoadingOutlined /> : <></>}
          </Button>
        ) : (
          <Button
            type="text"
            icon={
              <EditOutlined
                style={{
                  color:
                    localStorage.getItem("guest") === "true"
                      ? "grey"
                      : "#2c3fff",
                }}
              />
            }
            disabled={localStorage.getItem("guest") === "true" ? true : false}
            onClick={() => {
              setEditId({ slabNumber: record.slabNumber, id: record.id });
              setEditValue({
                entityId: record.id,
                slabNumber: record.slabNumber,
                blockNumber: record.blockNumber,
                status: record.status,
                netHeight: record.netHeight,
                netWidth: record.netWidth,
                grossHeight: record.grossHeight,
                grossWidth: record.grossWidth,
                finish: record.finish,
                quality: record.quality,
                price: record.price,
                priceMetric: record.currencyCode,
                thickness: record.thickness,
                thicknessMetric: record.thicknessUnit,
              });
            }}
          />
        ),
    },
  ].filter((item) => !item.hidden);
  //const [selectedSlab, setSelectedSlab] = useState([]);
  const slabsByBundleIdQuery = useGetSlabsByBundleId(
    ["bundles", productId, bundleId, { filterContext: props.filterObject }],
    null,
    !!bundleId
  );

  useEffect(() => {
    if (selectedParentRowKeys.includes(bundleId)) {
      const childRowKeys = slabsByBundleIdQuery.isSuccess
        ? slabsByBundleIdQuery.data.map((slab) => slab.slabNumber)
        : [];
      setSelectedSlab(childRowKeys);
    } else {
      setSelectedSlab([]);
    }
  }, [
    bundleId,
    selectedParentRowKeys,
    slabsByBundleIdQuery.data,
    slabsByBundleIdQuery.isSuccess,
  ]);

  const handleRowSelection = (selectedRowKey, selectedRow) => {
    setSelectedSlab(selectedRowKey);
    onItemsSelected(selectedRow);
  };

  return (
    <>
      <Table
        style={{
          background: "rgba(238, 244, 253, 0.8)",
        }}
        columns={columns}
        dataSource={slabsByBundleIdQuery.data}
        pagination={{
          hideOnSinglePage: true,
        }}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedSlab,
          onChange: handleRowSelection,
          getCheckboxProps: (record) => ({
            disabled: record.status === "HOLD",
          }),
        }}
        loading={slabsByBundleIdQuery.isLoading}
        scroll={{ x: 150 }}
        className={styles.slabTable}
      ></Table>
      <Popup
        title={popUpContent === 1 ? "Success" : "Add Notes"}
        openPopup={openPopup}
        togglePopup={togglePopup}
      >
        {popUpContent === 1 ? (
          <SuccessComponent detail={"Slab updated successfully!!"} />
        ) : (
          <EmptyNotesForm onClosePopup={togglePopup} data={notesOf} />
        )}
      </Popup>
    </>
  );
};

export default SlabTable;
