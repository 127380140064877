import { LoadingOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import { Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../public/components/navbar/Navbar";
import routes from "../utils/routes";
import Widgets from "./Widgets";

const { Header } = Layout;

export default function PublicLayout({ setCurrentPath }) {
  const location = useLocation();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location, setCurrentPath]);
  return (
    <Layout>
      <Header
        style={{ backgroundColor: "#fff", borderBottom: "4px solid #f1f1ef" }}
      >
        <Navbar />
      </Header>
      <div>
        <Suspense fallback={<LoadingOutlined />}>
          <Routes>
            {routes.map((route) => {
              return (
                <Route
                  key={route.name}
                  path={route.path}
                  exact={route.exact}
                  element={<Widgets section={route.name} />}
                />
              );
            })}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </div>
    </Layout>
  );
}
