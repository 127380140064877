import { Divider, Slider } from "@mui/material";
import { Button, Checkbox, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../styles/filter.css";
const { Sider } = Layout;

export default function MobileFilters({ Filters, ...props }) {
  const [filterCheck, setFilterCheck] = useState([]);
  const { t } = useTranslation();
  props.Filters = props.isSales ? props.salesFilters() : Filters;
  useEffect(() => {
    if (props.filterList !== undefined) props.setFilterList(filterCheck);
  }, [filterCheck]);
  function valuetext(value) {
    return `${value}USD`;
  }

  const typecast = {
    Thickness: "thickness",
    Category: "category",
    Height: "height",
    Width: "width",
    Finish: "finish",
    Price: "price",
    Quality: "quality",
    Colour: "colour",
    Country: "country",
    SalesPrice: "salesPrice",
    Customer: "customer",
    Inspector: "inspector",
    Department: "department",
    JobTitle: "jobTitle",
    Supplier: "tenantId",
    CustomerCountry: "customerCountry",
    CustomerType: "customerType",
    City: "city",
    SupplierCountry: "supplierCountry",
    SupplierCity: "supplierCity",
    "Supplier Country": "supplierCountry",
    "Supplier City": "supplierCity",
    "Customer Country": "customerCountry",
    "Customer Type": "customerType",
    "Job Title": "jobTitle",
    "Sales Price": "salesPrice",
  };
  const convertText = (param1, param2) => {
    const t = typecast[param2];
    return param1[t];
  };

  return (
    <div style={{ marginTop: "1vh", width: "100%" }}>
      <h4 className="filterHeading" style={{ marginLeft: "5vw" }}>
        {t("filters.filters")}
      </h4>
      <Divider style={{ marginBottom: "2vh" }} />
      {Filters.map((filter, id) => {
        return filter.value.length === 0 ? (
          <></>
        ) : (
          <div key={id} style={{ marginLeft: "5vw" }} className="filterBody">
            <p className="FilterName">
              {t(`filters.${filter.key.replace(/\s/g, "")}`)}
            </p>
            {filter.type === "checkbox" ? (
              <div className="filterValue">
                {filter.value.map((val, innerId) => {
                  return (
                    <div key={(id + 1) * 4 + innerId} className="FilterHeaders">
                      {props.dynamic ? (
                        <Checkbox
                          onChange={() => {
                            let keys = typecast[filter.key];
                            props.setFilterObject((prevState) => {
                              const pName =
                                filter.key === "Supplier"
                                  ? val.paramName.split(":")[0]
                                  : val.paramName;
                              if (prevState[keys] === pName) {
                                const { [keys]: removedKey, ...rest } =
                                  prevState;
                                return rest;
                              } else {
                                return {
                                  ...prevState,
                                  [keys]:
                                    filter.key === "Supplier"
                                      ? val.paramName.split(":")[0]
                                      : val.paramName,
                                };
                              }
                            });
                            props.queryClient.refetchQueries([props.queryKey]);
                          }}
                          checked={
                            convertText(props.filterObject, filter.key)
                              ? filter.key === "Supplier"
                                ? convertText(
                                    props.filterObject,
                                    filter.key
                                  ) === val.paramName.split(":")[0]
                                : convertText(
                                    props.filterObject,
                                    filter.key
                                  ) === val.paramName
                                ? true
                                : false
                              : false
                          }
                        ></Checkbox>
                      ) : (
                        <Checkbox
                          onChange={() => {
                            let keys = typecast[filter.key];
                            props.setFilterObject((prevState) => {
                              const pName =
                                filter.key === "Supplier"
                                  ? val.split(":")[0]
                                  : val;
                              if (prevState[keys] === pName) {
                                const { [keys]: removedKey, ...rest } =
                                  prevState;
                                return rest;
                              } else {
                                return {
                                  ...prevState,
                                  [keys]:
                                    filter.key === "Supplier"
                                      ? val.split(":")[0]
                                      : val,
                                };
                              }
                            });
                            props.queryClient.refetchQueries([props.queryKey]);
                          }}
                          checked={
                            convertText(props.filterObject, filter.key)
                              ? filter.key === "Supplier"
                                ? convertText(
                                    props.filterObject,
                                    filter.key
                                  ) === val.split(":")[0]
                                : convertText(
                                    props.filterObject,
                                    filter.key
                                  ) === val
                                ? true
                                : false
                              : false
                          }
                        ></Checkbox>
                      )}
                      <p
                        className="FilterVals"
                        style={{
                          marginLeft: "15px",
                          marginBottom: "0",
                        }}
                      >
                        {props.dynamic
                          ? filter.key === "Supplier"
                            ? `${val.paramName.split(":")[1]} (${val.cnt})`
                            : `${val.paramName} (${val.cnt})`
                          : filter.key === "Supplier"
                          ? val.split(":")[1]
                          : val}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                <Slider
                  getAriaLabel={() => "pretto slider"}
                  sx={{ color: "#0C25F3" }}
                  getAriaValueText={valuetext}
                  max={filter.maxValue}
                  onChangeCommitted={(event, newValue) => {
                    let keys = typecast[filter.key];
                    props.setFilterObject((prev) => ({
                      ...prev,
                      [keys]: {
                        lesserThanOrEqualTo: newValue[1],
                        greaterThanOrEqualTo: newValue[0],
                      },
                    }));
                  }}
                  defaultValue={filter.value}
                  valueLabelDisplay="auto"
                  marks={[
                    {
                      value: 0,
                      label: "0",
                    },
                    {
                      value: filter.maxValue,
                      label: `${filter.maxValue}`,
                    },
                  ]}
                />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}
