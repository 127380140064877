import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Avatar, Button, Col, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";

export default function CustomerRow(props) {
  const HistoryClicked = () => {
    props.stateClickChange(true);
    props.setUserDetails({
      userName: props.data.customerName,
    });
  };

  const deleteCustomer = () => {
    // props.deleteCustomerMutation.mutate({
    //   customerName: props.data.customerName,
    // });
    props.setDeleteClicked(true);
    props.setDeleteData({ customerName: props.data.customerName });
  };

  const editCustomer = () => {
    props.setEditClicked(true);
    props.setEditValue(props.data);
  };

  return (
    <Row
      style={{
        padding: "10px",
        paddingRight: "35px",
        background: "var(--neutral-background-text-white-100, #F9F9F9)",
      }}
    >
      <Col span={6} className="TableColumn mainCollapseSales">
        <Avatar
          id="Avatar"
          style={{
            backgroundColor: "#1055BB",
            verticalAlign: "middle",
          }}
          size="large"
          gap={4}
        >
          {props.data.customerName.charAt(0)
            ? props.data.customerName.charAt(0)
            : "P"}
        </Avatar>
        <p style={{ marginBottom: "0" }}>
          {props.data.customerName ? props.data.customerName : "--"}
        </p>
      </Col>
      <Col span={6} className="TableColumn mainCollapseSales">
        {props.data.customerType ? props.data.customerType : "--"}
      </Col>
      <Col
        span={6}
        style={{ paddingLeft: "0.35vw" }}
        className="TableColumn mainCollapseSales"
      >
        {props.data.country ? props.data.country : "  --"}
      </Col>
      <Col span={6} className="TableColumn mainCollapseSales">
        <p
          className="tableValue"
          style={{ marginBottom: "0", width: "50%", textAlign: "center" }}
        >
          {props.data.sinceDate ? props.data.sinceDate : "--"}
        </p>
        {props.searchView ? (
          <></>
        ) : (
          <Content
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "50%",
              wordBreak: "break-word",
            }}
          >
            <EditOutlined
              sx={{ fontSize: "x-large", color: "#0066FF", marginLeft: "3vw" }}
              onClick={editCustomer}
            />
            {/* 
          <DeleteOutline
            sx={{ fontSize: "x-large", color: "red", marginLeft: "1vw" }}
            onClick={deleteCustomer}
          />

          <Button
            type="primary"
            shape="round"
            style={{ marginLeft: "1vw" }}
            onClick={HistoryClicked}
          >
            Sales hi  story
          </Button> */}
          </Content>
        )}
      </Col>
    </Row>
  );
}
