import { PlusOutlined } from "@ant-design/icons";
import { DeleteOutline } from "@mui/icons-material";
import { Box } from "@mui/material";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Empty,
  Layout,
  List,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import BundleCard from "../components/BundleCard";
import CreateOffer from "../components/Cart/CreateOffer";
import CreateSalesOrder from "../components/Cart/CreateSalesOrder";
import Slider from "../components/Common/MediaSlider/MediaSlider";
import { Popup, usePopup } from "../components/CreatePopup";
import { ExpandableDrawer, useDrawer } from "../components/ExpandableDrawer";
import ImagePopUp from "../components/ImagePopUp";
import SuccessComponent from "../components/SuccessComponent";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import {
  useDeleteCart,
  useGetBundleList,
  useGetCartItems,
} from "../queries/react-query/cart";
import "../styles/inventory.css";

export function InventoryCart(props) {
  const cartListQuery = useGetCartItems(["cart"]);
  const { t } = useTranslation();
  const offerSalesPopupCardRef = useRef(null);
  const {
    openDrawer,
    setOpenDrawer,
    toggleDrawer,
    drawerTitle,
    setDrawerTitle,
    expand,
    setExpand,
  } = useDrawer(false);
  const inventoryBundleQuery = useGetBundleList(
    ["bundles", drawerTitle],
    null,
    !!drawerTitle
  );
  const { openPopup, togglePopup } = usePopup(false);
  const [isOfferSalesPopupOpen, setIsOfferSalesPopupOpen] = useState(false);
  const [salesClicked, setSalesClicked] = useState(false);
  const [isOfferClicked, setOfferClicked] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [imagePopUp, setImagePopUp] = useState(false);
  const [imagePopUpValue, setImagePopUpValue] = useState("");
  const [selectedSlab, setSelectedSlab] = useState([]);
  const [cartItemSelected, setCartItemSelected] = useState([]);
  const [images, setImages] = useState([]);
  const [viewing, setViewing] = useState({ bundle: "-1", slab: "-1" });
  const [errMsg, setErrMsg] = useState("");
  const [showAddImages, setShowAddImages] = useState(
    images.length === 0 && viewing.bundle !== "-1"
      ? true
      : viewing.slab !== "-1"
      ? true
      : false
  );
  const [popup, setPopup] = useState(false);
  const [addImageTo, setAddImageTo] = useState({
    bundle: "-1",
    slab: "-1",
    block: "-1",
  });
  const queryClient = useQueryClient();
  const listItemStyle = {
    height: "80px",
    padding: "16px",
    paddingRight: "3vw",
    marginBottom: "24px",
    cursor: "pointer",
  };

  /* Handling the hide/show functionality of
   * Sales/Offer navigation popup
   */
  const hideOfferSalesPopupCard = () => {
    setIsOfferSalesPopupOpen(false);
  };

  useOnClickOutside(offerSalesPopupCardRef, hideOfferSalesPopupCard); // Call the useOnClickOutside hook

  /* --- */

  const handleCloseDrawerCallBack = () => {};

  const handleListClick = function (title) {
    setDrawerTitle(title);
    setOpenDrawer(true);
  };

  const handleItemsSelected = (data) => {
    setSelectedItems(data);
  };

  const handleDrawerExpand = (expanded) => {
    setExpand(expanded);
  };

  const onSuccessDelete = () => {
    togglePopup();
    queryClient.refetchQueries(["cart"]);
    queryClient.refetchQueries(["bundles"]);
  };

  const deleteItemFromCart = useDeleteCart(
    ["cart", "bundles"],
    onSuccessDelete
  );

  const deleteCart = () => {
    deleteItemFromCart.mutate({
      slabEntityIds: selectedItems
        ? selectedItems.map((option) => option.id)
        : [],
    });
  };

  const isOnHold = (data) => {
    let booleanVal = false;
    for (let i = 0; i < cartListQuery.data.length; ++i) {
      if (cartListQuery.data[i].slabsOnHold !== 0) {
        booleanVal = true;
      }
    }
    if (data === undefined || data.length === 0) {
      booleanVal = true;
    }
    return booleanVal;
  };

  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <Layout>
      {salesClicked && (
        <CreateSalesOrder
          setSalesClicked={setSalesClicked}
          setFlag={props.setFlag}
          setPopup={setPopup}
          setErrMsg={setErrMsg}
        />
      )}
      {!salesClicked && !isOfferClicked && (
        <>
          <List
            itemLayout="horizontal"
            style={{
              marginTop: "30px",
              marginLeft: "2vw",
              marginRight: "2vw",
            }}
          >
            <List.Item
              style={{
                width: "93vw",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "18px",
                fontSize: "14px",
                marginBottom: "10px",
                marginLeft: "2vw",
              }}
              className="tableHeader1"
            >
              <div className="tableHeader1">{t("cart.products")}</div>
              <div
                style={{ marginLeft: "200px", marginRight: "20px" }}
                className="tableHeader1"
              >
                Total Slabs
              </div>
              <div className="tableHeader1" style={{ paddingLeft: "2vw" }}>
                {t("cart.slabsAvailable")}
              </div>
              <div className="tableHeader1" style={{ paddingRight: "2vw" }}>
                {t("cart.slabsOnHold")}
              </div>
            </List.Item>
          </List>
          {cartListQuery.isLoading ? (
            <Skeleton active />
          ) : cartListQuery.data === undefined ? (
            <Empty
              style={{
                width: "100%",
                marginTop: "2vh",
                fontFamily: "Roboto",
              }}
              description={<span>Error in loading data</span>}
            />
          ) : cartListQuery.data.length === 0 ? (
            <Empty
              style={{
                width: "100%",
                marginTop: "2vh",
                fontFamily: "Roboto",
              }}
              description={<span>No Items in Cart</span>}
            />
          ) : (
            <>
              <List
                style={{
                  marginLeft: "2vw",
                  marginRight: "2vw",
                }}
                className="ListViewCart"
                itemLayout="horizontal"
                dataSource={cartListQuery.data}
                renderItem={(item) => (
                  <List.Item
                    style={listItemStyle}
                    className="ListViewItem"
                    onClick={() => handleListClick(item.productId)}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        flexBasis: "200px",
                      }}
                    >
                      <Avatar
                        style={{ backgroundColor: "#A2A3A5" }}
                        src={
                          "https://media.istockphoto.com/id/1393792645/photo/part-of-a-stone-wall-for-background-or-texture.jpg?s=1024x1024&w=is&k=20&c=z5RBPMHwGV0UfxVmdOpulDzPYqhIe-uDt0FB_lTTsm8="
                        }
                      />
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                        }}
                      >
                        {item.productId}
                      </div>
                    </div>
                    <div>{item.totalSlabs}</div>
                    <div style={{ marginRight: "-1.5vw" }}>
                      {item.availableSlabs}
                    </div>
                    <div style={{ marginRight: "1vw" }}>{item.slabsOnHold}</div>
                  </List.Item>
                )}
              />
              <ExpandableDrawer
                toggleDrawer={toggleDrawer}
                openDrawer={openDrawer}
                closeDrawerCallback={handleCloseDrawerCallBack}
                title={""}
                expand={true}
                onDrawerExpand={handleDrawerExpand}
              >
                <Content
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    height: "100%",
                    width: "100%",
                    alignItems: " flex-start",
                  }}
                >
                  <Content style={{ width: "40%" }}>
                    <p
                      className="imageHeadingInventory"
                      style={{ marginTop: "10vh" }}
                    >
                      {" "}
                      {drawerTitle}
                    </p>

                    {inventoryBundleQuery.isLoading ? (
                      <Skeleton active />
                    ) : (
                      <>
                        <Content
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="imageHeadingInventory"
                            style={{ marginTop: "2vh" }}
                          >
                            {viewing.bundle === "-1"
                              ? `Bundle ${
                                  inventoryBundleQuery.data &&
                                  inventoryBundleQuery.data[0]
                                    ? inventoryBundleQuery.data[0].bundleNumber
                                    : ""
                                }`
                              : viewing.slab === "-1"
                              ? `Bundle ${viewing.bundle}`
                              : `Bundle ${viewing.bundle} : Slab ${viewing.slab}`}
                          </p>
                        </Content>
                        <Box
                          sx={{
                            maxWidth: "100%",
                            flexGrow: 1,
                            marginTop: "3vh",
                          }}
                        >
                          <Slider slides={images} />
                        </Box>
                      </>
                    )}
                  </Content>
                  <Divider
                    type="vertical"
                    style={{
                      height: "100%",
                      backgroundColor: "#D3D3D3",
                      marginLeft: "2vw",
                      marginRight: "2vw",
                    }}
                  />
                  <Content style={{ width: "55%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      <h2 className="drawerTitle" style={{ margin: 0 }}>
                        {drawerTitle}
                      </h2>
                      <Button
                        className="contactSupplierBtn"
                        icon={<DeleteOutline />}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "2vh 1vw",
                        }}
                        shape="round"
                        onClick={deleteCart}
                      >
                        <span style={{ marginLeft: 8 }}>
                          {t("globalBtn.removeFromCart")}
                        </span>
                      </Button>
                    </div>
                    <div style={{ marginTop: "30px", width: "100%" }}>
                      <Space
                        direction="vertical"
                        style={{ width: "100%" }}
                        size={24}
                      >
                        <List
                          itemLayout="horizontal"
                          style={{
                            marginTop: "10px",
                            width: "100%",
                          }}
                        >
                          <List.Item
                            style={{
                              width: "96%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingLeft: "18px",
                              marginBottom: "0",
                            }}
                          >
                            <Space className="tableHeader1">
                              {t("bundleHeader.bundleNo")}
                            </Space>
                            <Space
                              style={{ marginLeft: "-3vw" }}
                              className="tableHeader1"
                            >
                              {t("bundleHeader.slabInStock")}
                            </Space>
                            <Space className="tableHeader1">
                              {t("bundleHeader.slabAvailable")}
                            </Space>
                            <Space className="tableHeader1">
                              {t("bundleHeader.slabOnHold")}
                            </Space>
                            <Space className="tableHeader1">
                              {t("bundleHeader.image")}
                            </Space>
                          </List.Item>
                        </List>
                        <Content className="ListViewInner">
                          {inventoryBundleQuery.isLoading ? (
                            <Skeleton active />
                          ) : drawerTitle !== "" ? (
                            inventoryBundleQuery.data.map((data, i) => {
                              return (
                                <BundleCard
                                  key={drawerTitle}
                                  productDetails={{ productId: drawerTitle }}
                                  bundles={data}
                                  productId={drawerTitle}
                                  expand={expand}
                                  onDrawerExpand={handleDrawerExpand}
                                  selectedItems={selectedItems}
                                  onItemsSelected={handleItemsSelected}
                                  setImagePopUp={setImagePopUp}
                                  setImagePopUpValue={setImagePopUpValue}
                                  isCart={true}
                                  selectedSlab={selectedSlab}
                                  setSelectedSlab={setSelectedSlab}
                                  setCartItemSelected={setCartItemSelected}
                                  cartItemSelected={cartItemSelected}
                                  setImages={setImages}
                                  images={images}
                                  viewing={viewing}
                                  setViewing={setViewing}
                                  addImageTo={addImageTo}
                                  setAddImageTo={setAddImageTo}
                                  setActiveStep={setActiveStep}
                                  setShowAddImages={setShowAddImages}
                                />
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </Content>
                      </Space>
                    </div>
                  </Content>
                </Content>
                <Tooltip></Tooltip>
                {imagePopUp ? (
                  <>
                    <ImagePopUp
                      setImagePopUp={setImagePopUp}
                      imagePopUpValue={imagePopUpValue}
                    />
                  </>
                ) : (
                  <></>
                )}
                <Popup
                  title={""}
                  openPopup={openPopup}
                  togglePopup={togglePopup}
                  flag={false}
                >
                  <SuccessComponent detail={"Success!!"} />
                </Popup>
              </ExpandableDrawer>
            </>
          )}
        </>
      )}

      {isOfferClicked && (
        <CreateOffer
          setOfferClicked={setOfferClicked}
          setFlag={props.setFlag}
          setPopup={setPopup}
          setErrMsg={setErrMsg}
        />
      )}
      {popup ? (
        <Content
          style={{
            backgroundColor: "rgba(128, 128, 128, 0.5)",
            width: "100%",
            height: "75.75vh",
            zIndex: 2,
            position: "absolute",
            top: "-2vh",
            left: "0",
            borderRadius: "5px",
          }}
        >
          <Card
            style={{
              width: "30%",
              height: "auto",
              backgroundColor: "white",
              margin: "20vh 0 0 34vw",
            }}
          >
            <SuccessComponent isError={true} detail={errMsg} />
          </Card>
        </Content>
      ) : (
        <></>
      )}
      {cartListQuery.isLoading || salesClicked || isOfferClicked ? (
        <></>
      ) : (
        <div ref={offerSalesPopupCardRef}>
          {isOfferSalesPopupOpen && (
            <Card className="offerOptionCard">
              <div>
                <Button
                  style={{
                    textAlign: "left",
                  }}
                  type="text"
                  onClick={() => {
                    setOfferClicked(true);

                    if (salesClicked) {
                      setSalesClicked(false);
                    }
                  }}
                >
                  {t("globalBtn.createOffer")}
                </Button>
              </div>
              <div>
                <Button
                  style={{
                    textAlign: "left",
                  }}
                  type="text"
                  onClick={() => {
                    setSalesClicked(true);

                    if (isOfferClicked) {
                      setOfferClicked(false);
                    }
                  }}
                >
                  {t("globalBtn.createSales")}
                </Button>
              </div>
            </Card>
          )}

          <Tooltip
            title={
              isOnHold(cartListQuery.data)
                ? t("globalBtn.offerCreateFail")
                : t("globalBtn.createOfferOrSalesText")
            }
            placement="left"
          >
            <Button
              style={{
                position: "fixed",
                right: "1vw",
                bottom: "3vh",
                width: "50px",
                height: "50px",
                fontSize: "18px",
                textAlign: "center",
              }}
              type="primary"
              className="addButton"
              icon={<PlusOutlined />}
              onClick={() => {
                setIsOfferSalesPopupOpen(!isOfferSalesPopupOpen);
              }}
              disabled={isOnHold(cartListQuery.data)}
            ></Button>
          </Tooltip>
        </div>
      )}
    </Layout>
  );
}
