import axios from "axios";
import { getEndPointFor, getHeaders } from "../../utils/api_endpoints";

export const getTenant = async ({ queryKey }) => {
  const headers = await getHeaders();
  const response = await axios
    .get(`${getEndPointFor("/getTenant")}`, {
      headers: headers,
    })
    .then((response) => response.data);
  return response;
};
