import { useMutation, useQuery } from "react-query";
import {
  deleteCustomer,
  editCustomer,
  getCustomers,
  getSalesHistory,
  insertCustomer,
} from "../api/customers";

export const useGetCustomers = (queryParam) => {
  return useQuery({
    queryKey: queryParam,
    queryFn: getCustomers,
  });
};

export const useGetSalesHistory = (queryName) => {
  return useQuery({
    queryKey: queryName,
    queryFn: getSalesHistory,
  });
};

export const useInsertCustomer = (queryName, onSuccess) => {
  return useMutation({
    mutationKey: queryName,
    mutationFn: insertCustomer,
    onSuccess: onSuccess,
  });
};

export const useDeleteCustomer = (queryName, onSuccess) => {
  return useMutation({
    mutationKey: queryName,
    mutationFn: deleteCustomer,
    onSuccess: onSuccess,
  });
};

export const useEditCustomer = (queryName, onSuccess) => {
  return useMutation({
    mutationKey: queryName,
    mutationFn: editCustomer,
    onSuccess: onSuccess,
  });
};
