import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Autocomplete, TextField } from "@mui/material";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import _ from "lodash";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import CollapsiblePanel from "../CollapsiblePanel";
import SubProductForm from "../SubProductForm";
import { fields } from "./config";
import "./style.scss";

const ProductForm = ({ form, product, updateMode, ...props }) => {
  const { t } = useTranslation();
  const skusListRef = useRef();
  useEffect(() => {
    if (updateMode) {
      // console.log(product)
      props.setAutoCompleteFields({
        category: product.category,
        colour: product.colour,
        location: product.location,
      });
      form.setFieldsValue({
        name: product.name,
        category: _.has(product, "category") ? product.category : null,
        colour: _.has(product, "colour") ? product.colour : null,
        location: _.has(product, "location") ? product.location : null,
        skus: product?.skus?.map((sku) => {
          return {
            thickness: _.has(sku, "thickness") ? sku.thickness : null,
            thicknessUnit: _.has(sku, "thicknessUnit")
              ? sku.thicknessUnit
              : null,
            quality: _.has(sku, "quality") ? sku.quality : null,
            finish: _.has(sku, "finish") ? sku.finish : null,
            price: _.has(sku, "price") ? sku.price : null,
            currencyCode: _.has(sku, "currencyCode") ? sku.currencyCode : null,
          };
        }),
      });
    } else {
      form.setFieldsValue({
        skus: [
          {
            thickness: "2",
            thicknessUnit: "in",
            quality: "Premium",
            finish: "Aged",
            price: "100",
            currencyCode: "US Dollar",
          },
        ],
      });
      skusListRef.current && skusListRef.current.add();
    }
  }, []);

  return (
    <Space direction="vertical" align="center">
      <Form form={form} layout="vertical">
        <Row gutter={[12, 12]}>
          {_.map(fields, (field) => (
            <Col span={field.column} key={field.name}>
              {/* {console.log(field)} */}
              <Form.Item name={field.name}>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder={t(field.label)}
                  name={field.name}
                  required={_.has(field, "required") ? true : false}
                  label={t(field.label)}
                  onChange={(e) => {
                    e.preventDefault();
                    props.setAutoCompleteFields((prev) => ({
                      ...prev,
                      productName: e.target.value,
                    }));
                  }}
                  // bordered={false}
                />
              </Form.Item>
              <Row gutter={[12, 12]}>
                {_.map(field.subfields, (subfield) => (
                  <Col span={subfield.column} key={subfield.name}>
                    {/* <Form.Item
                      name={subfield.name}
                      label={updateMode && t(subfield.label)}
                    > */}
                    <Autocomplete
                      options={subfield.options.map((option) => ({
                        label: option.label,
                      }))}
                      getOptionLabel={(option) => option.label}
                      defaultValue={{
                        label:
                          t(subfield.label) === "Category"
                            ? product.category === "" ||
                              product.category === undefined
                              ? "Select"
                              : product.category
                            : t(subfield.label) === "Colour"
                            ? product.colour === "" ||
                              product.colour === undefined
                              ? "Select"
                              : product.colour
                            : product.location === "" ||
                              product.category === undefined
                            ? "Select"
                            : product.location,
                      }}
                      onChange={(event, newValue) => {
                        if (t(subfield.label) === "Category") {
                          props.setAutoCompleteFields((prev) => ({
                            ...prev,
                            category: newValue ? newValue.label : "Select",
                          }));
                        } else if (t(subfield.label) === "Colour") {
                          props.setAutoCompleteFields((prev) => ({
                            ...prev,
                            colour: newValue ? newValue.label : "Select",
                          }));
                        } else {
                          props.setAutoCompleteFields((prev) => ({
                            ...prev,
                            location: newValue ? newValue.label : "Select",
                          }));
                        }
                      }}
                      id="combo-box-demo"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t(subfield.label)}
                          required
                        />
                      )}
                    />
                    {/* <Select
                        options={subfield.options}
                        placeholder={!updateMode ? t(subfield.label) : ""}
                        bordered={false}
                      /> */}
                    {/* </Form.Item> */}
                  </Col>
                ))}
              </Row>
            </Col>
          ))}
          <Col span={24}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Form.List
                  name="skus"
                  ref={(ref) => {
                    skusListRef.current = ref;
                  }}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <CollapsiblePanel
                          key={key}
                          panelProps={{
                            header: "Slab",
                            key: key,
                            style: {
                              backgroundColor: "#f7faf8",
                            },
                            extra: (
                              <DeleteOutlined onClick={() => remove(name)} />
                            ),
                          }}
                          collapseProps={{
                            size: "small",
                            style: {
                              marginBottom: 8,
                              backgroundColor: "transparent",
                              borderRadius: "10px",
                            },
                            key: key,
                            useKey: true,
                          }}
                          title={product.name}
                        >
                          <SubProductForm
                            formItemName={name}
                            formItemFields={restField}
                          />
                        </CollapsiblePanel>
                      ))}
                      <Form.Item style={{ marginBottom: 12 }}>
                        <Button
                          type="link"
                          className="submitBtnSales"
                          onClick={() => {
                            form.setFieldsValue({
                              skus: [
                                ...form.getFieldValue("skus"),
                                {
                                  thickness: 2,
                                  thicknessUnit: "in",
                                  quality: "Premium",
                                  finish: "Aged",
                                  price: 100,
                                  currencyCode: "US Dollar",
                                },
                              ],
                            });
                            skusListRef.current && skusListRef.current.add();
                          }}
                          icon={<PlusCircleOutlined />}
                          style={{ position: "absolute", right: 0, top: 0 }}
                        >
                          Add Slab
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Space>
  );
};

export default ProductForm;
