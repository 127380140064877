import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Check,
  CheckOutlined,
  CloseOutlined,
  NotesOutlined,
} from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import Slider from "../../components/Common/MediaSlider/MediaSlider";
import { Popup, usePopup } from "../../components/CreatePopup";
import EmptyNotesForm from "../../components/EmptyNotesForm";
import InventoryImageForm from "../../components/InventoryImageForm";
import SuccessComponent from "../../components/SuccessComponent";
import { useUpdateSlabDetails } from "../../queries/react-query/inventory";
import { inventoryConstants } from "../../utils/inventory";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#2c3fff",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#2c3fff",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#2c3fff",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#2c3fff",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

export default function InventorySlab(props) {
  const { t } = useTranslation();
  const { openPopup, togglePopup } = usePopup(false);
  const [notesOf, setNotesOf] = useState([]);
  const [edit, setEdit] = useState(false);
  const [popUpContent, setPopUpContent] = useState(1);
  const [editValue, setEditValue] = useState({});
  const [loadingState, setLoadingState] = useState(false);
  const [addImageTo, setAddImageTo] = useState({
    bundle: "-1",
    slab: "-1",
    block: "-1",
  });
  const [blockDisabled, setBlockDisabled] = useState([]);
  const [updateImages, setUpdateImages] = useState(false);
  const [imageAddedCheck, setImageAddedCheck] = useState([]);
  const [addImageSuccess, setImageSuccess] = useState(false);
  const [stepImage, setStepImage] = useState(0);
  const finish = inventoryConstants.finishTypes;
  const quality = inventoryConstants.qualityLevels;
  const thicknessMetric = inventoryConstants.thicknessUnit;
  const currency = inventoryConstants.currencies;
  const queryClient = useQueryClient();
  const updateSlabSuccess = () => {
    setLoadingState(false);
    togglePopup();
    setPopUpContent(2);
    setEdit(false);
    setTimeout(() => {
      queryClient.refetchQueries([
        "bundles",
        props.productId,
        props.currentBundleNumber,
      ]);
    }, [3000]);
  };
  const updateSlabDetails = useUpdateSlabDetails(
    ["bundles", props.productId, props.currentBundleNumber],
    updateSlabSuccess
  );
  const handleNotesClick = (record) => {
    localStorage.setItem("slabId", record.id);
    setNotesOf(record.notes ? record.notes : []);
    togglePopup();
  };

  const [images, setImages] = useState(
    props.currentSlab.images === undefined ||
      props.currentSlab.images.length === 0
      ? []
      : props.currentSlab.images
  );

  useEffect(() => {}, [props.currentSlab]);
  return (
    <Content style={{ width: "100%" }}>
      <Divider style={{ marginBottom: "2vh" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
        }}
      >
        <ArrowBackIcon
          style={{ color: "#0c25f3", width: "15%" }}
          onClick={() => {
            props.setComponentNumber(3);
          }}
        />
        <p
          className="bundleNameHeader"
          style={{ width: "70%", textAlign: "center", marginBottom: "0" }}
        >
          {t("slabHeader.slab")}
        </p>
        <div style={{ width: "15%" }}></div>
      </div>
      <Divider style={{ marginTop: "2vh", marginBottom: "2vh" }} />

      <Box
        sx={{
          maxWidth: "100%",
          flexGrow: 1,
          marginTop: "3vh",
          marginLeft: "3vw",
          marginRight: "3vw",
        }}
      >
        <Slider slides={images} />
      </Box>
      <div style={{ textAlign: "left", marginLeft: "4vw" }}>
        <Button
          className="addImageBtn"
          onClick={() => {
            setPopUpContent(3);
            setAddImageTo({
              bundle: props.currentBundleNumber,
              slab: props.currentSlab.slabNumber,
              block: props.currentSlab.blockNumber,
            });
            setStepImage(0);
            togglePopup();
          }}
        >
          {t("bundleHeader.addImage")}
        </Button>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "4vw",
          paddingRight: "4vw",
        }}
      >
        <div style={{ width: "100%" }}>
          <p className="SlabNumberHeader" style={{ marginBottom: "0" }}>{`${t(
            "slabHeader.slabNo"
          )} ${props.currentSlab.slabNumber}`}</p>
          <p className="BundleNumberHeader" style={{ marginBottom: "0" }}>{`${t(
            "bundleHeader.bundleNo1"
          )} ${props.currentBundleNumber}`}</p>
          <p style={{ marginBottom: "0" }} className="productNameInBundleView">
            {props.productId}
          </p>
        </div>
        <p
          style={{
            marginBottom: 0,
            textTransform: "uppercase",
            fontWeight: 600,
            fontSize: "large",
            color:
              props.currentSlab.status === "HOLD"
                ? "orange"
                : props.currentSlab.status === "AVAILABLE"
                ? "green"
                : "red",
          }}
        >
          {props.currentSlab.status}
        </p>
      </div>
      {/* <Divider style={{marginTop:"2vh", marginBottom:"2vh"}}/> */}
      <div
        style={{
          width: "100%",
          paddingTop: "1vh",
          padding: "1vh 3vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          paddingBottom: "6vh",
        }}
      >
        <div
          className="blueBoxSlab"
          style={{ width: "100%", textAlign: "center" }}
        >
          <p className="slabPropHeader">{t("slabHeader.blockNo")}</p>
          <p className="slabPropValue">
            {props.currentSlab.blockNumber
              ? props.currentSlab.blockNumber
              : "--"}
          </p>
        </div>
        <div
          className="blueBoxSlab"
          style={{ width: "45%", textAlign: "center" }}
        >
          <p className="slabPropHeader">{t("slabHeader.grossHeight")}</p>
          {edit ? (
            <TextField
              style={{ width: "70%" }}
              id="outlined-basic"
              label={t("slabHeader.grossHeight")}
              variant="outlined"
              defaultValue={editValue.grossHeight ? editValue.grossHeight : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  grossHeight: e.target.value,
                }));
              }}
            />
          ) : (
            <p className="slabPropValue">
              {props.currentSlab.grossHeight
                ? `${props.currentSlab.grossHeight} cm`
                : "--"}
            </p>
          )}
        </div>
        <div
          className="blueBoxSlab"
          style={{ width: "45%", textAlign: "center" }}
        >
          <p className="slabPropHeader">{t("slabHeader.grossWidth")}</p>
          {edit ? (
            <TextField
              style={{ width: "70%" }}
              id="outlined-basic"
              label={t("slabHeader.grossWidth")}
              variant="outlined"
              defaultValue={editValue.grossWidth ? editValue.grossWidth : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  grossWidth: e.target.value,
                }));
              }}
            />
          ) : (
            <p className="slabPropValue">
              {props.currentSlab.grossWidth
                ? `${props.currentSlab.grossWidth} cm`
                : "--"}
            </p>
          )}
        </div>
        <div
          className="blueBoxSlab"
          style={{ width: "45%", textAlign: "center" }}
        >
          <p className="slabPropHeader">{t("slabHeader.netHeight")}</p>
          {edit ? (
            <TextField
              style={{ width: "70%" }}
              id="outlined-basic"
              label={t("slabHeader.netHeight")}
              variant="outlined"
              defaultValue={editValue.netHeight ? editValue.netHeight : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  netHeight: e.target.value,
                }));
              }}
            />
          ) : (
            <p className="slabPropValue">
              {props.currentSlab.netHeight
                ? `${props.currentSlab.netHeight} cm`
                : "--"}
            </p>
          )}
        </div>
        <div
          className="blueBoxSlab"
          style={{ width: "45%", textAlign: "center" }}
        >
          <p className="slabPropHeader">{t("slabHeader.netWidth")}</p>
          {edit ? (
            <TextField
              style={{ width: "70%" }}
              id="outlined-basic"
              label={t("slabHeader.netWidth")}
              variant="outlined"
              defaultValue={editValue.netWidth ? editValue.netWidth : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditValue((prevState) => ({
                  ...prevState,
                  netWidth: e.target.value,
                }));
              }}
            />
          ) : (
            <p className="slabPropValue">
              {props.currentSlab.netWidth
                ? `${props.currentSlab.netWidth} cm`
                : "--"}
            </p>
          )}
        </div>
        <div
          className="blueBoxSlab"
          style={{
            width: "45%",
            textAlign: "center",
            paddingLeft: "4vw",
            paddingRight: "4vw",
          }}
        >
          <p className="slabPropHeader">{t("slabHeader.finish")}</p>
          {edit ? (
            <Autocomplete
              // className="slabAutocomplete"
              options={finish.map((option) => ({ label: option }))}
              getOptionLabel={(option) => option.label}
              defaultValue={{
                label: editValue.finish ? editValue.finish : "",
              }}
              sx={{ width: "100%" }}
              id="combo-box-demo"
              onChange={(event, newValue) => {
                setEditValue((prev) => ({
                  ...prev,
                  finish: newValue ? newValue.label : "Select",
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} label={t("slabHeader.finish")} />
              )}
            />
          ) : (
            <p className="slabPropValue">
              {props.currentSlab.finish ? props.currentSlab.finish : "--"}
            </p>
          )}
        </div>
        <div
          className="blueBoxSlab"
          style={{
            width: "45%",
            textAlign: "center",
            paddingLeft: "4vw",
            paddingRight: "4vw",
          }}
        >
          <p className="slabPropHeader">{t("slabHeader.quality")}</p>

          {edit ? (
            <Autocomplete
              // className="slabAutocomplete"
              options={quality.map((option) => ({ label: option }))}
              getOptionLabel={(option) => option.label}
              defaultValue={{
                label: editValue.quality ? editValue.quality : "",
              }}
              sx={{ width: "100%" }}
              id="combo-box-demo"
              onChange={(event, newValue) => {
                setEditValue((prev) => ({
                  ...prev,
                  quality: newValue ? newValue.label : "Select",
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} label={t("slabHeader.quality")} />
              )}
            />
          ) : (
            <p className="slabPropValue">
              {props.currentSlab.quality ? props.currentSlab.quality : "--"}
            </p>
          )}
        </div>
        <div
          className="blueBoxSlab"
          style={{ width: "100%", textAlign: "center" }}
        >
          <p className="slabPropHeader">{t("slabHeader.price")}</p>
          {edit ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 3vw",
              }}
            >
              <TextField
                style={{ width: "45%", marginRight: "2vw" }}
                id="outlined-basic"
                label={t("slabHeader.price")}
                variant="outlined"
                defaultValue={editValue.price ? editValue.price : ""}
                onChange={(e) => {
                  e.preventDefault();
                  setEditValue((prevState) => ({
                    ...prevState,
                    price: e.target.value,
                  }));
                }}
              />
              <Autocomplete
                // className="slabAutocomplete"
                options={currency.map((option) => ({ label: option }))}
                getOptionLabel={(option) => option.label}
                defaultValue={{
                  label: editValue.currencyCode ? editValue.currencyCode : "",
                }}
                sx={{ width: "45%" }}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setEditValue((prev) => ({
                    ...prev,
                    currencyCode: newValue ? newValue.label : "Select",
                  }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t("slabHeader.currency")} />
                )}
              />
            </div>
          ) : (
            <p className="slabPropValue">
              {props.currentSlab.price ? props.currentSlab.price : "--"}{" "}
              {props.currentSlab.currencyCode
                ? props.currentSlab.currencyCode
                : "USD"}
            </p>
          )}
        </div>
        <div
          className="blueBoxSlab"
          style={{ width: "100%", textAlign: "center" }}
        >
          <p className="slabPropHeader">{t("slabHeader.thickness")}</p>
          {edit ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 3vw",
              }}
            >
              <TextField
                style={{ width: "45%", marginRight: "2vw" }}
                id="outlined-basic"
                label={t("slabHeader.thickness")}
                variant="outlined"
                defaultValue={editValue.thickness ? editValue.thickness : ""}
                onChange={(e) => {
                  e.preventDefault();
                  setEditValue((prevState) => ({
                    ...prevState,
                    thickness: e.target.value,
                  }));
                }}
              />
              <Autocomplete
                // className="slabAutocomplete"
                options={thicknessMetric.map((option) => ({ label: option }))}
                getOptionLabel={(option) => option.label}
                defaultValue={{
                  label: editValue.thicknessUnit ? editValue.thicknessUnit : "",
                }}
                sx={{ width: "45%" }}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setEditValue((prev) => ({
                    ...prev,
                    thicknessUnit: newValue ? newValue.label : "Select",
                  }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t("slabHeader.unit")} />
                )}
              />
            </div>
          ) : (
            <p className="slabPropValue">
              {props.currentSlab.thickness ? props.currentSlab.thickness : "--"}{" "}
              {props.currentSlab.thicknessUnit
                ? props.currentSlab.thicknessUnit
                : ""}
            </p>
          )}
        </div>
      </div>
      <Tooltip>
        <Button
          style={{
            position: "fixed",
            right: "3vw",
            bottom: "2vh",
            width: "50px",
            height: "50px",
            fontSize: "18px",
            textAlign: "center",
          }}
          className="SlabNotes"
          shape="circle"
          onClick={() => {
            handleNotesClick(props.currentSlab);
            setPopUpContent(1);
          }}
          //   onClick={messageSeller}
        >
          <NotesOutlined />
        </Button>
      </Tooltip>
      <Tooltip>
        {edit ? (
          <div
            style={{
              position: "fixed",
              left: "3vw",
              bottom: "2vh",
            }}
          >
            <Button
              style={{
                width: "50px",
                height: "50px",
                fontSize: "18px",
                textAlign: "center",
                marginRight: "2vw",
              }}
              className="SlabEditConfirm"
              shape="circle"
              //   onClick={messageSeller}
              onClick={() => {
                setLoadingState(true);
                updateSlabDetails.mutate(editValue);
              }}
            >
              {loadingState ? (
                <LoadingOutlined style={{ fontSize: "x-large" }} />
              ) : (
                <CheckOutlined style={{ fontSize: "x-large" }} />
              )}
            </Button>
            <Button
              style={{
                width: "50px",
                height: "50px",
                fontSize: "18px",
                textAlign: "center",
              }}
              className="SlabEditCancel"
              shape="circle"
              //   onClick={messageSeller}
              onClick={() => {
                setEdit(false);
              }}
            >
              <CloseOutlined style={{ fontSize: "x-large" }} />
            </Button>
          </div>
        ) : (
          <Button
            style={{
              position: "fixed",
              left: "3vw",
              bottom: "2vh",
              width: "50px",
              height: "50px",
              fontSize: "18px",
              textAlign: "center",
            }}
            className="SlabNotes"
            shape="circle"
            //   onClick={messageSeller}
            onClick={() => {
              setEditValue({
                entityId: props.currentSlab.id,
                slabNumber: props.currentSlab.slabNumber,
                blockNumber: props.currentSlab.blockNumber,
                status: props.currentSlab.status,
                netHeight: props.currentSlab.netHeight,
                netWidth: props.currentSlab.netWidth,
                grossHeight: props.currentSlab.grossHeight,
                grossWidth: props.currentSlab.grossWidth,
                finish: props.currentSlab.finish,
                quality: props.currentSlab.quality,
                price: props.currentSlab.price,
                priceMetric: props.currentSlab.currencyCode,
                thickness: props.currentSlab.thickness,
                thicknessMetric: props.currentSlab.thicknessUnit,
              });
              setEdit(true);
            }}
          >
            <EditOutlined style={{ fontSize: "x-large" }} />
          </Button>
        )}
      </Tooltip>
      <Popup
        title={popUpContent === 1 ? t("tooltip.addNotes") : ""}
        openPopup={openPopup}
        togglePopup={togglePopup}
      >
        {popUpContent === 1 ? (
          <EmptyNotesForm onClosePopup={togglePopup} data={notesOf} />
        ) : popUpContent === 2 ? (
          <SuccessComponent detail={"Slab updated successfully!!"} />
        ) : addImageSuccess ? (
          <SuccessComponent detail={"Image added successfully!!"} />
        ) : (
          <div style={{ width: "100%" }}>
            <Stepper
              sx={{ marginBottom: "2vh" }}
              activeStep={stepImage}
              alternativeLabel
              connector={<QontoConnector />}
            >
              <Step>
                <StepLabel
                  className="stepperName"
                  StepIconComponent={QontoStepIcon}
                >
                  Select Image(s)
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  className="stepperName"
                  StepIconComponent={QontoStepIcon}
                >
                  Review Selection
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  className="stepperName"
                  StepIconComponent={QontoStepIcon}
                >
                  Upload Image
                </StepLabel>
              </Step>
            </Stepper>
            <Divider
              type="horizontal"
              style={{ backgroundColor: "#656564", marginBottom: "2vh" }}
            />
            <div style={{ width: "100%" }}>
              <InventoryImageForm
                step={stepImage}
                setStep={setStepImage}
                addImageTo={addImageTo}
                setAddImageTo={setAddImageTo}
                inventory={{ product: { name: props.productId } }}
                setBlockDisabled={setBlockDisabled}
                updateFlag={true}
                images={images}
                setImages={setImages}
                setUpdateImages={setUpdateImages}
                setImageAddedCheck={setImageAddedCheck}
                imageAddedCheck={imageAddedCheck}
                blockDisabled={blockDisabled}
                addCartSuccess={addImageSuccess}
                setAddCartSuccess={setImageSuccess}
                mobileView={true}
                togglePopup={togglePopup}
              />
            </div>
          </div>
        )}
      </Popup>
    </Content>
  );
}
