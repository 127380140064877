import Search from "@mui/icons-material/Search";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { throttle } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  PRODUCT_ROUTE,
  getEndPointFor,
  getHeaders,
} from "../utils/api_endpoints";

function AutoCompleteProduct({
  handleProductSelection,
  product,
  searchLabel,
  options,
  setOptions,
  children,
}) {
  const [inputValue, setInputValue] = useState("");
  const fetchOptions = useMemo(
    () =>
      throttle(async ({ headers, body, method, url }, callback) => {
        fetch(url, {
          headers,
          method,
          body,
        })
          .then((result) => result.json())
          .then((data) => callback(data));
      }, 500),
    []
  );
  useEffect(() => {}, [product, inputValue, fetchOptions]);
  const myFunc = async () => {
    let active = true;
    if (inputValue === "") {
      setOptions(product ? [product] : []);
      return undefined;
    }
    const headers = await getHeaders();
    fetchOptions(
      {
        url: `${getEndPointFor(PRODUCT_ROUTE.base, `/suggest`)}`,
        headers: headers,
        method: "POST",
        body: JSON.stringify({
          productNamePrefix: inputValue,
        }),
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  };
  return (
    <Autocomplete
      sx={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option && option.name
      }
      options={options}
      id="free-solo-with-text-demo"
      autoComplete
      freeSolo={true}
      includeInputInList
      filterSelectedOptions
      filterOptions={(x) => x}
      value={product ? product.name : ""}
      onChange={(event, newValue) => {
        // console.log(newValue)
        handleProductSelection(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => value && option.id === value.id}
      renderInput={(params) => {
        if (children) {
          return children;
        } else {
          return (
            <TextField
              {...params}
              style={{
                borderColor: "black",
              }}
              value={product ? product.name : ""}
              label={searchLabel}
              fullWidth
              onChange={myFunc}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          );
        }
      }}
    />
  );
}

export default AutoCompleteProduct;
