import { PlusCircleOutlined, UserOutlined } from "@ant-design/icons";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Input,
  Layout,
  Row,
  Space,
} from "antd";
import { getStates } from "country-state-picker";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomerConstants } from "../../utils/customers";
import AdditionalContact from "./AdditionalContact";
const { Content } = Layout;
export default function CustomerAdd(props) {
  const { t } = useTranslation();
  const [additionalDetails, setAdditionalDetails] = useState([]);
  const [additionalContact, setAdditionalContact] = useState([]);
  const [addValue, setAddValue] = useState({
    customerName: "",
    customerType: "",
    customerZone: "",
    sinceDate: "",
    contactName: "",
    phoneNumber: "",
    additionalContactList: [],
    currency: "",
    paymentTerms: "",
    paymentMethod: "",
    email: "",
    shareInventory: false,
    measurementSystem: "",
    incoTerms: "",
    shippingMode: "",
    address1: "",
    address2: "",
    shippingPort: "",
    zipCode: "",
    state: "",
    country: "",
  });
  useEffect(() => {}, [addValue, additionalDetails, additionalContact]);
  const measurementSystem = CustomerConstants.measurementSystem;
  const customerType = CustomerConstants.customerType;
  const customerZone = CustomerConstants.customerZone;
  const phoneNoCode = CustomerConstants.phoneNoCode;
  const currency = CustomerConstants.currency;
  const paymentTerms = CustomerConstants.paymentTerms;
  const paymentMethods = CustomerConstants.paymentMethod;
  const incoTerms = CustomerConstants.incoTerms;
  const shippingMode = CustomerConstants.shippingMode;
  const shippingPort = CustomerConstants.shippingPort;
  const country = CustomerConstants.country;
  const state = getStates(CustomerConstants.countryRef[addValue.country])
    ? getStates(CustomerConstants.countryRef[addValue.country])
    : [];
  const dateChange = (date, dateString) => {
    setAddValue((prevState) => ({
      ...prevState,
      sinceDate: dateString,
    }));
  };
  const goBack = () => {
    props.setAddClicked(false);
  };
  const submitClicked = () => {
    if (
      addValue.customerName === "" ||
      addValue.contactName === "" ||
      addValue.email === ""
    ) {
      alert("Please fill the required fields!!");
    } else {
      props.setCustomerLoading(true);
      setTimeout(() => {
        setAddValue((prevState) => ({
          ...prevState,
          additionalContactList: additionalContact,
        }));
        props.customerMutation.mutate(addValue);
        props.setAddClicked(false);
      }, [1000]);
    }
  };

  const handleDelete = (key) => {
    const updatedComponents = additionalDetails.filter(
      (component) => component.key !== key
    );
    setAdditionalDetails(updatedComponents);
  };
  const addContact = () => {
    const key = Date.now().toString();
    // setAdditionalContact([...additionalContact,])
    setAdditionalDetails([
      ...additionalDetails,
      <AdditionalContact
        key={key}
        key1={key}
        value={null}
        setAddValue={setAddValue}
        data={addValue}
        phoneNoCode={phoneNoCode}
        setAdditionalContact={setAdditionalContact}
        additionalContact={additionalContact}
        onDelete={() => handleDelete(key)}
      />,
    ]);
  };
  return (
    <Content style={{ width: "100%" }}>
      <Content
        style={{
          width: "100%",
          borderBottom:
            "0.5px solid var(--neutral-background-text-white-900, #797979)",
          paddingBottom: "4vh",
        }}
      >
        <h1 className="addEmployeeProfile">{t("customers.customerProfile")}</h1>
        <h1 className="subHeaderProfile">{t("customers.businessDetails")}</h1>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.registeredBusinessName")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ width: "30vw" }}
                id="outlined-basic"
                label={
                  <p
                    style={addValue.customerName === "" ? { color: "red" } : {}}
                  >
                    {t("customers.registeredBusinessName")}
                  </p>
                }
                variant="outlined"
                onChange={(e) => {
                  e.preventDefault();
                  setAddValue((prevState) => ({
                    ...prevState,
                    customerName: e.target.value,
                  }));
                }}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.customerType")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={customerType.map((option) => ({
                  label: option.label,
                }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setAddValue((prev) => ({
                    ...prev,
                    customerType: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.customerType")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.customerZone")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={customerZone.map((option) => ({ label: option }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setAddValue((prev) => ({
                    ...prev,
                    customerZone: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.customerZone")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.sinceDate")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  sx={{ width: "30vw" }}
                  defaultValue={dayjs()}
                  onChange={(date) => {
                    if (date) {
                      setAddValue((prevState) => ({
                        ...prevState,
                        sinceDate: `${date.date()}-${
                          date.month() + 1
                        }-${date.year()}`,
                      }));
                    }
                  }}
                />
              </LocalizationProvider>
            </Content>
          </Content>
        </Content>
      </Content>
      <Content
        style={{
          width: "100%",
          paddingTop: "2vh",
          borderBottom:
            "0.5px solid var(--neutral-background-text-white-900, #797979)",
          paddingBottom: "4vh",
        }}
      >
        <h1 className="subHeaderProfile">{t("customers.contactDetails")}</h1>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.contactName")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ width: "30vw" }}
                id="outlined-basic"
                label={
                  <p
                    style={addValue.contactName === "" ? { color: "red" } : {}}
                  >
                    {t("customers.contactName")}
                  </p>
                }
                variant="outlined"
                onChange={(e) => {
                  e.preventDefault();
                  setAddValue((prevState) => ({
                    ...prevState,
                    contactName: e.target.value,
                  }));
                }}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.phoneNo")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <Space>
                <FormControl sx={{ width: "5vw" }}>
                  <InputLabel id="demo-simple-select-label">Code</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Code"
                    onChange={(e) => {
                      if (addValue.phoneNumber === undefined) {
                        setAddValue((prevState) => ({
                          ...prevState,
                          phoneNumber: e.target.value,
                        }));
                      } else {
                        const firstWord = addValue.phoneNumber.split(" ");
                        if (firstWord.length === 1)
                          setAddValue((prevState) => ({
                            ...prevState,
                            phoneNumber: e.target.value,
                          }));
                        else
                          setAddValue((prevState) => ({
                            ...prevState,
                            phoneNumber: `${e.target.value} ${firstWord[1]}`,
                          }));
                      }
                    }}
                  >
                    {phoneNoCode.map((phoneNumber, i) => {
                      return (
                        <MenuItem key={i} value={phoneNumber.value}>
                          {phoneNumber.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label={t("customers.phoneNo")}
                  variant="outlined"
                  sx={{ width: "25vw" }}
                  onChange={(e) => {
                    e.preventDefault();
                    const firstWord = addValue.phoneNumber.split(" ");

                    setAddValue((prevState) => ({
                      ...prevState,
                      phoneNumber: `${firstWord[0]} ${e.target.value}`,
                    }));
                  }}
                />
              </Space>
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.email")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ width: "30vw" }}
                id="outlined-basic"
                label={
                  <p style={addValue.email === "" ? { color: "red" } : {}}>
                    {t("customers.email")}
                  </p>
                }
                variant="outlined"
                onChange={(e) => {
                  e.preventDefault();
                  setAddValue((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }));
                }}
              />
            </Content>
          </Content>
        </Content>
        <Content
          style={{
            width: "100%",
            paddingBottom: "2vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            className="inputHeader"
            style={{ width: "15vw", marginBottom: "0" }}
          >
            {t("customers.shareInventory")}
          </p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <FormControl style={{ marginLeft: "-23vw" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="false"
                  name="radio-buttons-group"
                  onChange={(event) => {
                    const newValue = event.target.value === "true"; // Convert string to boolean
                    setAddValue((prevState) => ({
                      ...prevState,
                      shareInventory: newValue,
                    }));
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Content>
          </Content>
        </Content>
      </Content>
      <Content
        style={{
          width: "100%",
          borderBottom:
            "0.5px solid var(--neutral-background-text-white-900, #797979)",
          paddingBottom: "4vh",
        }}
      >
        <h1 className="subHeaderProfile">
          {t("customers.additionalContactDetails")}
        </h1>
        {additionalDetails.map((component) => component)}
        <Content
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            paddingRight: "2vw",
          }}
        >
          <Button
            type="link"
            className="additionalContactBtn"
            onClick={addContact}
            icon={<PlusCircleOutlined />}
          >
            {t("customers.addAdditionalContact")}
          </Button>
        </Content>
      </Content>
      <Content
        style={{
          width: "100%",
          borderBottom:
            "0.5px solid var(--neutral-background-text-white-900, #797979)",
          paddingBottom: "4vh",
        }}
      >
        <h1 className="subHeaderProfile">{t("customers.purchasing")}</h1>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.currency")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={currency.map((option) => ({ label: option.label }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setAddValue((prev) => ({
                    ...prev,
                    currency: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.currency")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.paymentTerms")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={paymentTerms.map((option) => ({
                  label: option.label,
                }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setAddValue((prev) => ({
                    ...prev,
                    paymentTerms: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.paymentTerms")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.paymentMethod")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={paymentMethods.map((option) => ({
                  label: option.label,
                }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setAddValue((prev) => ({
                    ...prev,
                    paymentMethod: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.paymentMethod")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.measurementSystem")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={measurementSystem.map((option) => ({ label: option }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setAddValue((prev) => ({
                    ...prev,
                    measurementSystem: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("customers.measurementSystem")}
                  />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.incoTerms")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={incoTerms.map((option) => ({ label: option.label }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setAddValue((prev) => ({
                    ...prev,
                    incoTerms: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.incoTerms")} />
                )}
              />
            </Content>
          </Content>
        </Content>
      </Content>
      <Content
        style={{
          width: "100%",
          borderBottom:
            "0.5px solid var(--neutral-background-text-white-900, #797979)",
          paddingBottom: "4vh",
        }}
      >
        <h1 className="subHeaderProfile">{t("customers.shippingDetails")}</h1>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.shippingMode")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={shippingMode.map((option) => ({
                  label: option.label,
                }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setAddValue((prev) => ({
                    ...prev,
                    shippingMode: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.shippingMode")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.shippingPort")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={shippingPort.map((option) => ({ label: option }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setAddValue((prev) => ({
                    ...prev,
                    shippingPort: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.shippingPort")} />
                )}
              />
            </Content>
          </Content>
        </Content>
      </Content>
      <Content style={{ width: "100%", paddingBottom: "4vh" }}>
        <h1 className="subHeaderProfile">{t("customers.addressDetails")}</h1>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.addressLine1")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ width: "30vw" }}
                id="outlined-basic"
                label={t("customers.addressLine1")}
                variant="outlined"
                onChange={(e) => {
                  e.preventDefault();
                  setAddValue((prevState) => ({
                    ...prevState,
                    address1: e.target.value,
                  }));
                }}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.addressLine2")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ width: "30vw" }}
                id="outlined-basic"
                label={t("customers.addressLine2")}
                variant="outlined"
                onChange={(e) => {
                  e.preventDefault();
                  setAddValue((prevState) => ({
                    ...prevState,
                    address2: e.target.value,
                  }));
                }}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.zipCode")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content style={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ width: "30vw" }}
                id="outlined-basic"
                label={t("customers.zipCode")}
                variant="outlined"
                onChange={(e) => {
                  e.preventDefault();
                  setAddValue((prevState) => ({
                    ...prevState,
                    zipCode: e.target.value,
                  }));
                }}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.country")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={country.map((option) => ({ label: option }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setAddValue((prev) => ({
                    ...prev,
                    country: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("customers.country")} />
                )}
              />
            </Content>
          </Content>
        </Content>
        <Content style={{ width: "100%", paddingBottom: "2vh" }}>
          <p className="inputHeader">{t("customers.state")}</p>
          <Content style={{ width: "100%" }} className="ProfileRow">
            <Content
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={state.map((option) => ({ label: option }))}
                getOptionLabel={(option) => option.label}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setAddValue((prev) => ({
                    ...prev,
                    state: newValue ? newValue.label : "Select",
                  }));
                }}
                sx={{ width: "30vw" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("customers.state")}
                    disabled={addValue.country === "" ? true : false}
                  />
                )}
              />
            </Content>
          </Content>
        </Content>
      </Content>

      <Content
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "1vh 0 0 0",
        }}
      >
        <Button
          className="companyEditButton"
          shape="round"
          size="large"
          style={{ borderRadius: "100px", marginLeft: "2vw" }}
          onClick={goBack}
        >
          {t("globalBtn.cancel")}
        </Button>
        <Button
          className="contactSupplierBtn"
          shape="round"
          size="large"
          style={{
            borderRadius: "100px",
            marginRight: "2vw",
          }}
          onClick={submitClicked}
          loading={props.customerLoading}
          disabled={props.customerLoading}
        >
          {t("globalBtn.save")}
        </Button>
      </Content>
    </Content>
  );
}
