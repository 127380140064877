import { Col, Empty, Layout, Pagination, Row, Skeleton } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import Customer from "./Customer";
const { Content } = Layout;
export default function CustomerSearch(props) {
  const { t } = useTranslation();
  const pageChange = (page, pageSize) => {
    // console.log(val)
    localStorage.setItem("pageStart", (page - 1) * 10);
    localStorage.setItem("pageSize", 10);
    props.customerData.refetch();
  };
  return (
    <Content
      style={{
        padding: "24px 24px 0 24px",
        margin: 0,
        minHeight: 280,
        width: "85%",
      }}
    >
      {/* <Input
        className="searchBar"
        placeholder="Search customer"
        suffix={<SearchOutlined style={{ color: "#0066FF" }} />}
      /> */}
      {props.customerData.isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Row className="tableHeader">
            <Col
              span={props.searchView ? 7 : 6}
              style={{ paddingLeft: "3vw" }}
              className="salesHeaderList"
            >
              {`${t(`customers.companyName`)}`}
            </Col>
            <Col span={props.searchView ? 6 : 5} className="salesHeaderList">
              {`${t(`customers.customerType`)}`}
            </Col>
            <Col
              span={props.searchView ? 7 : 6}
              style={{ paddingLeft: props.searchView ? "2.7vw" : "2.7vw" }}
              className="salesHeaderList"
            >
              {`${t(`companyName.country`)}`}
            </Col>
            <Col
              span={props.searchView ? 4 : 5}
              style={
                props.searchView
                  ? { paddingLeft: "4.5vw" }
                  : { paddingLeft: "4vw" }
              }
              className="salesHeaderList"
            >
              {`${t(`customers.sinceDate`)}`}
            </Col>
          </Row>
          <Content
            className="ListViewSales"
            style={{
              height: "58vh",
              overflowY: "auto",
              width: "100%",
              overflowX: "hidden",
            }}
          >
            {props.customerData.data === null ||
            props.customerData.data === undefined ? (
              props.searchView ? (
                <Empty
                  style={{
                    width: "100%",
                    marginTop: "2vh",
                    fontFamily: "Roboto",
                  }}
                  description={<span>No results found</span>}
                />
              ) : (
                <Empty
                  style={{
                    width: "100%",
                    marginTop: "2vh",
                    fontFamily: "Roboto",
                  }}
                  description={<span>No results found</span>}
                />
              )
            ) : props.customerData.data.customerList.length === 0 ? (
              props.searchView ? (
                <Empty
                  style={{
                    width: "100%",
                    marginTop: "2vh",
                    fontFamily: "Roboto",
                  }}
                  description={<span>No results found</span>}
                />
              ) : (
                <Empty
                  style={{
                    width: "100%",
                    marginTop: "2vh",
                    fontFamily: "Roboto",
                  }}
                  description={<span>No results found</span>}
                />
              )
            ) : (
              props.customerData.data.customerList.map((customer, id) => {
                return (
                  <Customer
                    key={id}
                    setEditValue={props.setEditValue}
                    setEditClicked={props.setEditClicked}
                    deleteCustomerMutation={props.deleteCustomerMutation}
                    data={customer}
                    setUserDetails={props.setUserDetails}
                    stateClickChange={props.stateClickChange}
                    setDeleteClicked={props.setDeleteClicked}
                    setDeleteData={props.setDeleteData}
                    searchView={props.searchView}
                  />
                );
              })
            )}
          </Content>
          {props.searchView ? (
            <></>
          ) : (
            <Content
              style={{
                marginTop: "2vh",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Pagination
                current={localStorage.getItem("pageStart") / 10 + 1}
                total={props.customerData.data.totalItems}
                onChange={pageChange}
              />
            </Content>
          )}
        </>
      )}
    </Content>
  );
}
