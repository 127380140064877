const Filters = [
  {
    key: "OfferState",
    type: "radio",
    value: ["Active", "Cancelled"],
  },
];

const apiQueryKeys = {
  offersQueryKey: "offersList",
  cancelOfferQueryKey: "cancelOffer",
  getOffer: "getOffer",
};

export { Filters, apiQueryKeys };
