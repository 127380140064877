import styles from "./heroheader.module.scss";
export default function Heroheader({ headingText, subHeadingText }) {
  return (
    <div className={styles.heroHeaderContainer}>
      <div
        style={{
          width: "20%",
          paddingLeft: "4vw",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingRight: "1vw",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "23vh", // Adjust the height as needed
            width: "23vh",
            fontSize: "96px",
            color: "white",
            borderRadius: "500px",
            backgroundColor: "#dede02",
          }}
        >
          {headingText.split(" ")[0][0]}
          {headingText.split(" ")[1] ? headingText.split(" ")[1][0] : ""}
        </div>
      </div>

      <div style={{ width: "80%", paddingLeft: "2vw" }}>
        <h2 className="enterpriseName" style={{ color: "white" }}>
          {headingText}
        </h2>
        <div style={{ paddingLeft: "0.5vw", width: "100%" }}>
          <h6 className="subDetails" style={{ color: "white" }}>
            {subHeadingText}
          </h6>
        </div>
      </div>
    </div>
  );
}
