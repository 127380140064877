import { useMutation, useQuery } from "react-query";
import { editCompanyDetails, getCompanyDetails } from "../api/companyProfile";

export const useFetchCompanyProfile = (queryKey) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: getCompanyDetails,
  });
};

export const useEditCompanyProfile = (queryKey, onSuccess) => {
  return useMutation({
    mutationKey: queryKey,
    mutationFn: editCompanyDetails,
    onSuccess: onSuccess,
  });
};
