import { CheckCircleFilled, WarningOutlined } from "@ant-design/icons";
import { Modal } from "antd";

export default function ModalPopup({
  title,
  isError,
  isModalOpen,
  setIsModalPopupOpen,
  message,
}) {
  const handleOk = () => {
    setIsModalPopupOpen(false);
  };

  return (
    <>
      <Modal
        title={title}
        onOk={handleOk}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
        open={isModalOpen}
      >
        <div style={{ textAlign: "center" }}>
          {isError ? (
            <WarningOutlined
              style={{ fontSize: "400%", color: "red", marginBottom: "2vh" }}
            />
          ) : (
            <CheckCircleFilled
              style={{ fontSize: "400%", color: "green", marginBottom: "2vh" }}
            />
          )}
          <p
            style={{
              fontSize: "large",
              fontWeight: "bold",
              marginBottom: "1vh",
            }}
          >
            {message}
          </p>
        </div>
      </Modal>
    </>
  );
}
