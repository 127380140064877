import { Card, Divider } from "antd";
import _ from "lodash";
import { useState } from "react";

import { PictureFilled } from "@ant-design/icons";
import Slider from "../MediaSlider/MediaSlider";
import Slablistviewable from "./Slablistviewable";
import styles from "./product.module.scss";

export default function BundleInfo({ bundle }) {
  const [images, setImages] = useState(bundle.images);

  return (
    <Card
      type="inner"
      className={styles.bundleCard}
      title={`Bundle ${bundle.bundleNumber}`}
      extra={
        <PictureFilled
          style={{
            color: images.length > 0 ? "#0C25F3" : "#a2a3a5",
          }}
          onClick={() => {
            setImages(bundle.images);
          }}
        />
      }
    >
      <div className={styles.bundleInnerSection}>
        <div className={styles.bundleImageContainer}>
          <div>
            <Slider slides={images} />
          </div>
        </div>
        <Divider
          type="vertical"
          style={{
            height: "auto",
            backgroundColor: "#D3D3D3",
            marginLeft: "2vw",
            marginRight: "2vw",
          }}
        />
        <div className={styles.bundleSlabsInfoContainer}>
          <Slablistviewable slabList={bundle.slabList} setImages={setImages} />
        </div>
      </div>
    </Card>
  );
}
