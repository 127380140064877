import {
  CopyOutlined,
  DeleteOutlined,
  ExpandOutlined,
} from "@ant-design/icons";
import { Box } from "@mui/material";
import { Button, Divider, Layout, List, Skeleton, Space, Table } from "antd";
import format from "date-fns/format";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useCancelOffer, useGetOffers } from "../../queries/react-query/offers";
import { Filters, apiQueryKeys } from "../../utils/offers";
import CollapsiblePanel from "../CollapsiblePanel";
import Slider from "../Common/MediaSlider/MediaSlider";
import ModalPopup from "../Common/Popup/Popup";
import { ExpandableDrawer, useDrawer } from "../ExpandableDrawer";
import Filter from "../Filter";
import ImagePopUp from "../ImagePopUp";
import BundleCardSales from "./BundleCardSales";
import styles from "./offers.module.scss";

export default function Offers() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [offersList, setOffersList] = useState([]);
  const [offerId, setOfferId] = useState("");
  const [productInformationList, setProductInformationList] = useState([]);
  const [filterObject, setFilterObject] = useState({ offerState: "Active" });
  const [nextToken, setNextToken] = useState({}); // This object will be used to fetch the next set of offer list (pagination).
  const [activeStep, setActiveStep] = useState(0);
  const [images, setImages] = useState([]);
  const [imagePopUp, setImagePopUp] = useState(false);
  const [imagePopUpValue, setImagePopUpValue] = useState("");
  const [isModalPopupOpen, setIsModalPopupOpen] = useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState(
    "offers.offerDeleteSuccess"
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedSlab, setSelectedSlab] = useState([]);
  const [index, setIndex] = useState(0);
  const [drawerClick, setDrawerClick] = useState(0);
  const [viewing, setViewing] = useState({ bundle: "-1", slab: "-1" });
  const { openDrawer, setOpenDrawer, toggleDrawer, expand, setExpand } =
    useDrawer(false);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedImageSourceData, setSelectedImagesSourceData] = useState({}); //To store the parent object of selected image to be shown in the left side

  const copyOfferLink = async (offerLink) => {
    try {
      await navigator.clipboard.writeText(offerLink);
      setModalTitle("Clipboard status");
      setModalPopupMessage("offers.copiedLinkToClipboard");
      setIsModalPopupOpen(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const onOfferCancellationSuccess = () => {
    setModalTitle("Offer status");
    setModalPopupMessage("offers.offerDeleteSuccess");
    setIsModalPopupOpen(true);
    queryClient.refetchQueries([apiQueryKeys.offersQueryKey]);
  };

  const {
    data: offers,
    isLoading,
    isError,
  } = useGetOffers([
    apiQueryKeys.offersQueryKey,
    {
      status: filterObject.offerState.toUpperCase(),
      size: 10,
    },
  ]);

  const { mutate: cancelOffer } = useCancelOffer(
    [apiQueryKeys.cancelOfferQueryKey],
    onOfferCancellationSuccess
  );

  useEffect(() => {
    if (isError) {
      setModalPopupMessage("offers.offerDeleteFailure");
    }
  }, [isError]);

  useEffect(() => {
    if (!isLoading && offers && offers.offerList) {
      const offerListData = _.map(offers.offerList, (offer, idx) => ({
        key: idx + 1,
        offerNumber: offer.id,
        offerCreatedOn: format(new Date(offer.creationTimeInMs), "PPP"),
        productInformationList: offer.productInformationList,
        status: offer.status,
      }));

      setOffersList(offerListData ?? []);
      setNextToken(offers.nextToken);
    }
  }, [offers, isLoading]);

  const columns = [
    {
      title: "Offer number",
      dataIndex: "offerNumber",
      key: "offerNumber",
    },
    {
      title: "Offer created on",
      dataIndex: "offerCreatedOn",
      key: "offerCreatedOn",
    },
    {
      title: "Action",
      dataIndex: "offerActions",
      key: "offerActions",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="text"
            title={t("offers.viewDetails")}
            onClick={() => {
              setSelectedProductId(
                record.productInformationList.length > 0
                  ? record.productInformationList[0].productName
                  : ""
              );
              setSelectedImagesSourceData(
                record.productInformationList.length > 0
                  ? record.productInformationList[0].bundles[0]
                  : {}
              );
              setImages(
                record.productInformationList.length > 0
                  ? record.productInformationList[0].bundles[0].hasOwnProperty(
                      "images"
                    )
                    ? record.productInformationList[0].bundles[0].images
                    : []
                  : []
              );
              setProductInformationList(record.productInformationList);
              setOfferId(record.offerNumber);
              setOpenDrawer(true);
              setIndex(record.key - 1);
              setDrawerClick(0);
            }}
            icon={<ExpandOutlined />}
          />
          <Button
            type="text"
            title={t("offers.copyLinkToClipboard")}
            onClick={() => {
              copyOfferLink(
                window.location.hostname + "/offer/" + record.offerNumber
              );
            }}
            icon={<CopyOutlined />}
          />
          {record.status === "ACTIVE" && (
            <Button
              type="text"
              title={t("offers.cancel")}
              onClick={() => {
                cancelOffer({
                  offerId: record.offerNumber,
                  status: "CANCELLED",
                });
              }}
              icon={<DeleteOutlined />}
            />
          )}
        </Space>
      ),
    },
  ];

  const handleCloseDrawerCallBack = () => {};
  const handleDrawerExpand = (expanded) => {
    setExpand(expanded);
  };

  const handleItemsSelected = (data) => {
    setSelectedItems(data);
  };

  return (
    <Layout className={styles.offersLayout}>
      <Filter
        queryClient={queryClient}
        setFilterObject={setFilterObject}
        filterObject={filterObject}
        queryKey={apiQueryKeys.offersQueryKey}
        Filters={Filters}
      />

      <div
        style={{
          width: "85%",
          paddingRight: "3vw",
        }}
      >
        {isLoading && offersList.length < 1 ? (
          <Skeleton active />
        ) : (
          <Table
            style={{ width: "100%", marginTop: "2vh", borderRadius: "7px" }}
            columns={columns}
            pagination={false}
            dataSource={offersList}
            className={styles.offersTable}
          />
        )}

        {productInformationList.length > 0 && drawerClick === 0 && (
          <ExpandableDrawer
            toggleDrawer={toggleDrawer}
            openDrawer={openDrawer}
            closeDrawerCallback={handleCloseDrawerCallBack}
            title={""}
            expand={true}
            onDrawerExpand={handleDrawerExpand}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                height: "100%",
                alignItems: " flex-start",
                width: "100%",
              }}
            >
              {isLoading ? (
                <Skeleton active />
              ) : (
                <div style={{ width: "40%" }}>
                  <p
                    className="imageHeadingInventory"
                    style={{ marginTop: "10vh" }}
                  >
                    {viewing.bundle === "-1"
                      ? `Bundle ${productInformationList[0].bundles[0].bundleNumber}`
                      : viewing.slab === "-1"
                      ? `Bundle ${viewing.bundle}`
                      : `Bundle ${viewing.bundle} : Slab ${viewing.slab}`}
                  </p>
                  <Box sx={{ maxWidth: "100%", flexGrow: 1, marginTop: "3vh" }}>
                    <Slider
                      slides={images}
                      viewing={viewing}
                      setImages={setImages}
                      queryClient={queryClient}
                      productId={selectedProductId}
                      selectedImageSourceData={selectedImageSourceData}
                      allowDelete={false}
                    />
                  </Box>
                </div>
              )}
              <Divider
                type="vertical"
                style={{
                  height: "100%",
                  backgroundColor: "#D3D3D3",
                  marginLeft: "2vw",
                  marginRight: "2vw",
                }}
              />
              <div style={{ width: "55%" }}>
                <Space
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <h2 style={{ margin: 0, color: "#545454" }}>View List</h2>
                </Space>
                <div style={{ marginTop: "30px", width: "100%" }}>
                  <div direction="vertical" style={{ width: "100%" }} size={24}>
                    <div className="ListViewInner">
                      {productInformationList.map((data, i) => {
                        return (
                          <CollapsiblePanel
                            key={i}
                            panelProps={{
                              header: data.productName,
                              key: "1",
                            }}
                            collapseProps={{
                              ghost: true,
                              size: "large",
                              style: { marginBottom: "2vh" },
                              key: data.productName,
                              className: "SalesProductCollapse",
                            }}
                            title={data.productName}
                          >
                            <List
                              itemLayout="horizontal"
                              style={{
                                marginTop: "10px",
                                width: "100%",
                              }}
                            >
                              <List.Item
                                style={{
                                  width: "96%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  paddingLeft: "18px",
                                  marginBottom: "0",
                                }}
                              >
                                <Space className="tableHeader1">
                                  {t("bundleHeader.bundleNo")}
                                </Space>
                                <Space
                                  style={{ marginLeft: "-1vw" }}
                                  className="tableHeader1"
                                >
                                  {t("bundleHeader.slabInStock")}
                                </Space>
                                <Space
                                  style={{ marginLeft: "-1.5vw" }}
                                  className="tableHeader1"
                                >
                                  {t("bundleHeader.totalPrice")}
                                </Space>
                                <Space
                                  style={{ marginLeft: "-1vw" }}
                                  className="tableHeader1"
                                >
                                  {t("bundleHeader.quality")}
                                </Space>
                                <Space
                                  style={{ marginLeft: "-0.5vw" }}
                                  className="tableHeader1"
                                >
                                  {t("bundleHeader.finish")}
                                </Space>
                                <Space className="tableHeader1">
                                  {t("bundleHeader.image")}
                                </Space>
                              </List.Item>
                            </List>
                            {data.bundles.map((bundle, j) => {
                              return (
                                <BundleCardSales
                                  key={bundle}
                                  bundles={bundle}
                                  bundleIndex={j}
                                  productId={data.productName}
                                  setSelectedProductId={setSelectedProductId}
                                  expand={expand}
                                  onDrawerExpand={handleDrawerExpand}
                                  selectedItems={selectedItems}
                                  onItemsSelected={handleItemsSelected}
                                  setImagePopUp={setImagePopUp}
                                  setImagePopUpValue={setImagePopUpValue}
                                  setSelectedSlab={setSelectedSlab}
                                  selectedSlab={selectedSlab}
                                  setImages={setImages}
                                  images={images}
                                  viewing={viewing}
                                  setViewing={setViewing}
                                  setActiveStep={setActiveStep}
                                  entityId={null}
                                  value={3}
                                  offerId={offerId}
                                  setSelectedImagesSourceData={
                                    setSelectedImagesSourceData
                                  }
                                />
                              );
                            })}
                          </CollapsiblePanel>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {imagePopUp ? (
              <>
                <ImagePopUp
                  setImagePopUp={setImagePopUp}
                  imagePopUpValue={imagePopUpValue}
                />
              </>
            ) : (
              <></>
            )}
          </ExpandableDrawer>
        )}
      </div>

      <ModalPopup
        title={modalTitle}
        isModalOpen={isModalPopupOpen}
        isError={isError}
        setIsModalPopupOpen={setIsModalPopupOpen}
        message={t(modalPopupMessage)}
      />
    </Layout>
  );
}
